import styled from "styled-components";
import {
  Menu as MenuAnt,
  Modal as ModalAnt,
  Button as ButtonAnt,
  Row as RowAnt,
  Col as ColAnt,
  Input as InputAnt,
  Form as FormAnt,
} from "antd";
import Upload from "../../../components/Upload";

interface IPropsUpload {
  disableUploadField?: boolean;
}

export const Modal = styled(ModalAnt)<IPropsUpload>`
  .ant-modal-body {
    overflow: auto;
  }

  input {
    height: 3.25rem;
  }

  .ant-form-vertical .ant-form-item-label > label {
    color: var(--gray-45);
  }
  .ant-modal-footer button + button {
    margin-left: 0;
    width: 100%;
  }
  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.25rem;
  }
  .uploadTamMaxText {
    display: none;
  }
  .containerUpload {
    height: 3.25rem;
  }
  @media only screen and (max-width: 950px) {
    .ant-form-vertical .ant-form-item-label > label {
      height: 2.5rem;
    }
  }
  @media only screen and (max-width: 575px) {
    .ant-modal-body {
      max-height: 15rem;
      padding-top: 0;
    }
    .ant-modal-footer {
      background: none;
    }
    .ant-modal-footer button + button {
      margin: 0;
    }
    .ant-form-vertical .ant-form-item-label > label {
      height: 1rem;
    }
  }
`;

export const ModalTitle = styled.h1`
  font-size: 2.5rem;

  @media only screen and (max-width: 578px) {
    font-size: 1.75rem;
  }
`;

export const ButtonCancelModal = styled(ButtonAnt)`
  height: 3.25rem;
  border: none;
  box-shadow: none;
  margin-top: 0;

  @media only screen and (max-width: 578px) {
    width: 100%;
    border-radius: 5px;
    width: 100%;
    color: var(--gray-45);
    border: 1px solid var(--gray-45);
    background-color: white;
    margin-top: 1rem;
    margin-left: 8px;
  }
`;

export const SaveButton = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  width: 11.3rem;
  height: 3.25rem;

  color: white;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;

  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--orange-350);

  @media only screen and (max-width: 578px) {
    width: 100%;
    color: var(--orange-350);
    border: 1px solid var(--orange-350);
    color: white;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const ContentModalBody = styled(RowAnt)``;

export const ColModal = styled(ColAnt)`
  font-weight: 600;
  padding: 2px;
`;

export const Input = styled(InputAnt)`
  height: 3.25rem;
`;

export const Form = styled(FormAnt)``;

export const FormUpload = styled(FormAnt.Item)`
  .dropzone {
    border: 1px dashed var(--gray-500);
  }
`;

export const ContainerUpload = styled.div`
  width: 100%;
`;
export const UploadWaste = styled(Upload)`
  width: 100%;
`;
