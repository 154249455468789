import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  border-radius: 8px;
  margin-top: 10px;
`;

export const ProgressBarWithCPF = styled.div<{ percentWithCPF: number; bothPresent: boolean }>`
  flex: ${(props) => props.percentWithCPF};
  height: 100%;
  background: var(--green-400);
  opacity: 50%;
  border-radius: ${(props) => (props.bothPresent ? '8px 0 0 8px' : '8px')};
`;

export const ProgressBarWithoutCPF = styled.div<{ percentWithoutCPF: number; bothPresent: boolean }>`
  flex: ${(props) => props.percentWithoutCPF};
  height: 100%;
  background: var(--orange-5);
  border-radius: ${(props) => (props.bothPresent ? '0 8px 8px 0' : '8px')};
`;
