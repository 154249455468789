import styled from "styled-components";

import {
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Modal as ModalAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
} from "antd";
import { Add } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const ContentRadio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;

  margin-left: .5rem;

  label {
    display: block;
  }
`;

export const RadioItem = styled.div`
  display: flex;
`;

export const TopSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  width: 100%;
  padding: 0;
  min-height: 3.5rem;
  .ant-input {
    height: 3.25rem;
  }
  @media only screen and (max-width: 575px) {
    height: 8rem;
  }
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const RowModal = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Col = styled(ColAnt)`
  display: flex; 
  justify-content: end;

  @media only screen and (max-width: 575px) {
    margin-bottom: 0.2rem;
  }
`;

export const Input = styled(InputAnt)`
  border-radius: 0.2rem;
`;

export const ButtonAddAnt = styled(ButtonAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--orange-gradient);

  border: 1px solid var(--orange-gradient);
  border-radius: 5px;

  width: 3.25rem;
  height: 3.25rem;

  cursor: pointer;

  :hover,
  :active,
  :enabled {
    background: var(--orange-gradient);
    border: 1px solid var(--orange-gradient);
    color: white;
    opacity: 80%;
  }
`;

export const ButtonAdd = styled(ButtonAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--orange-350);

  border: 1px solid var(--orange-gradient);
  border-radius: 5px;

  height: 3.25rem;

  cursor: pointer;

  :hover,
  :active,
  :enabled {
    background: var(--orange-350);
    border: 1px solid var(--orange-gradient);
    color: white;
    opacity: 80%;
  }

`;
export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    padding: 3% 3% 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30%;
  margin-bottom: 1rem;
  h1 {
    color: var(--blue-999);
  }
`;

export const ModalBody = styled.div`
  overflow: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4rem;
  width: 100%;
  padding: 1rem 2%;
  .button-cancel {
    margin-right: 5%;
    font-weight: 500;
  }
`;

export const ButtonAdd2 = styled(ButtonAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: var(--orange-350);

  border: 1px solid var(--orange-gradient);
  border-radius: 5px;

  height: 3.25rem;
  width: 7rem;

  cursor: pointer;

  :hover,
  :active,
  :enabled {
    background: var(--orange-350);
    border: 1px solid var(--orange-gradient);
    color: white;
    opacity: 80%;
  }
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
`;

export const AddIcon = styled(Add)`
  width: 25px;
  height: 25px;
`;

export const Label2 = styled.h1`
  margin: 10px;
`;

export const LabelInfo = styled.h3`
  margin-left: 3rem;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const HeaderModal = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const Spin = styled.img``;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const ButtonOpenFranchiseeModal = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--orange-350);
  color: var(--white);
  border: none;
  border-radius: .5rem;
  height: 3.2rem;

  :hover, :focus {
    background-color: var(--orange-350);
    color: var(--white);
  }
`;