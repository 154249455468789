export enum PaymentType {
  Dinheiro = 0,
  C_Crédito = 1,
  C_Débito = 2,
  Ticket = 3,
  Online = 4,
  Boleto = 5,
  Pix = 6,
  Transferência = 7,
}
