import styled from "styled-components";

import { Modal as ModalAnt, Table as TableAnt } from "antd";

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    max-height: 20rem;
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    margin-top: 1rem;
    height: 5.25rem;
    padding: 1rem;
    button {
      width: 12rem;
      height: 3.25rem;

      :hover,
      :focus {
        background: var(--orange-350);
      }
    }
  }
`;

export const Spin = styled.img``;

export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    overflow-y: scroll;
    max-height: 19rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: unset;
  }

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
  }
`;
