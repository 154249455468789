import React, { useEffect, useState } from 'react';

import { Drawer, Empty, Modal, Spin, Tooltip, message } from 'antd';

import PageContainer from '../../containers/PageContainer';
import Centralizer from '../../containers/Centralizer';
import RouterList from '../../containers/RouterList';
import NewRouteModal from '../../containers/RouterList/Modals/NewRouteModal';
import Trucks from '../../containers/Trucks';

import { getTokenInfo } from '../../services/auth';
import apiAuth from '../../services/apiAuth';
import api from '../../services/api';

import { DeliveryRoute } from '../../models/DeliveryRoute';
import { User } from '../../models/User/User';
import { ITruck } from '../../models/Truck';
import { EmptyContent } from '../Waste/styles';
import {
    Container,
    Header,
    Content,
    Row,
    Title,
    Button,
    FilterIcon,
    AddIcon,
    ColHeader,
    TruckIcon,
    Checkbox,
    FilterText,
    Dropdown,
    Menu,
    MenuItem
} from './styles';
import { Status } from '../../models/enums/cargoType';

const DeliveryRoutes = () => {
    const [store, setStore] = useState<number | null | undefined>();
    const [stores, setStores] = useState<
        { key: number; value: string; cnpj: string }[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [loadingStores, setLoadingStores] = useState(true);
    const [shouldSearch, setShouldSearch] = useState(false);
    const [shouldLoad, setShouldLoad] = useState(false);
    const [routes, setRoutes] = useState<DeliveryRoute[]>([]);
    const [visible, setVisible] = useState(false);
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [visibleNewRoute, setVisibleNewRoute] = useState(false);

    const [visibleAddTruck, setVisibleAddTruck] = useState(false);

    const [drivers, setDrivers] = useState<User[]>([])

    const [trucks, setTrucks] = useState<ITruck[]>([]);
    const [loadingTruck, setLoadingTruck] = useState(false);
    const [statusFilter, setStatusFilter] = useState({
        [Status.ATRIBUIDA]: false,
        [Status.CANCELADA]: false,
        [Status.CRIADA]: false,
        [Status.EM_ROTA]: false,
        [Status.FINALIZADA]: false,
        [Status.ROTEIRIZADA]: false,
    });

    useEffect(() => {
        const fetchStores = async () => {
            try {
                setLoadingStores(true)
                const user = await getTokenInfo();
                const {
                    data: { content },
                } = await apiAuth.get(`/companyUser/${user?.id}/user`);
                const response = content.map((company) => ({
                    key: company.company_id,
                    value: company.company.company_name,
                    cnpj: company.company.cnpj,
                }));
                setStores(response);
            } catch (error) {
                const _message =
                    //@ts-ignore
                    error?.response?.data?.message;
                message.error(_message || "Houve um erro ao realizar busca");
            } finally {
                setLoadingStores(false)
            }
        };

        fetchStores();
    }, []);

    useEffect(() => {
        const getDeliveryRoutes = async () => {
            setLoading(true)
            try {
                const selectedStatus = Object.keys(statusFilter).find(key => statusFilter[key]);
                if (selectedStatus) {
                    const { data: { content } } = await api.get(`/delivery-route?page=1&size=30&status=${selectedStatus}`);
                    setRoutes(content);
                } else {
                    const { data: { data } } = await api.get(`/delivery-route`);
                    setRoutes(data);
                }
                setLoading(false);
            } catch (error) {
                const _message =
                    //@ts-ignore
                    error?.response?.data?.message;
                message.error(_message || "Houve um erro ao realizar busca");
            } finally {
                setLoading(false);
                setShouldSearch(false);
            }
        };

        getDeliveryRoutes();
    }, [store, shouldSearch, statusFilter]);

    useEffect(() => {
        const drivers = async () => {
            try {
                const { data: { content } } = await apiAuth.get(`/user/drivers-users`)

                setDrivers(content)
            } catch (error) {
                const _message =
                    //@ts-ignore
                    error?.response?.data?.message;
                message.error(_message || "Houve um erro ao realizar busca");
            }
        }

        drivers()
    }, [])

    useEffect(() => {
        const getTrucks = async () => {
            try {
                setLoadingTruck(true)
                const { data: { data } } = await api.get(`/trucks`);
                setTrucks(data)
                setLoadingTruck(false);
            } catch (error) {
                const _message =
                    //@ts-ignore
                    error?.response?.data?.message;
                message.error(_message || "Houve um erro ao realizar busca");
            } finally {
                setShouldLoad(false);
                setLoadingTruck(false)
            }
        }

        getTrucks();
    }, [shouldLoad])

    const deleteRoute = (id: number) => {
        Modal.confirm({
            title: "Excluir a rota?",
            content: "Deseja continuar e confirmar a exclusão desta rota? Isso implicará no cancelamento automatico da mesma",
            okText: "Sim",
            okType: "primary",
            cancelText: "Cancelar",
            width: 500,
            centered: true,
            async onOk() {
                try {
                    await api.delete(`/delivery-route/${id}`);
                    message.success("Rota deletada com sucesso");
                    setShouldSearch(true);
                } catch (error) {
                    //@ts-ignore
                    const _description = error.message;
                    message.error({
                        message: "Oops! Não foi possível remover a rota selecionada",
                        description: error
                            ? _description
                            : "Falha ao remover a rota selecionada.",
                        duration: 5,
                    });
                }
            },
        });
    };

    const cancelRoute = (id: number) => {
        Modal.confirm({
            title: "Cancelar a rota?",
            content: "Deseja continuar e confirmar o cancelamento desta rota?",
            okText: "Sim",
            okType: "primary",
            cancelText: "Cancelar",
            width: 500,
            centered: true,
            async onOk() {
                try {
                    const payload = {
                        status: 3
                    }
                    await api.put(`/delivery-route/${id}`, payload);
                    message.success("Rota cancelada com sucesso");
                    setShouldSearch(true);
                } catch (error) {
                    //@ts-ignore
                    const _description = error.message;
                    message.error({
                        message: "Oops! Não foi possível remover a rota selecionada",
                        description: error
                            ? _description
                            : "Falha ao cancelar a rota selecionada.",
                        duration: 5,
                    });
                }
            },
        });
    };

    const handleStatusFilterChange = (blingValue) => {
        setStatusFilter((prevState) => ({
            [Status.ATRIBUIDA]:
                blingValue === Status.ATRIBUIDA
                    ? !prevState[Status.ATRIBUIDA]
                    : false,
            [Status.CANCELADA]:
                blingValue === Status.CANCELADA
                    ? !prevState[Status.CANCELADA]
                    : false,
            [Status.CRIADA]:
                blingValue === Status.CRIADA
                    ? !prevState[Status.CRIADA]
                    : false,
            [Status.EM_ROTA]:
                blingValue === Status.EM_ROTA
                    ? !prevState[Status.EM_ROTA]
                    : false,
            [Status.FINALIZADA]:
                blingValue === Status.FINALIZADA
                    ? !prevState[Status.FINALIZADA]
                    : false,
            [Status.ROTEIRIZADA]:
                blingValue === Status.ROTEIRIZADA
                    ? !prevState[Status.ROTEIRIZADA]
                    : false,
        }));
    };

    const filterMenu = (
        <Menu>
            <MenuItem
                onClick={() => handleStatusFilterChange(Status.ATRIBUIDA)}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <FilterText>Atribuídos</FilterText>
                <Checkbox
                    checked={statusFilter[Status.ATRIBUIDA]}
                />
            </MenuItem>
            <MenuItem
                onClick={() =>
                    handleStatusFilterChange(Status.ROTEIRIZADA)
                }
            >
                <FilterText>Roteirizados</FilterText>
                <Checkbox
                    checked={statusFilter[Status.ROTEIRIZADA]}
                />
            </MenuItem>
            <MenuItem
                onClick={() =>
                    handleStatusFilterChange(Status.EM_ROTA)
                }
            >
                <FilterText>Em rota</FilterText>
                <Checkbox
                    checked={statusFilter[Status.EM_ROTA]}
                />
            </MenuItem>
            <MenuItem
                onClick={() =>
                    handleStatusFilterChange(Status.CANCELADA)
                }
            >
                <FilterText>Cancelados</FilterText>
                <Checkbox
                    checked={statusFilter[Status.CANCELADA]}
                />
            </MenuItem>
            <MenuItem
                onClick={() =>
                    handleStatusFilterChange(Status.FINALIZADA)
                }
            >
                <FilterText>Finalizados</FilterText>
                <Checkbox
                    checked={statusFilter[Status.FINALIZADA]}
                />
            </MenuItem>
        </Menu>
    )

    return (
        <PageContainer route="Rotas">
            <Container>
                <Header>
                    <Row gutter={6}>
                        <ColHeader sm={21}>
                            <Title>Lista de Rotas</Title>
                        </ColHeader>


                        <ColHeader xs={1}>
                            <Dropdown
                                onVisibleChange={(state) => {
                                    setVisibleFilter(state);
                                }}
                                visible={visibleFilter}
                                overlay={filterMenu}
                                trigger={["click"]}
                                placement="bottomCenter"
                            >
                                <Tooltip title="Filtros">
                                    <Button icon={<FilterIcon />} />
                                </Tooltip>
                            </Dropdown>
                        </ColHeader>
                        <ColHeader xs={1}>
                            <Tooltip title="Adicionar ou editar caminhão">
                                <Button icon={<TruckIcon />} onClick={() => setVisibleAddTruck(true)} />
                            </Tooltip>
                        </ColHeader>
                        <ColHeader xs={1}>
                            <Tooltip title="Adicionar rota">
                                <Button icon={<AddIcon />} onClick={() => setVisibleNewRoute(true)} />
                            </Tooltip>
                        </ColHeader>
                    </Row>
                </Header>
                <Content>
                    {loading ? (
                        <Centralizer>
                            <Spin />
                        </Centralizer>
                    ) : (
                        routes.length !== 0 ? (
                            <RouterList
                                visible={visible}
                                setVisible={setVisible}
                                setShouldSearch={setShouldSearch}
                                loading={loading}
                                deleteRoute={deleteRoute}
                                routes={routes}
                                drivers={drivers}
                                trucks={trucks}
                                setLoading={setLoading}
                                cancelRoute={cancelRoute}
                            />
                        ) : (
                            <EmptyContent>
                                <Empty description="Não há rotas cadastradas." />
                            </EmptyContent>
                        )
                    )}
                </Content>

            </Container>

            <NewRouteModal
                visible={visibleNewRoute}
                setVisible={setVisibleNewRoute}
                setShouldSearch={setShouldSearch}
                stores={stores}
                setStore={setStore}
                routes={routes}
                setRoutes={setRoutes}
                loadingStores={loadingStores}
            />

            <Drawer
                title={"Caminhões"}
                visible={visibleAddTruck}
                onClose={() => setVisibleAddTruck(false)}
                maskClosable
                mask={false}
                closable
                width={window.outerWidth < 768 ? '100%' : '50%'}
                destroyOnClose
            >
                <Trucks
                    trucks={trucks}
                    setVisibleAddTruck={setVisibleAddTruck}
                    loading={loadingTruck}
                    setShouldLoad={setShouldLoad}
                />
            </Drawer>
        </PageContainer>
    );
}

export default DeliveryRoutes