import styled, { css } from "styled-components";
import { MoreHoriz } from "../../styles/Icons";
import {
  Table as TableAnt,
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
} from "antd";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--orange-350);
  margin-left: 1%;
  min-width: 2rem;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;

export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 0 0 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--gray-50);

    max-height: 45rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: #fffefe;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #acaeae;
      border-radius: 5px;
    }

    @media only screen and (min-width: 575px) {
      overflow-y: scroll;
    }

    @media only screen and (max-width: 1250px) {
      max-height: 35rem;
    }

    @media only screen and (max-width: 800px) {
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    padding: 1rem 0.3rem;
    overflow: hidden;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    .ant-table-row .ant-table-row-level-0 {
      :last-child {
        margin-bottom: 0.5rem;
      }
    }
    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
  }

  .ant-pagination-options-size-changer.ant-select {
    width: 6.3rem;
  }
  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 1rem 0.3rem;
    overflow: hidden;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-table-tbody > tr.totalPay > td:first-child {
    border-left: 5px solid var(--green-400);
  }

  .ant-table-tbody > tr.totalPay:hover > td {
    background: var(--light-green);
  }

  .ant-table-tbody > tr.partialPay > td:first-child {
    border-left: 5px solid var(--orange-10);
  }

  .ant-table-tbody > tr.partialPay:hover > td {
    background: var(--orange-5);
  }

  .ant-table-tbody > tr.expiratedDate > td:first-child {
    border-left: 5px solid red;
  }

  .ant-table-tbody > tr.expiratedDate:hover > td,
  .ant-table-tbody > tr.expiratedDate:active > td {
    background: var(--light-red) !important;
  }

  .ant-table-tbody > tr.default:hover > td,
  .ant-table-tbody > tr.default:active > td {
    background: var(--white-25) !important;
  }
`;

interface IDateExpiration {
  date: boolean;
}

export const ExpirationDate = styled.div<IDateExpiration>`
  ${({ date }) => {
    if (date) {
      return css`
        color: var(--orange-600);
      `;
    } else {
      return css`
        color: var(--green-400);
      `;
    }
  }}
`;

export const TableCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IProp {
  cod: boolean;
}

export const CodNFVHSYS = styled.div<IProp>`
  overflow: hidden;

  ${({ cod }) => {
    if (cod) {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    }
  }}

  @media only screen and (max-width: 1500px) {
    max-width: 10rem;
  }
  @media only screen and (max-width: 1030px) {
    max-width: 5.2rem;
  }
  @media only screen and (max-width: 575px) {
    max-width: 4.5rem;
  }
`;

export const CodBankCel = styled.div`
  overflow: hidden;

  @media only screen and (max-width: 1500px) {
    max-width: 10rem;
  }
  @media only screen and (max-width: 1030px) {
    max-width: 5.2rem;
  }
  @media only screen and (max-width: 575px) {
    max-width: 4.5rem;
  }
`;

export const TitleResponsive = styled.div`
  .titleResponsive {
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
`;

export const Menu = styled(MenuAnt)`
  min-width: 10rem;
  .ant-dropdown-menu-item-only-child {
    align-items: center;
    color: var(--gray-45);
    border-bottom: 1px solid var(--gray-25);

    :last-child {
      border: none;
    }
  }
`;

export const Dropdown = styled(DropdownAnt)``;
