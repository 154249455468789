import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Modal, Row } from 'antd';

import { ProductAudit } from '..';
import { currencyFormater } from '../../../services/currencyFormater';
import { ProductStoreTranslated } from '../../../models/enums/productStoreTranslated';
import { removeAccentsAndLowerCase } from '../../../utils/removeAccentsAndCaseSensitive';

import { ContentModal, Input, Table, EmptyContent, TextCapitalize, ReloadIcon, ButtonReload } from './styles';
import { Page } from '../../../models/Page';
import Pagination from '../../../components/Pagination';
import moment from 'moment';

interface IProps {
    auditProductFranchisee: ProductAudit[];
    openProductFranchiseeModal: boolean;
    reloadAuditProductFranchisee: boolean;
    paginate: Page;
    setPaginate: React.Dispatch<React.SetStateAction<Page>>;
    setShouldAuditSearch: React.Dispatch<React.SetStateAction<boolean>>
    setOpenProductFranchiseeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAuditProductFranchisee: React.FC<IProps> = ({
    paginate,
    openProductFranchiseeModal,
    reloadAuditProductFranchisee,
    auditProductFranchisee,
    setOpenProductFranchiseeModal,
    setPaginate,
    setShouldAuditSearch
}) => {
    const [filteredProduct, setFilteredProduct] = useState<ProductAudit[]>([]);

    useEffect(() => {
        setFilteredProduct(auditProductFranchisee);
    }, [auditProductFranchisee]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (_, record) => (
                <span>{record.id}</span>
            ),
        },
        {
            title: "Data de alteração",
            dataIndex: "created_at",
            key: "created_at",
            render: (_, record) => {
                const formattedDate = moment(record.created_at, 'DD-MM-YYYY HH:mm:ss').subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss');
                return <span>{formattedDate}</span>;
            },
        },
        {
            title: "Responsável",
            dataIndex: "responsible",
            key: "responsible",
            render: (_, record) => (
                <TextCapitalize>{record?.user_name ? record?.user_name : "Nome indefinido"}</TextCapitalize>
            ),
        },
        {
            title: "Produto",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
                <TextCapitalize>{record.product_store?.product?.name}</TextCapitalize>
            ),
        },
        {
            title: "Campo",
            dataIndex: "field",
            key: "field",
            render: (_, record) => (
                <span>{ProductStoreTranslated[record?.field]}</span>
            ),
        },
        {
            title: "Valor alterado",
            dataIndex: "price_sell",
            key: "price_sell",
            render: (_, record) => {
                if (record?.field === 'price_unit' || record?.field === 'price_sell' || record?.field === 'unity_taxable' || record?.field === 'price_taxable') {
                    return <span>{`De R$${currencyFormater(+record?.old_value)} para R$${currencyFormater(+record?.new_value)}`}</span>
                }
                return <span>{record?.old_value ? `de ${record.old_value} para ${record.new_value}` : record?.new_value}</span>;
            }
        },
    ];

    const findProductModal = ({ target: { value } }) => {
        const searchValue = removeAccentsAndLowerCase(value);
        if (searchValue === '') {
            setFilteredProduct(auditProductFranchisee);
        } else {
            const filter = auditProductFranchisee.filter((product) =>
                removeAccentsAndLowerCase(product?.product_store?.product?.name).includes(searchValue)
            );
            setFilteredProduct(filter);
        }
    };

    return (
        <Modal
            title={"Auditoria dos produtos do gestor"}
            visible={openProductFranchiseeModal}
            onCancel={() => setOpenProductFranchiseeModal(false)}
            onOk={() => setOpenProductFranchiseeModal(false)}
            width={'70%'}
            centered
            closable
            footer={[
                <Pagination
                    setPaginate={setPaginate}
                    totalElements={paginate.totalElements}
                    setStateSearch={setShouldAuditSearch}
                />
            ]}
        >
            <ContentModal>
                <Row>
                    <Col md={24}>
                        <Input
                            placeholder='Busque pelo nome do produto'
                            onChange={findProductModal}
                        />
                    </Col>
                </Row>

                {filteredProduct.length !== 0 ? (
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={filteredProduct.map((entity) => ({
                            ...entity,
                            key: entity.id,
                        }))}
                        loading={reloadAuditProductFranchisee}
                    />
                ) : (
                    <EmptyContent>
                        <Empty
                            description="Não há alterações nos produtos dessa loja."
                        />
                    </EmptyContent>
                )}
            </ContentModal>
        </Modal >
    );
};

export default ModalAuditProductFranchisee;