import React from "react";

import { Container } from "./styles";

type IInputProp = React.ComponentProps<"input"> & {
  label?: string;
  readOnly?: boolean;
  value?: any;
  defaultValue?: any;
  type?: string;
  onChange?: (value: any) => void;
  onClick?: () => void;
  placeholder?: string;
  className?: string;
  name?: string;
  min?: number;
};

const InputCurrency: React.FC<IInputProp> = ({
  label,
  value,
  onChange,
  onClick,
  type,
  readOnly,
  defaultValue,
  placeholder,
  className,
  name,
  min,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      if (type === "currency" || type === "float") {
        const value = parseFloat(event.target.value.replace(/\D/g, ""));

        if (!isNaN(value)) {
          let formattedValue = (value / 100).toFixed(2);
          onChange(formattedValue.replace(",", ""));
        } else {
          onChange((0).toFixed(2));
        }
      } else {
        onChange(event.target.value);
      }
    }
  };
  return (
    <Container className={className || "inputContainer"}>
      {label && <label>{label}</label>}
      <div className="inputFormat">
        {type === "currency" && <span>R$</span>}
        <input
          placeholder={placeholder}
          value={value || (0).toFixed(2)}
          onChange={handleChange}
          defaultValue={defaultValue}
          readOnly={readOnly}
          type={type === "currency" ? "string" : type}
          name={name}
          min={min}
          onClick={onClick}
        />
      </div>
    </Container>
  );
};

export default InputCurrency;
