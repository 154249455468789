import styled from 'styled-components';

import {
  Col as ColAnt,
  Row as RowAnt,
  Table as TableAnt
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Table = styled(TableAnt)`
  height: 100%;
  width: 100%;
`;

export const ContentBody = styled.div`
  display: flex;
  width: 100%;
  min-height: 80%;

  @media screen and (max-width: 700px){
    min-height: 68%;
  }
`;

export const ContentInfo = styled.span`
  text-transform: capitalize;
`;

export const TopSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10%;
  width: 100%;
  padding: 0;
  min-height: 3.5rem;
  margin-bottom: 1rem;
  .ant-input {
    height: 3.25rem;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    height: 20%;
  }
  @media only screen and (max-width: 575px) {
    height: 8rem;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Col = styled(ColAnt)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 0.2rem;
  }
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalSalesContent = styled.div`
`;

export const TotalSale = styled.span`
   font-weight: 400;
   font-size: .8rem;
   color: var(--gray-600);
`;

export const SummaryTypeSale = styled(Row)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;

  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--gray-45);
    margin-left: 10px;

    div {
      width: 1rem;
      height: 1rem;
      border-radius: 50px;
      margin-right: 5px;
    }
  }

  .without-cpf {
    div {
      background: var(--orange-5);
    }
  }

  .with-cpf {
    div {
      background: var(--green-400);
      opacity: 50%;
    }
  }
`;

export const LegendSummaryType = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentDescription = styled.div`
  display: flex;
  align-items: center;

  
  @media screen and (max-width: 768px) {
      span{
        justify-content: center;
      }
    }
`;

export const ContentSummaryTypeSale = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-top: 1rem;
`;