import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { currencyFormater } from "../../../services/currencyFormater";
import { verifyPermission } from "../../../services/auth";

import { Order as OrderModel } from "../../../models/Order/Order";
import { OrderPayments } from "../../../models/Order/OrderPayments";
import { PaymentType } from "../../../models/enums/PaymentType";

import api from "../../../services/api";

import { Modal, notification } from "antd";

import {
  Container,
  Table,
  ButtonRemovePayment,
  InfoTotalPayment,
  Center,
  TrashIcon,
} from "./styles";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalAuditPayment: React.FC<IProps> = ({
  order,
  setVisible,
  visible,
  setShouldSearch,
}) => {
  const [orderPayment, setOrderPayment] = useState<OrderPayments[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const {
          data: {
            data: { content },
          },
        } = await api.get(`/orders_payments/${order?.id}`);
        setOrderPayment(content);
      } catch (error) {
        //@ts-ignore
        const _description = error.message;

        notification.error({
          message: "Oops! Não foi possível carregar a lista de pagamentos",
          description: error ? _description : "Falha ao encontrar pagamentos.",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    if (visible) {
      fetchPayments();
    }
  }, [visible, order]);

  const handleDeletePaymentOrder = async (payment_id: number) => {
    Modal.confirm({
      title: "Excluir pagamento",
      content: "Deseja continuar e confirmar a exclusão deste pagamento?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Cancelar",
      className: "modalDelete",
      width: 500,
      async onOk() {
        try {
          await api.delete(`/orders_payments/${payment_id}`);
          notification.success({
            message: "Pagamento excluído",
            description: "O pagamento selecionado foi removido com sucesso.",
            duration: 5,
          });

          setShouldSearch(true);
          setVisible(false);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Oops! Não foi possível remover o pagamento",
            description: error ? _description : "Falha ao remover o pagamento.",
            duration: 5,
          });
        }
      },
    });
  };

  const columns = [
    {
      title: "Data de lançamento",
      dataIndex: "created_at",
      key: "date",
      render: (text) => <>{text}</>,
    },
    {
      title: "Responsável",
      dataIndex: "user_id",
      key: "user_id",
      render: (text, record) => (
        <>{text ? record?.user?.name : "Usuário não idenficado"}</>
      ),
    },
    {
      title: "Tipo de pagamento",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (text) => <>{PaymentType[+text]}</>,
    },
    {
      title: "Valor",
      dataIndex: "payment_value",
      key: "payment_value",
      render: (text) => <>R$ {currencyFormater(+text)}</>,
    },
    {
      title: <Center>Ações</Center>,

      render: (text, record) => (
        <Center>
          {verifyPermission("orders.delete_payment") && (
            <ButtonRemovePayment
              onClick={() => handleDeletePaymentOrder(record.id)}
            >
              <TrashIcon />
            </ButtonRemovePayment>
          )}
        </Center>
      ),
    },
  ];

  return (
    <Container
      title={`Movimentação de Pagamento`}
      visible={visible}
      centered
      width={1000}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Table
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={orderPayment}
        rowKey={(entity: any) => entity.id}
      />
      <InfoTotalPayment>Valor total do pedido R$ </InfoTotalPayment>
      <span className="paymentValue">
        {currencyFormater(order?.payment_value || 0)}
      </span>
    </Container>
  );
};

export default ModalAuditPayment;
