import styled, { css } from "styled-components";

import { Modal as AntModal, Button } from "antd";

const ButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30%;
  height: 3.25rem;
  border-radius: 5px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(Button)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const ButtonEdit = styled(Button)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Modal = styled(AntModal)`
`;

export const TextModal = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;
