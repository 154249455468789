import React, { Dispatch, SetStateAction, useState } from "react";

import moment from "moment";

import apiMsNf from "../../../services/apiMsNf";
import { verifyPermission } from "../../../services/auth";

import { currencyFormater } from "../../../services/currencyFormater";

import { useWindowSize } from "../../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../../hooks/useResponsiveSizeTable";

import { MoreInfo } from "../../../containers/CashHistoryList/styles";

import { Dropdown, Menu, Tooltip, notification } from "antd";

import {
  Actions,
  CancelCircleIcon,
  Container,
  EditIcon,
  PdfIcon,
  Table,
  Modal,
  Input,
  ButtonCancel,
  Button,
  Form,
  Row,
  Col,
  SendIcon,
  XmlIcon,
  IconInfo,
} from "./styles";

const { TextArea } = Input;

interface IPaginateProps {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

interface IProps {
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  nfeList: any[];
  store: any;
  pagination: IPaginateProps;
  setPagination: Dispatch<SetStateAction<IPaginateProps>>;
}

const NfeList: React.FC<IProps> = ({
  setShouldSearch,
  nfeList,
  store,
  pagination,
  setPagination,
}) => {
  const responsiveWindow = useWindowSize();
  const [formEmail] = Form.useForm();
  const [formCancel] = Form.useForm();
  const [formCarta] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [visibleCarta, setVisibleCarta] = useState<boolean>(false);
  const [visibleCancel, setVisibleCancel] = useState<boolean>(false);
  const [visibleEmail, setVisibleEmail] = useState<boolean>(false);

  const [selectNFE, setSelectNFE] = useState<any>();

  const timeNow = moment().subtract(1, "minute");

  const columns = [
    {
      title: "Número",
      dataIndex: "numero",
      key: "numero",
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <>{text}</>
        </Tooltip>
      ),
    },
    {
      title: "Nome",
      dataIndex: "nome_fantasia_emitente",
      key: "nome_fantasia_emitente",
      responsive: ["md"] as any,
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <>{text}</>
        </Tooltip>
      ),
    },
    {
      title: "Data de emissão",
      dataIndex: "data_emissao",
      key: "data_emissao",
      render: (text, record) => (
        <Tooltip
          placement="top"
          title={moment(text || record?.created_at).format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        >
          <>
            {moment(text || record?.created_at).format("DD/MM/YYYY HH:mm:ss")}
          </>
        </Tooltip>
      ),
    },
    {
      title: "Situaçao",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tooltip placement="top" title={text}>
          <span
            style={{
              color:
                text === "autorizado"
                  ? "var(--midori-green)"
                  : text === "cancelado"
                  ? "var(--maroon)"
                  : "var(--blue-999)",
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <div className="centralizer">Mensagem Sefaz</div>,
      dataIndex: "mensagem_sefaz",
      key: "mensagem_sefaz",
      render: (text) => (
        <div className="centralizer">
          <Tooltip placement="top" title={text}>
            <IconInfo />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Valor (R$)",
      dataIndex: "valor_total",
      key: "valor_total",
      responsive: ["md"] as any,
      render: (text) => (
        <Tooltip placement="top" title={`R$ ${currencyFormater(+text)}`}>
          <>{`R$ ${currencyFormater(+text)}`}</>
        </Tooltip>
      ),
    },
    {
      title: <div className="centralizer">Ações</div>,
      key: "action",
      width: "5rem",
      render: (_, record) => {
        return (
          <>
            {(record?.status === "autorizado" ||
              record?.status === "cancelado") && (
              <Actions>
                <Dropdown
                  overlay={
                    <>
                      {record?.status_sefaz !== "102" ? (
                        <Menu>
                          <Menu.Item>
                            <a
                              href={`https://api.focusnfe.com.br/${record?.caminho_danfe}`}
                              target="_blank"
                            >
                              <PdfIcon /> Gerar PDF DANFE
                            </a>
                          </Menu.Item>

                          {record?.status === "autorizado" && (
                            <Menu.Item>
                              <a
                                href={`https://api.focusnfe.com.br/${record?.caminho_xml_nota_fiscal}`}
                                target="_blank"
                              >
                                <XmlIcon /> Gerar XML
                              </a>
                            </Menu.Item>
                          )}
                          {record?.status === "cancelado" && (
                            <Menu.Item>
                              <a
                                href={`https://api.focusnfe.com.br/${record?.caminho_xml_cancelamento}`}
                                target="_blank"
                              >
                                <XmlIcon /> Gerar XML Cancelamento
                              </a>
                            </Menu.Item>
                          )}
                          {record?.caminho_pdf_carta_correcao && (
                            <Menu.Item>
                              <a
                                href={`https://api.focusnfe.com.br/${record?.caminho_pdf_carta_correcao}`}
                                target="_blank"
                              >
                                <PdfIcon /> Visualizar PDF carta de correção
                              </a>
                            </Menu.Item>
                          )}
                          {record?.caminho_xml_carta_correcao && (
                            <Menu.Item>
                              <a
                                href={`https://api.focusnfe.com.br/${record?.caminho_xml_carta_correcao}`}
                                target="_blank"
                              >
                                <XmlIcon /> Visualizar XML carta de correção
                              </a>
                            </Menu.Item>
                          )}
                          <Menu.Item
                            onClick={() => {
                              setVisibleEmail(true);
                              setSelectNFE(record);
                            }}
                          >
                            <SendIcon />
                            Enviar NFE por E-mail
                          </Menu.Item>
                          {verifyPermission("nfe.cancel") && (
                            <Menu.Item
                              onClick={() => {
                                setSelectNFE(record);
                                setVisibleCancel(true);
                              }}
                              disabled={
                                record.status === "cancelado" ? true : false
                              }
                            >
                              <CancelCircleIcon />
                              Cancelar NFE
                            </Menu.Item>
                          )}
                          {verifyPermission("nfe.correction") && (
                            <Menu.Item
                              onClick={() => {
                                setVisibleCarta(true);
                                setSelectNFE(record);
                              }}
                              disabled={
                                record.status === "autorizado" ||
                                record.status !== "cancelado"
                                  ? false
                                  : true
                              }
                            >
                              <EditIcon />
                              Enviar carta de correção
                            </Menu.Item>
                          )}
                        </Menu>
                      ) : (
                        <Menu>
                          <Menu.Item>
                            <a
                              href={`https://api.focusnfe.com.br/${record?.caminho_xml}`}
                              target="_blank"
                            >
                              <XmlIcon /> Gerar XML Inutilização
                            </a>
                          </Menu.Item>
                        </Menu>
                      )}
                    </>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <MoreInfo />
                </Dropdown>
              </Actions>
            )}
          </>
        );
      },
    },
  ];

  const sendEmail = async () => {
    await formEmail.validateFields();
    setLoading(true);
    try {
      const payload = {
        email: formEmail.getFieldValue("email"),
        caminho_xml_nota_fiscal: selectNFE?.caminho_xml_nota_fiscal,
        caminho_danfe: selectNFE?.caminho_danfe,
        numero: selectNFE?.numero,
      };

      await apiMsNf.post(`/nfe/send-email-nfe`, payload);

      notification.success({
        message: "E-mail enviado com sucesso",
        duration: 5,
      });

      formEmail.resetFields();
      setVisibleEmail(false);
    } catch (e) {
      const _message = "Erro ao enviar E-mail";
      //@ts-ignore
      const _description = e?.response?.data?.message || "";
      notification.error({
        message: _message,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishCarta = async () => {
    await formCarta.validateFields();
    setLoading(true);
    try {
      let payload = formCarta.getFieldsValue();

      payload = { ...payload, referencia: selectNFE?.ref, id: selectNFE?.id };

      await apiMsNf.post(`/nfe/corretion-letter/${store.key}`, payload);

      notification.success({
        message: "Carta emitida com sucesso",
        duration: 5,
      });

      setShouldSearch(true);
      setVisibleCarta(false);
      formCarta.resetFields();
    } catch (e) {
      const _message = "Erro ao enviar carta de correção";
      //@ts-ignore
      const _description = e?.response?.data?.message || "";
      notification.error({
        message: _message,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishCancel = async () => {
    if (!moment(selectNFE?.created_at).subtract(3, "hour").isBefore(timeNow)) {
      return notification.warning({
        message:
          "Necessário aguardar 1 minuto após a emissão da nota para efetuar o cancelamento",
        description: "Tente novamente em alguns instantes",
        duration: 5,
      });
    }

    await formCancel.validateFields();
    setLoading(true);
    try {
      let payload = formCancel.getFieldsValue();

      payload = {
        ...payload,
        id: selectNFE?.id,
        store_id: store.key,
        referencia: selectNFE?.ref,
      };

      await apiMsNf.post(`/nfe/cancel`, payload);

      notification.success({
        message: "Cancelamento emitido com sucesso",
        duration: 5,
      });

      setShouldSearch(true);
      setVisibleCancel(false);
      formCancel.resetFields();
    } catch (e) {
      const _message = "Erro ao emitir cancelamento da NFE";
      //@ts-ignore
      const _description = e?.response?.data?.message || "";
      notification.error({
        message: _message,
        description: _description,
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (_page: number) => {
    setPagination((oldValues) => ({ ...oldValues, page: _page }));
    setShouldSearch(true);
  };

  return (
    <>
      <Container>
        <Table
          loading={loading}
          columns={columns}
          scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
          dataSource={nfeList.map((entity) => ({
            ...entity,
            key: entity?.id,
          }))}
          pagination={{
            current: pagination.page,
            pageSize: pagination.size,
            total: pagination.totalElements,
            showSizeChanger: false,
            onChange: handleTableChange,
          }}
        />

        <Modal
          centered
          closable={true}
          visible={visibleCarta}
          title={"Carta de correção"}
          footer={
            <>
              <ButtonCancel
                onClick={() => {
                  setVisibleCarta(false);
                  formCarta.resetFields();
                }}
                disabled={loading}
              >
                Cancelar
              </ButtonCancel>

              <Button onClick={() => onFinishCarta()} loading={loading}>
                Enviar
              </Button>
            </>
          }
          width={600}
          onCancel={() => setVisibleCarta(false)}
        >
          <Form layout="vertical" form={formCarta} validateTrigger="onSubmit">
            {selectNFE?.carta_correcao && (
              <Form.Item label="Última carta de correção">
                <p>{selectNFE?.carta_correcao}</p>
              </Form.Item>
            )}
            <Form.Item
              label="Descrição da correção"
              name="correcao"
              rules={[
                { required: true, message: "Campo obrigatório" },
                { min: 15, message: "Deve possuir no mínimo 15 caracteres" },
                {
                  max: 1000,
                  message: "Deve possuir no máximo 1000 caracteres",
                },
              ]}
            >
              <TextArea
                rows={5}
                minLength={15}
                maxLength={1000}
                placeholder="A correção mais recente substitui as anteriores"
                name="correcao"
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          centered
          closable={true}
          visible={visibleCancel}
          title={"Cancelar NFE"}
          footer={
            <>
              <ButtonCancel
                onClick={() => {
                  setVisibleCancel(false);
                  formCancel.resetFields();
                }}
                disabled={loading}
              >
                Cancelar
              </ButtonCancel>

              <Button onClick={() => onFinishCancel()} loading={loading}>
                Enviar
              </Button>
            </>
          }
          width={600}
          onCancel={() => setVisibleCancel(false)}
        >
          <Form layout="vertical" form={formCancel} validateTrigger="onSubmit">
            <Form.Item
              label="Digite a justificativa do cancelamento"
              name="justify"
              rules={[
                { required: true, message: "Campo obrigatório" },
                { min: 15, message: "Deve possuir no mínimo 15 caracteres" },
                {
                  max: 255,
                  message: "Deve possuir no máximo 255 caracteres",
                },
              ]}
            >
              <TextArea
                rows={5}
                minLength={15}
                maxLength={255}
                name="justify"
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          centered
          closable={true}
          visible={visibleEmail}
          title={"Enviar NFE por E-mail"}
          footer={
            <>
              <ButtonCancel
                onClick={() => {
                  setVisibleEmail(false);
                  formEmail.resetFields();
                }}
                disabled={loading}
              >
                Cancelar
              </ButtonCancel>

              <Button onClick={() => sendEmail()} loading={loading}>
                Enviar
              </Button>
            </>
          }
          width={600}
          onCancel={() => setVisibleEmail(false)}
        >
          <Form layout="vertical" form={formEmail} validateTrigger="onSubmit">
            <Row gutter={[4, 4]}>
              <Col sm={24} xs={24}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Container>
    </>
  );
};

export default NfeList;
