import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import locale from "antd/es/date-picker/locale/pt_BR";

import apiMsNf from "../../services/apiMsNf";

import moment from "moment";
import { notification, DatePicker, Spin } from "antd";

import {
  Container,
  Row,
  Col,
  PDFIcon,
  ContentModal,
  ContentButtons,
  ButtonFooter,
  DownloadIcon,
  ButtonSearch,
  SpinContainer,
  Title,
  ContentActions,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  store_id: number;
  cnpj?: string;
}

type NFCe = {
  mes: number;
  xmls: string;
  danfes?: any;
};

const NFceModal: React.FC<IProps> = ({
  visible,
  setVisible,
  store_id,
  cnpj
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [xmlUrl, setXmlUrl] = useState<NFCe>()

  useEffect(() => {
    if (!visible) {
      setSelectedDate(null);
      setXmlUrl(undefined);
    }
  }, [visible]);

  const fetchSalesData = async () => {
    try {
      setLoading(true);

      let mes = moment(selectedDate).format("YYYYMM");

      const {data} = await apiMsNf.get(
        `/nfce/focus_backup/${cnpj}?mes=${mes}`
      );

      if (!data) {
        return notification.error({
          message: "Não foi encontrado notas fiscais no mês informado",
        });
      }

      notification.success({
        message: `As notas foram encontradas com sucesso`,
      });
      setXmlUrl(data)
      console.log(xmlUrl?.xmls)
    } catch (e) {
      notification.error({
        //@ts-ignore
        message: e?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadPdfReport = async () => {
    try {
      setPDFLoading(true);

      const { data: pdf } = await apiMsNf.get(
        `/nfce/focus_backup/pdf?pdf_url=${xmlUrl?.xmls}`,
        {
          responseType: "blob",
        }
      );
      if (pdf.size === 0) {
        return notification.error({
          message: "Não foi encontrado notas fiscais no mês informado",
        });
      }
      const url = window.URL.createObjectURL(
        new Blob([pdf], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notification.error({ message: "Falha ao gerar arquivo.", duration: 5 });
    } finally {
      setPDFLoading(false);
    }
  };

  const reset = () => {
    setVisible(false);
    setSelectedDate(null);
  };

  return (
    <Container
      centered
      closable={true}
      visible={visible}
      title={"Notas Fiscais"}
      okText="Download NFC-e"
      footer={false}
      onCancel={reset}
      destroyOnClose
    >
      <ContentModal>
        <Row>
          <Col xs={18}>
            <p>Selecione o mês e o ano para buscar a nota fiscal</p>
          </Col>

          <Col xs={18}>
            <DatePicker
              placeholder="Selecione o mês e o ano"
              picker="month"
              locale={locale}
              value={selectedDate}
              format="MM/YYYY"
              onChange={(e: React.SetStateAction<moment.Moment | null>) => {
                setSelectedDate(e);
              }}
            />
          </Col>
          <Col xs={5}>
            <ButtonSearch
              onClick={fetchSalesData}
              disabled={!selectedDate || loading}
            >
              Buscar
            </ButtonSearch>
          </Col>

        </Row>
        {loading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <>
            {xmlUrl?.xmls ? (
              <>
                <Title>Notas fiscais encontradas: </Title>
                <ContentActions>
                  <>
                    <ContentButtons>
                      <ButtonFooter href={xmlUrl?.xmls} download={xmlUrl?.mes}>
                        <DownloadIcon />
                        Baixar
                      </ButtonFooter>
                      <ButtonFooter
                        onClick={downloadPdfReport}
                        disabled={pdfLoading}
                        loading={pdfLoading}
                      >
                        <PDFIcon /> Consultar
                      </ButtonFooter>
                    </ContentButtons>
                  </>
                </ContentActions>
              </>
            ) : <></>}
          </>
        )}

      </ContentModal>
    </Container>
  );
};

export default NFceModal;