import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import api from '../../../../services/api';
import { Button, Form, message } from 'antd';

import { InfoIcon } from '../../styles';
import { User } from '../../../../models/User/User';
import { ITruck } from '../../../../models/Truck';

import { Content, ContentLine, Header, Modal, Select } from './styles';
import { Status } from '../../../../models/enums/cargoType';

const { Option } = Select;

interface IProps {
    routes;
    selectedRoute;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    drivers: User[];
    trucks: ITruck[];
    setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const LinkDriverModal: React.FC<IProps> = ({ trucks, visible, setVisible, drivers, routes, selectedRoute, setShouldSearch }) => {
    const [form] = Form.useForm();
    const [driver, setDriver] = useState<number | undefined>(undefined);
    const [truck, setTruck] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (selectedRoute) {
            setDriver(selectedRoute?.deliveryRoute?.drive_id);
            setTruck(selectedRoute?.deliveryRoute?.truck_info);
        }
    }, [selectedRoute]);

    const updateDriver = async (id: number) => {
        const payload = {
            drive_id: driver,
            truck_info: truck,
            status: Status.ATRIBUIDA
        }
        try {
            if (!driver || !truck) {
                return message.error("Por favor, selecione um motorista e um caminhão.");
            }

            await api.put(`/delivery-route/${id}`, payload);

            message.success("Motorista e caminhão atualizados com sucesso!");
            setVisible(false);
            setShouldSearch(true);
        } catch (error) {
            //@ts-ignore
            if (error?.response?.data?.error?.message === "O motorista já está associado a outra rota ativa!") {
                return message.error("O motorista já está associado a outra rota ativa!")
            }
            //@ts-ignore
            if (error?.response?.data?.error?.message === "O nome do caminhão já está sendo usado em outra rota ativa.") {
                return message.error("O Caminhão já está associado a outra rota ativa!")
            }
            return message.error("Oops! Ocorreu um erro desconhecido")
        } finally {
            setShouldSearch(false);
        }
    };

    return (
        <Modal
            title={
                <span>
                    <InfoIcon color='var(--orange-350)' style={{ marginRight: '8px' }} /> Atribuir motorista e caminhão
                </span>
            }
            destroyOnClose
            centered
            visible={visible}
            onCancel={() => setVisible(false)}
            closable={false}
            width={"30%"}
            footer={[
                <Button key="cancel" onClick={() => setVisible(false)}>Não</Button>,
                <Button
                    key="ok"
                    type="primary"
                    onClick={() => selectedRoute && updateDriver(selectedRoute?.deliveryRoute?.id)}
                >
                    Sim
                </Button>
            ]}
        >
            <Form
                layout="vertical"
                form={form}
            >
                <Content>

                    <Header>
                        <span>
                            Rota:
                            <span className='orange-span'>
                                {" "}{selectedRoute?.deliveryRoute?.id}
                            </span>
                        </span>
                        <span>Status: {selectedRoute && 'Roteirizada'}</span>
                    </Header>

                    <Form.Item
                        label="Motorista"
                        name="driver_id"
                        style={{ width: '100%' }}
                    >
                        <ContentLine>
                            <Select
                                placeholder="Ver todos os motoristas"
                                onChange={(id) => {
                                    const selectedDriver = drivers.find(driver => driver.id === id);
                                    const isDriverLinkedToRoute = routes.some(route => route.deliveryRoute?.drive_id === id);
                                    const isRouteFinished = routes.some(route => (route.deliveryRoute?.status === Status.FINALIZADA || route.deliveryRoute?.status === Status.CANCELADA) && route.deliveryRoute?.drive_id === id);

                                    if (isDriverLinkedToRoute && !isRouteFinished) {
                                        message.warning(`O motorista ${selectedDriver?.name} já está vinculado a uma rota que ainda está em andamento.`);
                                    } else {
                                        setDriver(id as number);
                                    }
                                }}
                                value={driver}
                            >
                                {drivers.map((driver) => (
                                    <Option
                                        value={driver.id}
                                        key={driver.id}
                                    >
                                        <span>{driver.name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </ContentLine>
                    </Form.Item>

                    <Form.Item
                        label="Caminhão"
                        name="truck_info"
                        style={{ width: '100%' }}
                    >
                        <ContentLine>
                            <Select
                                placeholder="Ver todos os caminhões"
                                onChange={(id) => {
                                    const isTruckLinkedToRoute = routes.some(route => route.deliveryRoute?.truck_info === id);
                                    const isRouteFinished = routes.some(route => (route.deliveryRoute?.status === Status.FINALIZADA || route.deliveryRoute?.status === Status.CANCELADA) && route.deliveryRoute?.truck_info === id);

                                    if (isTruckLinkedToRoute && !isRouteFinished) {
                                        message.warning(`O caminhão ${id} já está vinculado a uma rota.`);
                                    } else {
                                        setTruck(id as string);
                                    }
                                }}
                                value={truck}
                            >
                                {trucks.map((truck) => (
                                    <Option
                                        value={truck.name}
                                        key={truck.id}
                                    >
                                        <span>{truck.name}</span>
                                    </Option>
                                ))}
                            </Select>
                        </ContentLine>
                    </Form.Item>
                </Content>
            </Form>
        </Modal>
    );
};

export default LinkDriverModal;
