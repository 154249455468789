import styled from "styled-components";
import {
  Modal as ModalAnt,
  Checkbox as CheckboxAnt,
  Table as TableAnt,
  Button as ButtonAnt,
} from "antd";

import { Download } from "../../styles/Icons";

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    max-height: 20rem;
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    display: flex;
    margin-top: 1rem;
    height: 5.25rem;
    padding: 1rem;
    justify-content: end;
  }
`;

export const Button = styled(ButtonAnt)`
  width: 12rem;
  height: 3.25rem;
  background: none;
  box-shadow: none;
  border: none;

  :hover,
  :focus {
    background: none;
  }

  .primary {
    background: var(--orange-350);
    :hover,
    :focus {
      background: var(--orange-350) !important;
    }
  }

  @media only screen and (max-width: 578px) {
    width: 10rem;
  }
`;

export const DownloadButton = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  height: 3.25rem;
  background: var(--orange-350);
  box-shadow: none;
  border: none;

  :hover,
  :focus {
    background: var(--orange-350);
  }

  svg {
    display: none;
  }

  @media only screen and (max-width: 578px) {
    width: 5rem;

    .button-title {
      display: none;
    }

    svg {
      display: flex;
    }
  }
`;

export const DownloadIcon = styled(Download)`
  color: white;
  width: 1.2rem;
  height: 1.2rem;
`;

export const Checkbox = styled(CheckboxAnt)`
  zoom: 1.5;
`;
export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    overflow-y: scroll;
    max-height: 19rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: unset;
    white-space: nowrap;
  }

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
  }
`;
