import styled from "styled-components";

import { PowerBIEmbed as PowerBIEmbedComponent } from "powerbi-client-react";

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: #F9F9F9;
  
   .embed-container {
    width: 100%;
    height: 100%;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
`;

export const PowerBIEmbed = styled(PowerBIEmbedComponent)``;
