import styled from "styled-components";

import {
  Table as TableAnt,
  Button as ButtonAnt,
  Modal as AntModal,
} from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled(TableAnt)`
  border: 1px solid var(--gray-25);
  border-radius: 4px;
  .ant-table-thead > tr > th {
    background-color: transparent;
  }
`;

export const ContentFooter = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 2rem;
`;

export const ButtonOk = styled(ButtonAnt)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange-350);
  height: 3.25rem;
  border: 0;
  border-radius: 5px;
  color: var(--white-10);

  :hover {
    background-color: var(--orange-350);
    color: var(--white-10);
    opacity: 80%;
  }
`;
export const ButtonCancel = styled(ButtonAnt)`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  :hover {
    color: var(--black-opaco);
    opacity: 80%;
  }
`;

export const Modal = styled(AntModal)`
  .ant-modal-header {
    padding: 35px 25px 0;
  }
  .ant-modal-body {
    padding: 8px 24px 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    background-color: var(--white-25);
    padding: 20px;
    display: flex;
    justify-content: end;
  }
`;

export const ContentTopInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IdText = styled.p`
  font-size: 1.25rem;
  span {
    color: var(--orange-350);
  }
`;

export const ContentAmountAndDiscount = styled.aside`
  display: flex;

  span {
    :first-child {
      margin-right: 0.9rem;
    }
  }
`;
