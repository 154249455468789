import styled, { css } from "styled-components";

import { Col as ColAnt, Button as ButtonAnt } from "antd";
import {
  EditAlt,
  Trash,
  Restore,
  PublishedWithChanges,
} from "../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  .ant-table-container {
    border: 1px solid var(--gray-75);
    padding: 0.3rem;

    .ant-table-header {
      font-size: 1rem;
      @media only screen and (max-width: 578px) {
        font-size: 0.8rem;
      }
    }
    .ant-table-cell {
      background: white;
      color: var(--gray-45);
      padding: 0.5rem 0.2rem;
    }
    .ant-table-column-sorters {
      padding: 0;
    }
  }
  .ant-select-selection-item {
    width: 6rem;
  }
`;

export const Col = styled(ColAnt)``;

export const Button = styled(ButtonAnt)`
  margin: 0;
  box-shadow: none;
  border: none;
  background: none;
  @media only screen and (max-width: 578px) {
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    font-size: 0.9rem;
  }
  @media only screen and (max-width: 500px) {
    min-width: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    font-size: 0.9rem;
  }
`;

export const LabelName = styled.label`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1rem;
  @media only screen and (max-width: 578px) {
    margin-left: 0.2rem;
    font-size: 0.8rem;
  }
`;
interface IManagerStatus {
  active?: boolean;
}

export const ManagerStatus = styled.label<IManagerStatus>`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1rem;
  @media only screen and (max-width: 578px) {
    margin-left: 0.2rem;
    font-size: 0.8rem;
  }
  ${({ active }) => {
    if (active) {
      return css`
        color: var(--midori-green);
      `;
    } else {
      return css`
        color: var(--maroon);
      `;
    }
  }}
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--orange-350);

  @media only screen and (max-width: 578px) {
    width: 1.1rem;
    height: 1.1rem;
  }
  @media only screen and (max-width: 500px) {
    width: 0.9rem;
    height: 0.9rem;
    margin: 0;
  }
`;

export const EditIcon = styled(EditAlt)`
  ${iconCSS}
`;

export const RemoveIcon = styled(Trash)`
  ${iconCSS}
`;

export const StatusChangeIcon = styled(PublishedWithChanges)<IManagerStatus>`
  ${iconCSS}
  ${({ active }) => {
    if (!active) {
      return css`
        fill: var(--gray-45);
      `;
    }
  }}
`;

export const RestoreIcon = styled(Restore)`
  ${iconCSS}
  :hover {
    fill: var(--orange-350);
  }
`;
export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
