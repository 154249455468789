import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';

import { CompanyInfo, DeliveryRoute, DriveInfo } from '../../../../models/DeliveryRoute';
import { Status } from '../../../../models/enums/cargoType';

import { Col } from 'antd';
import { Body, Content, Header, Modal, OrangeSpan, Table } from './styles';

interface IProps {
    routes: DeliveryRoute[]
    visible: boolean;
    selectedRoute: any | null;
    setVisible: Dispatch<SetStateAction<boolean>>;
    selectedRouteInfo: DriveInfo | null
    selectedRouteStore: CompanyInfo[] | null
    allItems: any
}

const statusMap = {
    [Status.ATRIBUIDA]: 'Atribuída',
    [Status.EM_ROTA]: 'Em rota',
    [Status.CANCELADA]: 'Cancelada',
    [Status.FINALIZADA]: 'Finalizada',
    [Status.CRIADA]: 'Criada',
    [Status.ROTEIRIZADA]: 'Roteirizada',
};

const InfoRouteModal: React.FC<IProps> = ({ visible, selectedRouteStore, selectedRouteInfo, setVisible, selectedRoute }) => {

    const calculateSLA = (dispatchedAt: string | null, finishedAt: string | null): string => {
        if (!dispatchedAt || !finishedAt) return "N/A";
        const dispatchedMoment = moment(dispatchedAt, 'DD-MM-YYYY HH:mm:ss');
        const finishedMoment = moment(finishedAt, 'DD-MM-YYYY HH:mm:ss');
        const duration = moment.duration(finishedMoment.diff(dispatchedMoment));
        const hours = duration.asHours();
        return `${hours.toFixed(2)} horas`;
    };

    const columns = [
        {
            title: "Sequência",
            dataIndex: "sequence",
            align: "center" as const,
            key: "sequence",
            render: (text) => {
                return <span>{text}</span >;
            },
        },
        {
            title: "Nome da(s) loja(s)",
            dataIndex: "company_name",
            align: "center" as const,
            key: "company_name",
            render: (text) => (
                <span>
                    {text}
                </span>
            )
        },
        {
            title: "Nº pedido",
            dataIndex: "id_order",
            align: "center" as const,
            key: "id_order",
            render: (text) => {
                return <span>{text}</span >;
            },
        },
        {
            title: "Tipo de carga",
            dataIndex: "cargo_type",
            align: "center" as const,
            key: "cargo_type",
            render: (text, record) => {
                if (record?.cargo_type === 0) return <span>Carga gelada</span>
                if (record?.cargo_type === 1) return <span>Carga seca</span>
                return <span>Carga mista</span>
            }
        },
        {
            title: "Data prevista de chegada",
            dataIndex: "expected_date",
            align: "center" as const,
            key: "expected_date",
            render: (text) => (
                <span>{text ?
                    moment(text, "DD-MM-YYYY").format("DD-MM-YYYY") : "Adicione na roteirização"}</span>
            )
        },
        {
            title: "Data real de chegada",
            dataIndex: "finished_at",
            align: "center" as const,
            key: "finished_at",
            render: (text) => (
                <span>{text ? moment(text, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss") : "-"}</span>
            )
        },
        {
            title: "SLA",
            dataIndex: "dispatched_at",
            align: "center" as const,
            key: "dispatched_at",
            render: (text, record) => (
                <span>
                    {calculateSLA(record?.expected_date, record.finished_at)}
                </span>
            )
        },
        {
            title: "Status da entrega",
            dataIndex: "status",
            align: "center" as const,
            key: "status",
            render: (text) => (
                <span>{statusMap[text] || 'Criado'}</span>
            )
        },
    ];

    const sortedDeliveryRouteItems = selectedRoute?.deliveryRoute?.deliveryRouteItem?.sort((a, b) => {
        return a.sequence - b.sequence;
    });

    return (
        <Modal
            title={"Informações da rota"}
            destroyOnClose
            centered
            closable
            visible={visible}
            onCancel={() => setVisible(false)}
            width={"95%"}
            footer={null}
        >
            <Content>
                <Header>
                    <Col>
                        Motorista: <OrangeSpan>{selectedRouteInfo?.name ? selectedRouteInfo?.name : "Sem motorista atribuído"}</OrangeSpan>
                    </Col>
                    <Col>
                        Data da última atualização: <OrangeSpan>{selectedRoute?.deliveryRoute?.updated_at && moment(selectedRoute?.deliveryRoute?.updated_at, "DD-MM-YYYY HH:mm:ss")
                            .subtract(3, "hours")
                            .format("DD-MM-YYYY HH:mm:ss") || "Sem atualizações recentes"}</OrangeSpan>
                    </Col>
                    <Col>
                        Caminhão: <OrangeSpan>{selectedRoute?.deliveryRoute?.truck_info ? selectedRoute?.deliveryRoute?.truck_info : "Sem caminhão atribuído"}</OrangeSpan>
                    </Col>
                    <Col>
                        Status: <OrangeSpan>{selectedRoute ? (statusMap[selectedRoute?.deliveryRoute?.status] || 'Criado') : 'N/A'}</OrangeSpan>
                    </Col>
                </Header>

                <Body>
                    {selectedRoute && (
                        <Table
                            columns={columns}
                            dataSource={sortedDeliveryRouteItems?.map((item, index) => {
                                const orderInfo = selectedRoute?.orderInfo || [];
                                const routeStore = selectedRouteStore || [];

                                return {
                                    ...item,
                                    id_order: orderInfo.find(
                                        (_item) => _item.id === item.id_order
                                    )?.id,
                                    quantity: orderInfo.find(
                                        (_item) => _item.id === item.id_order
                                    )?.quantity,
                                    weight: orderInfo.find((_item) => _item.id === item.id_order)
                                        ?.weight,
                                    company_name: routeStore.find(
                                        (_company) => _company.id === item.company_id
                                    )?.company_name,
                                    cargo_type: item?.cargo_type,
                                    status: item?.status,
                                    dispatched_at: selectedRoute?.deliveryRoute?.dispatched_at,
                                    finished_at: item?.finished_at,
                                };
                            })}
                            pagination={false}
                            rowKey={(item: any) => item.id}
                        />
                    )}
                </Body>

            </Content>
        </Modal >
    );
}

export default InfoRouteModal;
