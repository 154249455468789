import styled from "styled-components";

export const ContentDisabledStore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-25);
  padding: .5rem;
  border-radius: 8px;
  margin-bottom: .5rem;

  span {
    font-size: 1.1rem;
    color: var(--chain-gang-grey);
  }
`;