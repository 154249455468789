import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import moment from 'moment';
import { Modal, notification } from 'antd';

import api from '../../../services/api';
import { currencyFormater } from '../../../services/currencyFormater';

import { Royalty } from '../../../models/Royalty';

import { ButtonSave, Table } from './styles'

interface IProps {
    royalty: Royalty | undefined;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

interface IAudit {
    id: number;
    store_id: number;
    user_name: string;
    type: number;
    field: string;
    old_value: null;
    new_value: number;
    user_id: number;
    royalty_payments_id: number;
    created_at: Date;
    deleted_at: Date;
}

const ModalAudit: React.FC<IProps> = ({
    royalty,
    visible,
    setVisible
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [audit, setAudit] = useState<IAudit[]>([]);

    useEffect(() => {
        const getAudit = async () => {
            if (!royalty) return;

            setLoading(true);
            try {
                const { data: { content } } = await api.get(`/royalty_payments/audit/${royalty.id}?page=1&size=5`);
                setAudit(content);
            } catch (error) {
                notification.error({
                    message: "Ops, Um erro interno ocorreu.",
                    duration: 5,
                });
            } finally {
                setLoading(false);
            }
        }

        getAudit();
    }, [royalty]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text) => <span>{text}</span>
        },
        {
            title: "Campo",
            dataIndex: "field",
            key: "field",
            render: (text) => <span>{text}</span>
        },
        {
            title: "Responsável",
            dataIndex: "user_name",
            key: "user_name",
            render: (text) => <span>{text}</span>
        },
        {
            title: "Alteração",
            dataIndex: "values",
            key: "values",
            render: (_, record) => (
                <span> R$
                    {record?.field !== "Pagamento deletado"
                        ? currencyFormater(+record?.new_value)
                        : currencyFormater(+record?.old_value)}
                </span>

            )
        },
        {
            title: "Modificado em",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => <span>{text}</span>
        }
    ]

    return (
        <Modal
            title={`Auditoria de pagamento`}
            visible={visible}
            centered
            destroyOnClose={true}
            closable={false}
            width={'50%'}
            footer={[
                <ButtonSave
                    loading={loading}
                    onClick={() => setVisible(false)}
                >
                    Fechar
                </ButtonSave>,
            ]}
        >
            <Table
                loading={loading}
                columns={columns}
                pagination={false}
                dataSource={audit.map((entity) => ({
                    ...entity,
                    key: entity.id,
                }))}
            />
        </Modal>
    )
}

export default ModalAudit