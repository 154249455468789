import React, { useEffect, useState } from 'react'

import { Link, RouteComponentProps, useHistory } from 'react-router-dom'

import apiMercury from '../../../services/apiMercury'
import apiS3Handler from '../../../services/apiS3Handler'

import PageContainer from '../../../containers/PageContainer'

import OrderPaymentType from "../../../models/json/OrderPaymentType.json";
import OcurrencyType from "../../../models/json/OcurrencyType.json";
import PaymentType from "../../../models/json/PaymentType.json";
import CategoryAccountType from "../../../models/json/CategoryAccountType.json";
import { PaymentCondition } from '../../../models/enums/PaymentCondition'

import Upload from '../../../components/Upload'
import MonetaryInput2 from '../../../components/MonetaryInput2'
import SearchProvider from '../../../components/SearchProvider';
import ModalProvider from '../../../components/ModalProvider';

import moment from 'moment';

import {
    Col,
    Form,
    Modal,
    notification,
    Row,
    Tooltip,
} from 'antd'

import {
    AddNewSupplier,
    ButtonCancel,
    ButtonSave,
    Container,
    Content,
    ContentFiles,
    ContentFilesRow,
    ContentImage,
    ContentPDF,
    DatePicker,
    FiletypePdfIcon,
    Footer,
    InfoCircleIcon,
    Input,
    Select,
    Title,
    TrashIconUpload
} from './styles'
import locale from 'antd/es/date-picker/locale/pt_BR'

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

const NewAccountPayable: React.FC<ComponentProps> = ({ location }) => {
    const history = useHistory();

    const { state } = location;
    const { accountsPayable, store } = state;

    const [form] = Form.useForm();

    const [paymentValue, setPaymentValue] = useState<number | null>(0);

    const [fileList, setFileList] = useState<File[]>([]);
    const [provider, setProvider] = useState<number | null>(null);

    const [visible, setVisible] = useState<boolean>(false);
    const [ocurrence, setOcurrence] = useState(1);

    const [isLate, setIsLate] = useState(false);

    const handleDateChange = (date) => {
        if (date) {
            const currentDate = moment();
            const selectedDate = moment(date);
            setIsLate(selectedDate.isBefore(currentDate, 'day'));
        } else {
            setIsLate(false); 
        }
    };

    const handleUpload = (files: File[]): void => {
        if (fileList.length + files.length > 5) {
            return notification.warning({
                message: "Limite máximo de imagens excedido",
                description: "O limite de imagens vinculadas é de no máximo 5.",
                duration: 5,
            });
        }

        const sameNames = fileList.filter((uploadedFile) =>
            files.some((file) => file.name === uploadedFile.name)
        );

        if (sameNames.length) {
            return notification.warning({
                message: "Arquivo já selecinado",
                description: "A imagem selecionada já existe na sua lista de upload.",
                duration: 5,
            });
        }

        const _newList = [...fileList, ...files];
        setFileList(_newList);
    };

    const uploadFiles = async () => {
        const result = await Promise.all(
            fileList.map(async (file) => {
                const fileToUpload = new FormData();
                fileToUpload.append("file", file)

                const {
                    data: { location, key },
                } = await apiS3Handler.post(
                    `/s3-upload/upload/amatech-purchase-files`,
                    fileToUpload
                );
                return {
                    url_file: location,
                    s3_key: key,
                };
            })
        )
        return result || [];
    }

    const handleSubmit = async () => {
        const values = form.getFieldsValue();

        const payload = {
            account_type: 2,
            store_id: store,
            category_id: values.category_id,
            provider_id: provider,
            purchase_id: undefined,
            payment_amount: paymentValue,
            due_date: moment(values?.due_date).toISOString(),
            payment_status: values.payment_status,
            payment_method: values.payment_method,
            payment_condition: values.payment_condition,
            issue_date: moment(values?.issue_date).toISOString(),
            month_competence: moment(values?.month_competence).toISOString(),
            observation: values.observation,
            accountsPayableFiles: await uploadFiles(),
            ocurrence: values?.ocurrence
        }

        Modal.confirm({
            title: "Cadastrar Conta?",
            content: "Deseja continuar e cadastrar essa conta a pagar?",
            okText: "Sim",
            okType: "primary",
            cancelText: "Não",
            centered: true,
            async onOk() {
                try {
                    await apiMercury.post(`/accounts-payable`, payload);

                    form.resetFields();
                    notification.success({
                        message: "Conta criada com sucesso",
                        duration: 5,
                    });

                    history.push({
                        pathname: "/bills-to-pay",
                        state: { store_param: store }
                    });
                } catch (error) {
                    //@ts-ignore
                    const _description = error?.response?.data?.error?.message;

                    notification.error({
                        message: `Oops, ocorreu um erro ao cadastrar a compra`,
                        description: _description,
                        duration: 5,
                    });
                }
            },
        });
    };

    const deleteUploadDocument = async (
        s3Key: string,
        purchaseFileId?: number
    ) => {
        try {
            if (s3Key) {
                await apiS3Handler.delete(`/s3-upload/upload-services/${s3Key}`);
            }

            if (purchaseFileId) {
                await apiMercury.delete(`/purchases/${purchaseFileId}`);
            }

            const updatedFileList =
                accountsPayable?.accountsPayableFiles.filter((item) => item.s3_key !== s3Key) || [];

            setFileList(updatedFileList);

            notification.success({
                message: "Arquivo deletado com sucesso",
                duration: 5,
            });
        } catch (error) {
            notification.error({
                message: "Erro ao deletar imagem",
                duration: 5,
            });
        }
    };

    return (
        <PageContainer
            route="Nova Conta a Pagar"
            content={
                <>
                    <Link
                        to={{
                            pathname: "/bills-to-pay",
                            state: { store_param: store },
                        }}
                    >
                        Voltar
                    </Link>
                </>
            }
        >
            <Container>
                <Form
                    layout="vertical"
                    initialValues={{ remember: false }}
                    form={form}
                >
                    <Content>
                        <Row gutter={8}>
                            <Col md={12} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Fornecedor
                                            <Tooltip title="Empresa responsável pela venda dos produtos">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="provider_id"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <SearchProvider
                                        defaultValue={state.provider?.id}
                                        handleChange={(value) => setProvider(value)}
                                        height="2.5rem"
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Categoria
                                            <Tooltip title="Tipo do produto">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="category_id"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Selecione uma categoria"
                                        disabled={!provider}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(value) => form.setFieldsValue({ category_id: value })}
                                    >
                                        {CategoryAccountType.map((productCategory) => (
                                            <Select.Option
                                                value={productCategory.value}
                                                key={productCategory.value}
                                                label={productCategory.label}
                                            >
                                                {productCategory.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={8}>
                            <Col xs={24}>
                                <AddNewSupplier onClick={() => setVisible(true)}>Cadastrar novo fornecedor</AddNewSupplier>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col xs={24}>
                                <Title>Pagamento</Title>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col md={ocurrence !== PaymentCondition.A_VISTA ? 3 : 4} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Valor
                                            <Tooltip title="Preço de uma unidade do produto">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name={`payment_amount`}
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <MonetaryInput2
                                        style={{ height: "2.5rem" }}
                                        getValue={(value) => setPaymentValue(+value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={ocurrence !== PaymentCondition.A_VISTA ? 2 : 3} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Ocorrência
                                            <Tooltip title="Preço de uma unidade do produto">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name={`payment_condition`}
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <Select
                                        placeholder="Selecione uma opção"
                                        onChange={(value) => {
                                            form.setFieldsValue({ occurrence: value })
                                            setOcurrence(+value)
                                        }}
                                        value={form.getFieldValue("occurrence")}
                                    >
                                        {OcurrencyType.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {ocurrence !== PaymentCondition.A_VISTA && (
                                <Col xs={2}>
                                    <Form.Item
                                        label="Parcelas"
                                        name="ocurrence"
                                        rules={[]}
                                    >
                                        <Input
                                            type="text"
                                            name="ocurrence"
                                            placeholder="*"
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col md={3} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Data de Venc.
                                            <Tooltip title="Dia que a compra foi feita">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="due_date"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <DatePicker
                                        placeholder="dd/mm/aaaa"
                                        format="DD/MM/YYYY"
                                        name="due_date"
                                        onChange={handleDateChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={3} xs={24}>
                                <Form.Item
                                    name={`payment_status`}
                                    label={
                                        <span>
                                            Status do Pgto.
                                            <Tooltip title="Status do Pagamento">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    rules={[
                                        { required: true, message: "Campo obrigatório" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Selecione uma opção"
                                        onChange={(value) => form.setFieldsValue({ payment_status: value })}
                                    >
                                        {PaymentType?.map((type) => (
                                            <Select.Option value={type.value} key={type.value} disabled={isLate && type.value !== 2}>
                                                {type.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={3} xs={24}>
                                <Form.Item
                                    name={`payment_method`}
                                    label={
                                        <span>
                                            Forma de Pgto.
                                            <Tooltip title="Método de pagamento">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    rules={[
                                        { required: true, message: "Campo obrigatório" },
                                    ]}
                                >
                                    <Select
                                        placeholder="Selecione uma opção"
                                        onChange={(value) => form.setFieldsValue({ payment_method: value })}
                                    >
                                        {OrderPaymentType?.map((type) => (
                                            <Select.Option value={type.value} key={type.value}>
                                                {type.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={4} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Data de Emissão
                                            <Tooltip title="Dia que a compra foi feita">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="created_at"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <DatePicker
                                        placeholder="dd/mm/aaaa"
                                        format="DD/MM/YYYY"
                                        name="created_at"
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={4} xs={24}>
                                <Form.Item
                                    label={
                                        <span>
                                            Mês de Competência
                                            <Tooltip title="Mês e ano da competência">
                                                <InfoCircleIcon />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="month_competence"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <DatePicker
                                        placeholder="mm/aaaa"
                                        format="MM/YYYY"
                                        picker="month"
                                        name="month_competence"
                                        locale={locale}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col xs={24}>
                                <Title>Observações e Anexos</Title>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col xs={24}>
                                <Form.Item
                                    label="Observação"
                                    name="observation"
                                    rules={[]}
                                >
                                    <Input
                                        type="text"
                                        name="observation"
                                        placeholder="Adicione uma observação"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col xs={24}>
                                <Form.Item label="Anexos">
                                    <Upload
                                        onUpload={(file) => handleUpload(file)}
                                        maxFiles={3}
                                        fileType="image/*,.pdf,.zip,.rar"
                                        dropzoneMsg={"Arraste seu arquivo ou procure aqui!"}
                                    />
                                    {accountsPayable?.accountsPayableFiles &&
                                        accountsPayable?.accountsPayableFiles.length > 0 ? (
                                        <ContentFiles>
                                            {accountsPayable?.accountsPayableFiles.map((item, index) => (
                                                <ContentFilesRow key={index}>
                                                    <div>
                                                        {item.url_file.endsWith(".pdf") ? (
                                                            <ContentPDF>
                                                                <FiletypePdfIcon onClick={item.url_file} />
                                                            </ContentPDF>
                                                        ) : item.url_file.endsWith(".jpg") ||
                                                            item.url_file.endsWith(".jpeg") ||
                                                            item.url_file.endsWith(".png") ? (
                                                            <ContentImage
                                                                src={item.url_file}
                                                                alt={`Purchase File ${index + 1}`}
                                                            />
                                                        ) : (
                                                            <ContentPDF>
                                                                <FiletypePdfIcon />
                                                                <a
                                                                    href={item.url_file}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    visualizar arquivo:{" "}
                                                                    {item.url_file.split("/").pop()}
                                                                </a>
                                                            </ContentPDF>
                                                        )}
                                                    </div>
                                                    <TrashIconUpload
                                                        onClick={() =>
                                                            deleteUploadDocument(item.s3_key, item?.id)
                                                        }
                                                    />
                                                </ContentFilesRow>
                                            ))}
                                        </ContentFiles>
                                    ) : (
                                        fileList && (
                                            <ContentFiles>
                                                {fileList.map((item, index) => (
                                                    <ContentFilesRow key={index}>
                                                        <span>{item?.name}</span>
                                                        <TrashIconUpload
                                                            onClick={() => deleteUploadDocument(item?.name)}
                                                        />
                                                    </ContentFilesRow>
                                                ))}
                                            </ContentFiles>
                                        )
                                    )}

                                </Form.Item>
                            </Col>
                        </Row>

                        <Footer>
                            <ButtonSave onClick={() => handleSubmit()}                            >
                                Salvar
                            </ButtonSave>
                            <ButtonCancel>
                                Cancelar
                            </ButtonCancel>
                        </Footer>
                    </Content>
                </Form>
            </Container>

            <ModalProvider
                visible={visible}
                setVisible={setVisible}
                providerId={state.provider?.id}
            />
        </PageContainer >
    )
}

export default NewAccountPayable