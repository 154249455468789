import styled from "styled-components";

import { PowerBIEmbed as PowerBIEmbedComponent } from "powerbi-client-react";

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: #f5f5f5;

  .embed-container {
    width: 100%;
    height: 100%;
  }
`;

export const PowerBIEmbed = styled(PowerBIEmbedComponent)``;
