import React from "react";

import {
  Container,
  TopContent,
  Title,
  Content,
  Row,
  CancelIcon,
} from "./styles";

interface IProps {
  title?: string;
  withDelete?: boolean;
  handleDelete?: any;
  width?: string | number;
}

const FormCard: React.FC<IProps> = ({
  title,
  children,
  withDelete,
  handleDelete,
  width,
}) => (
  <Container width={width}>
    <TopContent className="formTopContent">
      <Title className="formTitle">{title}</Title>
      {withDelete && <CancelIcon onClick={() => handleDelete()} />}
    </TopContent>
    <Content className="formContent">
      <Row className="formRow">{children}</Row>
    </Content>
  </Container>
);

export default FormCard;
