import React from "react";

import { Container, Spin } from "./styles";

import SpinIcon from "../../assets/svg/spin.svg";

const Spinner: React.FC = () => {
  return (
    <Container>
      <Spin src={SpinIcon} />
    </Container>
  );
};

export default Spinner;
