import styled, { css } from "styled-components";

import {
  Table as TableAnt,
  Modal as ModalAnt,
  Input as InputAnt,
  Button as ButtonAnt,
  Form as FormAnt,
  Row as RowAnt,
  Col as ColAnt,
} from "antd";

import {
  CancelCircle,
  DocumentPdf,
  Edit,
  FiletypeXml,
  InfoCircle,
  MoreHoriz,
  Send,
} from "../../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  .longMessage {
    overflow: hidden;
    overflow-wrap: unset;
    text-overflow: ellipsis;
  }
`;

export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--gray-50);

    max-height: 45rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: #fffefe;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #acaeae;
      border-radius: 5px;
    }

    @media only screen and (min-width: 575px) {
      overflow-y: scroll;
    }

    @media only screen and (max-width: 1250px) {
      max-height: 35rem;
    }

    @media only screen and (max-width: 800px) {
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: unset;
    .ant-table-row .ant-table-row-level-0 {
      :last-child {
        margin-bottom: 0.5rem;
      }
    }
    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.2rem;
      height: 3.25rem;
      max-height: 3.25rem;
      font-size: 0.7rem;
    }
  }

  .ant-pagination-options-size-changer.ant-select {
    width: 6.3rem;
  }
  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.2rem;
      font-size: 0.7rem;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .centralizer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange-350);
  margin-left: 1%;
  min-width: 2rem;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;
`;

export const PdfIcon = styled(DocumentPdf)`
  width: 18px;
  height: 18px;
  margin: 0.2rem;
`;
export const XmlIcon = styled(FiletypeXml)`
  width: 18px;
  height: 18px;
  margin: 0.2rem;
`;

export const CancelCircleIcon = styled(CancelCircle)`
  width: 18px;
  height: 18px;
  margin: 0.2rem;
`;

export const EditIcon = styled(Edit)`
  width: 18px;
  height: 18px;
  margin: 0.2rem;
`;

export const SendIcon = styled(Send)`
  width: 18px;
  height: 18px;
  margin: 0.2rem;
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1 1rem;
  }
  .ant-modal-body {
    padding: 0 1rem;
  }
  .ant-modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1 1rem;
  }
  .ant-row .ant-form-item {
    margin: 0;
  }
`;

export const Form = styled(FormAnt)``;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)``;

export const Input = styled(InputAnt)``;

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--orange-350);
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  width: 7rem;

  :hover,
  :active,
  :focus {
    color: var(--white);
    background: var(--orange-350);
    border: none;
    box-shadow: none;
    opacity: 70%;
  }
`;

export const Button = styled(ButtonAnt)`
  ${buttonCss}
`;

export const ButtonCancel = styled(ButtonAnt)`
  ${buttonCss}
  color: var(--gray-45);
  background: var(--white);
  border: 1px solid var(--gray-45);

  :hover,
  :active,
  :focus {
    color: var(--gray-45);
    background: var(--white);
    border: 1px solid var(--gray-45);
  }
`;

export const IconInfo = styled(InfoCircle)`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.3rem;
  color: var(--gray-600);
`;
