import axios from "axios";
import { getToken } from "./auth";
import { logout } from "./auth";

const API_URL = process.env.REACT_APP_API_AUTH_URL;
const api = axios.create({
  baseURL: API_URL,
  responseType: "json",
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    let currentToken = config?.headers?.Authorization;
    if (currentToken) {
      currentToken = currentToken.includes("Bearer")
        ? currentToken.split(" ")[1]
        : currentToken;
    }
    config.headers.Authorization = `Bearer ${currentToken || token}`;
  }
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
