import styled from "styled-components";
import { DatePicker as DatePickerWithoutStyles } from "antd";

export const DatePicker = styled(DatePickerWithoutStyles)`
  width: -webkit-fill-available;
  input {
    font-size: 18px;
    text-align: center;
  }
`;
