import styled from "styled-components";

import { Modal as ModalAnt, Table as TableAnt } from "antd";

import { KeyboardArrowLeft, KeyboardArrowRight } from "../../../styles/Icons";

export const Container = styled(ModalAnt)``;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`
  padding: 0.2rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
`;

export const HeaderOrder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
`;

export const MoreIcon = styled(KeyboardArrowRight)`
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--gray-45);
`;
export const ReturnIcon = styled(KeyboardArrowLeft)`
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--gray-45);
`;
