import React, { useEffect, useState } from 'react'
import api from '../../services/apiSalesHandler';

import { Empty, message } from 'antd';
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from 'moment';


import PageContainer from '../../containers/PageContainer'
import DatePickerAnt from '../../components/DatePicker';
import ProgressSaleBar from '../../components/ProgressSaleBar';

import { Container, TopSide, Row, Col, EmptyContent, ContentBody, ContentInfo, Table, TotalSale, SummaryTypeSale, ContentSummaryTypeSale, LegendSummaryType, ContentDescription } from './styles'

interface IAppProps {
  company_name: string;
  id: number;
  total_percent_with_cpf: number;
  total_percent_without_cpf: number;
  total_sales: number;
  total_sales_with_cpf: number;
  total_sales_without_cpf: number;
}

const PontosApp = () => {
  const [loading, setLoading] = useState(false);
  const [salesInfo, setSalesInfo] = useState<IAppProps[]>([])
  const [selectedDate, setSelectedDate] = useState({
    data_inicial: moment(),
    data_final: moment(),
  });

  useEffect(() => {
    const getSaleInfo = async () => {
      setLoading(true)
      const { data_inicial, data_final } = {
        data_inicial: moment(selectedDate.data_inicial).format("DD/MM/YYYY"),
        data_final: moment(selectedDate.data_final).add(1, "days").format("DD/MM/YYYY"),
      };

      try {
        const {
          data: { content },
        } = await api.get(`/sales/with-cpf?initial_date=${data_inicial}&final_date=${data_final}`);
        setSalesInfo(content);
        setLoading(false);
      } catch (error) {
        const _message =
          //@ts-ignore
          error?.response?.data?.message;
        message.error(_message || "Houve um erro ao realizar busca");
      }
    }

    getSaleInfo()
  }, [selectedDate.data_inicial, selectedDate.data_final])

  const data = [
    {
      title: "Loja",
      dataIndex: "company_name",
      key: "company_name",
      width: '15%',
      render: (text, record) => (
        <ContentInfo>
          {record.company_name}
        </ContentInfo>
      ),
    },
    {
      title: "Total de vendas",
      dataIndex: "total_sales",
      key: "total_sales",
      width: '18%',
      sorter: (a, b) => a.total_sales - b.total_sales,
      render: (text, record) => (
        <span>
          {record.total_sales} vendas
        </span>
      ),
    },
    {
      title: "Total de vendas com CPF",
      dataIndex: "total_sales_with_cpf",
      responsive: ["md"] as any,
      key: "total_sales_with_cpf",
      width: '18%',
      render: (text, record) => (
        <span>
          {record.total_sales_with_cpf} vendas
        </span>
      ),
    },
    {
      title: "Total de vendas sem CPF",
      dataIndex: "total_sales_without_cpf",
      responsive: ["md"] as any,
      key: "total_sales_without_cpf",
      width: '18%',
      render: (text, record) => (
        <span>
          {record.total_sales_without_cpf} vendas
        </span>
      ),
    },
    {
      title: "Porcentagem de vendas",
      dataIndex: "total_sales_percent",
      key: "total_sales_percent",
      render: (text, record) => (
        <>
          {record.total_sales ? (
            <>
              <ProgressSaleBar
                percentWithCPF={record.total_percent_with_cpf}
                percentWithoutCPF={record.total_percent_without_cpf}
              />
            </>
          ) : "Não há vendas no período selecionado."}
        </>
      ),
    },
  ];

  return (
    <PageContainer route="Vendas do Club">
      <Container>
        <TopSide>
          <Row gutter={8}>
            <Col xl={6} md={24}>
              <DatePickerAnt
                date={selectedDate}
                setDate={setSelectedDate}
                locale={locale}
                bordered={true}
                defaultValue={[moment(), moment().add(1, 'day')]}
              />
            </Col>

            <Col xl={24} md={24}>
              <SummaryTypeSale>
                <ContentSummaryTypeSale>
                  <LegendSummaryType>
                    <ContentDescription>
                      <span className="with-cpf">
                        <div /> Vendas com CPF
                      </span>
                    </ContentDescription>

                    <ContentDescription>
                      <span className="without-cpf">
                        <div /> Vendas sem CPF
                      </span>
                    </ContentDescription>
                  </LegendSummaryType>
                </ContentSummaryTypeSale>
              </SummaryTypeSale>
            </Col>
          </Row>
        </TopSide>

        {!salesInfo && (!selectedDate.data_inicial || !selectedDate.data_final) ? (
          <EmptyContent>
            <Empty description="Selecione uma data" />
          </EmptyContent>
        ) : (
          <ContentBody>
            <Table
              loading={loading}
              columns={data}
              dataSource={salesInfo.map((record) => ({
                key: record.id,
                ...record,
              }))}
            />
          </ContentBody>
        )
        }
      </Container>
    </PageContainer>
  )
}

export default PontosApp