import React, { Dispatch, SetStateAction, useState } from "react";

import api from "../../../services/api";

import { Order as OrderModel } from "../../../models/Order/Order";

import { notification } from "antd";

import {
  Container,
  RatingContent,
  CommentContent,
  Title,
  Rate,
  StarIcon,
  Divider,
  TextArea,
  ButtonCancel,
  Button,
  Form,
  StepperContainer,
  Steps,
  StepIcon,
} from "./styles";

interface Rating {
  rating_product: number;
  obs_rating_product: string;
  rating_time: number;
  obs_rating_time: string;
  rating_assistant: number;
  obs_rating_assistant: string;
}
interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalRatingOrder: React.FC<IProps> = ({
  setVisible,
  setShouldSearch,
  visible,
  order,
}) => {
  const [rate, setRate] = useState<Rating>({
    rating_product: 0,
    obs_rating_product: "",
    rating_time: 0,
    obs_rating_time: "",
    rating_assistant: 0,
    obs_rating_assistant: "",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);

  const clearFields = () => {
    setCurrentPage(1);
    setVisible(false);
    setRate({
      rating_product: 0,
      obs_rating_product: "",
      rating_time: 0,
      obs_rating_time: "",
      rating_assistant: 0,
      obs_rating_assistant: "",
    });
  };

  const handleFeedBack = async () => {
    try {
      setLoading(true);
      const payload = {
        id: order?.id,
        comment: order?.comment,
        observation: order?.observation,
        status: order?.status,
        time_to_get: order?.time_to_get,
        to_delivery: order?.to_delivery,
        total: order?.total,
        rating_product: rate.rating_product,
        obs_rating_product: rate.obs_rating_product,
        rating_time: rate.rating_time,
        obs_rating_time: rate.obs_rating_time,
        rating_assistant: rate.rating_assistant,
        obs_rating_assistant: rate.obs_rating_assistant,
      };

      await api.put(`/orders/${order?.id}`, payload);
      notification.success({
        message: "Sua avaliação foi registrada com sucesso!",
        duration: 5,
      });
      clearFields();
      setShouldSearch(true);
    } catch (error) {
      //@ts-ignore
      const _description = error.message;
      notification.error({
        message: "Oops! Não foi possível cadastrar sua avaliação",
        description: _description,
        duration: 5,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      title="Avaliação do pedido"
      visible={visible}
      centered
      width={800}
      destroyOnClose={true}
      closable={false}
      footer={[
        <ButtonCancel
          key={"cancelButton"}
          onClick={() => {
            clearFields();
          }}
        >
          Cancelar
        </ButtonCancel>,
        <>
          {currentPage > 1 && (
            <Button
              key={"cancelStep1"}
              onClick={() => setCurrentPage((prev) => prev - 1)}
              style={{ backgroundColor: "var(--gray-150)" }}
            >
              Voltar
            </Button>
          )}
        </>,
        <>
          {(currentPage === 1 || currentPage === 2) && (
            <Button
              key={"cancelStep2"}
              onClick={async () => {
                setCurrentPage((prev) => prev + 1);
              }}
              disabled={
                (currentPage === 1 &&
                  !rate.rating_product &&
                  !order?.rating_product) ||
                (currentPage === 2 &&
                  !rate.rating_time &&
                  !order?.rating_product)
              }
            >
              Próximo
            </Button>
          )}
        </>,
        <>
          {currentPage === 3 && (
            <Button
              key={"cancelStep3"}
              loading={loading}
              onClick={() =>
                !order?.rating_assistant ? handleFeedBack() : clearFields()
              }
              disabled={!order?.rating_assistant && !rate.rating_assistant}
            >
              Enviar
            </Button>
          )}
        </>,
      ]}
    >
      <Form layout="vertical" initialValues={{ remember: false }}>
        <StepperContainer>
          <label>
            Progresso da Avaliação: <span>{currentPage} de 3</span>
          </label>

          <Steps currentPage={currentPage}>
            <StepIcon className="step-icon1" />
            <div className="step-line1" />
            <StepIcon className="step-icon2" />
            <div className="step-line2" />
            <StepIcon className="step-icon3" />
          </Steps>
        </StepperContainer>
      </Form>
      {currentPage === 1 && (
        <>
          <RatingContent>
            <Title>O que achou do nosso produto? Avalie:</Title>
            <Rate
              count={5}
              character={<StarIcon />}
              onChange={(value) =>
                setRate((oldValues) => ({
                  ...oldValues,
                  rating_product: value,
                }))
              }
              defaultValue={order?.rating_product || rate.rating_product}
              disabled={!!order?.rating_product}
            />
          </RatingContent>
          <Divider />
          <CommentContent>
            <Title>Compartilhe sua experiência conosco</Title>
            <TextArea
              rows={5}
              onChange={({ target: { value } }) =>
                setRate((oldValues) => ({
                  ...oldValues,
                  obs_rating_product: value,
                }))
              }
              disabled={order?.obs_rating_product ? true : false}
              defaultValue={
                rate.obs_rating_product || order?.obs_rating_product
              }
            />
          </CommentContent>
        </>
      )}
      {currentPage === 2 && (
        <>
          {" "}
          <RatingContent>
            <Title>O item foi entregue dentro do prazo? Avalie:</Title>
            <Rate
              count={5}
              character={<StarIcon />}
              onChange={(value) => {
                setRate((oldValues) => ({
                  ...oldValues,
                  rating_time: value,
                }));
              }}
              defaultValue={order?.rating_time || rate.rating_time}
              disabled={!!order?.rating_time}
            />
          </RatingContent>
          <Divider />
          <CommentContent>
            <Title>Compartilhe sua experiência conosco</Title>
            <TextArea
              rows={5}
              onChange={({ target: { value } }) =>
                setRate((oldValues) => ({
                  ...oldValues,
                  obs_rating_time: value,
                }))
              }
              disabled={order?.obs_rating_time ? true : false}
              defaultValue={rate.obs_rating_time || order?.obs_rating_time}
            />
          </CommentContent>
        </>
      )}
      {currentPage === 3 && (
        <>
          {" "}
          <RatingContent>
            <Title>
              O que você achou do motorista e de seu ajudante? Avalie:
            </Title>
            <Rate
              count={5}
              character={<StarIcon />}
              onChange={(value) => {
                setRate((oldValues) => ({
                  ...oldValues,
                  rating_assistant: value,
                }));
              }}
              defaultValue={order?.rating_assistant || rate.rating_assistant}
              disabled={!!order?.rating_assistant}
            />
          </RatingContent>
          <Divider />
          <CommentContent>
            <Title>Compartilhe sua experiência conosco</Title>
            <TextArea
              rows={5}
              onChange={({ target: { value } }) =>
                setRate((oldValues) => ({
                  ...oldValues,
                  obs_rating_assistant: value,
                }))
              }
              disabled={order?.obs_rating_assistant ? true : false}
              defaultValue={
                rate.obs_rating_assistant || order?.obs_rating_assistant
              }
            />
          </CommentContent>
        </>
      )}
    </Container>
  );
};

export default ModalRatingOrder;
