import React, { Dispatch, SetStateAction, } from 'react'
import { Button } from 'antd';
import { Body, Content, Header, Modal, Table } from './styles';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedRoute: any | null;
}

const OcorrencyAudit: React.FC<IProps> = ({ visible, setVisible, selectedRoute }) => {
  const columns = [
    {
      title: "ID do item da rota",
      dataIndex: "id",
      width: '10%',
      align: "center" as const,
      key: "id",
    },
    {
      title: "ID do pedido",
      dataIndex: "id_order",
      width: '10%',
      align: "center" as const,
      key: "id_order",
    },
    {
      title: "Sequencia",
      dataIndex: "sequence",
      width: '10%',
      align: "center" as const,
      key: "sequence",
    },
    {
      title: "Descrição",
      dataIndex: "comment",
      align: "center" as const,
      key: "comment",
      render: (text) => (
        <span>
          {text ? text : "Não há nenhuma ocorrência nessa rota"}
        </span>
      )
    },
  ]

  return (
    <Modal
      title={
        <Header>
          <span>Histórico de ocorrências</span>
        </Header>
      }
      destroyOnClose
      centered
      closable
      visible={visible}
      onCancel={() => setVisible(false)}
      width={"80%"}
      footer={[
        <Button key="ok" type="primary" onClick={() => setVisible(false)}>Fechar</Button>,
      ]}
    >
      <Content>
        <Body>
          <Table
            columns={columns}
            dataSource={selectedRoute?.deliveryRoute?.deliveryRouteItem?.map((item, index) => {
              return {
                ...item,
              }
            })}
            pagination={false}
            rowKey={(item: any) => item.id}
          />
        </Body>
      </Content>
    </Modal>
  )
}

export default OcorrencyAudit