import React, { Dispatch, SetStateAction } from "react";
import { Moment } from "moment";

import RangeDataField from "../../components/RangeDataField";
import FixedIntervalDateField from "../../components/FixedIntervalDateField";
import Button from "../../components/Button";

import { Column, Select, ActionContainer } from "./styles";
import { Row, Col } from "antd";
const { Option } = Select;

interface IProps {
  loadingStore: boolean;
  stores: { key: number; value: string }[];
  data: { data_inicial: Moment; data_final: Moment };
  setData: Dispatch<
    SetStateAction<{ data_inicial: Moment; data_final: Moment }>
  >;
  loadingHandlers: boolean;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setStore: Dispatch<SetStateAction<number | undefined>>;
}

const HandlerSearch: React.FC<IProps> = ({
  loadingStore,
  stores,
  data,
  setData,
  setShouldSearch,
  loadingHandlers,
  setStore,
}) => {
  return (
    <Row>
      <Column sm={4} xs={24}>
        <Select
          placeholder="Loja"
          style={{ width: "100%", textTransform: "uppercase" }}
          onChange={(id) => setStore(+id)}
          loading={loadingStore}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {stores.map((store) => (
            <Option
              key={store.key}
              value={store.key}
              style={{ textTransform: "uppercase" }}
            >
              {store.value}
            </Option>
          ))}
        </Select>
      </Column>
      <Col sm={4} xs={0}>
        <RangeDataField data={data} setData={setData} />
      </Col>
      <Col sm={0} xs={15}>
        <FixedIntervalDateField data={data} setData={setData} />
      </Col>
      <ActionContainer>
        <Button onClick={() => setShouldSearch(true)} loading={loadingHandlers}>
          Buscar
        </Button>
      </ActionContainer>
    </Row>
  );
};

export default HandlerSearch;
