import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../../../services/api';
import { Col, message, Button } from 'antd';
import { DeliveryRoute, Props } from '../../../../models/DeliveryRoute';
import { Order } from '../../../../models/Order/Order';
import { OrderItem } from '../../../../models/Order/OrderItem';
import {
    Body,
    Content,
    Header,
    Modal,
    Select,
    Table,
    Option,
    Box,
    ButtonAddOrRemove,
    ContentFlex,
    TotalBox
} from './styles';
import { Status } from '../../../../models/enums/cargoType';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setShouldSearch: Dispatch<SetStateAction<boolean>>;
    setStore: React.Dispatch<React.SetStateAction<number | null | undefined>>;
    stores: {
        key: number;
        value: string;
        cnpj: string;
    }[];
    routes: Props | any;
    loadingStores: boolean;
    setRoutes: React.Dispatch<React.SetStateAction<DeliveryRoute[]>>;
}

interface IOrderInfo {
    index: number | null;
    store_id: number | null;
    order_id: number | null;
    cargo_type: number | null;
    sequence: number | null;
    weight: number | undefined;
    quantity: number | undefined;
    order: Order[] | null;
}

const NewRouteModal: React.FC<IProps> = ({ visible, setVisible, setShouldSearch, stores, loadingStores }) => {
    const [rows, setRows] = useState<IOrderInfo[]>([]);
    const [cargoTypeInfo, setCargoTypeInfo] = useState<OrderItem[] | undefined>([]);
    const [selectedCargoTypes, setSelectedCargoTypes] = useState<(number | undefined)[]>([]);
    const [totals, setTotals] = useState<{ weight: number; quantity: number }>({ weight: 0, quantity: 0 });

    const [storeLoading, setStoreLoading] = useState(false);
    const [filteredCargoTypes, setFilteredCargoTypes] = useState<OrderItem[] | undefined>([])

    const calculateTotals = () => {
        const { weight, quantity } = rows.reduce((acc, row) => {
            if (row.weight && row.quantity) {
                acc.weight += row.weight;
                acc.quantity += row.quantity;
            }
            return acc;
        }, { weight: 0, quantity: 0 });
        setTotals({ weight, quantity });
    };

    useEffect(() => {
        calculateTotals();
    }, [rows]);

    useEffect(() => {
        if (visible && rows.length === 0) {
            addRow();
        }
    }, [visible]);

    const addRow = () => {
        setRows(prevRows => [
            ...prevRows,
            {
                index: prevRows.length,
                store_id: null,
                order_id: null,
                cargo_type: null,
                sequence: null,
                weight: undefined,
                quantity: undefined,
                order: []
            }
        ]);
        setSelectedCargoTypes(prevTypes => [...prevTypes, undefined]);
    };

    const removeRow = async (indexOrder: any) => {
        setRows(oldValues => oldValues.filter((_, index) => index !== indexOrder));
    };

    const handleOrderChange = (index: number, orderIds: number, order: Order[]) => {
        const ordersInfo = order?.find((item) => item.id === orderIds);
        const cargoType = ordersInfo?.orderItems?.filter((item) => item.products)

        setCargoTypeInfo(cargoType)
       
        setRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows[index] = {
                ...updatedRows[index],
                order_id: orderIds,
            };
            return updatedRows;
        });
    };

    const handleCargoTypeChange = (index: number, cargoType: number) => {
        const filteredProducts = cargoTypeInfo?.filter(item => item.products.type_cargo === cargoType);
        const cargoTypeAlreadySelected = rows.some(row => row.cargo_type === cargoType && row.index !== index && row.order_id === rows[index].order_id);

        if (cargoTypeAlreadySelected) {
            message.warning("Esse tipo de carga já foi selecionado em outra linha com o mesmo número de pedido.");
            return;
        }

        if (filteredProducts?.length === 0) {
            message.warn(`Não há produtos do tipo selecionado ${cargoType === 0 ? 'gelado' : 'seco'}`);
            return;
        }
        setFilteredCargoTypes(filteredProducts)

        const calculateTotalWeight = () => {
            return filteredProducts?.reduce(
                (total, item) => total + Number(item.quantity) * Number(item.products.weight), 0);
        };
        const calculateTotalQuantity = () => {
            return filteredProducts?.reduce(
                (total, item) => total + Number(item.quantity), 0);
        };

        setRows(prevRows => {
            const updatedRows = [...prevRows];
            const updatedOrder = { ...updatedRows[index] };
            updatedOrder.weight = Number(calculateTotalWeight()?.toFixed(2));
            updatedOrder.quantity = calculateTotalQuantity();
            updatedOrder.cargo_type = cargoType;
            updatedRows[index] = updatedOrder;
            return updatedRows;
        });

        setSelectedCargoTypes(prevTypes => {
            const updatedTypes = [...prevTypes];
            updatedTypes[index] = cargoType;
            return updatedTypes;
        });
    };


    const handleStoreChange = async (index: number, storeId: number) => {
        try {
            setStoreLoading(true)
            const { data: { content } } = await api.get(`/orders/store/${storeId}?page=1&size=10&status=0`);

            const filteredOrders = content.filter((order: any) => {
                return order.orderItems.some((item: any) => item.id_delivery_route === null);
            });

            setRows(prevRows => {
                const updatedRows = [...prevRows];
                updatedRows[index].store_id = storeId;
                updatedRows[index].order = filteredOrders;
                return updatedRows;
            });
        } catch (error) {
            //@ts-ignore
            const errorMessage = error?.response?.data?.message;
            message.error(errorMessage || "Erro ao carregar as lojas");
        } finally {
            setStoreLoading(false)
        }
    };



    const clearFields = () => {
        setRows(prevRows => prevRows.map(row => ({
            ...row,
            store_id: null,
            cargo_type: null,
            order: null,
            order_id: null,
            quantity: undefined,
            sequence: null,
            weight: undefined,
        })));
        setSelectedCargoTypes([]);
        setCargoTypeInfo(undefined);
        setVisible(false);
    };

    const addRoute = async () => {
        try {
            const anyFieldEmpty = rows.some(row =>
                row.store_id === null ||
                row.order_id === null ||
                row.cargo_type === null ||
                row.weight === undefined ||
                row.quantity === undefined
            );

            if (anyFieldEmpty) {
                message.error("Por favor, preencha todos os campos antes de adicionar a rota.");
                return;
            }

            const allSameType = rows.every(row => row.cargo_type !== undefined && row.cargo_type === rows[0].cargo_type);
            const hasType0 = rows.some(row => row.cargo_type === 0);
            const hasType1 = rows.some(row => row.cargo_type === 1);

            let cargoTypeToSend;

            if (allSameType) {
                cargoTypeToSend = rows[0].cargo_type;
            } else if (hasType0 && hasType1) {
                cargoTypeToSend = 2;
            } else if (hasType0) {
                cargoTypeToSend = 0;
            } else if (hasType1) {
                cargoTypeToSend = 1;
            } else {
                cargoTypeToSend = 2;
            }

            const payload = rows.map((row, index) => {
                return {
                    sequence: index + 1,
                    company_id: row.store_id,
                    id_order: row.order_id,
                    cargo_type: row.cargo_type,
                    id_order_item: filteredCargoTypes?.map(item => item.id)
                };
            });

            const deliveryRouteItems = {
                deliveryRoute: {
                    status: Status.CRIADA,
                    cargo_type: cargoTypeToSend,
                    total_weight: totals?.weight,
                    total_quantity: totals?.quantity,
                },
                deliveryRouteItems: payload
            };

            await api.post(`/delivery-route`, deliveryRouteItems);

            message.success("Rota adicionada com sucesso!");
            clearFields();
            setVisible(false);
            setShouldSearch(true);
        } catch (error) {
            //@ts-ignore
            const errorMessage = error?.response?.data?.message;
            message.error(errorMessage || "Erro ao fazer a criação da rota");
        } finally {
            setShouldSearch(false);
        }
    };

    const columns = [
        {
            title: "Sequencia",
            width: '5%',
            align: "center" as const,
            dataIndex: "sequence",
            key: "sequence",
            render: (_, __, index) => (
                <Box>
                    {index + 1}
                </Box>
            )
        },
        {
            title: "Nome da loja",
            dataIndex: "store_id",
            align: "center" as const,
            width: '15%',
            key: "store_id",
            render: (store_id, record) => (
                <Select
                    loading={storeLoading}
                    placeholder="Selecione a loja"
                    onChange={(id) => handleStoreChange(record.index, +id)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={store_id}
                    style={{ width: '100%' }}
                >
                    {stores.map((store) => (
                        <Option
                            key={store.key}
                            value={store.key}
                            style={{ textTransform: "capitalize" }}
                        >
                            {store?.value}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: "Nº pedido",
            dataIndex: "order_id",
            width: '15%',
            align: "center" as const,
            key: "order_id",
            render: (_, record) => (
                <Select
                    filterOption={(input, option) =>
                        option?.children.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Selecionar itens"
                    disabled={!record?.store_id}
                    onChange={(items) => handleOrderChange(record.index, items as any, record?.order)}
                    style={{ width: '100%' }}
                >
                    {record?.order?.map((order) => (
                        <Option key={order.id} value={order.id}>
                            {order.vhsys}
                        </Option>
                    ))}
                </Select>
            )
        },
        {
            title: "Tipo carga",
            dataIndex: "cargo_type",
            width: '15%',
            align: "center" as const,
            key: "cargo_type",
            render: (_, record, index) => (
                <Select
                    filterOption={(input, option) =>
                        option?.children.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={!record?.order_id}
                    placeholder="Selecione o tipo de carga"
                    onChange={(type) => { handleCargoTypeChange(record.index, type as any) }}
                    value={selectedCargoTypes[index]}
                    style={{ width: '100%' }}
                >
                    {cargoTypeInfo && cargoTypeInfo.length > 0 && (
                        cargoTypeInfo
                            .filter((item, index, self) =>
                                item.products.type_cargo !== null &&
                                index === self.findIndex((itemIndex) =>
                                    itemIndex.products.type_cargo === item.products.type_cargo &&
                                    rows.some(row =>
                                        row.order?.some(order =>
                                            order.orderItems.some(orderItem =>
                                                orderItem.products.type_cargo === item.products.type_cargo &&
                                                orderItem.id_delivery_route === null
                                            )
                                        )
                                    )
                                )
                            )
                            .map(item => (
                                <Option key={item.products.type_cargo} value={item.products.type_cargo}>
                                    {item.products.type_cargo === 0 ? 'Carga gelada' : 'Carga seca'}
                                </Option>
                            ))
                    )}
                </Select >
            )
        },
        {
            title: "Peso",
            dataIndex: "weight",
            align: "center" as const,
            key: "weight",
            render: (text) => {
                return <span>{text} kg</span>
            }
        },
        {
            title: "Quantidade de volumes",
            dataIndex: "quantity",
            align: "center" as const,
            key: "quantity",
            render: (text) => (
                <span>{text}</span>
            )
        },
        {
            title: "Adicionar rota",
            dataIndex: "addRoute",
            align: "center" as const,
            key: "addRoute",
            render: (_, record, index) => (
                <ContentFlex>
                    <ButtonAddOrRemove
                        backgroundColor='var(--orange-350)'
                        onClick={() => addRow()}
                    >+
                    </ButtonAddOrRemove>

                    {index === rows.length - 1 &&
                        <ButtonAddOrRemove
                            margin='10px'
                            backgroundColor='transparent'
                            onClick={() => removeRow(record.index)}
                        >-
                        </ButtonAddOrRemove>
                    }
                </ContentFlex>
            )
        },
    ];


    return (
        <Modal
            title={"Nova rota"}
            destroyOnClose
            centered
            closable
            visible={visible}
            onCancel={clearFields}
            width={"80%"}
            footer={[
                <Button key="cancel" onClick={clearFields}>Cancelar</Button>,
                <Button key="ok" type="primary" onClick={() => addRoute()}>Enviar</Button>,
            ]}
        >
            <Content>
                <Header>
                    <Col xs={24}>
                        Adicione as rotas de entrega
                    </Col>
                </Header>

                <Body>
                    <Table
                        columns={columns}
                        dataSource={rows}
                        pagination={false}
                    />
                </Body>

                <TotalBox>
                    <span>Total em peso: </span>{totals.weight.toFixed(2)} Kg
                    <span style={{ marginLeft: '1rem' }}>Total em quantidade: </span> {totals.quantity}
                </TotalBox>
            </Content>
        </Modal >
    );
}

export default NewRouteModal;
