import { OrderStatus } from "../models/enums/OrderStatus";

export const changeTitleTextStatusOrder = (status: number) => {
  let title;
  let text;

  switch (status) {
    case OrderStatus.CONFIRMADO:
      title = "Aceitar pedido";
      text = "Deseja continuar e aceitar o pedido?";
      break;
    case OrderStatus.EM_TRANSPORTE:
      title = "Enviar pedido para transporte";
      text = "Deseja continuar e enviar o pedido para transporte?";
      break;
    case OrderStatus.FINALIZADO:
      title = "Finalizar pedido";
      text = "Deseja continuar e finalizar o pedido?";
      break;
    case OrderStatus.CANCELADO:
      title = "Cancelar pedido";
      text =
        "Deseja realmente cancelar o pedido? Caso o pedido esteja no bling ele será estornado também.";
      break;
    case OrderStatus.EM_ESTOQUE:
      title = "Enviar ao estoque";
      text = "Deseja continuar e enviar o pedido ao estoque?";
      break;
    case OrderStatus.ESTORNADO:
      title = "Estornar pedido";
      text = "Deseja continuar e estornar o pedido?";
      break;
  }

  return { title, text };
};
