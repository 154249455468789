import styled, { css } from "styled-components";
import { Pencil, Reload } from "../../styles/Icons";

import {
  Modal as ModalAnt,
  Col as ColAnt,
  Row as RowAnt,
  Button as ButtonAnt,
} from "antd";

export const Modal = styled(ModalAnt)`
  * .ant-modal-header {
    padding: 2rem;
  }
  .ant-modal-body {
    max-height: 25rem;
    padding: 0 2rem 1rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
      padding: 0 0.5rem 1rem;
    }
  }
  .ant-modal-footer {
    margin-top: 1rem;
    height: 5.25rem;
    padding: 1rem;
    button {
      width: 12rem;
      height: 3.25rem;
    }
  }

  @media only screen and (max-width: 1366px) {
    * .ant-modal-header {
      padding: 1rem;
    }

    .ant-modal-title {
      font-size: 1.5rem;
    }
    .ant-modal-footer button {
      width: 8rem;
      height: 3rem;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-25);
  border-radius: 0.6rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 700px) {
    padding: 1rem;
  }
`;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-height: 3.35rem;
  border-bottom: 1px solid var(--gray-25);
  font-size: 1rem;
  font-weight: 500;

  @media only screen and (max-width: 578px) {
    min-height: 2.3rem;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }
`;

interface IActive {
  active: boolean;
}

export const Item = styled.div<IActive>`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-height: 5.3rem;
  color: var(--gray-45);
  font-size: 1rem;
  font-weight: 400;
  margin-right: 0.2rem;
  @media only screen and (max-width: 578px) {
    min-height: 3.3rem;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }
  input {
    width: 75%;
    font-size: 1rem;
    color: var(--gray-45);
    padding: 0 0.2rem;
    border: 1px solid var(--white-25);
    box-shadow: none;
    ${({ active }) => {
      if (!active) {
        return css`
          :hover,
          :active,
          :focus,
          :disabled {
            color: var(--gray-45);
            background: none;
            border: 1px solid var(--white-25);
            box-shadow: none;
          }
        `;
      }
      return css`
        border: 1px solid var(--orange-350);
      `;
    }};
  }
`;

export const ContainerDescription = styled.div`
  overflow: scroll;
  text-overflow: ellipsis;
  padding: 1rem 0 2rem;
  min-height: 2rem;
  font-size: 1rem;
  .title {
    font-weight: 500;
    color: var(--blue-900);

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
  .obs {
    font-weight: 400;
    color: var(--gray-45);
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const ButtonReload = styled(ButtonAnt)`
  width: 12rem;
  height: 3rem;
  background-color: var(--orange-350);
  color: var(--white-50);
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  :hover {
    background-color: var(--orange-350);
    color: var(--white-50);
    opacity: 40%;
  }

  @media screen and (max-width: 700px) {
    span {
      display: none;
    }
    width: 6rem;
    height: 2rem;
    font-size: 0.7rem;
  }
`;

export const ReloadIcon = styled(Reload)`
  margin-left: 0.7rem;
  width: 1.1rem;
  color: var(--white-50);

  @media screen and (max-width: 700px) {
    margin-left: 0.7rem;
  }
`;
export const PencilIcon = styled(Pencil)`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange-350);
  cursor: pointer;
`;
