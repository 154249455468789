import styled from "styled-components";

import { DatePicker } from 'antd'
const { RangePicker } = DatePicker;

export const Container = styled.div``;

export const DatePickerStyle = styled(RangePicker)`
    .ant-picker-input > input {
        font-weight: 400;
        font-size: 1rem;
    }
`;
