import React, { SetStateAction, Dispatch, forwardRef } from "react";

import { DatePicker } from "./styles";
import moment, { Moment } from "moment";

interface IProps {
  data: { data_inicial: Moment; data_final: Moment };
  setData: Dispatch<
    SetStateAction<{ data_inicial: Moment; data_final: Moment }>
  >;
  [rest: string]: any;
}

const dateFormat = "DD/MM/YYYY";

const FixedIntervalDateField = forwardRef<null, IProps>(
  ({ data, setData, ...rest }, ref) => {
    function onChange(date) {
      setData({
        data_inicial: date,
        data_final: moment(date).add(1, "days"),
      });
    }
    return (
      <DatePicker
        ref={ref}
        value={data.data_inicial}
        onChange={onChange}
        {...rest}
        format={dateFormat}
        allowClear={false}
        inputReadOnly={true}
      />
    );
  }
);

export default FixedIntervalDateField;
