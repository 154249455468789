import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";

import { Order as OrderModel } from "../../../models/Order/Order";

import api from "../../../services/api";

import Spinner from "../../../components/Spinner";

import { Modal, notification, Tooltip } from "antd";

import {
  Container,
  Table,
  Preview,
  RemoveButton,
  ViewButton,
  Center,
  ImageIcon,
  Tabs,
  IconPDF,
  DownloadIcon,
} from "./styles";
import { verifyPermission } from "../../../services/auth";

const { TabPane } = Tabs;

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalImageOrder: React.FC<IProps> = ({
  order,
  setVisible,
  visible,
  setShouldSearch,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [nfeBling, setNfeBling] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (visible && order) {
        setLoading(true);
        try {
          const sale_order_ids = order?.bling
            ?.filter((bling_ids) => bling_ids?.sale_order_id)
            ?.map((bling_ids) => bling_ids?.sale_order_id);
          if (sale_order_ids?.length) {
            const {
              data: { data },
            } = await api.get(
              `/order/nfe/bling?sale_order_ids=${sale_order_ids}`
            );
            setNfeBling(data);
          }
        } catch (error) {
          console.error("Erro", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [visible, order]);

  const deleteImage = async (upload) => {
    Modal.confirm({
      title: "Excluir Imagem",
      content: "Deseja continuar e confirmar a exclusão desta imagem?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Cancelar",
      className: "modalDelete",
      width: 500,
      async onOk() {
        try {
          setLoading(true);
          await api.delete(`/order/${upload.id}/upload`);

          notification.success({
            message: "Imagem excluída com sucesso",
            description:
              "A imagem selecionada foi removida do pedido com sucesso",
            duration: 5,
          });
          setVisible(false);
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Oops! Não foi possível excluir a imagem",
            description: error
              ? _description
              : "Falha ao remover a imagem selecionada do pedido.",
            duration: 5,
          });
        } finally {
          setVisible(false);
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "Imagem",
      dataIndex: "url_file",
      key: "url_file",
      render: (text) => (
        <span>
          <Preview src={text} alt="Image do pedido" />
        </span>
      ),
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      key: "created_at",

      render: (text) => (
        <span>{moment(text, "DD-MM-YYYY").format("DD/MM/YYYY")}</span>
      ),
    },

    {
      title: <Center>Ação</Center>,
      key: "Ação",

      render: (text, record) => (
        <Center>
          <>
            <Tooltip title={"Visualizar"}>
              <ViewButton
                href={record?.url_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ImageIcon />
              </ViewButton>
            </Tooltip>

            {verifyPermission("orders.edit") && (
              <Tooltip title={"Excluir"}>
                <RemoveButton onClick={() => deleteImage(record)} />
              </Tooltip>
            )}
          </>
        </Center>
      ),
    },
  ];

  // const columnsNF = [
  //   {
  //     title: "Notas Fiscais",
  //     dataIndex: "pdf",
  //     key: "pdf",
  //     render: (text) => (
  //       <span>
  //         <IconPDF />
  //       </span>
  //     ),
  //   },
  //   {
  //     title: "N° da Nota Fiscal",
  //     dataIndex: "numero",
  //     key: "numero",
  //     render: (text) => <span>{text}</span>,
  //   },
  //   {
  //     title: "Razão Social",
  //     dataIndex: "emitente_razao_social",
  //     key: "emitente_razao_social",
  //     width: "35%",

  //     responsive: ["lg"] as any,
  //     render: (text) => <span>{order?.store?.emitente_razao_social}</span>,
  //   },
  //   {
  //     title: "Data/Hora",
  //     dataIndex: "created_at",
  //     key: "created_at",
  //     render: (text) => (
  //       <span>
  //         {moment(order?.created_at, "DD-MM-YYYY").format("DD/MM/YYYY")}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: <Center>Ação</Center>,
  //     key: "Ação",
  //     width: "30%",
  //     render: (text, record) => (
  //       <Center>
  //         <>
  //           <Tooltip title={"Baixar"}>
  //             <ViewButton
  //               href={record?.pdf}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //             >
  //               <DownloadIcon />
  //             </ViewButton>
  //           </Tooltip>
  //         </>
  //       </Center>
  //     ),
  //   },
  // ];

  return (
    <Container
      title={`Imagens do Pedido`}
      visible={visible}
      centered
      onCancel={() => {
        setVisible(false);
        setNfeBling([]);
      }}
      width={1000}
      footer={null}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Arquivos" key="1">
            <Table
              columns={columns}
              dataSource={order?.uploadOrders?.map((entity) => ({
                ...entity,
                key: entity?.id,
              }))}
              scroll={{ y: 400 }}
              pagination={false}
            />
          </TabPane>
          {/* <TabPane tab="Notas Fiscais" key="2">
            <Table
              columns={columnsNF}
              dataSource={nfeBling.map((entity) => ({
                ...entity,
                key: entity.id,
              }))}
              scroll={{ y: 400 }}
              pagination={false}
            />
          </TabPane> */}
        </Tabs>
      )}
    </Container>
  );
};

export default ModalImageOrder;
