import styled, { css } from "styled-components";
import {
  MagnifyingGlass,
  FilterAlt,
  AddCircleOutline,
  Truck,
} from "../../styles/Icons";

import {
  Menu as MenuAnt,
  Checkbox as CheckboxAnt,
  Row as RowAnt,
  Select as SelectAnt,
  Button as ButtonAnt,
  Col as ColAnt,
  Input as InputAnt,
  Dropdown as DropdownAnt,
} from "antd";
const { Option: OptionAnt } = SelectAnt;

const Icon = css`
  width: 1.1rem;
  height: 1.1rem;
  color: white;
`;

export const Checkbox = styled(CheckboxAnt)`
  font-size: 0.6rem;
  zoom: 1;
  font-weight: 600;
`;

export const Menu = styled(MenuAnt)``;
export const MenuItem = styled(MenuAnt.Item)`
  display: flex !important;
  justify-content: space-between !important;
`;

export const Dropdown = styled(DropdownAnt)``;

export const FilterText = styled.span`
  margin-right: 0.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ColHeader = styled(ColAnt)``;

export const Option = styled(OptionAnt)``;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  min-height: 3rem;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: 800px) {
    height: 20%;
  }
`;

export const Row = styled(RowAnt)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  color: var(--gray-45);
  font-size: 1.2rem;
  font-weight: 500;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 3.2rem;
  border-radius: 5px;

  .ant-input {
    height: 2.5rem;
  }
`;

export const Content = styled.div`
  height: 87%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(ButtonAnt)`
  width: 100%;
  height: 3.2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-350);
  color: var(--white);

  :hover,
  :focus {
    background-color: var(--orange-350);
    color: var(--white);
    opacity: 70%;
  }
`;

export const SearchIcon = styled(MagnifyingGlass)`
  ${Icon}
`;

export const FilterIcon = styled(FilterAlt)`
  ${Icon}
`;

export const AddIcon = styled(AddCircleOutline)`
  ${Icon}
`;
export const TruckIcon = styled(Truck)`
  ${Icon}
`;
