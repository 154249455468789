import styled from "styled-components";
import { Button } from "antd";

export default styled(Button)`
  display: flex;
  width: 182px;
  height: 52px;
  align-items: center;
  text-align: center;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 400;
  padding: 16px 15px 16px 20px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--orange-350);
  transition: ease-in-out .3s;

  :hover {
    background-color: var(--orange-350);
    color: var(--white-10);
    opacity: 80%;
    border: none;
  }
`;
