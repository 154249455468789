import React, { Dispatch, SetStateAction } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { DREModel } from "../../models/DREModel";

import { MoreInfo } from "../CashHistoryList/styles";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../hooks/useResponsiveSizeTable";

import { Dropdown, Menu, Tooltip, Modal, notification } from "antd";

import {
  Actions,
  Delete,
  Center,
  LabelName,
  Table,
  RestoreIcon,
} from "./styles";

const { confirm } = Modal;

interface IDREProps extends RouteComponentProps {
  dre: DREModel[];
  setDre: Dispatch<SetStateAction<DREModel[]>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setEditDre: (id: number) => void;
  loading: boolean;
}

const DREList: React.FC<IDREProps> = ({
  dre,
  setDre,
  setShouldSearch,
  setEditDre,
  loading,
  setLoading,
}) => {
  const responsiveWindow = useWindowSize();
  const deleteDreRow = async (id: number) => {
    confirm({
      title: "Remover DRE",
      content: "Tem certeza que gostaria de remover este DRE?",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          await api.delete(`dre/${id}`);
          setShouldSearch(true);
          notification.success({
            message: "DRE removido com sucesso",
            duration: 5,
          });
        } catch (error) {
          const _message = "Erro ao remover DRE";
          //@ts-ignore
          const _description = error?.response?.data?.error?.message;
          notification.error({
            message: _message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };
  const restoreDreRow = async (id: number) => {
    confirm({
      title: "Restaurar DRE",
      content: "Tem certeza que gostaria de restaurar este DRE?",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          await api.patch(`dre/${id}`, {
            deleted_at: null,
          });
          setShouldSearch(true);
          notification.success({
            message: "DRE restaurado com sucesso",
            duration: 5,
          });
        } catch (error) {
          const _message = "Erro ao restaurar DRE";

          //@ts-ignore
          const _description = error?.response?.data?.error?.message;
          notification.error({
            message: _message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <>
          <Tooltip title={text}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "NOME",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <Tooltip title={text}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "ORDENAÇÃO",
      dataIndex: "sort",
      key: "sort",
      responsive: ["sm"] as any,
      render: (text) => (
        <>
          <Tooltip title={text}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: <Center>Ações</Center>,
      width: "10%",
      render: (text, record) => (
        <Center>
          {verifyPermission("category.restoreDre") && record?.deleted_at ? (
            <Tooltip title="Restaurar DRE">
              <RestoreIcon
                onClick={() => {
                  restoreDreRow(record.id);
                }}
              />
            </Tooltip>
          ) : (
            <Actions>
              <Dropdown
                overlay={
                  <Menu>
                    {verifyPermission("category.editDre") && (
                      <Menu.Item
                        onClick={() => {
                          setEditDre(record.id);
                        }}
                      >
                        Editar DRE
                      </Menu.Item>
                    )}

                    {verifyPermission("category.deleteDre") && (
                      <Menu.Item
                        onClick={() => {
                          deleteDreRow(record.id);
                        }}
                      >
                        <Delete>Excluir DRE</Delete>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <MoreInfo />
              </Dropdown>
            </Actions>
          )}
        </Center>
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
        dataSource={dre.map((entity) => ({
          ...entity,
          key: entity.id,
        }))}
      />
    </>
  );
};

export default withRouter(DREList);
