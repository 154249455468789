import styled from "styled-components";
import { DatePicker } from "antd";

const { RangePicker: RangePickerAnt } = DatePicker;

export const RangePicker = styled(RangePickerAnt)`
  width: 100%;
  input {
    text-align: center;
    font-size: 18px;
  }
  div.ant-picker-panel-container {
    width: min-content;
  }
  div.ant-picker-panels {
    flex-wrap: wrap;
  }

  .ant-picker-suffix {
    color: var(--orange-350);
  }
`;
