import styled from 'styled-components';
import { Modal as ModalAnt, Table as TableAnt, Select as SelectAnt, Button as AntButton, Input as InputAnt } from 'antd'
const { Option: OptionAnt } = SelectAnt;

export const Modal = styled(ModalAnt)``;

export const Select = styled(SelectAnt)`
  width: 100%;
`;
export const Option = styled(OptionAnt)``;

export const Input = styled(InputAnt)`
  width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 1rem !important;
    border: 1px solid var(--gray-25) !important;
    border-radius: 5px !important;
`;

export const ContentFlex = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface ButtonProps {
    backgroundColor: string;
    margin?: string;
}
export const ButtonAddOrRemove = styled(AntButton) <ButtonProps>`
    ${ContentFlex}
    width: 2rem !important;
    height: 2rem;
    border-radius: 5px;
    
    background-color: ${props => props.backgroundColor};
    margin-left: ${props => props.margin};
    border: ${props => props.backgroundColor === 'transparent' ? '1px solid var(--orange-350)' : 'none'};
    color: ${props => props.backgroundColor === 'transparent' ? 'var(--orange-350)' : 'white'};

    :hover, :focus, :active {
      background-color: ${props => props.backgroundColor};
      border: ${props => props.backgroundColor === 'transparent' ? '1px solid var(--orange-350)' : 'none'};
      opacity: 70%;
      color: ${props => props.backgroundColor === 'transparent' ? 'var(--orange-350)' : 'white'};

      border-color: var(--orange-350);
    }
`;

export const Box = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid var(--gray-25);
    border-radius: 5px;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Body = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

export const Table = styled(TableAnt)`
    .ant-table-container {
        border-radius: 5px;
        border: 1px solid var(--gray-25);
    }
`;

export const TotalBox = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem;
    border: 1px solid var(--gray-25);
    border-radius: 5px;

    span {
        color: var(--orange-350);
        margin-left: 5px;
    }
`