import styled from "styled-components";
import { Spin as SpinWithOutStyles, Row } from "antd";

export const Spin = styled(SpinWithOutStyles)`
  position: relative;
  z-index: 2;
`;

export const Container = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 578px) {
    flex-grow: 0.8;
  }
`;
