import styled, { css } from "styled-components";

import {
  Col as ColAnt,
  Row as RowAnt,
  Button as ButtonAnt,
  Input as InputAnt,
  Modal as ModalAnt,
} from "antd";
import { EditAlt, History } from "../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const THeaderContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid var(--gray-75);
`;

export const THead = styled(RowAnt)`
  width: 100%;
  font-size: 14px;
  padding: 19px 0px 16px;
  border-bottom: 1px solid var(--gray-625);
`;

export const HeaderInfo = styled.label`
  display: flex;
  justify-content: flex-start;
  color: var(--blue-900);
  font-weight: 500;
  @media only screen and (max-width: 578px) {
    font-size: 14px;
  }
`;
export const Body = styled.div`
  max-width: 100%;
  padding: 0 0px;
  overflow-y: scroll;
  border: 1px solid var(--gray-75);
  border-top: 0;
  border-radius: 0px 0px 5px 5px;
  background: transparent;
  overflow: scroll;
  height: 87%;
  @media only screen and (max-width: 578px) {
    height: 81%;
  }
  label {
    font-size: 16px;
  }
  span {
    display: flex;
    justify-content: flex-start;
    color: var(--blue-900);
    @media only screen and (max-width: 940px) {
      font-size: 0.7rem;
    }
    @media only screen and (max-width: 578px) {
      font-size: 0.9rem;
    }
  }
`;

export const Col = styled(ColAnt)``;

export const Row = styled(RowAnt)`
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--gray-75);
  align-items: center;
`;

export const Button = styled(ButtonAnt)`
  border: none;
  box-shadow: none;
  background: none;
  margin-right: 5%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface IStatus {
  quantity?: number;
}

export const Status = styled.label<IStatus>`
  color: rgba(4, 15, 46, 0.6);

  padding: 3px;
  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;
  fill: var(--orange-350);

  :hover {
    fill: var(--orange-350);
  }
  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const EditIcon = styled(EditAlt)`
  ${iconCSS}
`;
export const HistoryIcon = styled(History)`
  ${iconCSS}
`;

export const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 2rem;
`;

export const ButtonOk = styled(ButtonAnt)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange-350);
  height: 3.25rem;
  border: 0;
  border-radius: 5px;
  color: var(--white-10);

  :hover {
    background-color: var(--orange-350);
    color: var(--white-10);
    opacity: 80%;
  }
`;
export const ButtonCancel = styled(ButtonAnt)`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  :hover {
    color: var(--black-opaco);
    opacity: 80%;
  }
`;

export const ContainerEditInfo = styled.div`
  margin-bottom: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 0.5rem;
`;

export const RowEdit = styled(RowAnt)`
  display: flex;
  flex-direction: column;
  span {
    text-transform: capitalize;
  }

  .titleProduct {
    color: #686f82;
    font-size: 1rem;
    font-weight: 500;
  }
  .textProduct {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);

    background-color: var(--white-50);
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    padding: 1rem;

    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const UpdateContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ModalContent = styled(ModalAnt)`
  .ant-modal-body {
    padding: 1rem 3rem 3rem;
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 2.25rem;
    color: (--black-opaco);
    padding: 0 1.2rem;
  }
`;

export const QtdCurrent = styled.div`
  .ant-modal-content,
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-input,
  .ant-input-disabled {
    background-color: var(--white-50);
    width: 100%;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  }
`;

export const QtdChange = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditInfo = styled.label`
  font-size: 1rem;
  font-weight: 500;
  margin-right: 10px;
  color: var(--gray-45);
`;

export const InputChange = styled(InputAnt)`
  height: 3.2rem;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
`;
export const Input = styled(InputAnt)`
  width: 12.7rem;
  height: 3.2rem;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);

  .ant-input,
  .ant-input-disabled {
    color: var(--white-50);
    background-color: var(--white-50);
  }
`;
