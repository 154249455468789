import React, { useState } from "react";

import api from "../../services/api";

import { Handler } from "../../models/Handler";
import { CashHandlerTypes } from "../../models/enums/CashHandlerTypes";

import Centralizer from "../../containers/Centralizer";

import { Container, Column, Title, Description, Check } from "./styles";

import { Spin, Modal } from "antd";

const { confirm } = Modal;

const HandlerInfo: React.FC<Handler> = ({
  id,
  type,
  reason,
  created_at,
  amount,
  verified,
}) => {
  const [checkValue, setCheckValue] = useState<boolean>(verified);
  const [loading, setLoading] = useState<boolean>(false);

  const time = created_at.split(" ")[1];

  const handleCheckBox = async (value: boolean) => {
    confirm({
      title: "Atualização movimentação",
      content: "Deseja continuar e atualizar esta movimentação?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        await api.put(`/cash_handler/${id}`, { verified: value });
        setCheckValue(value);
        setLoading(false);
      },
    });
  };

  return (
    <Container>
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <>
          <Column sm={3} xs={24}>
            <Title>Horário: </Title>
            <Description>{time}</Description>
          </Column>
          <Column sm={3} xs={24}>
            <Title>Tipo: </Title>
            <Description>{CashHandlerTypes[type]}</Description>
          </Column>
          <Column sm={3} xs={24}>
            <Title>Quantia: </Title>
            <Description>{amount}</Description>
          </Column>
          <Column sm={3} xs={24}>
            <Title>Verificada: </Title>
            <Check
              checked={checkValue}
              onClick={() => handleCheckBox(!checkValue)}
            />
          </Column>
          <Column sm={12} xs={24}>
            <Title>Razão: </Title>
            <Description>{reason}</Description>
          </Column>
        </>
      )}
    </Container>
  );
};

export default HandlerInfo;
