export enum DeliveryRouteStatus {
    INDISPONIVEL = 0,
    ATRIBUIDA = 1,
    ROTEIRIZADA = 2,
    CANCELADA = 3,
    FINALIZADA = 4,
    CRIADA = 5,
    EM_PAUSA = 6,
    EM_ROTA = 7,
    EM_ROTA_ATE_VOCE = 8
}
