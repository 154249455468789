import styled from "styled-components";
import {
  Row as RowAnt,
  DatePicker as DatePickerAnt,
  Select as SelectAnt,
  Input as InputAnt,
  Button as ButtonAnt,
} from "antd";
const { Option: OptionAnt } = SelectAnt;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled(ButtonAnt)`
  color: white;
  background-color: var(--orange-350);
  width: 100%;
  height: 3.2rem;
  border-radius: 8px;
  border: none;

  &:hover, &:focus, &:active{
    color: white;
    background-color: var(--orange-350);
    opacity: 70%;
  }
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 9%;
  min-height: 3rem;

  @media only screen and (max-width: 800px) {
    min-height: 25%;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
`;

export const Input = styled(InputAnt)`
  border-radius: 0.4rem;
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 1400px) {
    margin-top: 1%;
  }
  @media screen and (max-width: 500px) {
    height: 80%;
    overflow: scroll;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Option = styled(OptionAnt)``;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
