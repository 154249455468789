import React, { Dispatch, SetStateAction } from "react";

import { Audit as AuditModel } from "../../models/Audit";

import { Container, Modal, ContentRow, Table, StatusChange } from "./styles";
import { ArrowRightIcon } from "../../pages/Order/ModalHistoryOrderItens/styles";
import { Button, Empty } from "antd";
import { AuditType } from "../../containers/CashHistoryList";
import moment from "moment";
import { Spin } from "../Spinner/styles";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";

interface IProps {
  visible: boolean;
  loading: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedAuditoria: AuditModel[];
  auditType?: AuditType;
  countryPY?: boolean;
  pygExchangeRate?: string | undefined;
}

const ModalCashManagementAuditoria: React.FC<IProps> = ({
  visible,
  setVisible,
  selectedAuditoria,
  auditType,
  loading,
  countryPY,
  pygExchangeRate,
}) => {
  const paymentMethodCodes = {
    money: "money",
    credit_card: "credit_card",
    debit_card: "debit_card",
    ticket: "ticket",
    online: "online",
    pix: "pix",
  };

  const getSaleType = (field: string) => {
    switch (field) {
      case paymentMethodCodes["money"]: {
        return "DINHEIRO";
      }
      case paymentMethodCodes["credit_card"]: {
        return "CARTÃO DE CRÉDITO";
      }
      case paymentMethodCodes["debit_card"]: {
        return "CARTÃO DE DÉBITO";
      }
      case paymentMethodCodes["online"]: {
        return "ONLINE";
      }
      case paymentMethodCodes["pix"]: {
        return "PIX";
      }
      case paymentMethodCodes["ticket"]: {
        return "TICKET";
      }
      default: {
        return "";
      }
    }
  };

  const getAuditType = (
    type: AuditType,
    oldValue: string,
    newValue: string
  ): string => {
    switch (type) {
      case AuditType.sale:
        return oldValue !== null ? "Restaurada" : "Deletada";
      case AuditType.cash_history:
        return `Alterado`;
      case AuditType.balance_history:
        const exchangeRate = countryPY ? +(pygExchangeRate || 1) : 1;
        const amountDifference = (+newValue - +oldValue) / exchangeRate;
        const currencyCode = countryPY ? "PYG" : "BRL";
        const currencySymbol = countryPY ? "Gs." : "R$";
        return `Adicionado: ${currencySymbol}${currencyFormaterPYGBRL(
          amountDifference,
          currencyCode
        )}`;
      default:
        return "";
    }
  };

  const columnsCashHistory = [
    {
      title: "Status",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <StatusChange type={record.type}>
            {getAuditType(record.type, record.old_value, record.new_value)}
          </StatusChange>
        );
      },
    },
    {
      title: "Data / Hora",
      dataIndex: "created_at",
      responsive: ["md"] as any,
      key: "date",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Responsável",
      dataIndex: "user.name",
      key: "user.name",
      render: (text, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Alteração",
      dataIndex: "field",
      key: "field",
      render: (field, record) => {
        const exchangeRate =
          countryPY && pygExchangeRate ? +pygExchangeRate : 1;
        const currencySymbol = countryPY ? "Gs." : "R$";
        const currencyCode = countryPY ? "PYG" : "BRL";
        const oldValue = +record.old_value / exchangeRate;
        const newValue = +record.new_value / exchangeRate;

        if (auditType === AuditType.sale) {
          return (
            <span>
              {record.old_value !== null ? (
                <span>
                  {`Horario: ${moment(
                    record.old_value,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD-MM-YYYY HH:mm:ss")}`}
                </span>
              ) : (
                <span>
                  {`Horario: ${moment(
                    record.new_value,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("DD-MM-YYYY HH:mm:ss")}`}
                </span>
              )}
            </span>
          );
        } else {
          const SALE_TYPE: string = getSaleType(field);

          return (
            <span>
              {`${SALE_TYPE} ${currencySymbol} ${currencyFormaterPYGBRL(
                oldValue,
                currencyCode
              )}`}{" "}
              <ArrowRightIcon />{" "}
              {`${currencySymbol} ${currencyFormaterPYGBRL(
                newValue,
                currencyCode
              )}`}
            </span>
          );
        }
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          <ContentRow>
            <p>
              {auditType === AuditType.cash_history
                ? "Histórico de alterações"
                : auditType === AuditType.sale
                ? "Histórico de alterações da venda"
                : "Histórico de alteração do faturamento"}
            </p>
          </ContentRow>
        </>
      }
      centered
      visible={visible}
      closable={false}
      width={"90%"}
      footer={[
        <Button type="primary" onClick={() => setVisible(false)}>
          Concluir
        </Button>,
      ]}
    >
      <>
        {loading ? (
          <Spin />
        ) : (
          <Container>
            {selectedAuditoria.length !== 0 ? (
              <Table
                loading={loading}
                columns={columnsCashHistory}
                pagination={false}
                dataSource={selectedAuditoria}
                rowKey={(entity: any) => entity.id}
                scroll={{ y: 320 }}
              />
            ) : (
              <Empty description="Não há histórico" />
            )}
          </Container>
        )}
      </>
    </Modal>
  );
};

export default ModalCashManagementAuditoria;
