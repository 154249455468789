import styled, { css } from 'styled-components';
import { AddCircleOutline, TrashIconA, Edit } from '../../styles/Icons'
import { Row as RowAnt, Select as SelectAnt, Button as ButtonAnt, Col as ColAnt, Input as InputAnt, Table as TableAnt, Space } from 'antd'
const { Option: OptionAnt } = SelectAnt;

const Icon = css`
    width: 1rem;
    height: 1rem;
    margin-right: 10px;

    cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SpaceAnt = styled(Space)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IButtonProps {
    background: string;
    border: string;
    color: string;
}
export const Button = styled(ButtonAnt) <IButtonProps>`
    display: flex;
    align-items: center;
    padding: .5rem;
    border-radius: 5px;
    border: ${props => props.border};
    background-color: ${props => props.background};
    color: ${props => props.color};
`;

export const Input = styled(InputAnt)`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-25);
  border-radius: 5px;
  `;

export const Row = styled(RowAnt)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ColHeader = styled(ColAnt)`
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Option = styled(OptionAnt)``;

export const Table = styled(TableAnt)`
    .ant-table-container {
        border-radius: 5px;
        border: 1px solid var(--gray-25);
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0 0 5px !important;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 5px 0 0  !important;
    }

    .ant-table-thead > tr > th {
        background: transparent !important;
}
`;

export const AddIcon = styled(AddCircleOutline)`
    color: var(--white);
    width: .8rem;
    height: .8rem;
    margin-right: .5rem;
`;

export const TrashIcon = styled(TrashIconA)`
    ${Icon}
    color: red;
`;
export const EditIcon = styled(Edit)`
    ${Icon}
    color: var(--gray-45);
`;