import React, {
  SetStateAction,
  Dispatch,
  useState,
  useRef,
  useEffect,
} from "react";

import { verifyPermission } from "../../services/auth";
import api from "../../services/api";

import { ProductStore as ProductStoreModel } from "../../models/ProductStore";
import { Page } from "../../models/Page";

import { Spin } from "../../components/Spinner/styles";

import StockAuditList from "../../containers/StockAuditList";

import { Tooltip, Modal, message, Input } from "antd";

import lodash from "lodash";

import {
  Actions,
  Body,
  Button,
  Col,
  Container,
  EditIcon,
  EditInfo,
  HeaderInfo,
  HistoryIcon,
  InputChange,
  ModalContent,
  ButtonCancel,
  ButtonOk,
  ContentFooter,
  QtdChange,
  QtdCurrent,
  Row,
  THead,
  THeaderContainer,
  UpdateContainer,
  LoadingContainer,
  ContainerEditInfo,
  RowEdit,
} from "./styles";

const { confirm } = Modal;
interface IProps {
  products: ProductStoreModel[];
  filteredProducts: ProductStoreModel[] | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setProductsStock: Dispatch<SetStateAction<ProductStoreModel[]>>;
  loading: boolean;
  isStoreActive: boolean | undefined
}

const StockList: React.FC<IProps> = ({
  products,
  filteredProducts,
  loading,
  setLoading,
  setProductsStock,
  isStoreActive
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<ProductStoreModel | null>(null);
  const [newQuantity, setNewQuantity] = useState<number | undefined>(undefined);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });

  const inputRef = useRef<Input | null>(null);

  useEffect(() => {
    if (isModalVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalVisible]);

  const ordenationFunction = (prop: string) => {
    const compareById = (arr1, arr2) => {
      return arr1.every((elem, index) => elem.id === arr2[index].id);
    };
    const ordenedData = lodash.orderBy(
      products.map((product) => ({
        ...product,
        category: product.product?.category?.name,
      })),
      [prop],
      ["asc"]
    );
    const equalOrder = compareById(products, ordenedData);
    const currentData = equalOrder ? ordenedData.reverse() : ordenedData;
    setProductsStock(currentData);
  };

  const header = [
    {
      sm: 5,
      xs: 0,
      description: "Categoria",
      ordenationProp: "category",
    },
    {
      sm: 7,
      xs: 14,
      description: "Produto",
      ordenationProp: "product.name",
      ordenationFunction: ordenationFunction,
    },
    {
      sm: 4,
      xs: 8,
      description: "Quantidade",
      ordenationFunction: ordenationFunction,
    },

    {
      sm: 5,
      xs: 0,
      description: "Status",
    },
    {
      sm: 3,
      xs: 2,
      description: "Ação",
      ordenationFunction: ordenationFunction,
    },
  ];

  const getQuatity = (quantity?: number): string => {
    if (quantity === 0 || !quantity) {
      return "Esgotado";
    }
    if (quantity < 0) {
      return "Estoque negativo";
    }
    if (quantity <= 10) {
      return "Estoque baixo";
    }

    return "";
  };

  const handleUpdateProduct = async () => {
    confirm({
      title: "Atualizar Produto",
      content: "Gostaria de prosseguir com a atualização deste produto?",
      okText: "Sim",
      okType: "default",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        setIsModalVisible(false);
        try {
          await api.patch(`/products_store/${selectedProduct?.id}/quantity`, {
            quantity: newQuantity || 0,
          });

          const productIndex = products.findIndex(
            (product) => product.id === selectedProduct?.id
          );

          let newProduct = products;
          newProduct[productIndex].quantity = newQuantity || 0;

          setProductsStock([...newProduct]);
          setSelectedProduct(null);
          message.success("Produto atualizado com sucesso!");
        } catch {
          message.error("Erro ao atualizar produto!");
        }

        setLoading(false);
      },
      onCancel() {
        setIsModalVisible(false);
      },
    });
  };

  return (
    <Container>
      <THeaderContainer>
        <THead>
          {header.map(({ sm, xs, description }, index) => (
            <Col sm={sm} xs={xs} key={index}>
              <HeaderInfo>{description}</HeaderInfo>
            </Col>
          ))}
        </THead>
      </THeaderContainer>
      <Body>
        {loading ? (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        ) : (
          <>
            {(filteredProducts || products).map((storeProduct) => (
              <Row key={`${storeProduct.id}`}>
                <Col key={`${storeProduct.id}`} xs={0} sm={0}></Col>
                <Col sm={5} xs={0}>
                  <span style={{ textTransform: "capitalize" }}>
                    {storeProduct.product?.category?.name}
                  </span>
                </Col>
                <Col sm={7} xs={14}>
                  <span style={{ textTransform: "capitalize" }}>
                    {storeProduct.product?.name}
                  </span>
                </Col>
                <Col sm={4} xs={8}>
                  <span>
                    {storeProduct.quantity
                      ? storeProduct.quantity + " Itens"
                      : 0 + " Itens"}
                  </span>
                </Col>
                <Col sm={5} xs={0}>
                  <span>{getQuatity(storeProduct?.quantity)}</span>
                </Col>
                <Col sm={3} xs={2}>
                  <Actions>
                    <Col sm={6} xs={24}>
                      {verifyPermission("stock.edit") && (
                        <Tooltip placement="top" title={`${isStoreActive ? `A loja está inativa` : `Editar`}`}>
                          <Button
                            icon={<EditIcon />}
                            disabled={isStoreActive}
                            onClick={() => {
                              setNewQuantity(undefined);
                              setIsModalVisible(true);
                              setSelectedProduct(storeProduct);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Col>
                    <Col sm={6} xs={0}>
                      {verifyPermission("stock.historic") && (
                        <Tooltip placement="top"  title={`${isStoreActive ? `A loja está inativa` : `Histórico`}`}>
                          <Button
                            disabled={isStoreActive}
                            icon={
                              <HistoryIcon
                                onClick={() => {
                                  setPaginate((oldValues) => ({
                                    ...oldValues,
                                    page: 1,
                                    size: 10,
                                  }));
                                  setSelectedProduct(storeProduct);
                                  setVisible(true);
                                }}
                              />
                            }
                          />
                        </Tooltip>
                      )}
                    </Col>
                  </Actions>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Body>

      <ModalContent
        title="Editar"
        visible={isModalVisible}
        centered
        closable={false}
        footer={
          <ContentFooter>
            <ButtonCancel type="link" onClick={() => setIsModalVisible(false)}>
              Cancelar
            </ButtonCancel>

            <ButtonOk
              onClick={() => {
                handleUpdateProduct();
                setIsModalVisible(false);
              }}
            >
              Salvar
            </ButtonOk>
          </ContentFooter>
        }
      >
        <ContainerEditInfo>
          <RowEdit>
            <span className="titleProduct">Categoria: </span>
            <span className="textProduct">
              {" "}
              {selectedProduct?.product?.category?.name}
            </span>
          </RowEdit>
          <RowEdit>
            <span className="titleProduct">Produto:</span>
            <span className="textProduct">
              {selectedProduct?.product?.name}
            </span>
          </RowEdit>
        </ContainerEditInfo>
        <UpdateContainer>
          <QtdCurrent>
            <EditInfo>Quantidade atual:</EditInfo>
            <Input
              type="number"
              disabled
              value={
                selectedProduct?.quantity === null
                  ? 0
                  : selectedProduct?.quantity
              }
            />
          </QtdCurrent>
          <QtdChange>
            <EditInfo>Alterar para:</EditInfo>
            <InputChange
              ref={inputRef}
              type="number"
              value={newQuantity}
              onChange={({ target: { value } }) => setNewQuantity(+value)}
            />
          </QtdChange>
        </UpdateContainer>
      </ModalContent>

      <StockAuditList
        visible={visible}
        setVisible={setVisible}
        id={selectedProduct?.id}
        paginate={paginate}
        setPaginate={setPaginate}
      />
    </Container>
  );
};

export default StockList;
