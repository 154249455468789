import styled from "styled-components";

import {
  Input as InputAnt,
  Row as RowAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
  Checkbox as CheckboxAnt,
} from "antd";

import { AddCircleOutline } from "../../styles/Icons";

const { Option: OptionAnt } = SelectAnt;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .pencilIcon {
    display: none;
  }
`;

export const Select = styled(SelectAnt)`
  display: flex;
  background-color: var(--white);
  border-radius: 5px;

  border-radius: 5px;
  height: 3.35rem;
  width: 100%;

  .ant-select {
    align-items: center;
  }
  .ant-select-arrow {
    color: var(--orange-350);
  }

  .pencilIcon {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .ant-select-selector {
      padding: 0;
    }
  }
`;

export const TopSide = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 10%;
  min-height: 4rem;
  width: 100%;
  padding: 0;

  @media screen and (max-width: 575px) {
    min-height: 10rem;
  }
`;

export const ContentDRETopSide = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;

  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 100%;
  color: var(--white);
  background: var(--orange-350);
  border: 1px solid var(--white);
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    opacity: 80%;
    background: var(--orange-350);
    color: var(--white-10);
    border: 1px solid var(--white);
  }

  @media screen and (max-width: 600px) {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
`;

export const ButtonModal = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 8rem;
  color: var(--white);
  background: var(--orange-350);
  border: 1px solid var(--white);
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    opacity: 80%;
    background: var(--orange-350);
    color: var(--white-10);
    border: 1px solid var(--white);
  }

  @media screen and (max-width: 600px) {
    height: 2rem;
    font-size: 0.9rem;
  }
`;
export const ButtonAddCategory = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: 100%;
  color: var(--white);
  background: var(--orange-350);
  border: 1px solid var(--white);
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    opacity: 80%;
    background: var(--orange-350);
    color: var(--white-10);
    border: 1px solid var(--white);
  }

  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 0.2rem;
  }
`;

export const CancelButton = styled(ButtonAnt)``;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export const AddIcon = styled(AddCircleOutline)`
  width: 20px;
  height: 20px;
  fill: var(--white);
  margin-left: 10px;
  cursor: pointer;
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SaveButton = styled(ButtonAnt)`
  display: flex;
  width: 11.3rem;
  height: 52px;
  align-items: center;
  text-align: center;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--orange-350);
`;

export const Input = styled(InputAnt)`
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-height: 3.25rem;
  &::placeholder {
    color: var(--gray-45);
  }
  :hover,
  :focus,
  :active {
    box-shadow: 0 0 0 0;
    outline: none;
    border: 1px solid #ccc;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 0.2rem;
  }  
`;

export const DREButton = styled.button`
  font-size: 0.9rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;

export const ContentDRE = styled.div`
  padding: 1rem 0 0;
  border-top: 1px solid var(--gray-25);
  width: 100%;
`;

export const TitleDRE = styled.h3`
  font-size: 1.5rem;
  color: var(--black-opaco);
`;

export const OptionInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Option = styled(OptionAnt)``;

export const ContentIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const OptionLabel = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
`;
export const ContentDRETable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0 0 1rem;
`;

export const Checkbox = styled(CheckboxAnt)`
  font-size: 0.7rem;
  font-weight: 600;
`;