import styled from "styled-components";
import MonetaryInput2 from "../../../components/MonetaryInput2";

import {
  Modal as ModalAnt,
  Table as TableAnt,
  Checkbox as CheckboxAnt,
  Row as RowAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
} from "antd";

export const CreateCompanyInvoice = styled(ModalAnt)``;

export const DatePicker = styled(DatePickerAnt)`
  width: 30%;
  margin: 5px;
`;

export const CreateCompanyInvoiceTable = styled(TableAnt)`
  .valid_row {
    background-color: #00b70038;
  }
  .unvalid_row {
    background-color: #ff000052;
  }
`;

export const Checkbox = styled(CheckboxAnt)``;

export const Row = styled(RowAnt)``;

export const Input = styled(InputAnt)`
  width: 30%;
  margin: 5px 0;
`;

export const MonetaryInput = styled(MonetaryInput2)``;
