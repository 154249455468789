import styled, { css } from "styled-components";
import {
  Select as SelectAnt,
  Row as RowAnt,
  Button,
  Radio as RadioAnt,
  Checkbox as CheckboxAnt,
  Col as ColAnt,
  Dropdown as DropdownAnt,
} from "antd";

import { FilterAlt, MagnifyingGlass, Check2 } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const Radio = styled(RadioAnt)`
  border-radius: 8px !important;
  border: 1px solid var(--orange-250) !important;
`;

export const RadioGroup = styled(RadioAnt.Group)`
  border-radius: 8px !important;
  font-weight: 500;

  .ant-radio-button-wrapper-checked {
    background-color: var(--orange-350) !important;
    color: white !important;
  }

  @media screen and (max-width: 578px) {
    
  }
`;

export const FilterIcon = styled(FilterAlt)`
  width: 25px;
  height: 25px;
  margin-right: 3px;

  @media screen and (max-width: 1600px){
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1200px){
    width: 15px;
    height: 15px;
  }
`;

export const FilterMenu = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;

  background: var(--orange-350);
  color: white;

  :hover {
    color: white;
    opacity: 80%;
  }
`;

export const CheckboxMenu = styled(CheckboxAnt)`
  zoom: 1.2;
  font-size: 12px;
`;

export const CheckboxMenuGroup = styled(CheckboxAnt.Group)`
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
    padding: 5px 0px;
  }
`;

export const List = styled.div`
  flex-grow: 1;
  margin-bottom: 2rem;
  .ant-col {
    color: var(--blue-999);
    font-weight: 500;
  }
  .rank {
    display: none;
    @media only screen and (max-width: 600px) {
      display: flex;
      color: var(--blue-980);
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
  }
`;

export const TotalInfo = styled.label`
  font-weight: 400;
  font-size: 1.3rem;
  margin-right: 10px;
  color: var(--gray-45);

  span {
    color: var(--orange-350);
  }

  @media only screen and (max-width: 1150px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 900px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 575px) {
    font-size: 1rem;
  }
`;

export const TotalContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 575px) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 9px;
    padding: 0 0 10px;
    margin-top: 20px;
    width: 100%;
  }
`;

export const ButtonSearch = styled(Button)`
  width: 3.3rem;
  height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--orange-350);
  border: none;
  border-radius: 0.4rem;
  padding: 0;

  span {
    font-weight: 400;
    font-size: 1rem;
    display: none;
  }

  &:hover,
  &:focus {
    background-color: var(--orange-350);
    color: var(--white);
    border: none;
    opacity: 80%;
  }
  @media only screen and (max-width: 1400px) {
    height: 2.8rem;
    width: 2.8rem;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.4rem;
    margin-left: 0;
    width: 100%;
    svg {
      width: 20px;
    }
    span {
      display: block;
      
    }
  }
`;

export const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-row {
    border: 1px solid var(--gray-25);
    border-radius: 9px;
    min-height: 4rem;
    box-shadow: none;
  }
  & + & {
    margin-top: 12px;
  }

  @media only screen and (max-width: 1400px) {
    .ant-row {
      min-height: 4rem;
    }
  }
`;

export const Spin = styled.img``;

export const Col = styled(ColAnt)`

  .col-button {
    display: flex;
    justify-content: flex-end;
    max-width: 4.35rem;
    min-width: 3.35rem;

    @media only screen and (max-width: 575px) {
      max-width: 100%;
    }
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: flex-end;
`;

export const TopContent = styled(RowAnt)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0 15px;

  .ant-picker-suffix {
    color: var(--orange-450);
  }

  .ant-picker {
    border-radius: 5px;
    padding: 0.6rem;
    width: 100%;
    height: 3.35rem;
  }

  @media only screen and (max-width: 1400px) {
    .ant-picker {
      height: 2.8rem;
    }
  }

  @media only screen and (max-width: 575px) {
    label {
      margin-top: 0.75rem;
    }
    .ant-select {
      height: 3.35rem !important;
    }
    .ant-picker {
      width: 100%;
      margin-top: 0.45rem;
      height: 3.35rem;
    }
  }
`;

export const SummaryTypeSale = styled(Row)`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 0.2rem 0 1rem;

  span {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--gray-45);

    div {
      width: 1rem;
      height: 1rem;
      border-radius: 50px;
      margin-right: 2px;
    }
  }

  .type-store {
    div {
      background: linear-gradient(90deg, #FF875E 0%, #FD67AE 133.8%);
    }

    margin-right: 1%;
  }

  .type-delivery {
    div {
      background: linear-gradient(90deg, #4BA4D7 0%, #69DEA6 133.8%);
    }
  }
`

export const Select = styled(SelectAnt)`
  display: flex;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  border-radius: 5px;
  height: 3.35rem;
  width: 100%;

  .ant-select {
    align-items: center;
  }
  .ant-select-arrow {
    color: var(--orange-350);
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: .6rem;
    .ant-select-selector {
      padding: 0;
    }
  }

  @media only screen and (max-width: 1400px) {
    height: 2.8rem;
  }
`;

export const Checkbox = styled(CheckboxAnt)`
  zoom: 1.8;
  @media only screen and (max-width: 600px) {
    zoom: 2;
  }
`;

export const CheckBoxColumn = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.31rem;
  cursor: pointer;
  z-index: 2;
  @media only screen and (max-width: 600px) {
    margin-left: 0px;
    justify-content: flex-start;
  }
`;

export const SearchIcon = styled(MagnifyingGlass)`
  width: 25px;
  height: 25px;

  @media screen and (max-width: 1600px){
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 1200px){
    width: 15px;
    height: 15px;
  }
`;

export const ShortCurtContent = styled(RowAnt)`
  display: flex;
  justify-content: flex-end;

  .ant-picker {
    width: 100%;
    height: 3rem;
    border-radius: 0.3rem;
    margin-bottom: 2%;

    .ant-picker-suffix {
      color: var(--orange-350);
    }
  }
  .ant-btn,
  .ant-btn-primary {
    height: 46px !important;
    margin-bottom: 2%;
  }
`;

export const Dropdown = styled(DropdownAnt)`
width: 3.3rem;
  height: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background-color: var(--orange-350);
  border: none;
  border-radius: 0.4rem;

  span {
    font-weight: 400;
    font-size: 1rem;
    display: none;
  }

  &:hover,
  &:focus {
    background-color: var(--orange-350);
    color: var(--white);
    border: none;
    opacity: 80%;
  }
  @media only screen and (max-width: 1400px) {
    height: 2.8rem;
    width: 2.8rem;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 3.5rem;
    margin-top: 0.4rem;
    margin-left: 0;
    width: 100%;
    svg {
      width: 20px;
    }
    span {
      display: block;
    }
  }
`

export const CheckIcon = styled(Check2)`
  fill: white;
  height: 15px;
  width: 15px;
  margin-right: 5px;
`;