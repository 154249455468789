import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--black);
    font-family: 'Roboto';

    .eDLBVz label {
      @media only screen and (max-width: 578px){
        font-size: 12px;
      }
    }

    .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{
      height: 100%;
    }

    .ant-select-selector {
      touch-action: manipulation !important;
    }
    .ant-select {
      touch-action: manipulation !important;
    }

    .ant-select-selection-item{
     height: 100%;
     touch-action: manipulation !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 52px;
      border-radius: 5px;
      align-items: center;
    }

    .ant-input{
      height: 52px;
      border-radius: 5px;
      touch-action: manipulation !important;
    }

    .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: transparent;
      cursor: not-allowed;
    }

    .ant-picker-datetime-panel{

      @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        
      }
    }
    .ant-picker-panel {
      
    &:last-child {
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

    .ant-picker-cell-inner{
      background: transparent;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      font-size: 1rem;
      line-height: 22px;
      font-weight: bold;
    }
    .ant-picker-input{
      border-color:  var(--orange-350);
  
    }
    .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--orange-350);
    border-color: var(--orange-350);
    margin-bottom: 0px;
  }
    * .ant-picker-input > input {
      font-weight: 500;
      font-size: 1rem;
    @media only screen and (max-width: 1400px) {
        font-size: .8rem;
      }
    }
    .ant-picker-input > input:hover {
      border-color:  var(--orange-350);
    }
    .ant-picker{
      cursor: pointer;
      width: 275px;
      font-family: 'Roboto';
      
      @media only screen and (max-width: 1024px){
        width: 200px;
        * .ant-picker-input > input {
          font-weight: 500;
          font-size: 0.7rem;
        }
      }

      @media only screen and (max-width: 578px) {
        width: 95px;
      }
    }

    .ant-picker-focused {
      border-color: var(--orange-350);
    }
    .ant-col-sm-4{
      justify-content: flex-start;
    }
   
    .ant-picker-header>button{
      color: var(--gray-200);
      font-weight: bold;
    }

    .ant-picker:hover, .ant-picker-focused {
      border-color: transparent;
    }

    .ant-picker-input {
      font-size: 13px;
    }

    .ant-col {
      font-size: 1rem;
      text-align: start;

      @media only screen and (max-width: 768px){
        font-size: .8rem;
      }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 18px;
      text-align: initial;
      padding: 8px;
    }

    

    .ant-picker-panel-container{
      border-radius: 8px;
      @media only screen and (max-width: 650px) {
        .ant-picker-panels{

          flex-direction: column !important;
        }
    }
    }
    .ant-modal{
      top: 2%;
    }

    .ant-modal-footer{
      background-color: var(--white-25);
      padding: 20px;
    }

    .ant-modal-title{
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 42px;
    }

    .ant-modal-header{
      border: none;
      max-height: 521px;
    }

    .ant-picker{
      width: 100%;
      height: 52px;
      border-radius: 5px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0;
      
    } 

    .ant-dropdown-menu {
      max-height: 250px;
      overflow: auto;
    }

    .ant-form-vertical .ant-form-item-label > label {
      font-weight: 500;
      font-size: .9rem;
      line-height: 19px;
      color: var(--gray-650);
    }

    .ant-select-arrow{
      color: var(--orange-350);
    }

    .ant-radio-button-wrapper-checked{
        color: white !important;
        background-color: var(--orange-350) !important;
      border: 1px solid var(--orange-350) !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      &&:focus{
        border: none;

      }

      &&:focus-within{
        border: none;
      }

    }

    .ant-radio-button-wrapper {
      color: var(--orange-350) ;
      border: 1px solid var(--orange-350) !important;

      :hover{
        color: var(--orange-350) ;

      }
   
    }
    .ant-radio-button-wrapper:focus-within {
    -webkit-box-shadow: none;
    box-shadow: none;
    }
   
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;

}
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
      border: none;
      background-color: transparent !important;
    }
 
    .ant-picker-input > input {
      font-weight: 500;
      font-size: 1rem;

      @media only screen and (max-width: 1500px) {
      font-size: .7rem;
      }
    }

    .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
  .ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu-title-disabled{

      color: var(--gray-25) !important;
    }
  
  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner{
      background: var(--orange-350);
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background: var(--orange-350);
    }

    .ant-pagination-item a {
      color: var(--orange-350);
      background-color: transparent;
      margin: 0 5px;
      display: flex;
      align-items: center;
    }

    .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
      background-color: transparent;
      border: 1px solid var(--gray-250);
      border-radius: 5px;
    }

    .ant-col {
      font-weight: 400;
    }

    .ant-picker-header-view {
      font-size: 1rem;
      color: var(--gray-600);
    }
    .ant-picker-input > input:hover {
      border-color: none;
      border-right-width: 0;
   }

  .ant-picker-input > input {
     font-weight: 500;
     font-size: 1rem;
  }

    ::-webkit-scrollbar {
        width: 3px;

        &:hover {
          width: 15px;
        }
      }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  * .ant-select-single.ant-select-show-arrow .ant-select-selection-item, 
  * .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding: 8px 10px;
    align-items: center;
  }

  @media only screen and (max-width: 578px) {
    .ant-table-column-sorters {
      padding: 10px 0;
    }
  }

  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100vh;
  }

  *, button, input{
    border: 0;
    background: none;

    .ant-btn-primary {
      color: var(--white-10);
      border-radius: 5px;
      border: none;
      background: var(--orange-350);

      :hover, :active, :focus {
        background: var(--orange-350);
      }
      
    }
    .ant-btn-link {
      color: var(--black-opaco);
    }
  }
  
  button{
    cursor: pointer;
  }

  .ant-select-multiple .ant-select-selector {
    height: 3.3rem !important;
    border-radius: 5px;
}

  .ant-picker-suffix {
    color: var(--orange-350);
  }

  * .ant-picker:hover, * .ant-picker-focused {
    border-color: var(--gray-200);
  }   

  .ant-typography-edit{
    color: var(--orange-350);
  }

  .modalEdit{
    .ant-modal-content{
      .ant-modal-body{
        padding: 1rem 0 0;
      }
      svg{
        color: var(--orange-350);
      }
      .ant-modal-confirm-title{
        font-weight: 500;
        color: var(--blue-900);
      }
      .ant-modal-confirm-body{
        padding: 1rem 1rem 1rem 3rem;
      }
      .ant-modal-confirm-content{
        font-weight: 500;
        color: var(--gray-530);
      }
      button{
        height: 3.25rem;
        width: 7.5rem;
        border: none;
        box-shadow: none;
        border-radius: 0.5rem;
        :hover, :focus{
          border: none;
        }
      }
      .ant-modal-confirm-btns{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        width: 100%;
        height: 5rem;
        background: var(--white-10);
      }
      button.ant-btn{
        background: none;
        color: var(--gray-570);
      }

      button.ant-btn.ant-btn-default{
        background: var(--orange-350) ;
        color:white;
        :hover{
          opacity: 80%;
        }
      }
      button.ant-btn.ant-btn-dangerous{
        background: var(--orange-350) ;
        color:white;
        :hover{
          opacity: 80%;
        }
      }
    }
  }  
  .modalDelete, .modalConfirm{
    .ant-modal-content{
      .ant-modal-body{
        padding: 1rem 0 0;
      }
      svg{
        color: var(--orange-350);
      }
      .ant-modal-confirm-title{
        font-weight: 500;
        color: var(--blue-900);
      }
      .ant-modal-confirm-body{
        padding: 1rem 1rem 1rem 3rem;
      }
      .ant-modal-confirm-content{
        font-weight: 500;
        color: var(--gray-530);
      }
      button{
        height: 3.25rem;
        width: 7.5rem;
        border: none;
        box-shadow: none;
        border-radius: 0.5rem;
        :hover, :focus{
          border: none;
        }
      }

      .ant-modal-confirm-btns{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        width: 100%;
        height: 5rem;
        background: var(--white-10);
      }
      button.ant-btn{
        background: none;
        color: var(--gray-570);
      }

      button.ant-btn.ant-btn-primary{
        background: var(--orange-350) ;
        color:white;
        :hover{
          opacity: 80%;
        }
      }
      button.ant-btn.ant-btn-dangerous{
        background: var(--orange-350) ;
        color:white;
        :hover{
          opacity: 80%;
        }
      }
    }
  }  

  html {
    color: black;
  }
  
  :root {
    --white: #ffffff;
    --white-10: #f0f0f0;
    --white-50:#fffefe;
    --white-25: #f8f8f9;
    --white-100: #F1F1F1;
    --gray-25: #e0e1e3;
    --gray-45: #686F82;
    --gray-48: #686F8250;
    --gray-49: #686F8210;
    --gray-50: #D2D2D2;
    --gray-75: #E6E7EB;
    --gray-150: #969696;
    --gray-170: #B0B0B0;
    --gray-200: #9B9FAB;
    --gray-220: #676E80;
    --gray-250: #E9E9E9;
    --gray-270: #d9d9d9;
    --gray-300: #BDBEBF;
    --gray-530: #545454;
    --gray-550: #5E6578;
    --gray-570: #50576D;
    --gray-600: #707173;
    --gray-625: #736b6b17;
    --gray-650: #444444;
    --gray-700: #27282A;
    --gray-800: #323335;
    --soft-black: #1b1c1e;
    --orange-5: #FFDAA4;
    --orange-10: #EAD8D0;
    --orange-450: #FFA388;
    --orange-200:rgba(255, 200, 150, 0.1);
    --orange-350: #FF8561;
    --orange-600: #EA5757;
    --orange-700: #dea000;
    --green-400: #43B868;
    --green-800: #2E7B32;
    --orange-50: rgba(255, 135, 94, 0.1);
    --blue-900: #040F2E;
    --blue-960: #040F2E60;
    --blue-980: #040F2E80;
    --blue-999: #040F2E99;
    --black-opaco: #1B1C1E;

    --warning: yellow;
    --success: #f6ffed;
    --error: #fff2f0;

    //status
    --night-shift: #2b606c;
    --c64-blue: #003bff;
    --c80-blue: #1963A2;
    --clear-chill:#1E90FF;
    --goldenrod-tea: #A37843;
    --midori-green: #43b868;
    --light-green: #B9E0BB;
    --maroon: #800000;
    --pix: #00BDAE;
    --chain-gang-grey: #708090;
    --chanterelle: #DAA520;
    --golden-beryl-yellow: #dea000;
    --fading-sunset: #b4b7c1;
    --lilac-murmur: #e6e7ea;
    --roof-terracotta: #a44343;
    --rainbows-outer-rim: #ff0001;
    --coffe: #BD6247;
    --light-red: #E0C4B5;
    
    --orange-gradient: linear-gradient(90deg, #FF875E 0%, #FD67AE 224.58%);
  }
`;
