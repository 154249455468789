import React, { Dispatch, SetStateAction, useState, useEffect } from "react";

import api from "../../services/api";

import { ItemsOutCart as ItemsOutCartModel } from "../../models/ItemsOutCart";
import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";

import Centralizer from "../../containers/Centralizer";

import { Empty } from "antd";

import moment from "moment";

import SpinIcon from "../../assets/svg/spin.svg";

import { Modal, Spin, Table } from "./styles";
import useEscapeKey from "../../utils/useEscapeKey";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  cashHistory: CashHistoriesModel | null;
  countryPY?: boolean;
}

const ModalItemsCashHistory: React.FC<IProps> = ({
  visible,
  setVisible,
  cashHistory,
  countryPY,
}) => {
  const [loading, setLoading] = useState(false);
  const [itemOutCart, setItemOutCart] = useState<ItemsOutCartModel[]>([]);

  const handleModalToggle = () => {
    setVisible(!visible);
  };

  useEscapeKey({ setVisible: () => setVisible(false) });

  useEffect(() => {
    async function fetchItems() {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(`/items_out_cart/history/${cashHistory?.id}`);
      setItemOutCart(data);
      setLoading(false);
    }

    if (cashHistory && visible) {
      fetchItems();
    }
  }, [cashHistory, visible]);

  const pygExchangeRate = cashHistory?.pyg_exchange_rate || 1;

  const columns = [
    {
      title: "Usuário",
      key: "opened_user.name",

      render: () => (
        <span>
          {cashHistory?.opened_user
            ? cashHistory?.opened_user.name
            : "Usuário deletado"}
        </span>
      ),
    },
    {
      title: "Observações",
      key: "observation",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: () => (
        <>
          {cashHistory?.observation ? (
            <span>{cashHistory?.observation}</span>
          ) : (
            <span>Não há observações</span>
          )}
        </>
      ),
    },
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {`${moment(text, "DD-MM-YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}`}
        </span>
      ),
    },
    {
      title: "Produto",
      dataIndex: "product.name",
      key: "product.name",
      render: (_, record) => <span>{`${record?.product?.name}`}</span>,
    },
    {
      title: "Valor do produto",
      dataIndex: "product.price_sell",
      key: "product.price_sell",
      render: (_, record) => {
        const priceSell = record?.price_sell || 0;
        const convertedPrice = countryPY
          ? priceSell / +pygExchangeRate
          : priceSell;
        const currencyCode = countryPY ? "PYG" : "BRL";
        const currencySymbol = countryPY ? "Gs." : "R$";

        return (
          <span>{`${currencySymbol} ${currencyFormaterPYGBRL(
            +convertedPrice,
            currencyCode
          )}`}</span>
        );
      },
    },
    {
      title: "Justificativa da exclusão",
      dataIndex: "reason",
      key: "reason",
      textWrap: "word-break",
      render: (text) => <span>{`${text}`}</span>,
    },
  ];

  return (
    <Modal
      title="Itens excluídos"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={handleModalToggle}
      cancelButtonProps={{ hidden: true }}
      closable={false}
      width={"90%"}
    >
      {loading ? (
        <Centralizer>
          <Spin src={SpinIcon} />
        </Centralizer>
      ) : itemOutCart.length ? (
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          dataSource={itemOutCart.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
        />
      ) : (
        <Centralizer>
          <Empty description="Nenhum item encontrado!" />
        </Centralizer>
      )}
    </Modal>
  );
};

export default ModalItemsCashHistory;
