import React, { Dispatch, SetStateAction, useState } from "react";
import { Product as ProductModel } from "../../models/Product";
import { Tooltip, Table, Checkbox } from "antd";

import {
  Container,
  LabelName,
  Actions,
  ContentCheckbox,
  HeaderCenter,
} from "./styles";

type ProductAdd = {
  store_id: number | undefined;
  product_id: number | undefined;
  price_unit: number;
  cod_ncm: string | undefined;
};
interface IProps {
  products: ProductModel[];
  setProductsAdd: Dispatch<SetStateAction<ProductAdd[]>>;
  storeId: number | undefined;
  loading: boolean;
}

const AddProductFranchiseeList: React.FC<IProps> = ({
  loading,
  products,
  storeId,
  setProductsAdd,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<ProductAdd[]>([]);

  const handleSelectAll = (checked) => {
    if (checked) {
      const allProducts = products.map((product) => ({
        store_id: storeId,
        product_id: product.id,
        price_unit: +(product.price_sell || 0),
        cod_ncm: product.cod_ncm
      }));
      setSelectedProducts(allProducts);
      setProductsAdd(allProducts);
    } else {
      setSelectedProducts([]);
      setProductsAdd([]); 
    }
  };

  const handleSelectProduct = (product: ProductAdd, isChecked) => {
    if (isChecked) {
      setSelectedProducts((prevSelected) => [...prevSelected, product]);
      setProductsAdd((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((p) => p.product_id !== product.product_id)
      );
      setProductsAdd((prevSelected) =>
        prevSelected.filter((p) => p.product_id !== product.product_id)
      ); 
    }
  };

  const rowSelection = {
    onSelect: (record, selected) => {
      handleSelectProduct(record, selected);
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        setSelectedProducts(selectedRows.map((record) => ({
          store_id: storeId,
          product_id: record.id,
          price_unit: +(record.price_sell || 0),
          cod_ncm: record.cod_ncm
        })));
        setProductsAdd(selectedRows.map((record) => ({
          store_id: storeId,
          product_id: record.id,
          price_unit: +(record.price_sell || 0),
          cod_ncm: record.cod_ncm
        }))); 
      } else {
        setSelectedProducts([]);
        setProductsAdd([]); 
      }
    },
  };

  const columns = [
    {
      title: <HeaderCenter> Código </HeaderCenter>,
      dataIndex: "cod_product",
      key: "cod_product",
      width: "20%",
      sorter: (codProduct1, codProduct2) => codProduct1?.id - codProduct2?.id,
      render: (text) => (
        <>
          <Tooltip title={`${text}`}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (text, record) => (
        <>
          <Tooltip title={`${text}`}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "category?.name",
      key: "category?.name",
      width: "30%",
      render: (text, record) => (
        <>
          <Tooltip title={`${text}`}></Tooltip>
          <LabelName>{record?.category?.name}</LabelName>
        </>
      ),
    },
    {
      title: <HeaderCenter>Ações</HeaderCenter>,
      width: "10%",
      render: (text, record) => (
        <>
          <Actions>
            <Checkbox
              onChange={({ target: { checked } }) => {
                handleSelectProduct(
                  {
                    store_id: storeId,
                    product_id: record.id,
                    price_unit: +(record.price_sell || 0),
                    cod_ncm: record.cod_ncm
                  },
                  checked
                );
              }}
              checked={selectedProducts.some(p => p.product_id === record.id)}
            />
          </Actions>
        </>
      ),
    },
  ];

  return (
    <Container>
      <ContentCheckbox>
        <Checkbox onChange={(e) => handleSelectAll(e.target.checked)}>Selecionar todos os produtos</Checkbox>
      </ContentCheckbox>
      <Table
        rowSelection={rowSelection}
        loading={loading}
        columns={columns}
        scroll={{ y: 300 }}
        dataSource={products.map((entity) => ({
          ...entity,
          key: entity.id,
        }))}
      />
    </Container>
  );
};

export default AddProductFranchiseeList;
