import styled from "styled-components";

import { Checkbox as CheckboxAnt, Row as RowAnt } from "antd";
import { ShoppingCartOutline } from "../../styles/Icons";

export const TopSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  width: 90%;
  margin-left: 6%;

  @media only screen and (max-width: 840px) {
    justify-content: center;
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 50%;

  @media only screen and (max-width: 1400px) {
    width: 55%;
  }

  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  @media only screen and (max-width: 578px) {
    height: 60%;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  h3 {
    font-size: 2.5rem;
    color: var(--orange-350);
    font-weight: 500;
    margin-bottom: 0%;

    @media only screen and (max-width: 1600px) {
      font-size: 2.3rem;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 2rem;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 1.7rem;
    }

    @media only screen and (max-width: 578px) {
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 1rem;
    color: #abaaaa;

    @media only screen and (max-width: 578px) {
      font-size: 0.9rem;
    }
  }
`;
export const Row = styled(RowAnt)`
  display: flex;
  justify-content: flex-start;
`;

export const Checkbox = styled(CheckboxAnt)`
  font-size: 0.6rem;
  zoom: 1.5;
  font-weight: 600;
  margin: 0.5rem 0;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.1rem 0.3rem;
  border-radius: 8px;
  cursor: pointer;
  background-image: var(--orange-gradient);

  @media only screen and (max-width: 578px) {
    font-size: 1.1rem;
  }

  &:hover,
  &:active {
    span {
      opacity: 0;
    }

    svg {
      transform: translateX(310%);
      transition: all 0.5s;
    }
  }
`;

export const IconShoppingCart = styled(ShoppingCartOutline)`
  width: 25px;
  height: 25px;

  @media only screen and (max-width: 578px) {
    justify-content: space-around;
    height: 10%;
    width: 18px;
    height: 18px;
  }
`;

export const IconContent = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  width: 50%;

  img {
    width: 75%;

    @media only screen and (max-width: 1680px) {
      width: 70%;
    }

    @media only screen and (max-width: 840px) {
      width: 100%;
    }

    @media only screen and (max-width: 578px) {
      width: 100%;
      margin-top: 2%;
    }
  }

  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
