import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import {
  LogOut,
  Home,
  Support,
  Graph,
  CheckSquare,
  Menu,
  Close,
  Phone,
  MapPin,
  DocumentOnePage,
  Truck,
} from "../../styles/Icons";

interface IResponsive {
  responsive?: boolean;
}

export const Container = styled.nav<IResponsive>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 7%;
  background-color: var(--black-opaco);

  .logout-mobile {
    display: none;
  }

  ${({ responsive }) =>
    responsive === true &&
    css`
      position: absolute;
      z-index: 2;

      .logout-mobile {
        position: fixed;
        top: 6.5%;
        right: 4%;
        display: unset;
        color: var(--white-10);
        z-index: 3;
      }
    `}
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17%;
  width: 70%;

  img {
    width: 85%;
  }

  @media screen and (max-width: 700px) {
    height: 60%;

    img {
      width: 60%;
    }
  }
`;

export const NavMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  height: 68%;
  width: 100%;
  margin-top: 5%;

  @media screen and (max-width: 700px) {
    height: 65%;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
`;

interface IMenu {
  actived?: boolean;
}
interface INavItem {
  actived?: string;
}

export const MenuContainer = styled.li<IMenu>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 11%;
  padding: 3% 0;
  border-top: 1px solid var(--gray-700);
  transition: 0.2s;

  :last-child {
    border-bottom: 1px solid var(--gray-700);
  }

  @media screen and (max-width: 1600px) {
    height: 12%;
  }

  @media screen and (max-width: 1366px) {
    height: 15%;
  }

  @media screen and (max-width: 700px) {
    border-top: none;
    height: 10%;
    :last-child {
      border-bottom: none;
    }
  }

  ${({ actived }) =>
    actived &&
    css`
      @media screen and (max-width: 700px) {
        display: none;
      }
    `}
`;

export const NavItem = styled(Link)<INavItem>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15%;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  text-align: start;

  span {
    color: var(--gray-600);
  }

  :hover {
    span,
    svg {
      color: var(--white-10);
    }
  }

  ${({ actived }) =>
    actived === "true" &&
    css`
      border-radius: 0.9rem;
      background-color: var(--gray-800);
      span,
      svg {
        color: var(--white-10);
      }

      @media screen and (max-width: 700px) {
        background-color: transparent;
        &:before,
        &:after {
          content: "";
          flex: 1;
          height: 1px;
          margin: 0 1em;
          background: var(--white) !important;
          width: 100%;
        }
      }
    `}

  @media only screen and (max-width: 1680px) {
    padding: 0 15%;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 840px) {
    font-size: 0.6rem;
  }

  @media screen and (max-width: 700px) {
    justify-content: center;
    font-size: 1rem;
    padding: 0;
    svg {
      display: none;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 15%;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 24px;
  }

  .perfil {
    color: var(--gray-600);
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 1600px) {
    height: 17%;
  }

  @media only screen and (max-width: 1366px) {
    height: 21%;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55%;
  padding: 3%;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 24px;

    @media screen and (max-width: 840px) {
      width: 2rem;
      height: 2rem;
    }
  }

  .info-user {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 1rem;

    .name-user {
      color: var(--white-25);
      font-size: 0.8rem;
    }

    .role-user {
      color: var(--gray-600);
      font-size: 0.8rem;
    }

    @media only screen and (max-width: 840px) {
      .name-user {
        font-size: 0.6rem;
      }

      .role-user {
        font-size: 0.6rem;
      }
    }
  }

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const ButtonLogout = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45%;
  background-color: var(--gray-800);
  border-radius: 0.9rem;

  span,
  svg {
    color: var(--gray-50);
    font-size: 0.9rem;
  }
`;

const IconCSS = css`
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 10%;
  color: var(--gray-600);

  @media only screen and (max-width: 1366px) {
    width: 1.1rem;
    height: 1.1rem;
  }
  @media only screen and (max-width: 840px) {
    width: 0.8rem;
    height: 0.8rem;
  }
`;

export const HomeIcon = styled(Home)`
  ${IconCSS};
`;
export const SupportIcon = styled(Support)`
  ${IconCSS};
`;

export const BenchMarkIcon = styled(Graph)`
  ${IconCSS}
`;

export const CheckIcon = styled(CheckSquare)`
  ${IconCSS}
`;

export const NfeIcon = styled(DocumentOnePage)`
  ${IconCSS}
`;

export const MapPinIcon = styled(MapPin)`
  ${IconCSS}
  width: 1.4rem;
  height: 1.4rem;
`;

export const TruckIcon = styled(Truck)`
  ${IconCSS}
  width: 1.4rem;
  height: 1.4rem;
`;

export const LogOutIcon = styled(LogOut)`
  ${IconCSS}
`;

export const ParaguaiImg = styled.img`
  ${IconCSS}
`;

export const PhoneIcon = styled(Phone)`
  ${IconCSS}
  width: 1.8rem;
  height: 1.8rem;
  margin-left: -0.3rem;
`;

const ResponsiveIcon = css`
  display: none;
  @media only screen and (max-width: 700px) {
    position: fixed;
    top: 6.5%;
    left: 3%;
    display: unset;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--gray-700);
    z-index: 1;
  }

  @media only screen and (max-width: 600px) {
    top: 2.5%;
  }
`;

export const MenuIcon = styled(Menu)`
  ${ResponsiveIcon}
`;

export const CloseIcon = styled(Close)`
  ${ResponsiveIcon}
  color: var(--white-10) !important;
  z-index: 3 !important;
`;
