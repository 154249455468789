import styled, { css } from "styled-components";

import { Col as ColAnt, Button as ButtonAnt, Table as TableAnt } from "antd";

import {
  Store,
  AddShoppingCart,
  Pencil,
  Trash,
  Restore,
  ShoppingBags,
} from "../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;
export const Col = styled(ColAnt)``;

export const Label = styled.label``;

export const Button = styled(ButtonAnt)`
  margin: 0 5px;
  border: none;
  background: none;
  box-shadow: none;

  :hover,
  :focus {
    border: none;
    background: none;
    color: var(--gray-650);
  }

  @media only screen and (max-width: 578px) {
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    margin: 0;
  }
`;

export const LabelName = styled.label`
  text-transform: capitalize;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;
  :hover,
  :focus,
  :active {
    background: none;
    box-shadow: none;
    border: none;
  }
  @media only screen and (max-width: 900px) {
    width: 18px;
    height: 18px;
  }
  @media only screen and (max-width: 578px) {
    width: 13px;
    height: 13px;
  }
`;

interface IIcon {
  checked?: boolean;
}

export const StoreIcon = styled(Store)<IIcon>`
  ${iconCSS}

  :hover {
    fill: var(--orange-350);
  }

  ${({ checked }) =>
    checked &&
    css`
      fill: var(--orange-350);
    `}
`;

export const OrderIcon = styled(AddShoppingCart)<IIcon>`
  ${iconCSS}

  :hover {
    fill: var(--green-400);
  }

  ${({ checked }) =>
    checked &&
    css`
      fill: var(--green-400);
    `}
`;
export const SalesIcon = styled(ShoppingBags)<IIcon>`
  ${iconCSS}

  :hover {
    fill: blue;
  }

  ${({ checked }) =>
    checked &&
    css`
      fill: blue;
    `}
`;
export const EditIcon = styled(Pencil)`
  ${iconCSS}

  :hover {
    color: blue;
  }
`;

export const RestoreIcon = styled(Restore)`
  ${iconCSS}
  :hover {
    fill: var(--gary-550);
  }
`;
export const RemoveIcon = styled(Trash)`
  ${iconCSS}

  :hover {
    fill: red;
  }
`;

export const Table = styled(TableAnt)`
  height: 60%;

  .ant-table-container {
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--gray-50);

    max-height: 70%;
    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: #fffefe;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #acaeae;
      border-radius: 5px;
    }

    @media only screen and (max-width: 800px) {
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
  }
  .ant-table-column-sorters {
    padding: 0;
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }

  .ant-pagination-options-size-changer.ant-select {
    width: 6.3rem;
  }
  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
`;
export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
