import styled, { css } from 'styled-components';
import { NetworkChart, Status, Play, ArrowRightS } from '../../styles/Icons'

import { Progress, Button as ButtonAnt } from 'antd'

export const ProgressBar = styled(Progress)`
    width: 100%;

    .ant-progress-show-info .ant-progress-outer {
        margin-right: 0 !important; 
        padding-right: 0 !important;
    }
`;

interface IconsProps {
    color: string;
}
const Icons = css<IconsProps>`
    width: 1rem;
    height: 1rem;
    color: ${props => props.color};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
`;

export const ContentFlex = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ContentFlexBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const OrangeSpan = styled.span`
    font-size: 1.1rem;
    color: var(--orange-350);
`;

interface IBoxProps {
    background: string;
    justifyContent: string;
    margin?: string;
    border?: string;
    height?: string;
}
export const Box = styled.div<IBoxProps>`
    width: 100%;
    display: flex;
    justify-content: ${props => props.justifyContent};
    align-items: center;
    border-radius: 10px;
    background-color: ${props => props.background};
    padding: .5rem;
    height: ${props => props.height};

    margin: ${props => props.margin};
    border: ${props => props.border};
    span {
        color: white;
        font-size: 1.1rem;
    }

    .content-card {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .content-span {
        display: flex;
        width: 100%;
    }

    .truck {
        font-size: .9rem;
        color: var(--gray-45);
        margin-bottom: .2rem;
    }

    .plate {
        color: var(--green-400);
        font-size: 1rem;

        @media screen and (min-width: 768px) {
            font-size: 1.2rem;
        }
    }

    .date {
        color: var(--gray-45);
        text-transform: capitalize;
        font-size: 1rem;

        @media screen and (min-width: 768px) {
            font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 768px) {
     padding: 1rem;
    }
`;

export const NetworkIcon = styled(NetworkChart)`
    ${Icons}
    width: 2rem;
    height: 2rem;
`;

export const StatusIcon = styled(Status)`
    ${Icons}
    width: 2rem;
    height: 2rem;
    cursor: pointer;
`;

export const PlayIcon = styled(Play)`
    ${Icons}
    width: 2rem;
    height: 2rem;
    cursor: pointer;
`;

export const ArrowLeftIcon = styled(ArrowRightS)`
    ${Icons}
    width: 2rem;
    height: 2rem;
    cursor: pointer;
`;
interface IButtonProps {
    color: string;
    backgroundColor: string;
}
export const Button = styled(ButtonAnt) <IButtonProps>`
    width: 100%;
    height: 4.5rem;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 1.3rem;

    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};

    :hover, :focus {
      background-color: ${props => props.backgroundColor};
      color: ${props => props.color};
      opacity: 70%;
    }
`;