import React, { Dispatch, SetStateAction } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { CategoryModel } from "../../models/CategoryModel";

import { MoreInfo } from "../CashHistoryList/styles";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../hooks/useResponsiveSizeTable";

import { Dropdown, Menu, notification, Tooltip, Modal } from "antd";

import {
  Actions,
  Delete,
  Center,
  LabelName,
  Table,
  RestoreIcon,
} from "./styles";

const { confirm } = Modal;

interface ICategoryProps extends RouteComponentProps {
  category: CategoryModel[];
  setCategory: Dispatch<SetStateAction<CategoryModel[]>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setEditCategory: (id: number) => void;
  loading: boolean;
}

const CategoryList: React.FC<ICategoryProps> = ({
  category,
  setCategory,
  setShouldSearch,
  setEditCategory,
  loading,
  setLoading,
}) => {
  const responsiveWindow = useWindowSize();

  const deleteCategoryRow = async (id: number) => {
    confirm({
      title: "Remover categoria",
      content: "Tem certeza que gostaria de remover esta categoria?",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      async onOk() {
        try {
          setLoading(true);
          await api.delete(`product_categories/${id}`);

          notification.success({
            message: "Categoria deletado com sucesso",
            duration: 5,
          });
          setShouldSearch(true);
        } catch (error) {
          const _message = "Erro ao deletar categoria";

          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: _message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const restoreCategoryRow = async (category) => {
    confirm({
      title: "Restaura categoria",
      content: "Tem certeza que gostaria de restaurar esta categoria?",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      async onOk() {
        try {
          setLoading(true);
          await api.patch(`product_categories/${category?.id}`, {
            deleted_at: null,
          });

          setShouldSearch(true);

          notification.success({
            message: "Categoria restaurada com sucesso",
            duration: 5,
          });
        } catch (error) {
          const _message = "Erro ao restaurar categoria";

          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: _message,
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      defaultSortOrder: "ascend" as any,
      sorter: (ant, prox) => ant.id - prox.id,
      render: (text) => (
        <>
          <Tooltip title={text}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "NOME",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <>
          <Tooltip title={text}></Tooltip>
          <LabelName>{text}</LabelName>
        </>
      ),
    },
    {
      title: "CÓDIGO DRE",
      dataIndex: "dre.name",
      key: "dre.name",
      responsive: ["sm"] as any,
      render: (text, record) => (
        <>
          <Tooltip
            title={
              `${record?.dre?.name}`
                ? `${record?.dre?.name}`
                : "Sem dre vinculado"
            }
          ></Tooltip>
          <LabelName>
            {record?.dre?.name ? record?.dre?.name : "Sem dre vinculado"}
          </LabelName>
        </>
      ),
    },
    {
      title: <Center>Ações</Center>,
      width: "10%",
      render: (text, record) => (
        <Center>
          {verifyPermission("category.restore") && record?.deleted_at ? (
            <Tooltip title="Restaurar Categoria">
              <RestoreIcon
                onClick={() => {
                  restoreCategoryRow(record);
                }}
              />
            </Tooltip>
          ) : (
            <Actions>
              <Dropdown
                overlay={
                  <Menu>
                    {verifyPermission("category.edit") && (
                      <Menu.Item
                        onClick={() => {
                          setEditCategory(record.id);
                        }}
                      >
                        Editar categoria
                      </Menu.Item>
                    )}
                    {verifyPermission("category.delete") && (
                      <Menu.Item
                        onClick={() => {
                          deleteCategoryRow(record.id);
                        }}
                      >
                        <Delete>Excluir categoria</Delete>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <MoreInfo />
              </Dropdown>
            </Actions>
          )}
        </Center>
      ),
    },
  ];
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
        dataSource={category.map((entity) => ({
          ...entity,
          key: entity.id,
        }))}
      />
    </>
  );
};

export default withRouter(CategoryList);
