import styled from "styled-components";
import { Col, Select as SelectAnt } from "antd";

export const Column = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 15px;
  @media only screen and (max-width: 578px) {
    padding: 2px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 5px;
`;

export const Select = styled(SelectAnt)`
  div.ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px !important;
  }
`;
