import React, { forwardRef } from "react";

import Input from "react-input-mask";

interface IProps {
  value?: string | number | undefined;
  mask: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onKeyDown?: (event: any) => void;
}
const InputMask = forwardRef<null, IProps>((props, ref) => {
  const { value, onChange, mask, name, placeholder, disabled, onKeyDown } = props;
  return (
    <Input
      disabled={disabled}
      name={name}
      value={value || ""}
      mask={mask}
      placeholder={placeholder}
      className="ant-input"
      ref={ref}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
});

export default InputMask;
