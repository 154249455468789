export enum ProductStoreTranslated {
    id = "ID",
    cod_ncm = "Código NCM",
    price_unit = "Preço Unitário",
    created_at = "Data de Criação",
    deleted_at = "Data de Exclusão",
    product_id = "ID do Produto",
    store_id = "ID da Loja",
    permission = "Permissão",
    price_sell = "Preço de Venda",
    price_permission = "Permissão de Preço",
    quantity = "Quantidade",
    unity_taxable = "Unidade Taxável",
    price_taxable = "Preço Taxável",
    cfop = "CFOP",
    icms_origin = "Origem ICMS",
    icms_tax_situation = "Situação Tributária ICMS",
    tax_regime = "Regime Tributário",
    pis_tax_situation = "Situação Tributária PIS",
    pis_aliquot_value = "Valor Alíquota PIS",
    pis_calculation_type = "Tipo Cálculo PIS",
    pis_aliquot_percentage = "Percentual Alíquota PIS",
    cofins_calculation_type = "Tipo Cálculo COFINS",
    cofins_tax_situation = "Situação Tributária COFINS",
    cofins_aliquot_value = "Valor Alíquota COFINS",
    cofins_aliquot_percentage = "Percentual Alíquota COFINS",
    additional_information = "Informação Adicional",
    icms_aliquot_percentage = "Percentual Alíquota ICMS",
    bc_icms = "Base Cálculo ICMS",
    bc_icms_st = "Base Cálculo ICMS ST",
    redution_icms = "Redução ICMS",
    aliquot_final_consumer = "Alíquota Consumidor Final",
    codigo_beneficio_fiscal = "Código Benefício Fiscal",
    cest = "CEST",
    product_is_enable = "Produto Habilitado",
    product = "Produto",
    name = "Nome do Produto",
    category_id = "ID da Categoria",
    price_buy = "Preço de Compra",
    permission_store = "Permissão de Loja",
    permission_order = "Permissão de Pedido",
    cod_product = "Código do Produto",
    brand = "Marca",
    unity = "Unidade",
    weight = "Peso",
    created_at_product = "Data de Criação do Produto",
    deleted_at_product = "Data de Exclusão do Produto",
    category = "Categoria",
    category_name = "Nome da Categoria do Produto",
    factory_info = "Informação da Fábrica",
    factory_info_codigo = "Código da Fábrica",
    factory_info_descricaoCurta = "Descrição Curta da Fábrica",
    factory_info_estoque = "Estoque da Fábrica",
    factory_info_estoque_depositos = "Depósitos da Fábrica",
    factory_info_estoque_depositos_id = "ID do Depósito",
    factory_info_estoque_depositos_saldoFisico = "Saldo Físico do Depósito",
    factory_info_estoque_depositos_saldoVirtual = "Saldo Virtual do Depósito",
    factory_info_estoque_produto = "Produto da Fábrica",
    factory_info_estoque_produto_id = "ID do Produto da Fábrica",
    factory_info_estoque_saldoFisicoTotal = "Saldo Físico Total da Fábrica",
    factory_info_estoque_saldoVirtualTotal = "Saldo Virtual Total da Fábrica",
    factory_info_formato = "Formato da Fábrica",
    factory_info_id = "ID da Fábrica",
    factory_info_nome = "Nome da Fábrica",
    factory_info_preco = "Preço da Fábrica",
    factory_info_situacao = "Situação da Fábrica",
    factory_info_tipo = "Tipo da Fábrica",
}
