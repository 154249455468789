import styled, { css } from "styled-components";

import { Star, RecordCircle } from "../../../styles/Icons";

import {
  Modal,
  Rate as RateAnt,
  Divider as DividerAnt,
  Input,
  Button as ButtonAnt,
  Form as FormAnt,
} from "antd";

export const Container = styled(Modal)``;

export const RatingContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const CommentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const Title = styled.label`
  color: var(--gray-45);
  font-weight: 500;
  font-size: 1rem;
`;

export const Rate = styled(RateAnt)`
  color: var(--orange-350);
`;

export const Divider = styled(DividerAnt)`
  border-top: 1px solid var(--gray-75);
`;

export const StarIcon = styled(Star)`
  width: 3rem;
  height: 3rem;
  transition: 0.2s;
`;

export const TextArea = styled(Input.TextArea)`
  margin-top: 8px;

  :hover,
  :focus {
    box-shadow: 0 0 0 0;
    outline: none;
    border-color: var(--gray-75);
  }
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const Button = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const Form = styled(FormAnt)`
  .ant-divider-horizontal,
  .ant-divider-with-text {
    border-top-color: var(--gray-100);

    .ant-divider-inner-text {
      font-weight: 500;
      padding-left: 0;
      color: var(--red-600);
    }

    &::before {
      width: 0;
    }
  }

  .ant-input,
  .ant-select-selector,
  .ant-picker {
    width: 100%;
    height: 3rem !important;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 0;
  }

  .ant-form-item-label {
    font-weight: 500;
    color: var(--gray-550);
  }

  @media only screen and (max-width: 1366px) {
    .ant-form-item {
      margin-bottom: 0.8rem;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker {
      height: 2.3rem !important;
      font-size: 0.8rem;
    }
    .ant-form-item-label {
      font-size: 0.8rem;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 0.7rem;
    }
  }
`;

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4rem;

  label {
    font-weight: 500;
    color: var(--gray-550);

    span {
      color: var(--orange-100);
    }
  }

  @media only screen and (max-width: 1366px) {
    height: 3rem;

    label,
    span {
      font-size: 0.8rem;
    }
  }
`;

interface ISteps {
  currentPage: number;
}

export const Steps = styled.div<ISteps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .step-line1,
  .step-line2 {
    width: 50%;
    height: 0.5rem;
    background: var(--gray-25);
    content: "";
  }

  ${({ currentPage }) => {
    if (currentPage === 1) {
      return css`
        .step-icon1 {
          color: var(--orange-350);
        }
      `;
    }

    if (currentPage === 2) {
      return css`
        .step-icon1,
        .step-icon2 {
          color: var(--orange-350);
        }

        .step-line1 {
          background: var(--orange-350);
        }
      `;
    }

    if (currentPage === 3) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3 {
          color: var(--orange-350);
        }

        .step-line1,
        .step-line2 {
          background: var(--orange-350);
        }
      `;
    }
    if (currentPage === 4) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3 {
          color: var(--orange-350);
        }

        .step-line1,
        .step-line2 {
          background: var(--orange-350);
        }
      `;
    }
  }}
`;

export const StepIcon = styled(RecordCircle)`
  color: var(--gray-25);
  width: 1rem;
  height: 1rem;
`;
