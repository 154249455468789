import styled, { css } from "styled-components";

import { Modal, Row as RowAnt, Col as ColAnt, Button as AntButton } from "antd";
import { FileRemoveOutline, Download } from "../../styles/Icons";

export const Container = styled(Modal)`
  .ant-modal-header {
    padding: 1.3rem;
  }

  .ant-modal-body {
    padding: 0 2rem 1rem;
    overflow-y: auto;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 5rem;
`;

export const ButtonFooter = styled(AntButton)`
  display: flex;
  width: 9.5rem;
  height: 3.25rem;
  background-color: var(--orange-350);
  border: 1px solid var(--orange-350);
  color: var(--white);
  padding: 0.5rem !important;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  :hover,
  :focus {
    background-color: var(--orange-350);
    border: 1px solid var(--orange-350);
    color: var(--white);
    opacity: 70%;
  }
  :last-child {
    margin-left: 1rem;
  }
`;

export const ContentActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  display: block;
`;

export const ButtonSearch = styled(AntButton)`
  width: 100%;
  height: 100%;
  background-color: var(--orange-350);
  color: var(--white);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  :hover,
  :focus {
    background-color: var(--orange-350);
    color: var(--white);
    opacity: 70%;
  }
`;

export const Row = styled(RowAnt)`
  justify-content: space-between;
  color: var(--blue-900);
  font-weight: 500;
  margin-bottom: 2rem;
`;

export const icon = css`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
export const PDFIcon = styled(FileRemoveOutline)`
  ${icon}
`;

export const DownloadIcon = styled(Download)`
  ${icon}
`;

export const Col = styled(ColAnt)`
  display: flex;
  justify-content: start;
  align-items: baseline;
`;

export const InfoContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
`;

export const ContentModal = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

export const ContentButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
`;
