export const getIpi = (value) => {
  switch (value) {
    case "00":
      return "50";
    case "01":
      return "51";
    case "02":
      return "52";
    case "03":
      return "53";
    case "04":
      return "54";
    case "05":
      return "55";
    case "49":
      return "99";
    default:
      return value;
  }
};
