import axios from "axios";
import { getToken } from "./auth";

const API_URL = process.env.REACT_APP_MS_NF_API;
const apiMsNf = axios.create({
  baseURL: API_URL,
  responseType: "json",
});

apiMsNf.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiMsNf;
