import React, { useState, useEffect } from "react";

import moment from "moment";
import api from "../../services/api";
import apiAuth from "../../services/apiAuth";
import { getTokenInfo } from "../../services/auth";
import { exportCSVFile } from "../../services/exportCSVFile";

import Button from "../../components/Button";
import Centralizer from "../../containers/Centralizer";
import PageContainer from "../../containers/PageContainer";
import HandlerSearch from "../../containers/HandlerSearch";
import HandlerContainer from "../../containers/HandlerContainer";

import { HandlerResponse as HandlerModel } from "../../models/Handler";
import { CashHandlerTypes } from "../../models/enums/CashHandlerTypes";

import {
  Container,
  SearchContainer,
  Content,
  DownloadContainer,
  DownloadIcon,
} from "./styles";
import { message, Empty } from "antd";

const Handler: React.FC = () => {
  const [stores, setStores] = useState<{ key: number; value: string }[]>([]);
  const [handlers, setHandlers] = useState<HandlerModel[]>([]);
  const [data, setData] = useState({
    data_inicial: moment(),
    data_final: moment().add(1, "days"),
  });
  const [store, setStore] = useState<number | undefined>();
  const [loadingHandlers, setLoadingHandlers] = useState(false);
  const [loadingStore, setLoadingStore] = useState(true);
  const [shouldSearch, setShouldSearch] = useState(false);

  useEffect(() => {
    const fetchStores = async () => {
      const user = await getTokenInfo();
      const {
        data: { content },
      } = await apiAuth.get(`/companyUser/${user?.id}/user`);
      const response = content.map((company) => ({
        key: company.company_id,
        value: company.company.company_name,
      }));
      setStores(response);
      setLoadingStore(false);
    };
    fetchStores();
  }, []);

  const { data_inicial, data_final } = data;

  useEffect(() => {
    const dataInicial = moment(data_inicial).format("DD/MM/YYYY");
    const dataFinal = moment(data_final).format("DD/MM/YYYY");
    const fetchHandlers = async () => {
      const {
        data: { data },
      } = await api.get(
        `/cash_handler?store=${store}&data_inicial=${dataInicial}&data_final=${dataFinal}`
      );
      setHandlers(data);
      setShouldSearch(false);
      setLoadingHandlers(false);
      setLoadingStore(false);
    };
    if (shouldSearch) {
      if (!store) {
        return message.warning("Selecione uma loja.");
      }
      setLoadingHandlers(true);
      fetchHandlers();
    }
  }, [data_inicial, data_final, shouldSearch, store]);

  const downloadCSV = () => {
    const CSVHeaders = {
      caixa: "Caixa",
      tipo: "Tipo",
      valor: "Valor",
      razao: "Motivo",
      criadoEm: "Data",
      criadoAs: "Hora",
    };
    const getFormatedData = () => moment(new Date()).format("DD-MM-YYYY");

    const getFormatedHandlers = () => {
      let result: any[] = [];
      handlers.forEach((handler) => {
        handler.handlers.forEach((handler) => {
          const data = handler.handlers.map((handler) => {
            const [date, time] = handler.created_at.split(" ");
            return {
              tipo: CashHandlerTypes[handler.type],
              valor: handler.amount,
              razao: handler.reason.replace(/[^A-Za-z0-9]+/g, " "),
              criadoEm: date,
              criadoAs: time,
            };
          });
          const dataWithCashCode = data.map((item) => ({
            caixa: handler.cash_code,
            ...item,
          }));
          result = [...dataWithCashCode, ...result];
        });
      });
      return result;
    };
    exportCSVFile(
      CSVHeaders,
      getFormatedHandlers(),
      `Movimentacoes_${getFormatedData()}`
    );
  };

  return (
    <PageContainer route="Movimentação">
      <Container>
        <SearchContainer>
          <HandlerSearch
            stores={stores}
            loadingStore={loadingStore}
            data={data}
            setData={setData}
            loadingHandlers={loadingHandlers}
            setShouldSearch={setShouldSearch}
            setStore={setStore}
          />
        </SearchContainer>
        {handlers.length > 0 && (
          <DownloadContainer>
            <Button>
              <DownloadIcon onClick={() => downloadCSV()} />
            </Button>
          </DownloadContainer>
        )}
        <Content>
          {store ? (
            <>
              {handlers.length ? (
                <HandlerContainer
                  handlers={handlers}
                  loading={loadingHandlers}
                />
              ) : (
                <Centralizer>
                  <Empty description="Nenhuma movimentação encontrada neste período" />
                </Centralizer>
              )}
            </>
          ) : (
            <Centralizer>
              <Empty description="Nenhuma loja selecionada" />
            </Centralizer>
          )}
        </Content>
      </Container>
    </PageContainer>
  );
};

export default Handler;
