import React, { SetStateAction, Dispatch, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { useWindowSize } from "../../hooks/useWindowSize";

import { ProductStore as ProductStoreModel } from "../../models/ProductStore";

import ModalProductFranchisee from "../../components/ModalProductFranchisee";

import { Modal, Tooltip, Table, notification, Empty } from "antd";

import {
  Container,
  LabelName,
  Action,
  EditIcon,
  Button,
  HeaderCenter,
  ManagerStatus,
  StatusChangeIcon,
} from "./styles";
import Centralizer from "../Centralizer";

const { confirm } = Modal;
interface IProps extends RouteComponentProps {
  products: ProductStoreModel[] | undefined;
  setProducts: Dispatch<SetStateAction<ProductStoreModel[] | undefined>>;
  setShouldUpdated: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setFilteredProducts: Dispatch<
    SetStateAction<ProductStoreModel[] | undefined>
  >;
  isStoreActive: boolean | undefined;
  storeInfo?: any;
  pagination: { current: number; pageSize: number };
  setPagination: Dispatch<
    SetStateAction<{ current: number; pageSize: number }>
  >;
}

const ProductFranchiseeList: React.FC<IProps> = ({
  products,
  loading,
  setShouldUpdated,
  setFilteredProducts,
  isStoreActive,
  storeInfo,
  pagination,
  setPagination,
}) => {
  const windowSize = useWindowSize();
  const [visible, setVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] =
    useState<ProductStoreModel | null>(null);

  const columns = [
    {
      title: "Código",
      dataIndex: "product?.cod_product",
      key: "product?.cod_product",
      width: "15%",
      sorter: (codProduct1, codProduct2) =>
        codProduct1?.product?.id - codProduct2?.product?.id,
      render: (_, record) => (
        <>
          <Tooltip title={`${record.product?.cod_product}`}>
            <LabelName>{record.product?.cod_product}</LabelName>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Nome",
      dataIndex: "product?.name",
      key: "product?.name",
      width: windowSize.width > 500 ? "30%" : "20%",
      render: (_, record) => (
        <>
          <Tooltip title={`${record.product?.name}`}>
            <LabelName>{record.product?.name}</LabelName>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "product?.category?.name",
      key: "product?.category?.name",
      width: "20%",
      responsive: ["sm" as any],
      render: (_, record) => (
        <>
          <Tooltip title={`${record.product?.category?.name}`}>
            <LabelName>{record.product?.category?.name}</LabelName>
          </Tooltip>
        </>
      ),
    },

    {
      title: windowSize.width > 500 ? "Status no gestor" : "S. Gestor",
      dataIndex: "product_is_enable",
      key: "product_is_enable",
      width: windowSize.width > 500 ? "20%" : "12%",
      render: (text) => (
        <>
          <Tooltip title={`${text ? "Habilitado" : "Desabilitado"}`}>
            {windowSize.width > 500 ? (
              <ManagerStatus active={text}>
                {text ? "Habilitado" : "Desabilitado"}
              </ManagerStatus>
            ) : (
              <ManagerStatus active={text}>
                {text ? "Hab" : "Desab"}
              </ManagerStatus>
            )}
          </Tooltip>
        </>
      ),
    },
    {
      title: windowSize.width > 500 ? "Valor de venda" : "Valor",
      dataIndex: "price_unit",
      key: "price_unit",
      width: "20%",
      render: (text, record) => (
        <>
          <Tooltip title={`${text}`}>
            <LabelName>
              {storeInfo?.company?.country === "brasil" ? "R$" : "Gs."}{" "}
              {record.price_unit}
            </LabelName>
          </Tooltip>
        </>
      ),
    },

    {
      title: <HeaderCenter>Ações</HeaderCenter>,
      width: "14%",
      render: (_, record) => (
        <>
          <Action>
            <Tooltip
              title={`${
                isStoreActive
                  ? `A loja está inativa`
                  : record?.product_is_enable
                  ? "Desabilitar"
                  : "Habilitar"
              } produto no gestor`}
            >
              <Button
                onClick={() => statusManageChange(record)}
                icon={<StatusChangeIcon active={record.product_is_enable} />}
                disabled={isStoreActive}
              />
            </Tooltip>

            {!record.deleted_at &&
              verifyPermission("product_franchisee.edit") && (
                <Tooltip
                  title={`${isStoreActive ? `A loja está inativa` : `Editar`}`}
                >
                  <Button
                    onClick={() => {
                      setSelectedProduct(record);
                      setVisible(true);
                    }}
                    icon={<EditIcon />}
                    disabled={isStoreActive}
                  />
                </Tooltip>
              )}
          </Action>
        </>
      ),
    },
  ];

  const statusManageChange = async (product: ProductStoreModel) => {
    confirm({
      title: "Mudar status do produto no Gestor de Vendas",
      content: `Deseja ${
        product?.product_is_enable ? "desabilitar" : "habilitar"
      } ${product?.product?.name}
      no gestor?`,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      width: 500,

      async onOk() {
        try {
          const payload = { product_is_enable: !product?.product_is_enable };

          await api.patch(
            `/products_store/product_is_enable?ids=${product?.id}`,
            payload
          );
          notification.success({
            message: "Produto atualizado com sucesso!",
            description: `O status do produto ${
              product.product?.name
            } foi alterado para ${
              !product.product_is_enable ? "habilitado" : "desabilitado"
            } no gestor de vendas`,
            duration: 3,
          });
          setFilteredProducts(undefined);
          setShouldUpdated(true);
        } catch (e) {
          const _description =
            //@ts-ignore
            e.content.data.message ||
            "Erro inesperado ocorreu, por favor tente novamente mais tarde";

          notification.error({
            message: "Erro ao atualizar status do produto",
            description: _description,
            duration: 3,
          });
        }
      },
    });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <Container>
      {products?.length !== 0 ? (
        <Table
          loading={loading}
          columns={columns}
          scroll={{ y: 900 }}
          dataSource={products?.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
          pagination={pagination}
          onChange={handleTableChange}
        />
      ) : (
        <Centralizer>
          <Empty description="Produto não encontrado" />
        </Centralizer>
      )}

      <ModalProductFranchisee
        visible={visible}
        setVisible={setVisible}
        currentProduct={selectedProduct}
        setShouldUpdated={setShouldUpdated}
        storeInfo={storeInfo}
      />
    </Container>
  );
};

export default withRouter(ProductFranchiseeList);
