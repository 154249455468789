import styled from "styled-components";

import { Table as TableAnt } from "antd";
import { Restore } from "../../styles/Icons";

export const Container = styled.div``;

export const LabelName = styled.label``;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  justify-content: center;
  cursor: pointer;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

export const Delete = styled.span`
  color: var(--orange-600);
`;

export const Table = styled(TableAnt)`
`;

export const RestoreIcon = styled(Restore)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.1rem;

  width: 1.5rem;
  height: 1.5rem;

  background: var(--orange-350);

  border-radius: 0.2rem;

  fill: white;

  cursor: pointer;
`;
