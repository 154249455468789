import styled from "styled-components";

import { Table as TableAnt } from "antd";

export const Table = styled(TableAnt)`
  user-select: none;

  *::-webkit-scrollbar {
    display: none;
  }

  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }

  .children_table .ant-table-cell {
    background-color: #fff6f4;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 7px;
  }

  .children_table .tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row > td {
    padding: 0;
  }

  .children_table .ant-table-tbody {
    background-color: #fff6f4;
  }
  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    height: 100%;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    @media only screen and (max-width: 1250px) {
      max-height: 35rem;
    }

    @media only screen and (max-width: 600px) {
      height: calc(100% - 5rem);
      .ant-table-thead > tr > th {
        font-size: 0.8rem;
      }

      .ant-table-tbody > tr > td {
        font-size: 0.9rem;
      }

      .ant-table-cell .ant-table-cell-scrollbar {
        display: none;
      }
    }

    @media only screen and (max-width: 500px) {
      max-height: 100vh;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    overflow: hidden;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
  }
  .ant-select-selection-item {
    width: 6rem;
  }
  .nfce {
    color: var(--midori-green);
  }
  .not-nfce {
    color: var(--maroon);
  }
`;
