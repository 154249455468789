export const getAuditType = (
  old_value: string | number,
  new_value: string | number
) => {
  if ((!old_value && new_value) || (!+old_value && +new_value)) {
    return "Adicionado";
  }

  if ((old_value && !new_value) || (+old_value && !+new_value)) {
    return "Removido";
  }

  return "Alterado";
};
