import React from "react";
import { currencyFormater } from "../../services/currencyFormater";
import { Tooltip } from "antd";

import {
  Container,
  StoreOfProgress,
  DeliveryOfProgress,
  CompanyContainer,
  ProgressContainer,
  ProgressMaxContent,
} from "./styles";

interface IProp {
  classification: number;
  companyName: string;
  storeCity: string;
  balance: number;
  franchiseeId: number;
  percentOfRanking: number;
  percentOfSalesDelivery: number;
  percentOfSalesStore: number;
  totalStore: number;
  totalDelivery: number;
}
// const weatherToken = "6754e0e0267d89459e2a92c0f6f77a26";
const StoreProgress: React.FC<IProp> = ({
  classification,
  companyName,
  balance,
  percentOfRanking,
  percentOfSalesDelivery,
  percentOfSalesStore,
  totalStore,
  totalDelivery,
}) => {
  return (
    <Container>
      <CompanyContainer>
        <span className="index-ranking">{classification}º</span>
        <span className="company-name">{companyName}</span>
      </CompanyContainer>

      <ProgressContainer>
        <span className="total-balance">R$ {currencyFormater(balance)}</span>

        <ProgressMaxContent widthProgress={`${percentOfRanking}%`}>
          <Tooltip
            title={`Total loja: R$ ${currencyFormater(totalStore)}`}
            placement="bottomLeft"
          >
            <StoreOfProgress widthProgress={`${percentOfSalesStore}%`} />
          </Tooltip>

          <Tooltip
            title={`Total delivery: R$ ${currencyFormater(totalDelivery)}`}
            placement="bottomLeft"
          >
            <DeliveryOfProgress widthProgress={`${percentOfSalesDelivery}%`} />
          </Tooltip>
        </ProgressMaxContent>
      </ProgressContainer>
    </Container>
  );
};

export default StoreProgress;
