import React from "react";

import { Container } from "./styles";

const Centralizer: React.FC = ({ children }) => {
  return (
    <Container justify="center" align="middle">
      {children}
    </Container>
  );
};

export default Centralizer;
