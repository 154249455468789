import React, { useEffect, useState } from "react";

import moment from "moment";
import { RouteComponentProps, withRouter } from "react-router-dom";

import api from "../../services/api";
import { useStoreData } from "../../hooks/useStoreData";

import locale from "antd/es/date-picker/locale/pt_BR";
import { Col, Empty, Modal, Spin, notification } from "antd";

import { ProductWasteModel } from "../../models/Waste";
import { Audit as AuditModel } from "../../models/Audit";

import PageContainer from "../../containers/PageContainer";
import Centralizer from "../../containers/Centralizer";
import WasteList from "../../containers/WasteList";
import DatePickerAnt from "../../components/DatePicker";
import ModalWasteAuditoria from "../../components/ModalWasteAuditoria";
import DisabledFlag from "../../components/DisabledFlag";

import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";
import {
  Container,
  Content,
  Header,
  Row,
  Select,
  Option,
  EmptyContent,
  Input,
  Button
} from "./styles";

interface IProp extends RouteComponentProps { }

const Waste: React.FC<IProp> = () => {
  const [store, setStore] = useState<number | null | undefined>();
  const [loadingStore, setLoadingStore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [products, setProducts] = useState<ProductWasteModel[]>([]);
  const [auditModalVisible, setAuditModalVisible] = useState(false);
  const [auditProducts, setAuditProducts] = useState<AuditModel[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<
    ProductWasteModel[] | undefined
  >([]);

  const { isStoreActive, stores } = useStoreData(store);
  const [selectedDate, setSelectedDate] = useState({
    data_inicial: moment(),
    data_final: moment(),
  });

  const formattedDates = {
    data_inicial: moment(selectedDate.data_inicial).format("DD/MM/YYYY"),
    data_final: moment(selectedDate.data_final)
      .add(1, "days")
      .format("DD/MM/YYYY"),
  };

  useEffect(() => {
    setShouldSearch(true);
  }, [selectedDate]);

  useEffect(() => {
    const fetchWaste = async () => {
      setLoading(true);
      try {
        setShouldSearch(true);
        const {
          data: { data },
        } = await api.get(
          `/products/waste/${store}?dataInicial=${formattedDates.data_inicial}&dataFinal=${formattedDates.data_final}`
        );
        setProducts(data);
        setFilteredProducts(data);
        setShouldSearch(false);
      } catch (error) {
        //@ts-ignore
        const errorMsg = error?.response?.data?.error?.message;

        notification.error({
          message: errorMsg || "Erro ao carregar os desperdícios",
          duration: 5,
        });
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };

    if (
      store &&
      shouldSearch &&
      formattedDates.data_inicial &&
      formattedDates.data_final
    ) {
      fetchWaste();
    }
  }, [
    store,
    shouldSearch,
    formattedDates.data_inicial,
    formattedDates.data_final,
  ]);

  const getProducts = async () => {
    try {
      setShouldSearch(true);
      const {
        data: { data },
      } = await api.get(
        `/product_store_waste/audit/${store}?dataInicial=${formattedDates.data_inicial}&dataFinal=${formattedDates.data_final}`
      );

      setAuditProducts(data);
    } catch (error) {
      //@ts-ignore
      const errorMsg = error?.response?.data?.error?.message;

      notification.error({
        message: errorMsg || "Erro ao carregar os desperdícios",
        duration: 5,
      });
    } finally {
      setShouldSearch(false);
      setLoading(false);
    }
  }

  const deleteWaste = (id: number) => {
    Modal.confirm({
      title: "Excluir desperdício?",
      content: "Deseja continuar e confirmar a exclusão deste desperdício?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Cancelar",
      className: "modalDelete",
      width: 500,
      async onOk() {
        try {
          await api.delete(`/product_store_waste/${id}`);
          notification.success({
            message: "Desperdício excluído",
            description: "O desperdício selecionado foi removido com sucesso.",
            duration: 5,
          });

          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Oops! Não foi possível remover o desperdício",
            description: error
              ? _description
              : "Falha ao remover o desperdício.",
            duration: 5,
          });
        }
      },
    });
  };
  const findProduct = ({ target: { value } }) => {
    const searchValue = removeAccentsAndLowerCase(value);
    const filter = products?.filter((product) =>
      removeAccentsAndLowerCase(product?.product?.name).includes(searchValue)
    );
    setFilteredProducts(filter);
  };

  return (
    <PageContainer route="Desperdício">
      <Container>
        <DisabledFlag isStoreActive={isStoreActive} />

        <Header>
          <Row gutter={8}>
            {products.length !== 0 && (
              <Col sm={4} xs={24} style={{ marginTop: 8, marginBottom: 8 }}>
                <Input
                  placeholder="Digite o nome do produto"
                  onChange={findProduct}
                />
              </Col>
            )}
            <Col xl={6} md={5} sm={12} xs={12}>
              <Select
                style={{ textTransform: "uppercase" }}
                placeholder="Selecione uma loja"
                onChange={(id) => {
                  setStore(+id);
                  setShouldSearch(true);
                }}
                defaultValue={store ? store : undefined}
                loading={loadingStore}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {stores.map((store) => (
                  <Option
                    key={store.key}
                    value={store.key}
                    style={{ textTransform: "uppercase" }}
                  >
                    {store.value}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={6} md={4} sm={12} xs={12}>
              <DatePickerAnt
                date={selectedDate}
                setDate={setSelectedDate}
                locale={locale}
                bordered={true}
                defaultValue={[moment(), moment().add(1, 'day')]}
              />
            </Col>
            {store && products.length !== 0 && (
              <Col sm={3} xs={24}>
                <Button onClick={() => {
                  {
                    setAuditModalVisible(true)
                    getProducts()
                  }
                }}
                  style={{ marginTop: 8 }}
                >Auditoria da loja
                </Button>
              </Col>
            )}
          </Row>
        </Header>

        {loading ? (
          <Centralizer>
            <Spin />
          </Centralizer>
        ) : (
          <>
            {store ? (
              products.length !== 0 ? (
                <Content>
                  <WasteList
                    setShouldSearch={setShouldSearch}
                    wasteProducts={filteredProducts || products}
                    loading={loading}
                    deleteWaste={deleteWaste}
                    isStoreActive={isStoreActive}
                  />
                </Content>
              ) : (
                <EmptyContent>
                  <Empty description="Não há produtos cadastrados nesse dia" />
                </EmptyContent>
              )
            ) : (
              <EmptyContent>
                <Empty description="Para visualizar o desperdício, selecione uma loja" />
              </EmptyContent>
            )}
          </>
        )}
      </Container>

      <ModalWasteAuditoria
        visible={auditModalVisible}
        setVisible={setAuditModalVisible}
        loading={loading}
        auditProducts={auditProducts}
      />
    </PageContainer>
  );
};

export default withRouter(Waste);
