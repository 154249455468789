import React, { Dispatch, SetStateAction, useState } from 'react'
import api from '../../../../services/api';

import moment from 'moment';

import { CompanyInfo, DeliveryRouteItem, DriveInfo } from '../../../../models/DeliveryRoute';

import { Button, DatePicker, Form, message } from 'antd';
import { Body, Content, ContentTotal, Header, Modal, Table, EditIcon, ContentEdit, CapitalizeSpan } from './styles';

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedRoute: any | null;
  selectedRouteInfo: DriveInfo | null;
  setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  selectedRouteStore: CompanyInfo[] | null
}

const ScriptingModal: React.FC<IProps> = ({ visible, setLoading, setVisible, setShouldSearch, selectedRoute, selectedRouteStore }) => {
  const [form] = Form.useForm();
  const [expectedDate, setExpectedDate] = useState<{ [key: string]: Date | null }>({});
  const [dispatched_at, setDispatched_at] = useState<Date | any>();
  const [editItem, setEditItem] = useState(false);
  const [editItemExit, setEditItemExit] = useState(false);

  const handleCancel = () => {
    Modal.confirm({
      title: "Atenção",
      content: "Deseja cancelar essa roteirização?",
      okText: "Sim",
      cancelText: "Não",
      centered: true,
      onOk() {
        setVisible(false);
      },
    });
  };

  const handleChange = (date: moment.Moment | null, id: string) => {
    setExpectedDate(prevState => ({
      ...prevState,
      [id]: date ? date.toDate() : null,
    }));
  };

  const handleSave = async () => {
    Modal.confirm({
      title: "Atenção",
      content: "Deseja criar essa roteirização?",
      okText: "Sim",
      cancelText: "Não",
      centered: true,

      async onOk() {
        setLoading(true);

        try {
          const itemsPayload = selectedRoute?.deliveryRoute?.deliveryRouteItem?.map((item: DeliveryRouteItem) => ({
            id: item.id,
            expected_date: moment(expectedDate[item.id]).format('YYYY-MM-DD HH:mm:ss.SSS'),
          }));

          await api.put(`/delivery-route-item/update-many`, itemsPayload);

          await api.put(`/delivery-route/${selectedRoute?.deliveryRoute?.id}`, { status: 2, dispatched_at: dispatched_at });

          message.success("Roteirização atualizada com sucesso");
          setShouldSearch(true);
          setVisible(false);

        } catch (e) {
          const _message =
            //@ts-ignore
            e?.response?.data?.message ||
            "Houve um erro ao atualizar a roteirização";
          message.error(_message);
        } finally {
          setLoading(false);
          setShouldSearch(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "Sequencia",
      dataIndex: "sequence",
      width: '5%',
      align: "center" as const,
      key: "sequence",
      render: (text) => (
        <CapitalizeSpan>
          {text}
        </CapitalizeSpan>
      )
    },
    {
      title: "Nome da loja",
      dataIndex: "company_name",
      align: "center" as const,
      key: "company_name",
      render: (text) => (
        <CapitalizeSpan>
          {text}
        </CapitalizeSpan>
      )
    },
    {
      title: "Nº pedido",
      dataIndex: "id_order",
      align: "center" as const,
      key: "id_order",
      render: (text) => (
        <CapitalizeSpan>
          {text}
        </CapitalizeSpan>
      )
    },
    {
      title: "Tipo de carga",
      dataIndex: "cargo_type",
      align: "center" as const,
      key: "cargo_type",
      render: (text, record) => {
        if (text === 0) return <span>Carga gelada</span>
        if (text === 1) return <span>Carga seca</span>
        return <span>Carga mista</span>
      }
    },
    {
      title: "Data esperada de chegada",
      dataIndex: "expected_date",
      align: "center" as const,
      key: "expected_date",
      render: (_, record) => (
        expectedDate[record.id] ? (
          <DatePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            disabledDate={current => current && current < moment().startOf('day')}
            value={expectedDate[record.id] ? moment(expectedDate[record.id]) : undefined}
            onChange={(date) => handleChange(date, record.id)}
          />
        ) : (
          <>
            {editItem ? <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              disabledDate={current => current && current < moment().startOf('day')} 
              value={expectedDate[record.id] ? moment(expectedDate[record.id]) : undefined}
              onChange={(date) => handleChange(date, record.id)}
            /> : (
              <ContentEdit>
                <CapitalizeSpan>{moment(record?.expected_date, "DD/MM/YYYY").format("DD/MM/YYYY")}</CapitalizeSpan>
                <EditIcon onClick={() => setEditItem(!editItem)} />
              </ContentEdit>
            )}
          </>
        )
      )
    }
  ]

  const sortedDeliveryRouteItems = selectedRoute?.deliveryRoute?.deliveryRouteItem?.sort((a, b) => {
    return a.sequence - b.sequence;
  });

  const dataSourceConstructor = () => {
    let _dataSource = sortedDeliveryRouteItems?.map((item) => {
      return {
        ...item,
        id_order: selectedRoute?.orderInfo.find(
          (_item) => _item.id === item.id_order
        )?.id,
        cargo_type: item.cargo_type,
        quantity: selectedRoute?.orderInfo.find(
          (_item) => _item.id === item.id_order
        )?.quantity,
        weight: selectedRoute?.orderInfo.find(
          (_item) => _item.id === item.id_order
        )?.weight,
        company_name: selectedRouteStore?.find(
          (_company) => _company.id === item.company_id
        )?.company_name,
      };
    });
    return _dataSource;
  };

  return (
    <Modal
      title={"Roteirização"}
      destroyOnClose
      centered
      closable
      visible={visible}
      onCancel={() => setVisible(false)}
      width={"80%"}
      footer={[
        <Button style={{ border: 'none', backgroundColor: 'transparent' }} key="cancel" onClick={handleCancel}>Cancelar</Button>,
        <Button key="ok" type="primary" onClick={handleSave}>Salvar</Button>,
      ]}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Content>
          <Header>
            <span className='title'>Rota: {selectedRoute?.deliveryRoute?.id}</span>

            <div className='content'>
              <span>Data prevista de saída: </span>
              <span>
                {!selectedRoute?.deliveryRoute?.dispatched_at ? (
                  <Form.Item
                    name="dispatched_at"
                  >
                    <DatePicker
                      style={{
                        width: '100%', height: '2.5rem'
                      }}
                      disabledDate={current => current && current < moment().startOf('day')}
                      format="DD/MM/YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      placeholder="DD/MM/YYYY HH:mm"
                      value={dispatched_at}
                      onChange={(date) => setDispatched_at(date)}
                    />
                  </Form.Item>
                ) : (
                  <>
                    {editItemExit ? <DatePicker
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY HH:mm"
                      showTime={{ format: 'HH:mm' }}
                      placeholder="DD/MM/YYYY HH:mm"
                      value={dispatched_at}
                      disabledDate={current => current && current < moment().startOf('day')}
                      onChange={(date) => setDispatched_at(date)}
                    /> : (
                      <ContentEdit>
                        <span>{selectedRoute?.deliveryRoute?.dispatched_at}</span>
                        <EditIcon onClick={() => setEditItemExit(!editItemExit)} />
                      </ContentEdit>
                    )}
                  </>
                )}
              </span>
            </div>

          </Header>

          <Body>
            {selectedRoute && (
              <Table
                columns={columns}
                dataSource={dataSourceConstructor()}
                pagination={false}
                rowKey={(item: any) => item.sequence}
                footer={() => (
                  <ContentTotal>
                    <span>
                      Total:{" "}
                      {selectedRoute?.orderInfo?.reduce((total, item) => total + (+item.quantity || 0), 0)} volumes{" / "}
                      {selectedRoute?.orderInfo?.reduce((total, item) => total + (Number(item.weight) * +item.quantity || 0), 0).toFixed(2)} KG
                    </span>
                  </ContentTotal>
                )}
              />
            )}
          </Body>
        </Content>
      </Form>
    </Modal>
  )
}

export default ScriptingModal