import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 4.5rem;
  width: 100%;
  padding: 1.2%;
  border: 1px solid var(--gray-25);
  border-radius: 9px;

  @media only screen and (max-width: 1680px) {
    height: 3.8rem;
  }

  @media only screen and (max-width: 1440px) {
    height: 3.4rem;
  }

  @media only screen and (max-width: 1400px) {
    padding: 1.4%;
  }

  @media only screen and (max-width: 1280px) {
    height: 3rem;
    padding: 1.7%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: 5rem;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  height: 100%;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 70%;
    justify-content: center;
    text-align: center;
  }

  .index-ranking {
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: var(--orange-350);
    padding-left: 1rem;

    @media only screen and (max-width: 575px) {
      padding-left: 0;
      font-size: 1rem;
    }
  }

  .company-name {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--gray-45);
    margin-left: 5%;

    @media only screen and (max-width: 768px) {
      font-size: 0.8rem;
      margin-left: 2%;
    }
  }
`;

export const ProgressContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 70%;
  align-items: center;
  background-color: var(--gray-25);
  border-radius: 20px;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .total-balance {
    position: absolute;
    color: rgba(4, 15, 46, 0.6);
    width: 100%;
    font-weight: 500;
    text-align: center;
    font-size: 1rem;
    z-index: 3;
    background: none;
    pointer-events: none;

    @media only screen and (max-width: 1366px) {
      font-size: 0.8rem;
    }
  }
`;

interface WidthProgress {
  widthProgress: string;
}

export const ProgressMaxContent = styled.div<WidthProgress>`
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 20px;
  z-index: 2;

  .ant-tooltip-inner {
    z-index: 10000;
  }
  ${({ widthProgress }) => css`
    width: ${widthProgress};
  `}
`;

export const StoreOfProgress = styled.div<WidthProgress>`
  display: flex;
  background: linear-gradient(90deg, #ff875e 0%, #fd67ae 133.8%);
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: ${({ widthProgress }) =>
    widthProgress === "100%" ? "20px" : "0"};
  border-bottom-right-radius: ${({ widthProgress }) =>
    widthProgress === "100%" ? "20px" : "0"};
  z-index: 2;

  ${({ widthProgress }) => css`
    width: ${widthProgress};
  `}
`;

export const DeliveryOfProgress = styled.div<WidthProgress>`
  display: flex;
  height: 100%;
  background: linear-gradient(90deg, #4ba4d7 0%, #69dea6 133.8%);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: ${({ widthProgress }) =>
    widthProgress === "0%" ? "20px" : "0"};
  border-bottom-left-radius: ${({ widthProgress }) =>
    widthProgress === "0%" ? "20px" : "0"};
  z-index: 2;

  ${({ widthProgress }) => css`
    width: ${widthProgress};
  `}
`;
