export const getCepInformations = async (cep): Promise<{ city: string; address: string; state_registration: string; }> => {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
  const {
    localidade: city,
    logradouro: address,
    uf: state_registration,
  } = await response.json();
  return {
    city,
    address,
    state_registration,
  };
};
