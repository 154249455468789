import React, { Dispatch, SetStateAction } from "react";

import { verifyPermission } from "../../services/auth";

import GetStore from "../GetStore";

import {
  ContainerBackground,
  ContainerInfo,
  TopSide,
  HeaderInfo,
  IconContent,
  Checkbox,
  Row,
  Button,
  IconShoppingCart,
} from "./styles";

interface IProps {
  title: string;
  textTitle?: string;
  Img: string;
  store: number | undefined | null;
  setStore?: Dispatch<SetStateAction<number | undefined | null>>;
  setStoreInfo?: Dispatch<SetStateAction<any | undefined | null>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  checkBoxVisible?: boolean;
  checked?: boolean;
  setAll?: Dispatch<SetStateAction<boolean>>;
  permissionCheck?: string;
  textCheckBox?: string;
  buttonVisible?: boolean;
  permissionButton?: string;
  buttonClick?: () => void;
  buttonText?: string;
  setMinOrder?: Dispatch<SetStateAction<number | undefined>>;
  filterCountryPY?: boolean;
  filterCountryBR?: boolean;
  setBrasilCompanyIds?: Dispatch<SetStateAction<number[]>>;
  setParaguayCompanyIds?: Dispatch<SetStateAction<number[]>>;
}
const StoreSelectionPage: React.FC<IProps> = ({
  title,
  textTitle,
  Img,
  store,
  setStore,
  setStoreInfo,
  setShouldSearch,
  checkBoxVisible,
  checked,
  setAll,
  permissionCheck,
  textCheckBox,
  buttonVisible,
  permissionButton,
  buttonClick,
  buttonText,
  setMinOrder,
  filterCountryPY,
  filterCountryBR,
  setParaguayCompanyIds,
  setBrasilCompanyIds,
}) => {
  return (
    <ContainerBackground>
      <TopSide>
        <ContainerInfo>
          <HeaderInfo>
            <Row>
              <h3>{title}</h3>
            </Row>
            <Row>
              <p>{textTitle}</p>
            </Row>
            <GetStore
              defaultValue={store}
              handleChange={(id) => {
                setStore && setStore(id);
                setShouldSearch(true);
              }}
              handleChangeStore={(value) => {
                setStoreInfo && setStoreInfo(value);
              }}
              setMinOrder={setMinOrder}
              filterCountryPY={filterCountryPY}
              filterCountryBR={filterCountryBR}
              setParaguayCompanyIds={setParaguayCompanyIds}
              setBrasilCompanyIds={setBrasilCompanyIds}
            />
            <Row style={{ justifyContent: "flex-end" }}>
              {checkBoxVisible && (
                <>
                  {verifyPermission(permissionCheck) && (
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        setShouldSearch(true);
                        if (setAll) {
                          setAll(!checked);
                        }
                      }}
                    >
                      {textCheckBox}
                    </Checkbox>
                  )}
                </>
              )}
            </Row>
            <Row>
              {buttonVisible && (
                <>
                  {verifyPermission(permissionButton) && (
                    <Button onClick={buttonClick}>
                      <IconShoppingCart />
                      <span>{buttonText}</span>
                    </Button>
                  )}
                </>
              )}
            </Row>
          </HeaderInfo>
        </ContainerInfo>
        <IconContent>
          <img src={Img} alt="" />
        </IconContent>
      </TopSide>
    </ContainerBackground>
  );
};

export default StoreSelectionPage;
