import React from "react";
import { Modal } from "antd";
import { Imagem } from "./styles";

interface IProps {
  products;
  visible: boolean;
  closeModal: () => void;
}

const ModalImage: React.FC<IProps> = ({ visible, products, closeModal }) => {
  return (
    <Modal
      title="Imagem do desperdício"
      visible={visible}
      onCancel={closeModal}
      width={'50rem'}
      centered
      footer={null}
    >
      <Imagem
        key={products.id}
        src={products.url_file}
        alt={products.name}
      />
    </Modal>
  );
};

export default ModalImage;
