import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";

import { Audit as AuditModel } from "../../models/Audit";
import { Page } from "../../models/Page";

import Audit from "../../components/Audit";
import Pagination from "../../components/Pagination";

import Centralizer from "../../containers/Centralizer";

import { Spin, Empty } from "antd";

import { Container, Modal, TableAudit } from "./styles";

interface IProps {
  id: number | undefined;
  setVisible: Dispatch<SetStateAction<boolean>>;
  visible: boolean;
  setPaginate: Dispatch<SetStateAction<Page>>;
  paginate: Page;
}

const StockAuditList: React.FC<IProps> = ({
  id,
  visible,
  setVisible,
  paginate,
  setPaginate,
}) => {
  const [audits, setAudits] = useState<AuditModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldSearch, setShouldSearch] = useState(false);
  const { page, size } = paginate;

  const header = [
    {
      sm: 5,
      xs: 0,
      description: "Status",
    },
    {
      sm: 5,
      xs: 0,
      description: "Data",
    },
    {
      sm: 5,
      xs: 0,
      description: "Horário",
    },
    {
      sm: 5,
      xs: 0,
      description: "Usuário",
    },
    {
      sm: 4,
      xs: 0,
      description: "Quantidade",
    },
  ];
  useEffect(() => {
    async function fechtAudit() {
      const {
        data: { content, totalElements },
      } = await api.get(
        `/products_store_history/${id}?page=${page}&size=${size}`
      );

      setLoading(false);
      setAudits(content);
      setPaginate((oldValues) => ({ ...oldValues, totalElements }));
    }
    if (id || shouldSearch) {
      setLoading(true);
      fechtAudit();
    }
  }, [page, size, shouldSearch, id, setPaginate]);

  return (
    <Container>
      <Modal
        title="Histórico"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelButtonProps={{ hidden: true }}
        closable={true}
        width={1037}
        destroyOnClose={true}
        footer={
          <>
            <Pagination
              setStateSearch={setShouldSearch}
              setPaginate={setPaginate}
              totalElements={paginate.totalElements}
            />
          </>
        }
      >
        <TableAudit header={header} loading={loading}>
          {loading ? (
            <Centralizer>
              <Spin />
            </Centralizer>
          ) : audits.length ? (
            audits.map((audit) => <Audit key={audit.id} {...audit} />)
          ) : (
            <Centralizer>
              <Empty description="Nenhum histórico de alteração encontrado" />
            </Centralizer>
          )}
        </TableAudit>
      </Modal>
    </Container>
  );
};

export default StockAuditList;
