import React, { useState, useEffect } from "react";

import { getTokenInfo } from "../../services/auth";
import apiAuth from "../../services/apiAuth";
import apiAnalytics from "../../services/apiAnalytics";
import PageContainer from "../../containers/PageContainer";
import { models } from "powerbi-client";

import { Background, Container, PowerBIEmbed } from "./styles";

const Analytics: React.FC = () => {
  const [companies, setCompanies] = useState<number[]>([]);
  const [biAuth, setBiAuth] = useState<{
    id: string;
    embedUrl: string;
    token: string;
  } | null>(null);

  useEffect(() => {
    async function fetchBIToken() {
      try {
        const loggedUser = getTokenInfo();
        const { data: companies } = await apiAuth.get(
          `/companyUser/${loggedUser?.id}/user`
        );
        setCompanies(
          companies.content.map((companyUser) => companyUser.company_id)
        );
        const { data } = await apiAnalytics.get(
          "/power-bi/token?group_id=18b9cfc8-3c8c-4634-aa02-80e596391bd9&report_id=4f8d0307-ac12-41c5-88c8-967fa3b4477a"
        );
        setBiAuth({
          id: data.id,
          embedUrl: data.embedUrl,
          token: data.token,
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchBIToken();
  }, []);

  return (
    <Background>
      <PageContainer route="Balanço">
        {biAuth && (
          <Container>
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: biAuth?.id,
                embedUrl: biAuth?.embedUrl,
                accessToken: biAuth?.token,
                tokenType: models.TokenType.Embed,
                filters: [
                  {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                      table: "company",
                      column: "id",
                    },
                    operator: "In",
                    values: companies,
                    filterType: models.FilterType.Basic,
                    requireSingleSelection: true,
                  },
                ],
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              cssClassName={"embed-container"}
            />
          </Container>
        )}
      </PageContainer>
    </Background>
  );
};

export default Analytics;
