import styled from "styled-components";
import {
  Col as ColAnt,
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Input as InputAnt,
  Button as ButtonAnt
} from "antd";

import { Filter, FiletypeCsv, FiletypePdf } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;
  width: 100%;
  overflow: hidden;
  padding: 1rem 0;
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 1rem 0;
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const Col = styled(ColAnt)`
  min-width: 3.25rem;
  @media only screen and (max-width: 578px) {
    margin-top: 0.3rem;
    :last-child {
      margin-right: 0;
    }
  }
`;
export const Input = styled(InputAnt)`
  height: 3.25rem;
  width: 100%;
  padding: 0 0.2rem;
  @media only screen and (max-width: 800px) {
    height: 3.25rem !important;
  }
  @media only screen and (max-width: 578px) {
    height: 3.25rem !important;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  @media only screen and (max-width: 575px) {
    min-height: 10.5rem;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentButton = styled(ContentModal)`
  flex-direction: row;
`;

export const ProductStocksContent = styled(RowAnt)`
  display: flex;
  width: 100%;
  height: 3.2rem;
  border-radius: 5px;
  border-width: 7px 1px 1px 1px;
  border-style: solid;
  border-color: var(--orange-350) var(--gray-25) var(--gray-25);
`;

export const ProductStocks = styled(ColAnt)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.1rem;
  font-weight: 500 !important;
  color: var(--gray-45);

  @media only screen and (max-width: 1240px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 1160px) {
    font-size: 0.9rem;
  }

  @media only screen and (max-width: 880px) {
    font-size: 0.6rem;
  }
  @media only screen and (max-width: 560px) {
    font-size: 0.8rem;
  }
`;

export const CheckboxMenuGroup = styled(CheckboxAnt.Group)`
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 30%;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }
`;

export const ButtonSearch = styled.button`
  cursor: pointer;
  width: 50%;
  padding: 1% 2%;
  background-color: var(--orange-350);
  border-radius: 5px;
  color: white;
`;

export const CheckboxMenu = styled(CheckboxAnt)`
  font-weight: 400;
  font-size: 16px;
  color: var(--gray-45);
`;

export const FilterMenu = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;

  background: var(--orange-350);
  color: white;

  :hover {
    color: white;
    opacity: 80%;
  }
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.3rem;
  width: 100%;
  border-radius: 5px;

  background: var(--orange-350);
  color: white;

  :hover, :focus {
    color: white;
    background: var(--orange-350);
    opacity: 80%;
  }
`;

export const FilterIcon = styled(Filter)`
  width: 20px;
  height: 20px;
`;

export const FiletypeCsvIcon = styled(FiletypeCsv)`
  width: 20px;
  height: 20px;
`;

export const PdfIcon = styled(FiletypePdf)`
  width: 20px;
  height: 20px;
`;

export const ShortCurtContent = styled(RowAnt)`
  display: flex;
  justify-content: flex-end;

  .ant-picker {
    width: 100%;
    height: 3rem;
    border-radius: 0.3rem;
    margin-bottom: 2%;

    .ant-picker-suffix {
      color: var(--orange-350);
    }
  }
  .ant-btn,
  .ant-btn-primary {
    height: 52px !important;
    margin-bottom: 2%;

    img {
      width: 20px;
      height: 40px;
    }
  }
  @media only screen and (max-width: 575px) {
    margin-right: 0 !important;
    margin-left: -0.5rem !important;
  }
`;
