import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import moment from "moment";

import janusApi from "../../../services/apiAuth";
import apiS3Handle from "../../../services/apiS3Handler";

import {
  Container,
  Table,
  InvoiceInfo,
  ListContent,
  Dropdown,
  Menu,
  ThreeDotsIcon,
  Button,
  ButtonWrapper,
} from "./styles";

import { InvoiceRequest } from "../models/invoiceRequest";
import { ColumnsType } from "antd/lib/table";
import { notification } from "antd";

type IProps = {
  selectedInvoiceRequest: InvoiceRequest | null;
  openSendInvoicesRequest: boolean;
  setOpenSendInvoicesRequest: Dispatch<SetStateAction<boolean>>;
  setShouldFetchInvoices: Dispatch<SetStateAction<boolean>>;
};

const SendInvoicesRequest: React.FC<IProps> = ({
  openSendInvoicesRequest,
  setOpenSendInvoicesRequest,
  selectedInvoiceRequest,
  setShouldFetchInvoices,
}) => {
  const [invoiceRequest, setInvoiceRequest] = useState<InvoiceRequest>();
  const [pdfLoading, setPDFLoading] = useState(false);
  const [fetchingInvoicesRequest, setFetchingInvoicesRequest] = useState(false);
  const [sendingInvoicesRequest, setSendingInvoicesRequest] = useState(false);

  useEffect(() => {
    async function fetchInvoice() {
      try {
        setFetchingInvoicesRequest(true);
        let {
          data: { content },
        } = await janusApi.get(
          `/company-invoice/${selectedInvoiceRequest?.id}`
        );
        if (content?.company_invoice_file?.length)
          content.company_invoice_file = content.company_invoice_file.map(
            (file) => ({
              ...file,
              key: file.id,
              company_name: file?.company.company_name,
            })
          );
        setInvoiceRequest(content);
      } catch (error) {
        console.log({ error });
      } finally {
        setFetchingInvoicesRequest(false);
      }
    }
    if (selectedInvoiceRequest) fetchInvoice();
  }, [selectedInvoiceRequest]);

  const syncPromises = async (array, func) => {
    await array.reduce(async (previousPromise, item) => {
      await previousPromise;
      return func(item);
    }, Promise.resolve());
  };

  const integrateInvoiceRequest = async (invoiceRequest) => {
    if (invoiceRequest?.status === "enviado") return;
    let {
      data: { content },
    } = await janusApi.post("/company-invoice/send-invoice", invoiceRequest);
    if (content?.company_invoice_file?.length)
      content.company_invoice_file = content.company_invoice_file.map(
        (file) => ({
          ...file,
          key: file.id,
          company_name: file?.company.company_name,
        })
      );
    setInvoiceRequest(content);
  };

  const sendInvoiceRequests = async (content): Promise<void> => {
    try {
      setSendingInvoicesRequest(true);
      await syncPromises(content, integrateInvoiceRequest);
      setShouldFetchInvoices(true);
    } catch (error) {
      console.log({ error });
    } finally {
      setSendingInvoicesRequest(false);
    }
  };

  const downloadPdfInvoice = async (invoice) => {
    if (pdfLoading) return;
    if (invoice.status !== "enviado" || !invoice.key)
      return notification.warning({
        message: "Esta cobrança ainda não possui arquivo",
      });
    try {
      setPDFLoading(true);

      const { data } = await apiS3Handle.get(
        `/s3-upload/find/upload-autheticator/${invoice.file_key}`
      );

      const fileName = `${invoice.type}.pdf`;
      const link = document.createElement("a");
      link.href = "data:application/octet-stream;base64," + data.base64;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      notification.error({ message: "Falha ao gerar arquivo.", duration: 5 });
    } finally {
      setPDFLoading(false);
    }
  };

  const menu = (row) => {
    return (
      <>
        <Menu>
          <Menu.Item key="0" onClick={() => downloadPdfInvoice(row)}>
            <span>Baixar</span>
          </Menu.Item>
          {/* <Menu.Item key="1">
            <span>Enviar Email</span>
          </Menu.Item>
          <Menu.Item key="2">
            <span>Cancelar</span>
          </Menu.Item>
          <Menu.Item key="3">
            <span>Emitir novamente</span>
          </Menu.Item> */}
        </Menu>
      </>
    );
  };

  const columns: ColumnsType = [
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
    },
    {
      title: "Franquia",
      dataIndex: "company_name",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Enviado às",
      dataIndex: "emited_at",
      align: "center",
      render: (value) => (
        <span>{value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : ""}</span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      align: "center",
      render: (_, row) => (
        <Dropdown overlay={menu(row)}>
          <ThreeDotsIcon />
        </Dropdown>
      ),
    },
  ];

  const handleRowClass = (row): string => {
    if (row.status === "enviado") return "valid_row";
    if (row.status !== "pendente" && row.status !== "enviado")
      return "unvalid_row";
    return "";
  };

  return (
    <Container
      visible={openSendInvoicesRequest}
      closable={false}
      okText={"Fechar"}
      cancelButtonProps={{ hidden: true }}
      destroyOnClose
      onOk={() => setOpenSendInvoicesRequest(false)}
      confirmLoading={sendingInvoicesRequest}
      width={"95%"}
    >
      <InvoiceInfo>
        <span>ID: {invoiceRequest?.id}</span>
        <span>
          Criado Por: ({invoiceRequest?.created_by_id}){" "}
          {invoiceRequest?.created_by_name}
        </span>
        <span>Status: {invoiceRequest?.status}</span>
        <span>Cobranças Enviadas: {invoiceRequest?.invoices_sended}</span>
        <span>Total de Cobranças: {invoiceRequest?.total_invoices}</span>
        <span>
          Data Criação:{" "}
          {moment(invoiceRequest?.created_at)
            .subtract(3, "hours")
            .format("DD/MM/YYYY HH:mm:ss")}
        </span>
        <ButtonWrapper>
          <Button
            onClick={() =>
              sendInvoiceRequests(invoiceRequest?.company_invoice_file)
            }
          >
            Enviar
          </Button>
        </ButtonWrapper>
      </InvoiceInfo>
      <ListContent>
        <Table
          size="small"
          loading={fetchingInvoicesRequest}
          pagination={{
            showSizeChanger: false,
            pageSize: invoiceRequest?.company_invoice_file?.length,
            total: invoiceRequest?.company_invoice_file?.length,
          }}
          columns={columns as any}
          dataSource={(invoiceRequest?.company_invoice_file || []) as any}
          rowClassName={(row) => handleRowClass(row)}
        />
      </ListContent>
    </Container>
  );
};

export default SendInvoicesRequest;
