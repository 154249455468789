import styled from "styled-components";
import {
  Row as RowAnt,
  DatePicker as DatePickerAnt,
  Select as SelectAnt,
  Input as InputAnt,
  Table as TableAnt,
  Checkbox as CheckboxAnt,
  Dropdown as DropdownAnt,
  Button as ButtonAnt,
} from "antd";
import { Filter, Download } from "../../styles/Icons";
const { Option: OptionAnt } = SelectAnt;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const DownloadIcon = styled(Download)`
  color: var(--white);
  width: 1.2rem;
  height: 1.2rem;
`;

export const DownloadButton = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.25rem;
  background: var(--orange-350);
  box-shadow: none;
  border: none;

  :hover,
  :focus {
    background: var(--orange-350);
  }

  svg {
    display: none;
  }

  @media only screen and (max-width: 578px) {
    height: 2.9rem;
    .button-title {
      display: none;
    }

    svg {
      display: flex;
    }
  }
`;

export const Dropdown = styled(DropdownAnt)`
  width: 3.2rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-10);
  background-color: var(--orange-350);
  border: none;
  border-radius: 0.4rem;

  span {
    font-weight: 400;
    font-size: 1rem;
    display: none;
  }

  &:hover,
  &:focus {
    background-color: var(--orange-350);
    color: var(--white-10);
    border: none;
    opacity: 80%;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;

    margin-left: 0;
    width: 100%;
    svg {
      width: 20px;
    }
    span {
      display: block;
      margin-left: 0.2rem;
    }
  }

  :disabled {
    opacity: 50%;
    cursor: not-allowed;
  }
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 9%;
  min-height: 3rem;

  @media only screen and (max-width: 1024px) {
    margin-top: 1rem;
  }
`;

export const CheckboxMenu = styled(CheckboxAnt)`
  zoom: 1.2;
  font-size: 12px;
`;

export const FilterMenu = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 5px;

  background: var(--orange-350);
  color: var(--white);

  :hover {
    color: var(--white);
    opacity: 80%;
  }
`;

export const FilterIcon = styled(Filter)`
  width: 20px;
  height: 20px;
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
`;

export const Input = styled(InputAnt)`
  border-radius: 0.4rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: 2rem;

  @media screen and (max-width: 768px) {
    height: 80%;
    overflow: scroll;
  }
  @media screen and (max-width: 500px) {
    margin-top: 7.5rem;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Option = styled(OptionAnt)``;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`
  width: 100%;
  overflow: scroll;
`;
