import styled, { css } from "styled-components";

import Button from "../Button";
import Upload from "../Upload";

import {
  Col as ColAnt,
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Modal as ModalAnt,
} from "antd";

import { Store, AddShoppingCart, ShoppingBags } from "../../styles/Icons";

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const UploadComp = styled(Upload)``;

export const ImgProduct = styled.img`
  position: relative;
  width: 12rem;
  height: 12rem;
  border: 1px solid var(--gray-250);
  border-radius: 1.5rem;
`;

export const TextUpload = styled.h2``;

export const Modal = styled(ModalAnt)`
  .ant-modal-header {
    padding-bottom: 0;
    .ant-modal-title {
      color: var(--black-opaco);
    }
  }
  .ant-modal-body {
    padding-top: 0;
    label {
      color: var(--gray-45) !important;
      @media only screen and (max-width: 700px) {
        font-size: 0.75rem !important;
      }
      @media only screen and (max-width: 575px) {
        font-size: 0.8rem !important;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    input {
      height: 3.25rem;
    }
  }
`;

export const Row = styled(RowAnt)`
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const Checkbox = styled(CheckboxAnt)`
  zoom: 1.5;
`;

const iconCSS = css`
  width: 28px;
  height: 28px;
  margin-left: 5px;
`;

export const StoreIcon = styled(Store)`
  ${iconCSS}
  fill: var(--orange-350);
`;

export const OrderIcon = styled(AddShoppingCart)`
  ${iconCSS}
  fill: var(--green-400);
`;
export const ShopIcon = styled(ShoppingBags)`
  ${iconCSS}
  fill: blue;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 99%;
`;

export const SaveButton = styled(Button)`
  background: var(--orange-350);
  border-radius: 0.5rem;
  box-shadow: none;
  color: white;
  :hover,
  :focus {
    background: var(--orange-350);
    color: white;
    opacity: 80%;
  }
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const CancelButton = styled(Button)`
  width: 30%;
  background: none;
  border: none;
  box-shadow: none;
  color: var(--gray-530);
  :hover,
  :focus {
    color: var(--gray-530);
    opacity: 80%;
    background: none;
    border: none;
  }
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;
