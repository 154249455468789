import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import locale from "antd/es/date-picker/locale/pt_BR";

import moment from "moment";
import { DatePicker, Spin, notification } from "antd";

import {
  Container,
  Row,
  Col,
  ContentModal,
  ButtonSearch,
  SpinContainer,
} from "./styles";

import apiSalesHandler from "../../services/apiSalesHandler";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  store_id: number;
  cnpj?: string;
  company_name?: string;
}

const CsvSalesModal: React.FC<IProps> = ({
  visible,
  setVisible,
  store_id,
  cnpj,
  company_name
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    if (!visible) {
      setSelectedDate(null);
    }
  }, [visible]);

  const getFile = async () => {
    try {
      setLoading(true);

      let mesInitial = moment(selectedDate)
        .startOf("month")
        .format("DD/MM/YYYY");
      let mesFinal = moment(selectedDate).endOf("month").format("DD/MM/YYYY");
      let mes = moment(selectedDate).format("MM/YYYY");

      const { data } = await apiSalesHandler.get(
        `/sales/csv/${store_id}?initial_date=${mesInitial}&final_date=${mesFinal}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Vendas-${mes}-${company_name}-${cnpj}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      //@ts-ignore
      if (error.statusCode === 500) {
        notification.error({
          message: "Selecione um período para baixar o arquivo",
          duration: 5,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setVisible(false);
    setSelectedDate(null);
  };

  return (
    <Container
      centered
      closable={true}
      visible={visible}
      title={"CSV Vendas"}
      okText="Download CSV"
      footer={false}
      onCancel={reset}
    >
      <ContentModal>
        <Row>
          <Col xs={18}>
            <p>Selecione o período para baixar as vendas</p>
          </Col>

          <Col xs={18}>
            <DatePicker
              placeholder="Selecione o mês e o ano"
              picker="month"
              locale={locale}
              value={selectedDate}
              format="MM/YYYY"
              onChange={(e: React.SetStateAction<moment.Moment | null>) => {
                setSelectedDate(e);
              }}
            />
          </Col>
          <Col xs={5}>
            <ButtonSearch onClick={getFile} disabled={!selectedDate || loading}>
              Baixar
            </ButtonSearch>
          </Col>
        </Row>
        {loading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <></>
        )}
      </ContentModal>
    </Container>
  );
};

export default CsvSalesModal;
