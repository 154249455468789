import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import apiMercury from "../../services/apiMercury";

import InputMask from "../../components/InputMask";

import { Form, Input, message } from "antd";

import { Modal, Col, Container, Row, Select } from "./styles";
import { getCepInformations } from "../../services/cep";
interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  providerId?: number;
}

const ModalProvider: React.FC<IProps> = ({
  visible,
  setVisible,
  providerId,
}) => {
  const [loading, setLoading] = useState(false);
  const [typeOf, setTypeOf] = useState(1)

  const [providerForm] = Form.useForm();

  useEffect(() => {
    async function fetchProvider() {
      setLoading(true);
      const { data } = await apiMercury.get(`/providers/${providerId}`);
      providerForm.setFieldsValue({
        cnpj: data?.content?.cnpj,
        company_name: data?.content?.company_name,
        fantasy_name: data?.content?.fantasy_name,
        cpf: data?.content?.cpf,
        state_registration: data?.content?.state_registration,
        name_contact: data?.content?.name_contact,
        number_contact: data?.content?.number_contact,
        cep: data?.content?.cep,
        address: data?.content?.address,
        number: data?.content?.number,
        district: data?.content?.district,
        city: data?.content?.city,
        uf: data?.content?.uf,
      });
      setLoading(false);
    }
    if (providerId) {
      fetchProvider();
    }
  }, [providerId, providerForm]);

  const handleState = (event) => {
    if (event.target.name === "cnpj") {
      event.target.value = event.target.value.replace(/[^0-9]+/g, "");
    }
  };

  const handleCepChange = async (value) => {
    if (value && value.length === 9) {
      try {
        const { city, address, state_registration } = await getCepInformations(value.replace(/\D/g, ''));
        providerForm.setFieldsValue({
          address,
          district: "",
          city,
          uf: state_registration,
        });
      } catch (error) {
        message.error("Erro ao buscar informações do CEP.");
      }
    }
  };

  const validateField = async (): Promise<boolean> => {
    try {
      await providerForm.validateFields();
      return true;
    } catch {
      message.warning("Preencha todos os campos corretamente.");
      return false;
    }
  };

  const handleFinish = async () => {
    const validated = await validateField();
    if (!validated) {
      return;
    }
    try {
      const method = providerId ? "put" : "post";
      const url = providerId ? `/providers/${providerId}` : "/providers";
      setLoading(true);

      const { cnpj, company_name, fantasy_name, uf, cpf, state_registration, city, district, number, address, cep, number_contact, name_contact } =
        providerForm.getFieldsValue();
      await apiMercury[method](url, {
        cnpj: cnpj,
        company_name: company_name.toLowerCase(),
        fantasy_name: fantasy_name.toLowerCase(),
        uf: uf.toLowerCase(),
        cpf: cpf.toLowerCase(),
        state_registration: state_registration.toLowerCase(),
        city: city.toLowerCase(),
        district: district.toLowerCase(),
        number: number.toLowerCase(),
        address: address.toLowerCase(),
        cep: cep.toLowerCase(),
        number_contact: number_contact.toLowerCase(),
        name_contact: name_contact.toLowerCase(),
      });

      providerForm.resetFields();
      message.success("Fornecedor salvo com sucesso.");
      setLoading(false);
      setVisible(false);
    } catch {
      setLoading(false);
      message.error("Fornecedor já cadastrado.");
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      title="Novo Fornecedor"
      centered
      visible={visible}
      onOk={() => handleFinish()}
      onCancel={() => {
        providerForm.resetFields();
        setVisible(false);
      }}
      cancelText="Cancelar"
      okText="Adicionar"
      destroyOnClose={true}
      width={window.outerWidth > 768 ? '40%' : '100%'}
    >
      <Form layout="vertical" form={providerForm}>
        <Container>
          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Tipo de Pessoa"
                name=""
                key="key"
                rules={[
                  { required: true, message: "Campo obrigatório" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Selecione uma opção"
                  optionFilterProp="children"
                  onChange={(value: any) => setTypeOf(value)}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Select.Option value={0} key={0}>
                    Física
                  </Select.Option>
                  <Select.Option value={1} key={1}>
                    Jurídica
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {typeOf === 1 ? (
              <Col sm={24} xs={24}>
                <Form.Item
                  label="CNPJ"
                  name="cnpj"
                  rules={[
                    {
                      validator: (_, value) => {
                        const cleanedValue = value.replace(/[^0-9]+/g, "");
                        return cleanedValue.length === 14
                          ? Promise.resolve()
                          : Promise.reject("CNPJ inválido");
                      },
                    },
                  ]}
                >
                  <InputMask
                    disabled={loading}
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    value={"99.999.999/9999-99"}
                    onChange={handleState}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col sm={24} xs={24}>
                <Form.Item
                  label="CPF"
                  name="cpf"
                  rules={[
                    {
                      validator: (_, value) => {
                        const cleanedValue = value.replace(/[^0-9]+/g, "");
                        return cleanedValue.length === 11
                          ? Promise.resolve()
                          : Promise.reject("CPF inválido");
                      },
                    },
                  ]}
                >
                  <InputMask
                    disabled={loading}
                    placeholder="CPF"
                    mask="999.999.999-99"
                    name="cpf"
                    onChange={handleState}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              <Form.Item
                label="Nome do Fornecedor"
                name="company_name"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Input
                  disabled={loading}
                  placeholder="Nome do Fornecedor"
                  name="company_name"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col sm={12} xs={24}>
              <Form.Item
                label="Nome do Fantasia"
                name="fantasy_name"
              >
                <Input
                  disabled={loading}
                  placeholder="Nome do Fantasia"
                  name="fantasy_name"
                />
              </Form.Item>
            </Col>

            <Col sm={12} xs={24}>
              <Form.Item
                label="Inscrição Estadual"
                name="state_registration"
              >
                <Input
                  disabled={loading}
                  placeholder="Inscrição Estadual"
                  name="state_registration"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col sm={12} xs={24}>
              <Form.Item
                label="Nome do Contato"
                name="name_contact"
              >
                <Input
                  disabled={loading}
                  placeholder="Nome do Contato"
                  name="name_contact"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label="Número do Contato"
                name="number_contact"
              >
                <InputMask
                  disabled={loading}
                  placeholder="Número do Contato"
                  mask="(99) 99999-9999"
                  name="number_contact"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm={24} xs={24}>
              <Form.Item label="CEP" name="cep">
                <Input
                  disabled={loading}
                  placeholder="CEP"
                  name="cep"
                  value={providerForm.getFieldValue('cep')}
                  onChange={(e) => handleCepChange(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={8} xs={24}>
              <Form.Item label="Rua" name="address">
                <Input
                  disabled={loading}
                  placeholder="Rua do Fornecedor"
                  name="address"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Número" name="number">
                <Input
                  disabled={loading}
                  placeholder="Número"
                  name="number"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item label="Bairro" name="district">
                <Input
                  disabled={loading}
                  placeholder="Bairro"
                  name="district"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col md={12} xs={24}>
              <Form.Item label="Cidade" name="city">
                <Input
                  disabled={loading}
                  placeholder="Cidade"
                  name="city"
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Estado" name="uf">
                <Input
                  disabled={loading}
                  placeholder="Estado"
                  name="uf"
                />
              </Form.Item>
            </Col>
          </Row>
        </Container>
      </Form>
    </Modal >
  );
};

export default ModalProvider;
