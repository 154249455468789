import styled from "styled-components";

import { Modal as ModalAnt, Table as TableAnt } from "antd";
import { ArrowNarrowRight, InfoCircle } from "../../styles/Icons";

export const Modal = styled(ModalAnt)`
  width: 70%;
  * .ant-modal-header {
    padding: 2rem;
  }
  .ant-modal-body {
    max-height: 25rem;
    padding: 0 2rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    margin-top: 1rem;
    height: 5.25rem;
    padding: 1rem;
    button {
      width: 12rem;
      height: 3.25rem;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1366px) {
    * .ant-modal-header {
      padding: 1rem;
    }

    .ant-modal-title {
      font-size: 1.5rem;
    }
    .ant-modal-footer button {
      width: 8rem;
      height: 3rem;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Table = styled(TableAnt)`
  max-height: 25rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  overflow: auto;

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
  }
`;

export const ArrowRightIcon = styled(ArrowNarrowRight)`
  width: 1.1rem;
  height: 1.1rem;
  margin: 0 0.5rem;
  @media only screen and (max-width: 578px) {
    width: 0.9rem;
    height: 0.9rem;
  }
`;

export const InfoIcon = styled(InfoCircle)`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
