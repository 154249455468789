import styled, { css } from "styled-components";
import { Download } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;
  width: 100%;
  overflow: hidden;
`;

export const SearchContainer = styled.div`
  width: 100%;
  padding: 1%;
`;

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2%;
  height: 5vh;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 1%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

const iconCSS = css`
  width: 31px;
  height: 31px;

  cursor: pointer;

  fill: white;
`;

export const DownloadIcon = styled(Download)`
  ${iconCSS}
`;
