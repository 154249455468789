import styled from "styled-components";
import { Modal as ModalAnt } from "antd";
import Table from "../../containers/Table";

export const Container = styled.div`
  height: 100%;
`;

export const InfoContent = styled.div`
  display: flex;
`;

export const Modal = styled(ModalAnt)`
  width: 65rem;
  height: 32rem;

  .ant-modal-header {
    border-bottom: none !important;
  }

  .ant-btn-primary {
    background: #262626;
    border-color: #262626;
    width: 60px;
  }

  .ant-modal-body {
    max-height: 325px;
    overflow-y: scroll;
    border: 1px solid var(--gray-25);
    border-top: none !important;
    @media only screen and (max-width: 578px) {
      height: 350px;
    }
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 36px;
    color: (--black-opaco);
  }
  .ant-modal-footer {
    border-top: none !important;
    .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
      margin-right: 1px;
      border: 1px solid var(--gray-300);
      border-radius: 5px;
    }
  }
`;

export const TableAudit = styled(Table)`
  .ant-col {
    display: flex;
    justify-content: flex-start;
  }
`;
