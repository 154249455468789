import React, { useEffect, useState } from "react";
import janusApi from "../../services/apiAuth";

import CreateInvoiceRequest from "./CreateInvoiceRequest";
import ListInvoices from "./ListInvoices";
import SendInvoicesRequest from "./SendInvoicesRequest";

import { InvoiceRequest } from "./models/invoiceRequest";

import {
  Container,
  Title,
  ActionContainer,
  ListContainer,
  Button,
} from "./styles";

const CompanyInvoice: React.FC = () => {
  const [selectedInvoiceRequest, setSelectedInvoiceRequest] =
    useState<InvoiceRequest | null>(null);
  const [openSendInvoicesRequest, setOpenSendInvoicesRequest] = useState(false);
  const [invoicesRequest, setInvoicesRequest] = useState<InvoiceRequest[]>([]);
  const [shouldFetchInvoices, setShouldFetchInvoices] = useState(true);
  const [fetchingInvoices, setFetchingInvoices] = useState(false);
  const [openCreateCompanyInvoice, setOpenCreateCompanyInvoice] =
    useState(false);

  useEffect(() => {
    async function fetchInvoices() {
      try {
        setFetchingInvoices(true);
        const {
          data: { content },
        } = await janusApi.get("/company-invoice");
        setInvoicesRequest(content);
      } catch (error) {
        console.log(error);
      } finally {
        setFetchingInvoices(false);
        setShouldFetchInvoices(false);
      }
    }
    if (shouldFetchInvoices) fetchInvoices();
  }, [shouldFetchInvoices]);

  return (
    <Container>
      <Title>Envio de NFS-e/Débito para franquias</Title>
      <ActionContainer>
        {/* <Button>Download CSV</Button> */}
        <Button onClick={() => setOpenCreateCompanyInvoice(true)}>
          Nova Emissão
        </Button>
      </ActionContainer>
      <ListContainer>
        <ListInvoices
          setSelectedInvoiceRequest={setSelectedInvoiceRequest}
          setOpenSendInvoicesRequest={setOpenSendInvoicesRequest}
          invoicesRequest={invoicesRequest}
          fetchingInvoices={fetchingInvoices}
        />
      </ListContainer>
      <CreateInvoiceRequest
        openCreateCompanyInvoice={openCreateCompanyInvoice}
        setOpenCreateCompanyInvoice={setOpenCreateCompanyInvoice}
        setShouldFetchInvoices={setShouldFetchInvoices}
      />
      <SendInvoicesRequest
        selectedInvoiceRequest={selectedInvoiceRequest}
        openSendInvoicesRequest={openSendInvoicesRequest}
        setOpenSendInvoicesRequest={setOpenSendInvoicesRequest}
        setShouldFetchInvoices={setShouldFetchInvoices}
      />
    </Container>
  );
};

export default CompanyInvoice;
