import React from 'react';
import { Tooltip } from 'antd';
import {
    ProgressBarContainer,
    ProgressBarWithCPF,
    ProgressBarWithoutCPF,
} from './styles';

interface ProgressSaleBarProps {
    percentWithCPF: number;
    percentWithoutCPF: number;
}

const ProgressSaleBar: React.FC<ProgressSaleBarProps> = ({
    percentWithCPF,
    percentWithoutCPF,
}) => {
    const totalWidth = percentWithCPF + percentWithoutCPF;
    const bothPresent = percentWithCPF > 0 && percentWithoutCPF > 0;

    return (
        <ProgressBarContainer>
            <Tooltip title={`Com CPF: ${percentWithCPF}%`}>
                <ProgressBarWithCPF percentWithCPF={(percentWithCPF / totalWidth) * 100} bothPresent={bothPresent} />
            </Tooltip>
            <Tooltip title={`Sem CPF: ${percentWithoutCPF}%`}>
                <ProgressBarWithoutCPF percentWithoutCPF={(percentWithoutCPF / totalWidth) * 100} bothPresent={bothPresent} />
            </Tooltip>
        </ProgressBarContainer>
    );
};

export default ProgressSaleBar;