import React from "react";

import { Audit as AuditModel } from "../../models/Audit";

import { Container, ArrowRightIcon, Action, Col } from "./styles";

const Audit: React.FC<AuditModel> = (props) => {
  const { created_at, new_value, old_value, user_id, field, user } = props;

  const [date, time] = created_at.split(" ");

  const getAuditType = (): string => {
    if (!old_value && new_value) {
      return "Adicionado";
    }

    if (old_value && new_value) {
      return "Alterado";
    }
    return "Removido";
  };

  return (
    <Container
      oldvalue={old_value}
      newvalue={new_value}
      justify="space-between"
    >
      <Col sm={5} xs={24}>
        <Action oldvalue={old_value} newvalue={new_value}>
          {getAuditType()}
        </Action>
      </Col>

      <Col sm={5} xs={24}>
        {date.replaceAll("-", "/")}
      </Col>
      <Col sm={5} xs={24}>
        {time}
      </Col>
      <Col sm={0} xs={0}>
        {field}
      </Col>
      <Col sm={5} xs={24}>
        {`[${user_id}] `}
        {user?.name}
      </Col>
      <Col sm={4} xs={24}>
        {old_value} <ArrowRightIcon /> {new_value}
      </Col>
    </Container>
  );
};

export default Audit;
