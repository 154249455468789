import React, { Dispatch, SetStateAction, useState } from "react";
import apiS3Handle from "../../../services/apiS3Handler";
import api from "../../../services/api";
import { Tooltip } from "antd";
import moment from "moment";
import { Royalty as RoyaltyOrder } from "../../../models/Royalty";
import Spinner from "../../../components/Spinner";
import { notification, Spin } from "antd";
import {
  Center,
  Container,
  DownloadButton,
  ImageIcon,
  Preview,
  RemoveButton,
  Table,
  ViewButton,
  UploadWrap,
} from "./styles";

interface IRoyaltyProps {
  royalty: RoyaltyOrder;
  visible: boolean;
  loading: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  handleDeleteUpload: (royalty) => void;
  handleDownload: (royalty) => void;
}

const ModalImageRoyalty: React.FC<IRoyaltyProps> = ({
  royalty,
  setVisible,
  visible,
  handleDeleteUpload,
  loading,
  handleDownload,
  setShouldSearch,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const columns = [
    {
      title: "Imagem",
      dataIndex: "url_file",
      key: "url_file",
      render: (text) => (
        <span>
          <Preview src={text} alt="PDF" />
        </span>
      ),
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <span>
          {moment(text, "DD-MM-YYYY HH:mm:ss")
            .format("DD/MM/YYYY HH:mm")}
        </span>
      ),
    },

    {
      title: <Center>Ação</Center>,
      key: "Ação",
      width: "25%",
      render: (text, record) => (
        <Center>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Tooltip title={"Visualizar"}>
                <ViewButton
                  href={record.url_file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImageIcon />
                </ViewButton>
              </Tooltip>

              <Tooltip title={"Excluir"}>
                <RemoveButton onClick={() => handleDeleteUpload(record)} />
              </Tooltip>

              <Tooltip title={"Download"}>
                <DownloadButton onClick={() => handleDownload(record)} />
              </Tooltip>
            </>
          )}
        </Center>
      ),
    },
  ];

  const updateFiles = async (fileList: FileList) => {
    if (fileList && !fileList.length) {
      notification.error({
        message: "Erro ao realizar upload do arquivo",
        duration: 5,
      });
    }

    const uploadedFile = fileList[0];
    try {
      setUploadingFile(true);
      const imageToUpload = new FormData();
      imageToUpload.append("file", uploadedFile);

      const {
        data: { location, key },
      } = await apiS3Handle.post(
        `/s3-upload/upload/upload-services`,
        imageToUpload
      );

      await api.post("/royalty_files", {
        url_file: location,
        s3_key: key,
        royalty_id: royalty.id,
      });
      setShouldSearch(true);
    } catch (e) {
      console.log(e);
    } finally {
      setUploadingFile(false);
    }
  };

  return (
    <Container
      title={`Arquivos`}
      visible={visible}
      centered
      onCancel={() => setVisible(false)}
      width={700}
      footer={null}
    >
      {royalty?.royaltyFiles?.length < 3 && (
        <UploadWrap>
          {uploadingFile ? (
            <Spin />
          ) : (
            <input
              type="file"
              id="upload-input"
              onChange={({ target: { files } }) =>
                updateFiles(files as FileList)
              }
            />
          )}
        </UploadWrap>
      )}
      <Table
        columns={columns}
        dataSource={
          royalty?.royaltyFiles?.map((entity) => ({
            ...entity,
            key: entity.id,
          })) || []
        }
        scroll={{ y: 400 }}
      />
    </Container>
  );
};

export default ModalImageRoyalty;
