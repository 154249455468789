import styled from "styled-components";

import { Select as SelectAnt } from "antd";

export const Select = styled(SelectAnt)`
  width: 100%;
  text-transform: uppercase;
  text-align: initial;

  .ant-select-selection-search-input {
    height: 100% !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;
  }

  .ant-select-single:not(.ant-select-customize-input),
  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: var(--gray-45) !important;
      justify-content: flex-start !important;
      font-weight: 400 !important;
      text-transform: capitalize !important;
    }

    height: 3.25rem !important;
    border-radius: 5px !important;
    outline: none !important;
    border: 1px solid var(--gray-50) !important;
    box-shadow: 0 0 0 0 !important;
    background: transparent !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;

    :focus,
    :active,
    :hover {
      outline: none;
      box-shadow: 0 0 0 0;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      padding: 10px; 
    }
  }

  .ant-select-arrow {
    color: var(--orange-350) !important;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;

    .ant-select-selector {
      height: 3.25rem !important;
    }
  }
`;
