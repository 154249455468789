import styled, { css } from "styled-components";
import { Trash, Image, MoreHoriz } from "../../styles/Icons";
import { Dropdown as DropdownAnt, Menu as MenuAnt } from "antd";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  *::-webkit-scrollbar {
    display: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

export const Reason = styled.span`
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const TrashIcon = styled(Trash)`
  height: 1.2rem;
  width: 1.2rem;
  fill: red;
`;

export const ImageIcon = styled(Image)`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  color: var(--grey-200);
  margin-right: 0.5rem;
`;

export const ViewButton = styled.a`
  color: var(--gray-600);
  margin-left: 5%;
`;

export const Menu = styled(MenuAnt)`
  min-width: 10rem;
  .ant-dropdown-menu-item-only-child {
    align-items: center;
    color: var(--gray-45);
    border-bottom: 1px solid var(--gray-25);

    :last-child {
      border: none;
    }
  }
`;

export const Dropdown = styled(DropdownAnt)``;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;
