import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";

import { Empty, Tooltip, notification } from "antd";

import {
  Modal,
  Container,
  Table,
  ArrowRightIcon,
  InfoIcon,
  Center,
} from "./styles";

export enum PaymentTefCancelType {
  DESFEITO = 0,
  CANCELADO = 1,
}

export interface PaymentTefAuditDto {
  id?: string;
  field: string;
  old_value: string;
  new_value: string;
  user_id?: number;
  user?: { name?: string };
  ref: number;
  type: number;
  justify?: string;
  code_nsu?: string;
}

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  selectedRefId?: number;
  auditType?: number;
}
const ModalAuditTef: React.FC<IProps> = ({
  visible,
  setVisible,
  selectedRefId,
  auditType,
}) => {
  const [auditPaymentTef, setAuditPaymentTef] = useState([]);
  useEffect(() => {
    const fetchCashHistory = async () => {
      try {
        const {
          data: { data },
        } = await api.get(`/cash_history_audit/${auditType}/${selectedRefId}`);
        const newData = data.filter((audit) => audit.code_nsu);
        setAuditPaymentTef(newData);
      } catch (error) {
        const _message = "Houve um erro ao realizar busca";
        //@ts-ignore
        const _description = error?.response?.data?.error?.message;
        notification.error({
          message: _message,
          description: _description,
        });
      }
    };
    if (visible) {
      fetchCashHistory();
    }
  }, [visible, selectedRefId]);

  const columns = [
    {
      title: "Código NSU",
      dataIndex: "code_nsu",
      key: "code_nsu",
      render: (text) => {
        return text;
      },
    },
    {
      title: "Responsável",
      dataIndex: "user",
      key: "user",
      responsive: ["md"] as any,
      render: (text) => {
        return text.name;
      },
    },
    {
      title: "Data/Hora",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["md"] as any,
      render: (text) => {
        return text;
      },
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (text) => {
        return text ? "R$: "+ (text) : "";
      },
    },
    {
      title: "Tipo",
      dataIndex: "field",
      key: "field",
      responsive: ["md"] as any,
      render: (text) => {
        return text;
      },
    },
    {
      title: "Alteração",
      dataIndex: "old_value",
      key: "old_value",
      width: "20%",
      render: (_, record) => {
        return (
          <>
            {record.old_value} <ArrowRightIcon /> {record.new_value}
          </>
        );
      },
    },
    {
      title: <Center>Justificativa</Center>,
      dataIndex: "justify",
      key: "justify",
      render: (text) => {
        return (
          <Center>
            <Tooltip title={text}>
              <InfoIcon />
            </Tooltip>
          </Center>
        );
      },
    },
  ];
  
  return (
    <Modal
      title={`Histórico de pagamentos ${
        auditType === 1 ? "cancelados" : "desfeitos"
      } TEF`}
      centered
      onCancel={() => setVisible(false)}
      visible={visible}
      width={"90%"}
      footer={false}
    >
      <>
        <Container>
          {auditPaymentTef.length !== 0 ? (
            <Table
              columns={columns}
              pagination={false}
              dataSource={auditPaymentTef}
              rowKey={(entity: any) => entity.id}
              scroll={{ y: 320 }}
            />
          ) : (
            <Empty description="Não há histórico" />
          )}
        </Container>
      </>
    </Modal>
  );
};

export default ModalAuditTef;
