import React from "react";
import { HandlerResponse } from "../../models/Handler";
import HandlerInfo from "../../components/HandlerInfo";
import {
  Container,
  LoadingContainer,
  Title,
  Description,
  Col,
  Collapse,
} from "./styles";
import { Spin, Row } from "antd";
import moment from "moment";
const { Panel } = Collapse;
interface IProp {
  handlers: HandlerResponse[];
  loading: boolean;
}
const HandlerContainer: React.FC<IProp> = ({ handlers, loading }) => {
  return loading ? (
    <LoadingContainer>
      <Spin />
    </LoadingContainer>
  ) : (
    <Container>
      {handlers.map((handler) => {
        return handler.handlers.map((handler, index) => {
          let [date, time] = handler.created_at.split(" ");
          date = moment(date, "DD-MM-YYYY").format("DD/MM/YYYY");
          return (
            <Row key={index} style={{ width: "100%" }}>
              <Collapse style={{ width: "100%" }}>
                <Panel
                  key={handler.created_at}
                  header={
                    <Row>
                      <Col sm={6} xs={24}>
                        <Title style={{ marginLeft: "15px" }}>Caixa: </Title>
                        <Description
                          style={{
                            color: handler.closed_at
                              ? "#ff00008c"
                              : "#0080008f",
                          }}
                        >
                          {handler.cash_code}
                        </Description>
                      </Col>
                      <Col sm={6} xs={24}>
                        <Title style={{ marginLeft: "15px" }}>
                          D.Abertura:{" "}
                        </Title>
                        <Description>{date}</Description>
                      </Col>
                      <Col sm={6} xs={24}>
                        <Title style={{ marginLeft: "15px" }}>
                          H.Abertura:{" "}
                        </Title>
                        <Description>{time}</Description>
                      </Col>

                      <Col sm={6} xs={24}>
                        <Title style={{ marginLeft: "15px" }}>Abertura: </Title>
                        <Description>
                          {handler.amount_on_open.toFixed(2).replace(".", ",")}
                          R$
                        </Description>
                      </Col>
                    </Row>
                  }
                >
                  {handler.handlers.map((handler) => (
                    <HandlerInfo key={handler.id} {...handler} />
                  ))}
                </Panel>
              </Collapse>
            </Row>
          );
        });
      })}
    </Container>
  );
};

export default HandlerContainer;
