import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 3.25rem;
  padding: 0.5rem 0.5rem 0.2rem;
  border: 1px solid var(--gray-250);
  border-radius: 0.25rem;
  background: var(--white);
  .inputFormat {
    display: flex;
    flex-direction: row;

    span {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }
  label {
    width: 100%;
    font-size: 0.7rem;
    margin-bottom: 0.4rem;
  }
  input {
    width: 100%;
    font-size: 1rem;
    border: none;
    background: none;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
`;
