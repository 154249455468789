import styled from "styled-components";

import { Modal as ModalAnt, Col as ColAnt, Row as RowAnt, Select as SelectAnt } from "antd";

export const Row = styled(RowAnt)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1rem .6rem;
  }

  .ant-modal-body {
    height: 25rem;
    overflow-y: scroll;
    padding: 0 10px 10px 10px;
  }
`;

export const Col = styled(ColAnt)``;

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;