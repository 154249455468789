import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { getTokenInfo } from '../../services/auth';
import api from '../../services/api';

import PageContainer from '../../containers/PageContainer';
import Spinner from '../../components/Spinner';
import Centralizer from '../../containers/Centralizer';
import InfoScreen from './InfoScreen';
import { EmptyContent } from '../Waste/styles';
import { CompanyInfo, DeliveryRouteItem, Props } from '../../models/DeliveryRoute';
import { Status } from '../../models/enums/cargoType';

import { Drawer, Empty, Modal, message } from 'antd';
import {
    Box,
    Button,
    Container,
    ContentFlex,
    NetworkIcon,
    ProgressBar,
    OrangeSpan,
    PlayIcon,
    ArrowLeftIcon,
    ContentFlexBox
} from './styles';

const Driver = () => {
    const [loading, setLoading] = useState(false);
    const [driverInfo, setDriverInfo] = useState<Props[]>([]);
    const [selectedDriverInfo, setSelectedDriverInfo] = useState<DeliveryRouteItem | null>(null);
    const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
    const [progressBar, setProgressBar] = useState(0);
    const [shouldSearch, setShouldSearch] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [shouldLoad, setShouldLoad] = useState(false);
    const [shouldFinished, setShouldFinished] = useState(false);
    const [shouldFinishedRoute, setShouldFinishedRoute] = useState(false);
    const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss.SSS') || null;

    useEffect(() => {
        const getDriver = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/delivery-route/drivers/${getTokenInfo()?.id}`);
                setDriverInfo(response.data.data);
            } catch (error) {
                message.error('Erro ao buscar informações do motorista:' + error);
            } finally {
                setLoading(false);
            }
        };
        getDriver();
    }, [shouldSearch, shouldLoad, shouldFinished, shouldFinishedRoute]);

    useEffect(() => {
        if (driverInfo.length > 0) {
            const totalItems = driverInfo.reduce((acc, driver) => acc + driver.deliveryRouteItem.length, 0);
            const deliveredItems = driverInfo.reduce((acc, driver) => {
                return acc + driver.deliveryRouteItem.filter(item => item.status === 4).length;
            }, 0);
            const percentage = (deliveredItems / totalItems) * 100;
            setProgressBar(percentage);
        }
    }, [driverInfo, shouldSearch]);

    const changeStatus = async (id: number) => {
        try {
            await api.put(`/delivery-route/${id}`, { status: 7, started_at: formattedDate });
            message.success("Viagem iniciada!");
            const nextItem = driverInfo[0]?.deliveryRouteItem[0].id
            const itemsPayload = [{
                id: nextItem,
                status: 7,
            }]

            await api.put(`/delivery-route-item/update-many`, itemsPayload);
            setShouldSearch(true)
        } catch (error) {
            message.error('Erro ao mudar o status:' + error);
        } finally {
            setShouldSearch(false)
            setLoading(false)
        }
    };

    const inRouteDelivery = async () => {
        Modal.confirm({
            title: "Atualização de rota",
            content: "Deseja continuar e atualizar essa rota?",
            okText: "Sim",
            okType: "primary",
            centered: true,
            cancelText: "Não",
            async onOk() {
                try {
                    setLoading(true)

                    const itemsPayload = [{
                        id: selectedDriverInfo?.id,
                        status: 7
                    }]

                    await api.put(`/delivery-route-item/update-many`, itemsPayload);
                    message.success("Entrega marcada como Em Rota de Entrega!");
                    const updatedDriverInfo = [...driverInfo];
                    setDriverInfo(updatedDriverInfo);
                    setShouldSearch(true);
                    setShouldLoad(true)
                } catch (error) {
                    message.error('Erro ao marcar a entrega como Em Rota de Entrega:' + error);
                } finally {
                    setShouldSearch(false)
                    setLoading(false)
                }
            },
        });
    };

    const completeDelivery = async (id: number) => {
        Modal.confirm({
            title: "Finalizar rota",
            content: "Deseja concluir a rota atual?",
            okText: "Sim",
            okType: "primary",
            centered: true,
            cancelText: "Não",
            async onOk() {
                setLoading(true);
                try {
                    const itemsPayload = [{
                        id: selectedDriverInfo?.id,
                        status: 4,
                        finished_at: formattedDate
                    }];
    
                    await api.put(`/delivery-route-item/update-many`, itemsPayload);
                    message.success("Entrega marcada como concluída!");
                    setShouldFinishedRoute(true);
                    const updatedDriverInfo = [...driverInfo];
                    setDriverInfo(updatedDriverInfo);
    
                    const nextItems = driverInfo[0].deliveryRouteItem.filter((item) => item?.status === null);
                    if (nextItems.length === 0) {
                        setShouldSearch(true);
                    } else {
                        Modal.confirm({
                            title: "Iniciar próxima rota",
                            content: "Deseja iniciar a próxima rota?",
                            okText: "Sim",
                            okType: "primary",
                            centered: true,
                            cancelText: "Não",
                            async onOk() {
                                setLoading(true);
                                try {
                                    const nextItemPayload = [{
                                        id: nextItems[0]?.id,
                                        status: 7,
                                        finished_at: null,
                                    }];
                                    await api.put(`/delivery-route-item/update-many`, nextItemPayload);
                                    setShouldLoad(true);
                                    message.info("Próxima rota iniciada!");
                                } catch (error) {
                                    message.error('Erro ao iniciar a próxima rota:' + error);
                                } finally {
                                    setLoading(false);
                                    setShouldLoad(false);
                                }
                            },
                            onCancel() {
                                setShouldLoad(true);
                            },
                        });
                    }
                } catch (error) {
                    message.error('Erro ao marcar a entrega como concluída:' + error);
                } finally {
                    setLoading(false);
                }
            },
            onCancel() {
            },
        });
    };
    

    const finishRoute = async (id: number) => {
        Modal.confirm({
            title: "Finalizar viagem",
            content: `Deseja continuar e concluir DEFINITIVAMENTE essa viagem?`,
            okText: "Sim",
            okType: "primary",
            centered: true,
            cancelText: "Não",
            async onOk() {
                try {
                    setLoading(true)
                    await api.put(`/delivery-route/${id}`, { status: 4, finished_at: formattedDate });

                    message.success("Viagem concluída!");
                    setShouldSearch(true)
                    setShouldFinished(true)
                } catch (error) {
                    message.error('Erro ao concluir rota:' + error);
                } finally {
                    setShouldSearch(false)
                    setLoading(false)
                }
            },
        });
    }

    const sendComment = async (id: number, comment: string) => {
        try {
            setLoading(true)

            let itemsPayload = [{
                id: selectedDriverInfo?.id,
                comment: comment
            }]
            await api.put(`/delivery-route-item/update-many`, itemsPayload);
            message.success("Comentário enviado!");
            const updatedDriverInfo = [...driverInfo];
            setDriverInfo(updatedDriverInfo);
            setShouldSearch(true)
        } catch (error) {
            message.error('Erro ao enviar  comentário:' + error);
        } finally {
            setShouldSearch(false)
            setLoading(false)
        }
    };


    return (
        <PageContainer route='Motorista'>
            {loading ? (
                <div style={{ width: '100%' }}>
                    <Centralizer>
                        <Spinner />
                    </Centralizer>
                </div>
            ) : (
                <Container>
                    {driverInfo.length !== 0 ? (
                        <>
                            {driverInfo.map((driver, index) => (
                                <>
                                    <Box margin='1rem 0' background='var(--orange-450)' justifyContent='space-between'>
                                        <span>Rota {driver.deliveryRoute.id}</span>
                                        <NetworkIcon color='var(--coffe)' />
                                    </Box>
                                    <Box key={index} margin='0 0 1rem' border='1px solid var(--gray-25)' background='transparent' justifyContent='space-between'>
                                        <ContentFlexBox>
                                            <ContentFlex>
                                                <div className='content-card'>
                                                    <span className='truck'>Caminhão</span>
                                                    <span className='plate'>{driver.deliveryRoute.truck_info}</span>
                                                </div>
                                                <div className='content-card'>
                                                    <span className='truck'>Data de início</span>
                                                    <span className='date'>
                                                        {driverInfo[0].deliveryRoute.started_at !== null ? moment(driverInfo[0].deliveryRoute.started_at
                                                            , "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY HH:mm:ss") : "-"}
                                                    </span>
                                                </div>
                                            </ContentFlex>
                                            <div>
                                                <ProgressBar percent={progressBar} format={percent => `${percent}%`} strokeColor={{ '0%': '#cc3333', '100%': '#68d639' }} />
                                            </div>
                                        </ContentFlexBox>
                                    </Box>

                                    {driver.deliveryRoute.status === Status.ATRIBUIDA && (
                                        <Button backgroundColor='var(--green-400)' color='white' onClick={() => changeStatus(driver.deliveryRoute.id)}>
                                            Iniciar rota
                                            <PlayIcon color='white' />
                                        </Button>
                                    )}
                                    {driver.deliveryRouteItem.map((driverItem) => (
                                        <Box margin='1rem 0' border='1px solid var(--gray-25)' background='transparent' justifyContent='space-between'>
                                            <Container>
                                                <ContentFlex>
                                                    <div className='content-card'>
                                                        <span className="date">Sequência</span>
                                                        <OrangeSpan style={{ color: 'var(--orange-350)' }}>{driverItem.sequence}</OrangeSpan>
                                                    </div>
                                                    <div className='content-card' onClick={() => {
                                                        setOpenDrawer(true)
                                                        setSelectedDriverInfo(driverItem)
                                                        const company = driver.companiesInfo.find(company => company.id === driverItem.company_id);
                                                        setCompanyInfo(company || null);
                                                    }}>
                                                        <span className="date">Loja</span>
                                                        <div className='content-span'>
                                                            {driver.companiesInfo &&
                                                                driver.companiesInfo.find(company => company.id === driverItem.company_id) && (
                                                                    <span className="date">
                                                                        {driver.companiesInfo.find(company => company.id === driverItem.company_id)?.company_name}
                                                                    </span>
                                                                )}
                                                            <ArrowLeftIcon
                                                                color="var(--gray-45)"
                                                                onClick={() => setOpenDrawer(true)}
                                                            />
                                                        </div>

                                                    </div>
                                                </ContentFlex>
                                                <Box
                                                    height='2rem'
                                                    margin='1rem 0 0'
                                                    border='none'
                                                    background={driverItem.status === Status.EM_ROTA ? '#5EB2FF' : driverItem.status === Status.FINALIZADA ? 'green' : 'var(--orange-350)'}
                                                    justifyContent='center' style={{ height: '2rem !important' }}
                                                >
                                                    <span>
                                                        {driverItem.status === Status.EM_ROTA ? "Em Rota de Entrega" : driverItem.status === Status.FINALIZADA ? "Entregue" : "Pendente"}
                                                    </span>
                                                </Box>
                                            </Container>
                                        </Box >
                                    ))}
                                    {progressBar === 100 && (
                                        <Button backgroundColor='var(--green-400)' color='white' onClick={() => finishRoute(driver.deliveryRoute.id)}>
                                            Finalizar rota
                                            <PlayIcon color='white' />
                                        </Button>
                                    )}

                                    <Drawer
                                        title={"Informações da rota"}
                                        visible={openDrawer}
                                        onClose={() => setOpenDrawer(false)}
                                        maskClosable
                                        mask={false}
                                        className="drawer"
                                        closable
                                        width={window.outerWidth < 768 ? '100%' : '50%'}
                                    >
                                        <InfoScreen
                                            setOpenDrawer={setOpenDrawer}
                                            selectedDriverInfo={selectedDriverInfo}
                                            driver={companyInfo}
                                            inRouteDelivery={inRouteDelivery}
                                            completeDelivery={completeDelivery}
                                            sendComment={sendComment}
                                            loading={loading}
                                            driverInfo={driverInfo}
                                        />
                                    </Drawer>
                                </>

                            ))}
                        </>
                    ) : (
                        <EmptyContent>
                            <Empty description="Não há viagens vinculadas a você no momento" />
                        </EmptyContent>
                    )}
                </Container>
            )
            }

        </PageContainer >
    );
};

export default Driver;
