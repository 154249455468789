import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal, notification } from 'antd';

import api from '../../../services/api';
import { currencyFormater } from '../../../services/currencyFormater';

import { Royalty } from '../../../models/Royalty';

import { ButtonSave, Table, TrashIcon } from './styles'

interface IProps {
    royalty: Royalty | undefined;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

enum PaymentType {
    "Dinheiro" = 0,
    "Tranferência" = 1,
    "Boleto" = 5
}

const ModalMovimentation: React.FC<IProps> = ({
    royalty,
    visible,
    setVisible,
    setShouldSearch
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [movimentations, setMovimentations] = useState<Royalty[]>([]);

    useEffect(() => {
        const getMovimentations = async () => {
            if (!royalty) return;

            setLoading(true);
            try {
                const {
                    data: {
                        data: { content },
                    },
                } = await api.get(`/royalty_payments/${royalty.id}`);
                setMovimentations(content);
            } catch (error) {
                notification.error({
                    message: "Ops, Um erro interno ocorreu.",
                    duration: 5,
                });
            } finally {
                setLoading(false);
            }
        }

        getMovimentations();
    }, [royalty]);

    const deletePayment = async (id: number) => {
        Modal.confirm({
            title: "Remover pagamento",
            content: "Tem certeza que gostaria de remover este pagamento?",
            okText: "Sim",
            okType: "danger",
            cancelText: "Não",
            async onOk() {
                setLoading(true);
                try {
                    await api.delete(`royalty_payments/${id}`);
                    setShouldSearch(true);
                    notification.success({
                        message: "Pagamento removido com sucesso",
                        duration: 5,
                    });
                } catch (error) {
                    const _message = "Erro ao remover pagamento";
                    //@ts-ignore
                    const _description = error?.response?.data?.error?.message;
                    notification.error({
                        message: _message,
                        description: _description,
                        duration: 5,
                    });
                } finally {
                    setLoading(false);
                }
            },
        });
    };

    const columns = [
        {
            title: "Data de lançamento",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => <span>{text}</span>
        },
        {
            title: "Responsável",
            dataIndex: "user_name",
            key: "user_name",
            render: (_, record) => <span>{record?.user?.name}</span>
        },
        {
            title: "Tipo de pagamento",
            dataIndex: "payment_type",
            key: "payment_type",
            render: (text) => <span>{PaymentType[text]}</span>
        },
        {
            title: "Valor",
            dataIndex: "payment_value",
            key: "payment_value",
            render: (text) => <span>R$ {currencyFormater(+text)}</span>
        },
        {
            title: "Ação",
            dataIndex: "",
            key: "",
            render: (_, record) => <TrashIcon onClick={() => deletePayment(record?.id)} />
        }
    ]

    return (
        <Modal
            title={`Movimentações de pagamento`}
            visible={visible}
            centered
            destroyOnClose={true}
            closable={false}
            width={'50%'}
            footer={[
                <ButtonSave
                    loading={loading}
                    onClick={() => setVisible(false)}
                >
                    Fechar
                </ButtonSave>,
            ]}
        >
            <Table
                loading={loading}
                columns={columns}
                pagination={false}
                dataSource={movimentations.map((entity) => ({
                    ...entity,
                    key: entity.id,
                }))}
            />
        </Modal>
    )
}

export default ModalMovimentation;