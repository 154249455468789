import React, { SetStateAction, Dispatch, forwardRef } from "react";

import { RangePicker } from "./styles";
import { Moment } from "moment";

interface IProps {
  data: { data_inicial: Moment; data_final: Moment };
  setData: Dispatch<
    SetStateAction<{ data_inicial: Moment; data_final: Moment }>
  >;
  [rest: string]: any;
  allowClear: boolean;
}

const dateFormat = "DD/MM/YYYY";

const DataField = forwardRef<null, IProps>(
  ({ data, setData, allowClear,...rest }, ref) => {
    const { data_inicial, data_final } = data;
    function onChange(date) {
      setData({ data_inicial: date[0], data_final: date[1] });
    }
    return (
      <RangePicker
        ref={ref}
        value={[data_inicial, data_final]}
        onChange={onChange}
        {...rest}
        format={dateFormat}
        allowClear={allowClear}
        inputReadOnly={true}
      />
    );
  }
);

export default DataField;
