export enum CargoType {
    CargaSeca = 1,
    CargaGelada = 2,
    CargaMista = 3
}

export enum Status {
    ATRIBUIDA = 1,
    ROTEIRIZADA = 2,
    CANCELADA = 3,
    FINALIZADA = 4,
    CRIADA = 5,
    EM_ROTA = 7,
}