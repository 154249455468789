import { DeliveryRoute } from "../models/Order/Order";
import { DeliveryRouteStatus } from "../models/enums/DeliveryRoutes";

export const verifyStatusRoute = (
  routes?: DeliveryRoute[],
  order_id?: number
): DeliveryRouteStatus => {
  if (!routes || !routes.length) {
    return DeliveryRouteStatus.INDISPONIVEL;
  }

  const filterStatusFinishAndPause = routes.filter(
    (route) =>
      route.status !== DeliveryRouteStatus.CANCELADA &&
      route.status !== DeliveryRouteStatus.FINALIZADA &&
      route.status !== DeliveryRouteStatus.EM_PAUSA
  );

  let maiorStatus: DeliveryRouteStatus | null = null;

  if (filterStatusFinishAndPause.length > 0) {
    const filterSequence = filterStatusFinishAndPause
      .map((_routeItem) => ({
        id: _routeItem.id,
        status: _routeItem.status,
        deliveryRouteItem: _routeItem.deliveryRouteItem.filter(
          (item) => item.finished_at === null
        ),
      }))
      .filter((_routeItem) => _routeItem.deliveryRouteItem.length > 0);

    const idProxSequence = filterSequence.some(
      (_routeItem) =>
        _routeItem.deliveryRouteItem.length > 0 &&
        _routeItem.deliveryRouteItem[0].id_order === order_id &&
        _routeItem.status === DeliveryRouteStatus.EM_ROTA
    );

    if (idProxSequence) {
      return DeliveryRouteStatus.EM_ROTA_ATE_VOCE;
    }

    const finishedItem = routes?.flatMap(
      (route) => route?.deliveryRouteItem
    ).find(
      (_item) => _item?.id_order === order_id
    );

    if (finishedItem?.finished_at !== null) {
      return DeliveryRouteStatus.FINALIZADA;
    }

    maiorStatus = filterStatusFinishAndPause.reduce((maxStatus, item) => {
      return maxStatus === null || item.status > maxStatus
        ? item.status
        : maxStatus;
    }, filterStatusFinishAndPause[0].status);
  }

  if (maiorStatus === null) {
    const statusPause = routes.some(
      (route) => route.status === DeliveryRouteStatus.EM_PAUSA
    );
    if (statusPause) return DeliveryRouteStatus.EM_PAUSA;
    else {
      const cancelStatus = routes.every(
        (route) => route.status === DeliveryRouteStatus.CANCELADA
      );
      if (cancelStatus) return DeliveryRouteStatus.CANCELADA;
      else return DeliveryRouteStatus.FINALIZADA;
    }
  }

  return maiorStatus;
};
