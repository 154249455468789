import styled, {css} from "styled-components";

import { Modal as ModalAnt, Table as TableAnt, Button as ButtonAnt } from "antd";

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    flex-direction: row;
    padding: 1rem;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  height: 100%;
  padding: 0 1rem;

  .import {
    color: var(--midori-green);
  }
  .export {
    color: var(--maroon);
  }
`;

export const Table = styled(TableAnt)`
  height: 100%;

.ant-table-container {
  border-radius: 5px;
  border: 1px solid var(--gray-75);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--white-50);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray-300);
    border-radius: 5px;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none;
}

.ant-table-tbody > tr > td {
  padding: 1rem 0.6rem;
  color: var(--blue-999);
  font-weight: 400;
  font-size: 1rem;
  overflow: hidden;
  overflow-wrap: unset;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  @media only screen and (max-width: 1400px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 575px) {
    padding: 0.15rem;
    font-size: 0.7rem;
  }
}

.ant-table-thead > tr > th {
  padding: 1rem 0.6rem;
  background: #fdfdfd;
  font-size: 1rem;
  font-weight: 500;
  color: var(--blue-900);
  text-overflow: ellipsis;
  @media only screen and (max-width: 1400px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 575px) {
    padding: 0.15rem;
    font-size: 0.7rem;
  }
}
`;


const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;