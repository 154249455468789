import styled from 'styled-components';
import { Table as TableAnt, Input as InputAnt, Button as ButtonAnt } from 'antd'

import { Reload } from '../../../styles/Icons'

export const Container = styled.div``;

export const ButtonReload = styled(ButtonAnt)`
  background-color: var(--orange-350);
  color: var(--white);
  width: 100%;
  height: 3.2rem;

  border-radius: 8px;
  border: none;

    :hover, :focus {
      background-color: var(--orange-350);
      color: var(--white);
    }
`;

export const Table = styled(TableAnt)`
    overflow: auto;

        .ant-table{
            border: 1px solid var(--gray-25) !important;
            border-radius: 0.6rem !important;
        }
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 25rem;

    @media screen and (max-width: 768px){
        height: 100%;
    }
`;

export const Input = styled(InputAnt)`
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextCapitalize = styled.span`
  text-transform: capitalize;
`;

export const ReloadIcon = styled(Reload)`
  width: 1.2rem;
  height: 1.2rem;

  color: var(--white);
`