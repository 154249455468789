import React, { useState, useEffect } from "react";

import apiMercury from "../../services/apiMercury";

import { Provider as ProviderModel } from "../../models/Provider";

import ModalProvider from "../ModalProvider";

import {
  Select,
  PencilIcon,
  OptionInfoContainer,
  OptionLabel,
  OptionLabelCpnj,
} from "./styles";

interface IProps {
  handleChange: (provider: number) => void;
  defaultValue?: string;
  height?: string; 
}

const SearchProvider: React.FC<IProps> = ({ handleChange, defaultValue, height }) => {
  const [loading, setLoading] = useState(false);
  const [shoulOpen, setShoulOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<number | undefined>(
    undefined
  );
  const [providers, setProviders] = useState<ProviderModel[]>([]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      setShoulOpen(false);
      setLoading(true);
      const {
        data: { content },
      } = await apiMercury.get(`/providers`);
      setProviders(content.sort((a, b) => a.fantasy_name.localeCompare(b.fantasy_name)));
      setLoading(false);
    };

    fetchProviders();
  }, []);

  useEffect(() => {
    if (visible) {
      setShoulOpen(false);
    }
  }, [visible, shoulOpen]);

  const onChange = (id: number) => {
    handleChange(id);
    setSelectedProvider(id);
  };

  const handleEdit = (id: number) => {
    setSelectedProvider(id);
    setVisible(true);
    setShoulOpen(false);
  };

  return (
    <>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (!option || !option.props.children) return false;

          const name = option?.children.props.children[0].props.children.toLowerCase();
          const cnpj = option?.children.props.children[1].props.children.toLowerCase();
          return name.indexOf(input.toLowerCase()) >= 0 || cnpj.indexOf(input.toLowerCase()) >= 0;
        }}
        placeholder="Escolha um fornecedor"
        loading={loading}
        defaultValue={defaultValue}
        onClick={() => setShoulOpen(!shoulOpen)}
        onChange={(id) => onChange(+id)}
        onBlur={() => setShoulOpen(false)}
        open={shoulOpen}
        height={height}
        allowClear
      >
        {providers.length > 0 ? (
          providers.map((provider) => (
            <Select.Option key={provider.id} value={provider.id} label={provider.fantasy_name}>
              <OptionInfoContainer>
                <OptionLabel>{provider.fantasy_name}</OptionLabel>
                <OptionLabelCpnj style={{display: 'none'}}>{provider.cnpj || provider.cpf}</OptionLabelCpnj>
                <PencilIcon onClick={(e) => {
                  e.stopPropagation(); 
                  handleEdit(provider.id);
                }} />
              </OptionInfoContainer>
            </Select.Option>
          ))
        ) : (
          <Select.Option value="" disabled>
            Nenhum fornecedor encontrado
          </Select.Option>
        )}
      </Select>
      <ModalProvider
        visible={visible}
        setVisible={setVisible}
        providerId={selectedProvider}
      />
    </>
  );
};

export default SearchProvider;
