import React, { useState } from 'react'

import { Drawer, Tooltip } from 'antd'

import moment from 'moment';

import ModalProvider from '../../components/ModalProvider';

import { currencyFormater } from '../../services/currencyFormater';

import { Purchase } from '../../models/Purchase/Purchase';
import { PaymentType } from '../../models/enums/PaymentType';
import { PaymentCondition } from '../../models/enums/PaymentCondition';
import { ProductCategory as ProductCategoryResponse } from "../../models/ProductCategory";

import Centralizer from '../Centralizer';
import { Spin } from '../Centralizer/styles';
import {
    Button,
    ButtonRow,
    Content,
    ContentAnexos,
    ContentColumn,
    ContentItemInfo,
    ContentRow,
    Description,
    Divider,
    DownloadIcon,
    GoToPayment,
    InfoCircleIcon,
    ItemUploadInfo,
    Label,
    OpenIcon,
    TableElement,
    Title,
    TitlePaymentInfoSection,
    TitleSection,
    ContentItemInfoPayment
} from './styles';

interface IProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    purchasesOrder: Purchase | null
    productsCategory: ProductCategoryResponse[];
    purchases: Purchase[];
    loadingInfo: boolean
}

const InfoPurchaseItemsDrawer: React.FC<IProps> = ({ visible, setVisible, purchasesOrder, loadingInfo }) => {
    const [visibleEdit, setvisibleEdit] = useState(false);

    const handleDownloadFile = (url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url.split('/').pop() || 'download');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Drawer
            title={<Title>Detalhes da Compra</Title>}
            visible={visible}
            onClose={() => setVisible(false)}
            maskClosable
            mask={false}
            className="drawer"
            closable
            width={window.outerWidth < 768 ? '100%' : '40%'}
            footer={
                <ButtonRow>
                    <Button
                        onClick={() => setVisible(false)}
                        backgroundColor='transparent'
                        color='var(--gray-550)'
                        border='1px solid var(--gray-550)'
                    >
                        Fechar
                    </Button>
                </ButtonRow>
            }
        >
            {loadingInfo ? (
                <Centralizer>
                    <Spin />
                </Centralizer>
            ) : (
                <Content>
                    <Divider>
                        <ContentRow>
                            <ContentItemInfo>
                                <Label>Fornecedor</Label>
                                <Description alignLeft>{purchasesOrder?.provider.company_name ? purchasesOrder?.provider.company_name : "Sem Fornecedor Informado"}</Description>
                            </ContentItemInfo>
                            <ContentItemInfo>
                                <Label>Data de Criação</Label>
                                <Description>{moment(purchasesOrder?.created_at).format("DD/MM/YYYY HH:mm:ss")}</Description>
                            </ContentItemInfo>
                        </ContentRow>

                        <ContentRow>
                            <ContentItemInfo>
                                <Label>Responsável</Label>
                                <Description alignLeft>{purchasesOrder?.user?.name}</Description>
                            </ContentItemInfo>
                            <ContentItemInfo>
                                <Label>Contas a pagar?</Label>
                                <GoToPayment>Ver <OpenIcon /></GoToPayment>
                            </ContentItemInfo>
                        </ContentRow>

                        <ContentRow>
                            <ContentItemInfo>
                                <Label>Observação</Label>
                                <Description>{purchasesOrder?.observation ? purchasesOrder?.observation : "Sem Observação Cadastrada"}</Description>
                            </ContentItemInfo>
                        </ContentRow>
                    </Divider>


                    <Divider>
                        <TitleSection>Itens</TitleSection>
                        <TableElement>
                            <thead>
                                <tr>
                                    <th>Qtd</th>
                                    <th>Categoria</th>
                                    <th>Produto</th>
                                    <th>
                                        Valor
                                        <Tooltip title="Preço de uma unidade de produto por linha">
                                            <InfoCircleIcon />
                                        </Tooltip>
                                    </th>
                                    <th>{window.outerWidth > 768 ? "Observação" : "Obs"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchasesOrder?.purchasesItems.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.quantity}x</td>
                                        <td>{item.product?.category?.name || 'Categoria desconhecida'}</td>
                                        <td>{item.product?.name || 'Produto desconhecido'}</td>
                                        <td>R$ {Number(item.unitary_value).toFixed(2)}</td>
                                        <td>{item.observation ? item.observation : "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </TableElement>
                    </Divider>

                    <Divider>
                        <ContentRow>
                            {purchasesOrder?.purchasesItems.map((item) => (
                                <>
                                    <ContentItemInfo>
                                        <Label>IPI</Label>
                                        <Description>{`R$ ${currencyFormater(Number(item.value_ipi))}`}</Description>
                                    </ContentItemInfo>
                                    <ContentItemInfo>
                                        <Label>ICMS</Label>
                                        <Description>{`R$ ${currencyFormater(Number(item.value_icms))}`}</Description>
                                    </ContentItemInfo>
                                </>
                            ))}
                        </ContentRow>

                        <ContentRow>
                            <ContentItemInfo>
                                <Label>Valor adicional</Label>
                                <Description alignLeft>{purchasesOrder?.additional_value ? `R$ ${currencyFormater(+purchasesOrder?.additional_value || 0)}` : "-"}</Description>
                            </ContentItemInfo>
                            <ContentItemInfo>
                                <Label>Desconto</Label>
                                <Description>{purchasesOrder?.discount ? `R$ ${currencyFormater(purchasesOrder?.discount || 0)}` : "-"}</Description>
                            </ContentItemInfo>
                        </ContentRow>
                        <ContentRow>
                            <ContentItemInfo>
                                <Label>Frete</Label>
                                <Description alignLeft>{purchasesOrder?.value_freight ? `R$ ${currencyFormater(+purchasesOrder?.value_freight || 0)}` : "-"}</Description>
                            </ContentItemInfo>
                            <ContentItemInfo>
                                <Label>
                                    Total da compra
                                    <Tooltip title="Soma do valor total de todos itens da compra">
                                        <InfoCircleIcon />
                                    </Tooltip>
                                </Label>
                                <Description>{purchasesOrder?.total ? `R$ ${currencyFormater(+purchasesOrder?.total || 0)}` : "-"}</Description>
                            </ContentItemInfo>
                        </ContentRow>
                    </Divider>

                    <Divider>
                        <ContentItemInfoPayment>
                            <TitleSection>Pagamento</TitleSection>

                            <div>
                                <ContentItemInfoPayment>
                                    <Label>Data da compra - {" "}</Label>
                                    <Description alignLeft>
                                        {moment(purchasesOrder?.created_at).format("DD/MM/YYYY")}
                                    </Description>
                                </ContentItemInfoPayment>
                            </div>
                        </ContentItemInfoPayment>

                        {purchasesOrder?.accountsPayable?.map((item, index) => (
                            <ContentColumn key={index}>
                                <TitlePaymentInfoSection>{purchasesOrder?.accountsPayable && purchasesOrder?.accountsPayable.length < 1 ?
                                    `Parcela` : `${index + 1}ª Parcela`}
                                </TitlePaymentInfoSection>

                                <ContentRow>
                                    <ContentItemInfo>
                                        <Label>Condição de pagamento</Label>
                                        <Description>
                                            {PaymentCondition[item?.occurrence!]}
                                        </Description>
                                    </ContentItemInfo>
                                </ContentRow>

                                <ContentRow>
                                    <ContentItemInfo>
                                        <Label>Vencimento</Label>
                                        <Description alignLeft>
                                            {moment(item?.due_date).format("DD/MM/YYYY")}
                                        </Description>
                                    </ContentItemInfo>

                                    <ContentItemInfo>
                                        <Label>Valor</Label>
                                        <Description>{currencyFormater(+item?.payment_amount!)}</Description>
                                    </ContentItemInfo>

                                    <ContentItemInfo>
                                        <Label>Forma de pagamento</Label>
                                        <Description>
                                            {PaymentType[item?.payment_method!]}
                                        </Description>
                                    </ContentItemInfo>

                                    <ContentItemInfo>
                                        <Label>Observação</Label>
                                        <Description>{item?.observation}</Description>
                                    </ContentItemInfo>
                                </ContentRow>
                            </ContentColumn>
                        ))}
                    </Divider>

                    <ContentAnexos>
                        <TitleSection>Anexos</TitleSection>
                        <ContentColumn>
                            {purchasesOrder?.purchaseFiles && purchasesOrder.purchaseFiles.length > 0 ? (
                                purchasesOrder.purchaseFiles.map((file, index) => (
                                    <ItemUploadInfo key={index}>
                                        <DownloadIcon onClick={() => window.open(file.url_file, '_blank')} />
                                        <Description>
                                            Download do anexo: {file.url_file.split('/').pop()}
                                        </Description>
                                    </ItemUploadInfo>
                                ))
                            ) : (
                                <Description>Não há anexos disponíveis.</Description>
                            )}
                        </ContentColumn>
                    </ContentAnexos>

                    <ModalProvider
                        visible={visibleEdit}
                        setVisible={setvisibleEdit}
                    />

                </Content>
            )}
        </Drawer>
    )
}

export default InfoPurchaseItemsDrawer