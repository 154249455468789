import styled, { css } from "styled-components";

import { RecordCircle } from "../../../styles/Icons";
import { InfoCircle } from "../../../styles/Icons";

import {
  Modal as ModalAnt,
  Select as SelectAnt,
  Form as FormAnt,
  Table as TableAnt,
  Input as InputAnt,
  Button as ButtonAnt,
  Col as ColAnt,
  Row as RowAnt,
} from "antd";

const { TextArea } = InputAnt;

export const Container = styled(ModalAnt)`
  .ant-modal-header {
    padding: 0.5rem 1.4rem 0;
  }
  .ant-modal-body {
    padding: 0 1.4rem 0.5rem;
  }
  .ant-modal-footer {
    padding: 0.7rem 1.4rem;
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: ellipsis;
  text-align: center;
`;

export const ContentCategories = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  .spanText {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--gray-45);
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .loadType {
    font-size: 1.5rem;
    margin-left: 1rem;
    color: var(--gray-45);
  }
`;

export const Select = styled(SelectAnt)`
  width: 15rem;
  text-transform: uppercase;
  text-align: initial;

  .ant-select-selection-search-input {
    height: 100% !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;
  }

  .ant-select-single:not(.ant-select-customize-input),
  .ant-select-selector {
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      color: var(--blue-900) !important;
      justify-content: flex-start !important;
    }

    height: 3.25rem !important;
    border-radius: 5px !important;
    outline: none !important;
    border: 1px solid var(--gray-50) !important;
    box-shadow: 0 0 0 0 !important;
    background: transparent !important;
    color: var(--gray-45) !important;
    font-weight: 500 !important;

    :focus,
    :active,
    :hover {
      outline: none;
      box-shadow: 0 0 0 0;
    }
  }

  .ant-select-arrow {
    color: var(--orange-350) !important;
  }

  @media only screen and (max-width: 578px) {
    width: 100%;

    .ant-select-selector {
      height: 3.25rem !important;
    }
  }
`;

export const Form = styled(FormAnt)`
  .ant-divider-horizontal,
  .ant-divider-with-text {
    border-top-color: var(--gray-100);

    .ant-divider-inner-text {
      font-weight: 500;
      padding-left: 0;
      color: var(--red-600);
    }

    &::before {
      width: 0;
    }
  }
  .ant-row,
  .ant-form-item {
    margin: 0;
  }
  .ant-input,
  .ant-select-selector,
  .ant-picker {
    width: 100%;
    height: 3rem !important;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 0;
  }

  .ant-form-item-label {
    font-weight: 500;
    color: var(--gray-550);
  }

  @media only screen and (max-width: 1366px) {
    .ant-form-item {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker {
      height: 2.3rem !important;
      font-size: 0.8rem;
    }
    .ant-form-item-label {
      font-size: 0.8rem;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      font-size: 0.7rem;
    }
  }
`;

export const TextSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;

  .itemCount {
    height: 1.7rem;
    width: 1.7rem;
    margin-right: 1.5rem;
    border-radius: 16px;

    background: var(--orange-350);
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 3rem;

  label {
    font-weight: 500;
    color: var(--gray-550);

    span {
      color: var(--orange-100);
    }
  }

  @media only screen and (max-width: 1366px) {
    height: 3rem;

    label,
    span {
      font-size: 0.8rem;
    }
  }
`;
interface ISteps {
  currentPage: number;
}

export const Steps = styled.div<ISteps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .step-line1,
  .step-line2 {
    width: 50%;
    height: 0.5rem;
    background: var(--gray-25);
    content: "";
  }

  ${({ currentPage }) => {
    if (currentPage === 1) {
      return css`
        .step-icon1 {
          color: var(--orange-350);
        }
      `;
    }

    if (currentPage === 2) {
      return css`
        .step-icon1,
        .step-icon2 {
          color: var(--orange-350);
        }

        .step-line1 {
          background: var(--orange-350);
        }
      `;
    }

    if (currentPage === 3) {
      return css`
        .step-icon1,
        .step-icon2,
        .step-icon3 {
          color: var(--orange-350);
        }

        .step-line1,
        .step-line2 {
          background: var(--orange-350);
        }
      `;
    }
  }}
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled(InfoCircle)`
  color: var(--gray-600);
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;

export const StepIcon = styled(RecordCircle)`
  color: var(--gray-25);
  width: 1rem;
  height: 1rem;
`;

interface IStatus {
  active?: string;
}

export const Status = styled.span<IStatus>`
  display: flex;
  justify-content: center;
  ${({ active }) => {
    if (active === "A") {
      return css`
        color: var(--midori-green);
      `;
    } else if (active === "I") {
      return css`
        color: var(--maroon);
      `;
    }
  }}
`;

export const ContainerItens = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Table = styled(TableAnt)`
  width: 70%;
  height: 100%;

  border: 1px solid var(--gray-25);
  border-radius: 5px;
  overflow: hidden;

  .ant-table-container {
    max-height: 100%;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-table-thead > tr > th {
    background: white;

    padding: 0.6rem;

    color: var(--blue-900);

    font-weight: 500;
    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      padding: 0.1rem;
    }
  }

  .ant-table-tbody > tr > td {
    background: white;

    padding: 0.8rem 0.3rem;

    color: var(--gray-45);

    font-weight: 400;
    font-size: 0.9rem;

    text-transform: capitalize;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    white-space: nowrap;
    @media only screen and (max-width: 768px) {
      font-size: 0.6rem;
      padding: 0.1rem;

      input {
        padding: 0.1rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const QuantityInput = styled(InputAnt)`
  text-align: center;
  height: 3.2rem;
  width: 3.2rem;
  padding: 0.8rem;
  border: 1px solid var(--gray-25);
  margin: 0;
`;

export const ListItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 27%;
  height: 35rem;

  border: 1px solid var(--gray-25);
  border-radius: 5px;

  .textItens {
    font-size: 1rem;
    color: var(--blue-900);
    margin: 0.3rem;
  }
  @media only screen and (max-height: 850px) {
    height: 30rem;
  }
  @media only screen and (max-height: 768px) {
    height: 25rem;
  }
  @media only screen and (max-height: 620px) {
    height: 21rem;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0.4rem;
    width: 100%;
  }
`;

export const ListText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
`;

export const ContainerCards = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  padding: 0.3rem 0.4rem;
`;

export const ItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 5rem;

  border-bottom: 1px;
  border-top: 1px;
  border-color: var(--gray-25);
  border-style: solid;
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: 79%;

  padding: 1rem;

  .itemName {
    font-size: 1rem;
    font-weight: 500;
    color: var(--gray-45);

    text-transform: capitalize;
  }
  .itemQuantity {
    font-size: 1rem;
    font-weight: 500;
    color: var(--gray-48);
  }
`;

export const ItemImage = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: contain;
`;

export const ContainerItensInfo = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  padding: 0.4rem;

  width: 100%;
  height: 5rem;
  background: var(--white-25);

  .totalInfo {
    display: flex;
    flex-direction: column;
  }

  .totalCalc {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--gray-45);
    @media only screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

export const DeleteItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin: 0.5rem;

  color: var(--orange-600);

  span {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const TotalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-45);

  .totalCalc {
    font-size: 1.6rem;
    @media only screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 575px) {
    min-height: 7rem;
  }
`;

export const Total = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 1.7rem;
  @media only screen and (max-width: 500px) {
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonsContainer = styled.div``;

export const Input = styled(InputAnt)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;

  padding: 0.7rem;
  border: 1px solid var(--gray-25);
  border-radius: 0.3rem;
`;

export const ButtonSave = styled(ButtonAnt)`
  width: 10rem;
  height: 3.25rem;
  box-shadow: none;
  background: var(--orange-350);
  border-radius: 0.5rem;
  box-shadow: none;
  color: white;
  :hover,
  :focus {
    background: var(--maroon);
    color: white;
  }
  @media only screen and (max-width: 768px) {
    width: 7.5rem;
    padding: 0.1rem;
    font-size: 0.8rem;
  }
`;

export const Button = styled(ButtonAnt)`
  width: 10rem;
  box-shadow: none;
  background: none;
  border: none;
  color: var(--gray-530);
  :hover,
  :focus {
    color: var(--gray-530);
    opacity: 80%;
    background: none;
    border: none;
  }
  @media only screen and (max-width: 768px) {
    width: 6.5rem;
    font-size: 0.8rem;
  }
`;
export const Content = styled.div`
  .orderList {
    width: 100%;
    .ant-table-container {
      height: 15.5rem;
      @media only screen and (max-width: 1325px) {
        height: 10.5rem;
      }
    }
  }
`;

export const Row = styled(RowAnt)`
  width: 100%;
`;
export const Col = styled(ColAnt)``;

export const TextAreaComment = styled(TextArea)``;

export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.4rem;
  span {
    width: 15%;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--roof-terracotta);
    @media only screen and (max-width: 768px) {
      width: 100%;
      font-size: 0.9rem;
    }
  }
`;

export const LineDivider = styled.div`
  width: 85%;
  height: 0.2rem;
  background: var(--lilac-murmur);
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
