import { useEffect } from "react";

interface IProp{
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const useEscapeKey = ({ setVisible }: IProp): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setVisible(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setVisible]);
};

export default useEscapeKey;
