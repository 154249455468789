import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { getAuditType } from "../../../services/getAuditType";

import { Order as OrderModel } from "../../../models/Order/Order";
import api from "../../../services/api";

import { Audit as AuditModel } from "../../../models/Audit";

import Centralizer from "../../../containers/Centralizer";

import Spinner from "../../../components/Spinner";

import { notification, Empty } from "antd";

import {
  Container,
  ButtonCancel,
  ButtonChange,
  StatusChange,
  ArrowRightIcon,
  Table,
} from "./styles";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalHistoryOrderItens: React.FC<IProps> = ({
  setVisible,
  visible,
  order,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderOrItens, setOrderOrItens] = useState<boolean>(true);

  const [orderHistory, setOrderHistory] = useState<{
    orderHistory: AuditModel[];
    orderItemsHistory: AuditModel[];
  } | null>(null);

  useEffect(() => {
    const fetcHistory = async () => {
      setLoading(true);
      try {
        let { data } = await api.get(`/order_history/${order?.id}`);
        if (data) {
          setOrderHistory({
            orderHistory: data?.orderHistory,
            orderItemsHistory: data?.orderItemsHistory?.map(
              (orderItemsHistory) => ({
                ...orderItemsHistory,
                name: data?.orderItems?.find(
                  (orderItem) =>
                    orderItem?.id === orderItemsHistory?.order_items_id
                )?.products?.name,
              })
            ),
          });
        }

        notification.success({
          message: "Histórico localizado com sucesso.",
          duration: 5,
        });
      } catch (error) {
        //@ts-ignore
        const _description = error.message;
        notification.error({
          message: "Falha ao localizar histórico.",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    if (visible) {
      fetcHistory();
    }
  }, [visible, order]);

  const columnsOrderHistory = [
    {
      title: "Status",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <StatusChange oldvalue={record?.old_value} newvalue={record?.new_value}>
          {getAuditType(record?.old_value, record?.new_value)}
        </StatusChange>
      ),
    },
    {
      title: "Data / Hora",
      dataIndex: "created_at",
      key: "date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Responsável",
      dataIndex: "user.name",
      key: "user.name",
      render: (text, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Alteração",
      dataIndex: "field",
      key: "field",
      render: (text, record) => (
        <span>
          {record.old_value} <ArrowRightIcon /> {record.new_value}
        </span>
      ),
    },
  ];

  const columnsItensHistory = [
    {
      title: "Status",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <StatusChange
          oldvalue={+record?.old_value}
          newvalue={+record?.new_value}
        >
          {getAuditType(+record?.old_value, +record?.new_value)}
        </StatusChange>
      ),
    },
    {
      title: "Produto",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Data / Hora",
      dataIndex: "created_at",
      key: "date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Responsável",
      dataIndex: "user.name",
      key: "user.name",
      render: (text, record) => <span>{record?.user?.name}</span>,
    },
    {
      title: "Alteração",
      dataIndex: "field",
      key: "field",
      render: (text, record) => (
        <span>
          {text}: {record?.old_value} <ArrowRightIcon /> {record?.new_value}
        </span>
      ),
    },
  ];

  return (
    <Container
      title={orderOrItens ? "Histórico de Pedido" : "Histórico de Itens"}
      visible={visible}
      closable={true}
      onCancel={() => setVisible(false)}
      width={1100}
      footer={[
        <ButtonCancel
          onClick={() => {
            setVisible(false);
            setOrderOrItens(true);
          }}
        >
          Cancelar
        </ButtonCancel>,
        <ButtonChange onClick={() => setOrderOrItens(!orderOrItens)}>
          {!orderOrItens ? "Histórico de Pedido" : "Histórico de Itens"}
        </ButtonChange>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <>
          {orderOrItens ? (
            orderHistory?.orderHistory?.length ? (
              <Table
                loading={loading}
                columns={columnsOrderHistory}
                pagination={false}
                dataSource={orderHistory?.orderHistory}
                rowKey={(entity: any) => entity.id}
                scroll={{ y: 320 }}
              />
            ) : (
              <Centralizer>
                <Empty description="Nenhuma alteração de pedido encontrada" />
              </Centralizer>
            )
          ) : orderHistory?.orderItemsHistory?.length ? (
            <Table
              loading={loading}
              columns={columnsItensHistory}
              pagination={false}
              dataSource={orderHistory?.orderItemsHistory}
              rowKey={(entity: any) => entity.id}
              scroll={{ y: 320 }}
            />
          ) : (
            <Centralizer>
              <Empty description="Nenhuma alteração de itens encontrada" />
            </Centralizer>
          )}
        </>
      )}
    </Container>
  );
};

export default ModalHistoryOrderItens;
