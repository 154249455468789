import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import apiS3Handle from "../../services/apiS3Handler";

import FormCard from "../FormCard";
import MonetaryInput2 from "../MonetaryInput2";

import { CategoryModel } from "../../models/CategoryModel";
import { Product as ProductModel } from "../../models/Product";

import Centralizer from "../../containers/Centralizer";

import { Form, Select, Input, message, Spin, notification } from "antd";

import {
  Content,
  Col,
  Checkbox,
  StoreIcon,
  OrderIcon,
  Footer,
  CancelButton,
  SaveButton,
  ShopIcon,
  Modal,
  Row,
  TextUpload,
  UploadComp,
  ImgProduct,
} from "./styles";
import { enumTypeCarga } from "./enums/enumTypeCarga";

const { Option } = Select;

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  productEdit: ProductModel | null | undefined;
  categories?: CategoryModel[];
}
interface Files {
  path: string;
  name: string;
  size: number;
  type: string;
}

const ModalNewEditProduct: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  productEdit,
  categories,
}) => {
  const [product, setProduct] = useState<ProductModel | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [valuePriceSell, setValuePriceSell] = useState<number | null>();
  const [valuePriceSellPY, setValuePriceSellPY] = useState<number | null>();

  const [valuePriceBuy, setValuePriceBuy] = useState<number | null>();

  const [form] = Form.useForm();

  useEffect(() => {
    async function setFields() {
      if (productEdit) {
        setProduct(productEdit as ProductModel);

        form.setFieldsValue({
          ...productEdit,
        });
        setValuePriceBuy(productEdit?.price_buy);
        setValuePriceSell(productEdit?.price_sell);
        setValuePriceSellPY(productEdit?.price_sell_py);
      }
    }
    if (visible) {
      setFields();
    }
  }, [visible]);

  const handleState = (event) => {
    let { name, value } = event.target;

    setProduct((oldValues) => ({ ...oldValues, [name]: value }));
  };

  const clearFields = async () => {
    form.resetFields();
    setProduct(null);
    setUploadedFiles(null);
    setValuePriceBuy(null);
    setValuePriceSell(null);
    setValuePriceSellPY(null);
    setVisible(false);
  };

  const handleSubmit = async () => {
    const method = productEdit ? "put" : "post";
    const url = productEdit ? `/products/${product?.id}` : `/products`;

    if (
      valuePriceSell === 0.0 ||
      valuePriceBuy === 0.0 ||
      valuePriceSellPY === 0.0
    ) {
      notification.warning({
        message:
          "Os campos Valor de Venda e Valor de Custo devem ser preenchidos",
        duration: 5,
      });
      return;
    }

    form.setFieldsValue({
      price_sell: valuePriceSell,
      price_sell_py: valuePriceSellPY,
      price_buy: valuePriceBuy,
    });

    await form.validateFields();

    setLoading(true);
    try {
      const formatedProduct = {
        name: product?.name?.toLowerCase(),
        name_py: product?.name_py?.toLowerCase(),
        category_id: product?.category_id,
        price_buy: valuePriceBuy,
        permission_store: product?.permission_store,
        permission_order: product?.permission_order,
        permission_purchase: product?.permission_order
          ? false
          : product?.permission_purchase,
        cod_product: product?.cod_product,
        cod_product_py: product?.cod_product_py,
        cod_ncm: product?.cod_ncm ? product?.cod_ncm : null,
        brand: product?.brand,
        unity: product?.unity,
        weight: product?.weight,
        price_sell: valuePriceSell,
        price_sell_py: valuePriceSellPY,
        description: product?.description,
        recommended_product: product?.recommended_product,
        upload_url: product?.upload_url,
        s3_key: product?.s3_key,
        type_cargo: product?.type_cargo,
      };

      const oldKey = product?.s3_key;

      if (uploadedFiles) {
        setLoadingUpload(true);
        const imgUpload = new FormData();

        imgUpload.append("file", uploadedFiles);

        const {
          data: { location, key },
        } = await apiS3Handle.post(
          `/s3-upload/upload/upload-services`,
          imgUpload
        );

        formatedProduct.upload_url = location;
        formatedProduct.s3_key = key;
        setLoadingUpload(false);
      }

      await api[method](url, formatedProduct);
      message.success(
        productEdit
          ? "Produto atualizado com sucesso!"
          : "Produto cadastrado com sucesso!"
      );

      if (uploadedFiles && method === "put" && product?.s3_key) {
        try {
          await apiS3Handle.delete(`/s3-upload/upload-services/${oldKey}`);
        } catch (error) {
          const _message =
            //@ts-ignore
            error?.response?.data?.message;
          return message.error(_message || "Falha ao adicionar imagem");
        }
      }

      setShouldSearch(true);
      setLoading(false);
      clearFields();
    } catch (error) {
      const _message =
        //@ts-ignore
        error?.response?.data?.error?.message;
      return message.error(
        _message || "Um erro ocorreu, tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (files: Files[]): void => {
    setUploadedFiles(files[0]);
  };

  const enumValues = Object.values(enumTypeCarga).filter(
    (value) => typeof value === "number"
  );

  return (
    <Modal
      title={productEdit ? "Editar Produto" : "Cadastrar Produto"}
      centered
      visible={visible}
      onCancel={() => clearFields()}
      closable={false}
      destroyOnClose={true}
      width={"90%"}
      footer={[
        <React.Fragment key={productEdit?.id || 0}>
          <Footer>
            <Form.Item style={{ marginBottom: 0 }}>
              <CancelButton onClick={() => clearFields()}>
                Cancelar
              </CancelButton>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <SaveButton htmlType="submit" onClick={() => handleSubmit()}>
                Salvar
              </SaveButton>
            </Form.Item>
          </Footer>
        </React.Fragment>,
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <React.Fragment key={productEdit?.id || 0}>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            form={form}
          >
            <Content>
              <FormCard>
                <Row gutter={8}>
                  <Col sm={5} xs={24}>
                    <Form.Item
                      label="Nome do produto - BR"
                      name="name"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        style={{ textTransform: "capitalize" }}
                        onChange={handleState}
                        type="text"
                        name="name"
                        placeholder="Nome do produto - BR"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={24}>
                    <Form.Item
                      label="Nome do produto - PY"
                      name="name_py"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <Input
                        style={{ textTransform: "capitalize" }}
                        onChange={handleState}
                        type="text"
                        name="name_py"
                        placeholder="Nome do produto - PY"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={12}>
                    <Form.Item
                      label="Código do produto - BR"
                      name="cod_product"
                    >
                      <Input
                        onChange={handleState}
                        type="text"
                        name="cod_product"
                        placeholder="Código do produto Brasil"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={12}>
                    <Form.Item
                      label="Código do produto - PY"
                      name="cod_product_py"
                    >
                      <Input
                        onChange={handleState}
                        type="text"
                        name="cod_product_py"
                        placeholder="Código do produto Paraguai"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={4} xs={12}>
                    <Form.Item
                      label="Código do NCM"
                      name="cod_ncm"
                      rules={[
                        { required: true, message: "Campo obrigatório" },
                        {
                          pattern: /^\d+$/,
                          message:
                            "Esse campo só aceita números inteiros positivos",
                        },
                      ]}
                    >
                      <Input
                        onChange={handleState}
                        type="number"
                        name="cod_ncm"
                        placeholder="Código do produto"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col sm={6} xs={24}>
                    <Form.Item label="Seleções">
                      <Select
                        placeholder="Selecione Aqui"
                        value={selectedValue}
                        onChange={(value: string) => setSelectedValue(value)}
                      >
                        <Select.Option key={1} value={1}>
                          <Checkbox
                            onClick={() =>
                              setProduct((oldValues) => ({
                                ...oldValues,
                                permission_store: !oldValues?.permission_store,
                              }))
                            }
                            checked={product?.permission_store}
                            name="permission_store"
                          />
                          <span>Loja</span>
                          <StoreIcon />
                        </Select.Option>

                        <Select.Option key={2} value={2}>
                          <Checkbox
                            onClick={() =>
                              setProduct((oldValues) => ({
                                ...oldValues,
                                permission_order: !oldValues?.permission_order,
                              }))
                            }
                            checked={product?.permission_order}
                            name="permission_order"
                          />
                          <span>Pedido</span> <OrderIcon />
                        </Select.Option>

                        <Select.Option key={3} value={3}>
                          <Checkbox
                            onClick={() =>
                              setProduct((oldValues) => ({
                                ...oldValues,
                                permission_purchase:
                                  !oldValues?.permission_purchase,
                              }))
                            }
                            checked={product?.permission_purchase}
                            name="permission_purchase"
                          />
                          <span>Compra</span> <ShopIcon />
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={24}>
                    <Form.Item
                      label="Valor de venda - BR"
                      name="price_sell"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <MonetaryInput2
                        defaultValue={valuePriceSell}
                        getValue={(value) => setValuePriceSell(+value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={24}>
                    <Form.Item
                      label="Valor de venda - PY"
                      name="price_sell_py"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <MonetaryInput2
                        currency="USD"
                        defaultValue={valuePriceSellPY}
                        getValue={(value) => setValuePriceSellPY(+value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={3} xs={24}>
                    <Form.Item label="Peso" name="weight">
                      <Input
                        onChange={handleState}
                        type="number"
                        name="weight"
                        placeholder="Peso KG"
                        value={product?.weight}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={5} xs={24}>
                    <Form.Item
                      label="Valor de custo"
                      name="price_buy"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      <MonetaryInput2
                        defaultValue={valuePriceBuy}
                        getValue={(value) => setValuePriceBuy(+value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col sm={6} xs={24}>
                    <Form.Item
                      label="Qtd Recomendada"
                      name="recommended_product"
                    >
                      <Input
                        onChange={handleState}
                        name="recommended_product"
                        type="number"
                        placeholder="Qtd Recomendada"
                        value={product?.recommended_product}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={6} xs={24}>
                    <Form.Item
                      label="Categoria"
                      name="category_id"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      {
                        <Select
                          loading={loading}
                          onChange={(category) =>
                            setProduct((oldProps) => ({
                              ...oldProps,
                              category_id: +category,
                            }))
                          }
                        >
                          {categories
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.map((category) => (
                              <Option key={category.id} value={category.id}>
                                {category.name}
                              </Option>
                            ))}
                        </Select>
                      }
                    </Form.Item>
                  </Col>

                  <Col sm={12} xs={24}>
                    <Form.Item label="Descrição" name="description">
                      <Input
                        onChange={handleState}
                        name="description"
                        placeholder="Descrição"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={6} xs={24}>
                    <Form.Item
                      label="Carga"
                      name="type_cargo"
                      rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                      {
                        <Select
                          placeholder="Selecione o tipo da carga"
                          onChange={(carga) =>
                            setProduct((oldProps) => ({
                              ...oldProps,
                              type_cargo: +carga,
                            }))
                          }
                        >
                          {enumValues.map((value) => (
                            <Option key={value} value={value}>
                              {enumTypeCarga[value]}
                            </Option>
                          ))}
                        </Select>
                      }
                    </Form.Item>
                  </Col>
                </Row>
                <TextUpload>Imagem do produto</TextUpload>
                <Row gutter={8}>
                  <Col sm={24} xs={24}>
                    {loadingUpload ? (
                      <Centralizer>
                        <Spin />
                      </Centralizer>
                    ) : (
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                        gutter={4}
                      >
                        {product?.upload_url && (
                          <Col sm={4} xs={24} style={{ minWidth: "12rem" }}>
                            <ImgProduct
                              src={product?.upload_url}
                              alt="Imagem do Produto"
                            />
                          </Col>
                        )}

                        <Col xxl={20} xl={18} sm={16} xs={24}>
                          <UploadComp
                            onUpload={handleUpload}
                            sizeFiles={25}
                            fileName={uploadedFiles?.name}
                          ></UploadComp>{" "}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </FormCard>
            </Content>
          </Form>
        </React.Fragment>
      )}
    </Modal>
  );
};

export default ModalNewEditProduct;
