import styled from 'styled-components';
import { Modal as ModalAnt, Table as TableAnt, Input as InputAnt, DatePicker as DatePickerAnt } from 'antd'

import { Edit } from '../../../../styles/Icons'

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Modal = styled(ModalAnt)``;

export const DatePicker = styled(DatePickerAnt)``;

export const Input = styled(InputAnt)`
    border: none;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-size: 1.2rem;
    }

    .title {
        color: var(--orange-350);
    }

    .content {
        display: flex;
        align-items: baseline;

        span {
            margin-left: 10px;
        }
    }
`;

export const Body = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

export const Table = styled(TableAnt)`
    .ant-table-container {
        border-radius: 5px;
        border: 1px solid var(--gray-25);
    }
`;

export const ContentTotal = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 1rem;
    margin-top: 1rem;
    width: 100%;
    border:1px solid var(--gray-25);
    border-radius: 5px;
`;

export const ContentEdit = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

export const EditIcon = styled(Edit)`
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .5rem;
    color: var(--orange-350);

    cursor: pointer;
`;

export const CapitalizeSpan = styled.span`
    text-transform: capitalize;
`;