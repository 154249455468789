import styled, { css } from 'styled-components';
import { InfoCircle, Truck, CirclePlay, User, RouteRound, Warning, PauseCircleOutline, Cancel, Check2 } from '../../styles/Icons'
import { Table as TableAnt } from 'antd'

export const Container = styled.div`
  display: flex;
  width: 100%;
  
  .canceledRoute {
    background-color: rgba(251, 203, 203, 0.69); 
  }

  .finishedRoute {
    background-color: #E3FFDE; 
  }
`;

export const TextCapitalize = styled.span`
    text-transform: capitalize;
`;

export const Table = styled(TableAnt)`
    .ant-table-container {
        border-radius: 5px;
        border: 1px solid var(--gray-25);
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0 0 5px !important;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 5px 0 0  !important;
    }

    .ant-table-thead > tr > th {
        background: transparent !important;
}
`;

export const CenterRow = styled.span`
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

const Icon = css`
    width: 1.1rem;
    height: 1.1rem;
    color: green;
    
    &:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export const DivIcons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const InfoIcon = styled(InfoCircle)`
    ${Icon}
    cursor: pointer;
`;

export const UserIcon = styled(User)`
    ${Icon}
`;

export const CancelIcon = styled(Cancel)`
    ${Icon}
`;

export const RouteRoundIcon = styled(RouteRound)`
    ${Icon}
`;

export const PauseIcon = styled(PauseCircleOutline)`
    ${Icon}
`;

export const WarningIcon = styled(Warning)`
    ${Icon}
`;
export const CheckIcon = styled(Check2)`
    ${Icon}
`;

export const TruckIcon = styled(Truck)`
    ${Icon}
`;

export const PlayIcon = styled(CirclePlay)`
    ${Icon}
    width: 1rem;
    height: 1rem;
`;

