import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Empty, Input, Row, Table } from "antd";
import {
  Container,
  ContentModalBody,
  Modal,
  ModalTitle,
  NameContent,
} from "./styles";
import moment from "moment";
import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";
interface IProps {
  loading: boolean;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  auditProducts: any[];
}

enum productStats {
  "price-sell" = "Valor alterado em",
  quantity = "Quantidade alterada de",
  updated_at = "Alterado em",
  deleted_at = "Deletado em",
  reason = "Razão da exclusão alterada de",
}

const ModalWasteAuditoria: React.FC<IProps> = ({
  visible,
  setVisible,
  loading,
  auditProducts,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const searchValueItem = removeAccentsAndLowerCase(searchValue);

  const filteredAuditProducts = auditProducts.filter((item) =>
    item.product_name && searchValueItem
      ? removeAccentsAndLowerCase(item.product_name).includes(searchValueItem)
      : true
  );

  const column = [
    {
      title: "ID do produto",
      dataIndex: "product_waste_id",
      key: "product_waste_id",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Nome do produto",
      dataIndex: "product_name",
      key: "product_name",
      render: (text) => {
        return <NameContent>{text}</NameContent>;
      },
    },
    {
      title: "Data / Hora",
      dataIndex: "created_at",
      responsive: ["md"] as any,
      key: "created_at",
      render: (text) => {
        return (
          <span>
            {" "}
            {`${moment(text, "DD-MM-YYYY HH:mm:ss")
              .subtract(3, "hours")
              .format("DD/MM/YYYY HH:mm:ss")}`}{" "}
          </span>
        );
      },
    },
    {
      title: "Responsável",
      dataIndex: "user_name",
      key: "user_name",
      render: (text) => <NameContent>{text}</NameContent>,
    },
    {
      title: "Alteração",
      dataIndex: "field",
      key: "field",
      width: "33%",
      render: (field, record) => {
        const label = productStats[field as keyof typeof productStats] || field;
        const formatValue = (value: string) => {
          if (field === "price-sell") {
            return `R$ ${Number(value).toFixed(2)}`;
          } else if (field === "quantity" || field === "reason") {
            return `${record?.old_value} -> ${record?.new_value}`;
          } else {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          }
        };

        const oldValue =
          field === "price-sell" ? formatValue(record?.old_value) : "";
        const newValue = formatValue(record?.new_value);

        return (
          <span>{`${label}: ${
            oldValue ? `${oldValue} -> ` : ""
          }${newValue}`}</span>
        );
      },
    },
  ];

  return (
    <Modal
      title={<ModalTitle>Auditoria de desperdício</ModalTitle>}
      destroyOnClose={true}
      visible={visible}
      closable={false}
      onCancel={() => setVisible(false)}
      width={"70%"}
      centered
      footer={null}
    >
      <ContentModalBody>
        <Container>
          <Row>
            <Col xs={24}>
              <Input
                name="search"
                placeholder="Digite o nome do produto"
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ marginBottom: "1rem" }}
              />
            </Col>
          </Row>
          {auditProducts ? (
            <Table
              loading={loading}
              columns={column}
              pagination={false}
              dataSource={filteredAuditProducts}
              rowKey={(entity: any) => entity.id}
              scroll={{ y: 320 }}
            />
          ) : (
            <Empty description="Não há histórico" />
          )}
        </Container>
      </ContentModalBody>
    </Modal>
  );
};

export default ModalWasteAuditoria;
