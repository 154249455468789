import styled from 'styled-components';
import { Modal as ModalAnt, Table as TableAnt } from 'antd'

export const Modal = styled(ModalAnt)``;

export const OrangeSpan = styled.span`
    color: var(--orange-350);
    text-transform: capitalize;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Body = styled.div`
    width: 100%;
    margin-top: 1rem;
`;

export const Table = styled(TableAnt)`
    .ant-table-container {
        border-radius: 5px;
        border: 1px solid var(--gray-25);
    }

    .ant-table-tbody > tr:nth-child(odd) {
        background-color: var(--gray-100);
    }
`;