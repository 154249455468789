import styled from "styled-components";
import { Col as ColAnt, Collapse as CollapseAnt } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.label`
  font-size: 18px;
  @media only screen and (max-width: 578px) {
    margin-left: 0px !important;
  }
`;

export const Description = styled.label`
  font-size: 20px;
`;

export const Col = styled(ColAnt)``;

export const Collapse = styled(CollapseAnt)`
  width: 100%;
  background: white;
  margin: 6px 0;
  border: 1px solid #ff8c2aa1;
  div.ant-collapse-header {
    :hover {
      border-left: 5px solid var(--primary);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }
`;
