import React, { Dispatch, SetStateAction, useState } from "react";

import Upload from "../../../components/Upload";

import { Order as OrderModel } from "../../../models/Order/Order";

import api from "../../../services/api";

import { notification } from "antd";

import { Container, ButtonCancel, ButtonSave } from "./styles";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalUploadImageOrder: React.FC<IProps> = ({
  order,
  setVisible,
  visible,
  setShouldSearch,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload = (files: File[]): void => {
    if (uploadedFiles.length + files.length > 10) {
      return notification.warning({
        message: "Limite máximo de imagens excedido",
        description: "O limite de imagens vinculadas no pedido é de até 10.",
        duration: 5,
      });
    }

    const sameNames = uploadedFiles.filter((uploadedFile) =>
      files.some((file) => file.name === uploadedFile.name)
    );

    if (sameNames.length) {
      return notification.warning({
        message: "Arquivo já selecinado",
        description: "A imagem selecionada já existe na sua lista de upload.",
        duration: 5,
      });
    }
    setUploadedFiles((oldValues) => [...oldValues, ...files]);
  };

  const processUpload = async (uploadedFile) => {
    try {
      setLoading(true);

      const data = new FormData();
      data.append("file", uploadedFile);
      const {
        data: { location },
      } = await api.post("/upload-file", data);

      await api.post(`/order/${order?.id}/upload`, {
        url_file: location,
      });

      notification.success({
        message: `${"Imagem enviada com sucesso"}`,
        description: `${`A imagem selecionada foi vinculda com sucesso ao pedido ${order?.vhsys}`}`,
        duration: 5,
      });

      setShouldSearch(true);
    } catch (error) {
      //@ts-ignore
      const _description = error.message;
      notification.error({
        message: "Oops! Não foi possível realizar upload da imagem",
        description: error
          ? _description
          : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
        duration: 5,
      });
    } finally {
      setLoading(false);
      setVisible(false);
    }
  };

  const handleUploadAdd = async () => {
    uploadedFiles.forEach(processUpload);
  };

  return (
    <Container
      title={`Envio de imagem`}
      visible={visible}
      centered
      destroyOnClose={true}
      closable={false}
      footer={[
        <ButtonCancel onClick={() => setVisible(false)}>Cancelar</ButtonCancel>,
        <ButtonSave
          disabled={!uploadedFiles.length || loading}
          loading={loading}
          onClick={() => handleUploadAdd()}
        >
          Enviar
        </ButtonSave>,
      ]}
    >
      {uploadedFiles.length !== 10 && (
        <Upload onUpload={handleUpload} maxFiles={10} />
      )}

      <div className="upload-container">
        {uploadedFiles.length > 0 &&
          uploadedFiles.map((uploadedFile, index) => (
            <div key={index} className="upload-file">
              <span>{uploadedFile.name}</span>

              <button
                onClick={() =>
                  setUploadedFiles((oldValues) =>
                    oldValues.filter(
                      (oldValue) => oldValue.name !== uploadedFile.name
                    )
                  )
                }
              >
                remover
              </button>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default ModalUploadImageOrder;
