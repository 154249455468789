import React, { useEffect, useState } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { useStoreData } from "../../hooks/useStoreData";

import { getTokenInfo, verifyPermission } from "../../services/auth";
import apiMercury from "../../services/apiMercury";
import api from "../../services/api";
import allMonth from "../../services/allMonth";
import apiS3Handle from "../../services/apiS3Handler";

import { CategoryModel } from "../../models/CategoryModel";
import { RoyaltyType } from "../../models/enums/royaltyType";
import RoyaltiesType from "../../models/json/RoyaltiesType.json";
import { RoyaltFile, Royalty as RoyaltyModel } from "../../models/Royalty";
import { Page } from "../../models/Page";

import GetStore from "../../components/GetStore";
import { Spin } from "../../components/Spinner/styles";
import StoreSelectionPage from "../../components/StoreSelectionPage";
import MonetaryInput2 from "../../components/MonetaryInput2";
import Upload from "../../components/Upload";
import DisabledFlag from "../../components/DisabledFlag";

import PageContainer from "../../containers/PageContainer";
import RoyaltiesList from "../../containers/RoyaltiesList";
import Centralizer from "../../containers/Centralizer";
import { currencyFormater } from "../../services/currencyFormater";

import {
  Form,
  DatePicker,
  Empty,
  Modal,
  notification,
  Tooltip,
  Checkbox,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";

import "moment/locale/pt-br";
import moment from "moment";

import RoyaltyImg from "../../assets/svg/royalt.svg";
import spin from "../../assets/svg/spin.svg";
import { SelectValue } from "antd/lib/select";

import {
  Container,
  Content,
  Row,
  Col,
  AddButton,
  AddIcon,
  Input,
  Select,
  SaveButton,
  ContentModal,
  ColModal,
  RowModal,
  ModalRegister,
  ButtonCancelModal,
  ModalTitle,
  UploadContainer,
  UploadContent,
  TotalPaymentContent,
  Section,
  ContainerPagination,
} from "./styles";
import Pagination from "../../components/Pagination";

const { confirm } = Modal;
const { Option } = Select;

interface NewRoyalty {
  type: number;
  cod_bankslip: string;
  value_bankslip: number;
  month: string;
  cod_nf?: string;
  cod_vhsys?: string;
  expiration_date: string;
  uploadFiles?: File[];
}

interface ComponentProps extends RouteComponentProps {}

const Royalty: React.FC<ComponentProps> = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [store, setStore] = useState<number | null | undefined>(null);
  const [valueBanksLip, setValueBanksLip] = useState<number | undefined>();
  const [newList, setNewList] = useState<NewRoyalty[]>([
    {
      type: 0,
      cod_bankslip: "",
      value_bankslip: 0,
      month: "",
      cod_nf: "",
      cod_vhsys: "",
      expiration_date: "",
      uploadFiles: [],
    },
  ]);
  const [editRoyalty, setEditRoyalty] = useState<RoyaltyModel | undefined>(
    undefined
  );
  const [royalty, setRoyalty] = useState<RoyaltyModel[]>([]);
  const [categoryProduct, setCategoryProduct] = useState<CategoryModel>();
  const [selectedType, setSelectedType] = useState<SelectValue | undefined>(
    undefined
  );
  const [orderByIdDesc, setOrderByIdDesc] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 30,
    totalElements: 0,
  });

  const { isStoreActive } = useStoreData(store);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchRoyalties = async () => {
      if (!store) return;

      setLoading(true);
      try {
        const typeFilter =
          selectedType !== undefined ? `&type=${selectedType}` : "";
        const orderByFilter = orderByIdDesc ? `&orderByIdDesc=true` : "";
        const response = await api.get(
          `/royalty/${store}?page=${paginate.page}&size=${paginate.size}${typeFilter}${orderByFilter}`
        );
        if (response.data && response.data.data) {
          const { data, totalElements } = response.data;
          setRoyalty(data);
          setPaginate((oldValues) => ({ ...oldValues, totalElements }));
        } else {
          setRoyalty([]);
          notification.warning({
            message: "Nenhum dado encontrado",
            duration: 5,
          });
        }
      } catch (error) {
        const errorMsg =
          //@ts-ignore
          error?.response?.data?.error?.message ||
          "Erro ao carregar a lista de royalty";
        notification.error({
          message: errorMsg,
          duration: 5,
        });
      } finally {
        setShouldSearch(false);
        setLoading(false);
      }
    };

    fetchRoyalties();
  }, [
    store,
    selectedType,
    paginate.page,
    paginate.size,
    orderByIdDesc,
    shouldSearch,
  ]);

  const handleTypeChange = (value: SelectValue) => {
    setSelectedType(value);
    setPaginate((oldPaginate) => ({ ...oldPaginate, page: 1 }));
  };

  useEffect(() => {
    const setEditRoyalty = async () => {
      if (editRoyalty) {
        setNewList([
          {
            type: editRoyalty.type,
            cod_bankslip: editRoyalty.cod_bankslip,
            value_bankslip: +editRoyalty.value_bankslip,
            month: editRoyalty.month,
            cod_nf: editRoyalty.cod_nf,
            cod_vhsys: editRoyalty.cod_vhsys,
            expiration_date: editRoyalty.expiration_date,
          },
        ]);
        setValueBanksLip(+editRoyalty.value_bankslip);
        await form.setFieldsValue({
          type0: editRoyalty.type,
          cod_bankslip0: editRoyalty.cod_bankslip,
          month0: editRoyalty.month,
          cod_nf0: editRoyalty.cod_nf,
          cod_vhsys0: editRoyalty.cod_vhsys,
          expiration_date0: moment(
            editRoyalty?.expiration_date,
            "DD-MM-YYYY hh:mm:ss"
          ).isValid()
            ? moment(editRoyalty?.expiration_date, "DD-MM-YYYY hh:mm:ss")
            : "",
        });
      }
    };
    if (editRoyalty && visible) {
      setEditRoyalty();
    }
  }, [editRoyalty, visible]);

  const getType = (type) => {
    if (type === RoyaltyType.Royalty) return "Royalty";
    else if (type === RoyaltyType.Marketing) return "Marketing";
    else if (type === RoyaltyType.Sistema) return "Sistema";
    else if (type === RoyaltyType.Serviço) return "Serviço";
    else return "";
  };
  const getTypePurchase = (type) => {
    if (type === RoyaltyType.Royalty) return "royalties";
    else if (type === RoyaltyType.Marketing) return "marketing";
    else if (type === RoyaltyType.Sistema) return "sistema";
    else if (type === RoyaltyType.Serviço) return "serviço";
    else return "";
  };

  const clearFields = async () => {
    setNewList([
      {
        type: 0,
        cod_bankslip: "",
        value_bankslip: 0,
        month: "",
        cod_nf: "",
        cod_vhsys: "",
        expiration_date: "",
        uploadFiles: [],
      },
    ]);
    setEditRoyalty(undefined);
    setVisible(false);
    setValueBanksLip(undefined);
  };

  const handleCreate = async () => {
    const user = await getTokenInfo();
    await form.validateFields();

    setLoading(true);
    try {
      const method = editRoyalty ? "put" : "post";
      const url = editRoyalty ? `/royalty/${editRoyalty.id}` : "/royalty";

      await Promise.all(
        newList.map(async (item) => {
          let attachments: any = [];
          if (item.uploadFiles) {
            attachments = await Promise.all(
              item.uploadFiles.map(async (uploadedFile) => {
                const imageToUpload = new FormData();
                imageToUpload.append("file", uploadedFile);

                const {
                  data: { location, key },
                } = await apiS3Handle.post(
                  `/s3-upload/upload/upload-services`,
                  imageToUpload
                );
                return {
                  url_file: location,
                  s3_key: key,
                };
              })
            );
          }

          let _payload = {
            ...item,
            expiration_date: moment(
              item?.expiration_date,
              "DD-MM-YYYY HH:mm:ss"
            )?.toISOString(),
          };

          if (item.type === RoyaltyType.Serviço) {
            delete _payload.cod_nf;
          } else {
            delete _payload.cod_vhsys;
          }

          await api[method](url, {
            ..._payload,
            store_id: store,
            user_id: user?.id,
            royaltyFiles: attachments,
          });

          if (!!editRoyalty?.purchase_id) {
            const productIdFind = categoryProduct?.products?.find(
              (product) => product.name === getTypePurchase(item?.type)
            )?.id;

            const payload = {
              store_id: editRoyalty?.store_id,
              user_id: editRoyalty?.user_id,
              name: getTypePurchase(item?.type),
              pay_date: moment(
                editRoyalty?.pay_date,
                "DD-MM-YYYY HH:mm:ss"
              )?.toISOString(),
              created_at: moment(
                editRoyalty?.created_at,
                "DD-MM-YYYY HH:mm:ss"
              )?.toISOString(),
              due_date: moment(
                editRoyalty?.expiration_date,
                "DD-MM-YYYY HH:mm:ss"
              )?.toISOString(),
              total: item?.value_bankslip,
              payment_method: 0,
              purchasesItems: [
                {
                  category_id: categoryProduct?.id,
                  key: 1,
                  observation: "Importado de Royalt/Marketing",
                  product_id: productIdFind,
                  quantity: 1,
                  unitary_value: item?.value_bankslip,
                },
              ],
            };

            await apiMercury.put(
              `/purchases/${editRoyalty?.purchase_id}`,
              payload
            );
          }

          notification.success({
            message: editRoyalty?.id
              ? `${getType(item.type)} atualizado com sucesso`
              : `${getType(item.type)} cadastrado com sucesso`,
            duration: 5,
          });
        })
      );

      setShouldSearch(true);
      clearFields();
    } catch (error) {
      const _message =
        //@ts-ignore
        error?.response?.data?.message;
      return notification.warning({
        message: _message || "Preencha os campos corretamente",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async (royalty) => {
    if (royalty?.url_file) {
      try {
        const { data } = await apiS3Handle.get(
          `/s3-upload/find/upload-services/${royalty.s3_key}`
        );

        const fileName = `${getFileName(
          royalty.url_file
        )}.${getExtensionFromContentType(data.contentType)}`;
        const link = document.createElement("a");
        link.href = "data:application/octet-stream;base64," + data.base64;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        notification.error({
          message: error || "Falha ao gerar arquivo.",
          duration: 5,
        });
      }
    } else {
      return notification.warning({
        message: `O ${getType(
          royalty.type
        )} selecionado não possui boleto vinculado.`,
        duration: 5,
      });
    }
  };

  const getFileName = (url: string) => url;

  const getExtensionFromContentType = (contentType) => {
    const mimeTypes = {
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/gif": "gif",
      "application/pdf": "pdf",
      "application/octet-stream": "rar",
      "application/zip": "zip",
    };

    return mimeTypes[contentType] || "file";
  };

  const handleDelete = async (royalty: RoyaltyModel) => {
    setLoading(true);

    confirm({
      title: "Excluir Royalt/Marketing",
      className: "modalDelete",
      content: "Você deseja excluir este Royalt/Marketing?",

      async onOk() {
        try {
          if (royalty?.s3_key) {
            await apiS3Handle.delete(
              `/s3-upload/upload-services/${royalty.s3_key}`
            );
          }
          if (royalty?.purchase_id) {
            await apiMercury.delete(`/purchases/${royalty?.purchase_id}`);
          }

          await api.delete(`/royalty/${royalty?.id}`);
          notification.success({
            message: `${getType(royalty.type)} excluído com sucesso`,
            duration: 5,
          });

          setShouldSearch(true);
        } catch (error) {
          const _message =
            //@ts-ignore
            error?.response?.data?.message;
          notification.error({
            message: _message || "Erro ao excluir Royalty",
            duration: 5,
          });
        }
      },
    });

    setLoading(false);
  };

  const handleUpload = (files: File[], index: number): void => {
    let _newList = [...newList];

    const currentFiles = _newList[index].uploadFiles || [];

    if (currentFiles.length + files.length > 3) {
      return notification.warning({
        message: "Limite máximo de imagens excedido",
        description: "O limite de imagens vinculadas é de no máximo 3.",
        duration: 5,
      });
    }

    const sameNames = currentFiles.filter((uploadedFile) =>
      files.some((file) => file.name === uploadedFile.name)
    );

    if (sameNames.length) {
      return notification.warning({
        message: "Arquivo já selecinado",
        description: "A imagem selecionada já existe na sua lista de upload.",
        duration: 5,
      });
    }

    _newList[index].uploadFiles = [...currentFiles, ...files];
    setNewList(_newList);
  };

  const handleRemoveFile = (removeFile: File, index: number): void => {
    let _newList = [...newList];

    const currentFiles = _newList[index].uploadFiles || [];

    const updatedFiles = currentFiles.filter(
      (file) => file.name !== removeFile.name
    );

    _newList[index].uploadFiles = updatedFiles;

    setNewList(_newList);
  };

  const handleDeleteUpload = async (royaltyFile: RoyaltFile | undefined) => {
    setLoading(true);

    confirm({
      title: "Excluir Upload",
      className: "modalDelete",
      content: "Você deseja excluir este boleto",
      async onOk() {
        setLoadingDelete(true);
        try {
          if (royaltyFile?.s3_key) {
            await apiS3Handle.delete(
              `/s3-upload/upload-services/${royaltyFile.s3_key}`
            );
          }

          await api.delete(`/royalty_files/${royaltyFile?.id}`);

          notification.success({
            message: "Boleto excluido com sucesso",
            duration: 5,
          });
          setShouldSearch(true);
        } catch (error) {
          const _message =
            //@ts-ignore
            error?.response?.data?.message;
          notification.error({
            message: _message || "Erro ao excluir Boleto",
            duration: 5,
          });
        } finally {
          setLoadingDelete(false);
        }
      },
    });

    setLoading(false);
  };

  const totalPayable = royalty.reduce((total, item) => {
    const validPayments = item.royalty_payments.filter(
      (payment) => payment.deleted_at === null
    );

    const totalPayment = validPayments.reduce((acc, payment) => {
      return acc + parseFloat(payment.payment_value);
    }, 0);

    const remainingBalance = parseFloat(item.value_bankslip) - totalPayment;

    return total + remainingBalance;
  }, 0);

  const addRow = () => {
    const _newRow = {
      type: 0,
      cod_bankslip: "",
      value_bankslip: 0,
      month: "",
      cod_nf: "",
      cod_vhsys: "",
      expiration_date: "",
      uploadFiles: [],
    };

    setNewList((oldValues) => [...oldValues, _newRow]);
  };

  const changeValue = (value: any, field: string, index: number) => {
    let _newList = [...newList];
    _newList[index] = { ..._newList[index], [field]: value };

    setNewList(_newList);
  };

  const removeRow = () => {
    let removeList = [...newList];
    if (removeList.length !== 1) {
      removeList.pop();
    }

    setNewList([...removeList]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { productsFranchise },
      } = await api.get(
        `/product_categories/productFranchise?name=servi%C3%A7os%20franqueadora`
      );

      setCategoryProduct(productsFranchise);
    };

    fetchData();
  }, []);

  return (
    <PageContainer route="Royalty e Marketing">
      {!store ? (
        <StoreSelectionPage
          title="Para listar ou cadastrar"
          Img={RoyaltyImg}
          store={store}
          setStore={setStore}
          setShouldSearch={setShouldSearch}
        ></StoreSelectionPage>
      ) : (
        <Container>
          {loading ? (
            <Centralizer>
              <Spin src={spin} />
            </Centralizer>
          ) : (
            <Content>
              <Row gutter={10}>
                {!isStoreActive ? (
                  <>
                    <Col sm={8} xs={24}>
                      <Checkbox
                        onChange={(e) => setOrderByIdDesc(e.target.checked)}
                        checked={orderByIdDesc}
                      >
                        Ordenar por data de criação
                      </Checkbox>
                    </Col>

                    <Col sm={4} xs={24}>
                      <Select
                        placeholder="Filtrar por tipo"
                        onChange={handleTypeChange}
                        value={selectedType}
                        style={{ width: "100%" }}
                        allowClear={true}
                      >
                        {RoyaltiesType.map((types) => (
                          <Option
                            value={types.value}
                            key={types.value}
                            label={types.label}
                          >
                            {types.label}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </>
                ) : (
                  <Col sm={20} xs={24}>
                    <DisabledFlag isStoreActive={isStoreActive} />
                  </Col>
                )}

                <Col sm={4} xs={24}>
                  <GetStore
                    defaultValue={store}
                    handleChange={(id) => {
                      setStore(id);
                      setShouldSearch(true);
                      setSelectedType(undefined);
                      setPaginate({ page: 1, size: 30, totalElements: 0 });
                    }}
                  />
                </Col>
                {!isStoreActive && (
                  <>
                    <Col sm={3} xs={24}>
                      {verifyPermission("royalty.add") && (
                        <Tooltip title={"Adicionar novo Royalty ou Marketing"}>
                          <AddButton onClick={() => setVisible(true)}>
                            <AddIcon /> <span> Adicionar</span>
                          </AddButton>
                        </Tooltip>
                      )}
                    </Col>
                  </>
                )}
              </Row>
              {loading ? (
                <Centralizer>
                  <Spin src={spin} />
                </Centralizer>
              ) : (
                <>
                  {royalty.length !== 0 ? (
                    <>
                      <div>
                        <TotalPaymentContent>
                          <span>
                            Total a pagar: R$ {currencyFormater(totalPayable)}
                          </span>
                        </TotalPaymentContent>
                        <RoyaltiesList
                          setShouldSearch={setShouldSearch}
                          handleDelete={handleDelete}
                          setEditRoyalty={setEditRoyalty}
                          setVisible={setVisible}
                          royalty={royalty}
                          loading={loading}
                          setStateSearch={setShouldSearch}
                          handleDeleteUpload={handleDeleteUpload}
                          handleDownload={handleDownloadFile}
                          categoryProduct={categoryProduct}
                          isStoreActive={isStoreActive}
                        ></RoyaltiesList>
                      </div>
                      <ContainerPagination>
                        <Pagination
                          setStateSearch={setShouldSearch}
                          setPaginate={setPaginate}
                          defaultPageSize={30}
                          showSizeChanger={false}
                          current={paginate.page}
                          totalElements={paginate.totalElements}
                        />
                      </ContainerPagination>
                    </>
                  ) : (
                    <Centralizer>
                      <Empty description="Nenhum royalty encontrado" />
                    </Centralizer>
                  )}
                </>
              )}
            </Content>
          )}
          <ModalRegister
            title={
              editRoyalty ? (
                <ModalTitle>Editar Royalty</ModalTitle>
              ) : (
                <ModalTitle>Cadastrar novo Royalty</ModalTitle>
              )
            }
            destroyOnClose={true}
            visible={visible}
            closable={false}
            onOk={() => setVisible(false)}
            onCancel={clearFields}
            width={"70%"}
            centered
            footer={[
              <ContentModal>
                <ButtonCancelModal type="link" onClick={clearFields}>
                  <span>Cancelar</span>
                </ButtonCancelModal>
                <SaveButton loading={loading} onClick={() => handleCreate()}>
                  Salvar
                </SaveButton>
              </ContentModal>,
            ]}
          >
            {loading ? (
              <Centralizer>
                <Spin src={spin} />
              </Centralizer>
            ) : (
              <Form layout="vertical" form={form} preserve={false}>
                <RowModal>
                  <ColModal sm={0} xs={0}>
                    <Form.Item name="id">
                      <Input name="id" />
                    </Form.Item>
                  </ColModal>
                </RowModal>
                {newList.map((item, index) => (
                  <RowModal key={index}>
                    <ColModal sm={4} xs={24}>
                      <Form.Item
                        label="Tipo"
                        name={`type${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Escolha a Opção"
                          onChange={(value) =>
                            changeValue(+value, "type", index)
                          }
                          value={item.type}
                        >
                          {RoyaltiesType.map((types) => (
                            <Option
                              value={types.value}
                              key={types.value}
                              label={types.label}
                            >
                              {types.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </ColModal>

                    <ColModal sm={4} xs={24}>
                      <Form.Item
                        label="Código do boleto"
                        name={`cod_bankslip${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          type="number"
                          name={`cod_bankslip${index}`}
                          placeholder="Digite o código do boleto"
                          value={item.cod_bankslip}
                          onChange={(event) =>
                            changeValue(
                              event.target.value,
                              "cod_bankslip",
                              index
                            )
                          }
                        />
                      </Form.Item>
                    </ColModal>
                    <ColModal sm={4} xs={12}>
                      <Form.Item
                        label={
                          <span>
                            Valor do Boleto{" "}
                            <span style={{ color: "#ff4d4f" }}>*</span>
                          </span>
                        }
                        name={`value_bankslip${index}`}
                        rules={[
                          {
                            validator: () => {
                              return item.value_bankslip > 0
                                ? Promise.resolve()
                                : Promise.reject("Campo Obrigatório");
                            },
                          },
                        ]}
                      >
                        <MonetaryInput2
                          getValue={(value) =>
                            changeValue(+value, "value_bankslip", index)
                          }
                          defaultValue={valueBanksLip}
                        />
                      </Form.Item>
                    </ColModal>
                    <ColModal sm={4} xs={12}>
                      <Form.Item
                        label="Mês referente"
                        name={`month${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Escolha a Opção"
                          onChange={(value) =>
                            changeValue(value, "month", index)
                          }
                        >
                          {allMonth.map((month) => (
                            <Option value={month} key={month}>
                              {month}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </ColModal>

                    <ColModal sm={4} xs={12}>
                      {item.type !== RoyaltyType.Serviço ? (
                        <Form.Item
                          label="Código da nota fiscal"
                          name={`cod_nf${index}`}
                          rules={[
                            {
                              required:
                                item.type !== RoyaltyType.Serviço
                                  ? true
                                  : false,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            name={`cod_nf${index}`}
                            placeholder="Digite o código da nota fiscal"
                            onChange={(event) =>
                              changeValue(event.target.value, "cod_nf", index)
                            }
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          label={"Código VHSYS"}
                          name={`cod_vhsys${index}`}
                        >
                          <Input
                            type="number"
                            name={`cod_vhsys${index}`}
                            placeholder="Digite o código vhsys"
                            value={item.cod_vhsys}
                            onChange={(event) =>
                              changeValue(
                                event.target.value,
                                "cod_vhsys",
                                index
                              )
                            }
                          />{" "}
                        </Form.Item>
                      )}
                    </ColModal>
                    <ColModal sm={4} xs={12}>
                      <Form.Item
                        label="Data de válidade"
                        name={`expiration_date${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <DatePicker
                          locale={locale}
                          name={`expiration_date${index}`}
                          format="DD/MM/YYYY"
                          placeholder="DD/MM/AAAA"
                          onChange={(value) =>
                            changeValue(value, "expiration_date", index)
                          }
                        />
                      </Form.Item>
                    </ColModal>
                    {!editRoyalty && (
                      <ColModal
                        sm={12}
                        xs={24}
                        style={{ justifyContent: "center" }}
                      >
                        <Form.Item label="Envio de arquivo">
                          {item?.uploadFiles &&
                            item?.uploadFiles?.length <= 3 && (
                              <>
                                {loadingDelete ? (
                                  <Spin src={spin} />
                                ) : (
                                  <Upload
                                    onUpload={(file) =>
                                      handleUpload(file, index)
                                    }
                                    maxFiles={3}
                                    fileType="image/*,.pdf,.zip,.rar"
                                    dropzoneMsg={
                                      "Arraste seu arquivo ou procure aqui!"
                                    }
                                  />
                                )}
                              </>
                            )}
                          <UploadContainer>
                            {item?.uploadFiles &&
                              item?.uploadFiles?.length > 0 &&
                              item?.uploadFiles?.map(
                                (uploadedFile, indexRemove) => (
                                  <UploadContent key={indexRemove}>
                                    <span>{uploadedFile.name}</span>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFile(uploadedFile, index)
                                      }
                                    >
                                      remover
                                    </button>
                                  </UploadContent>
                                )
                              )}
                          </UploadContainer>
                        </Form.Item>
                      </ColModal>
                    )}
                    <Section />
                  </RowModal>
                ))}
              </Form>
            )}
            {!editRoyalty ? (
              <RowModal>
                <ColModal sm={12}>
                  <button onClick={() => addRow()}>+ Adicionar Royalty</button>
                </ColModal>
                <Col sm={12}>
                  <button
                    style={{ color: "var(--maroon)" }}
                    onClick={() => removeRow()}
                  >
                    - Remover Royalty
                  </button>
                </Col>
              </RowModal>
            ) : (
              <></>
            )}
          </ModalRegister>
        </Container>
      )}
    </PageContainer>
  );
};

export default withRouter(Royalty);
