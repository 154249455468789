import React from 'react'
import {
    ContentHeaderTitle,
    WarningIcon,
    Content,
    Button,
    ButtonCancel,
    Modal,
    Radio,
    FooterDiv
} from './styles';

interface IProps {
    visibleModalBlockStore: boolean;
    setvisibleModalBlockStore: React.Dispatch<React.SetStateAction<boolean>>;
    blockStore: () => void;
    selectedReason: number | undefined;
    setSelectedReason: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ModalBlockStore: React.FC<IProps> = ({ visibleModalBlockStore, setvisibleModalBlockStore, blockStore, selectedReason, setSelectedReason }) => {
    const handleBlockStore = async () => {
        if (selectedReason !== undefined) {
            await blockStore(); 
        }
        setvisibleModalBlockStore(false);
    };
    return (
        <Modal
            centered
            title={
                <ContentHeaderTitle>
                    <WarningIcon size={25} />
                    <span>Atenção</span>
                </ContentHeaderTitle>
            }
            visible={visibleModalBlockStore}
            onCancel={() => setvisibleModalBlockStore(false)}
            footer={[
                <FooterDiv>
                    <ButtonCancel onClick={() => setvisibleModalBlockStore(false)}>Cancelar</ButtonCancel>
                    <Button onClick={handleBlockStore}>
                        Bloquear
                    </Button>
                </FooterDiv>
            ]}
        >
            <Content>
                <Radio.Group onChange={(e) => setSelectedReason(Number(e.target.value))}>
                    <Radio value={0}>Pedidos com mais de 15 dias de atraso</Radio>
                    <Radio value={1}>Boletos de renegociação vencidos</Radio>
                    <Radio value={2}>Fechamento semanal está acima do permitido</Radio>
                </Radio.Group>
            </Content>
        </Modal>
    );
};

export default ModalBlockStore;
