import React, { Dispatch, SetStateAction } from "react";
import { Moment } from "moment";

import { DatePickerStyle } from './styles';
interface IRangePickerProps {
  locale: any;
  bordered: boolean;
  date: { data_inicial: Moment; data_final: Moment };
  setDate: Dispatch<
    SetStateAction<{ data_inicial: Moment; data_final: Moment }>
  >;
  [rest: string]: any;
}

const dateFormat = "DD-MM-YYYY";

const DatePickerAnt: React.FC<IRangePickerProps> = ({
  locale,
  bordered,
  date,
  setDate,
  ...rest
}) => {
  function onChange(date) {
    const [data_inicial, data_final] = date;
    setDate({
      data_inicial,
      data_final,
    });
  }

  return (
    <>
      <DatePickerStyle
        locale={locale}
        bordered={bordered}
        format={dateFormat}
        {...rest}
        onChange={onChange}
      />
    </>
  );
};

export default DatePickerAnt;
