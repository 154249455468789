import styled, { css } from "styled-components";

import { AddCircleOutline, Filter } from "../../styles/Icons";

import {
  Input as InputAnt,
  Row as RowAnt,
  Col as ColAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  text-align: center;
`;

export const TopSide = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10%;
  min-height: 3.5rem;
  width: 100%;
  padding: 0;
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.30rem;
  width: 100%;
  color: white;
  background: var(--orange-gradient);
  border: 1px solid white;
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    color: white;
    border: 1px solid white;
    background: var(--orange-gradient);
    opacity: 80%;
  }
`;

export const AddIcon = styled(AddCircleOutline)`
  width: 25px;
  height: 25px;
  cursor: pointer;

  margin-left: .5rem;
`;

export const Input = styled(InputAnt)`
  height: 3.25rem;
  border-radius: 5px;
  @media only screen and (max-width: 700px) {
    height: 3.25rem !important;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const ButtonFilter = styled(Button)`
  ${Button}
`;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const iconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconFilter = styled(Filter)`
  ${iconCSS}
`;
