import styled, { css } from "styled-components";
import {
  Modal,
  Row,
  Col as ColAnt,
  Table as TableAnt,
  Button as ButtonAnt,
} from "antd";
import { InfoCircle } from "../../../styles/Icons";

export const Container = styled(Modal)`
  .order {
    color: var(--orange-350);
  }
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    .time {
      font-size: 1.1rem;
    }
  }
  .ant-modal-header {
    padding: 1rem 2rem;
  }
  .ant-modal-body {
    padding: 0.5rem 2rem;
    max-height: 100%;
    overflow-y: auto;
  }
  .categoryTable {
    margin-top: 1.5rem;
    .ant-table-thead > tr > th {
      padding: 0.2rem;
    }

    .ant-table-tbody > tr > td {
      padding: 0.2rem;
    }
  }
  .ant-modal-footer {
    padding: 20px 30px;
  }

  .row-disabled {
    background-color: #edededfc;
    cursor: no-drop;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const OtherValuesOrder = styled(Row)`
  display: flex;
  gap: 2rem;
  flex: 10;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 7;
`;

export const Col = styled(ColAnt)`
  span,
  label {
    font-weight: 500;
  }

  span {
    color: var(--gray-650);
  }

  label {
    color: var(--orange-350);
  }
`;

export const RowNumber = styled(Row)`
  margin-bottom: 1rem;
  gap: 5px;
  span {
    font-weight: 500;
    color: var(--orange-350);
  }
`;

export const CheckBoxContainer = styled.div`
  margin-bottom: 1rem;
`;

export const Table = styled(TableAnt)`
  border: 1px solid var(--gray-25);

  .ant-table-thead > tr > th {
    background: white;

    padding: 0.6rem;

    color: var(--gray-530);

    font-weight: 500;
    @media only screen and (max-width: 500px) {
      font-size: 0.7rem;
      padding: 0.8rem 0.2rem;
    }
  }

  .ant-table-tbody > tr > td {
    background: white;

    padding: 0.6rem;

    color: var(--gray-530);

    font-weight: 400;
    font-size: 1rem;

    text-transform: capitalize;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    white-space: nowrap;

    @media only screen and (max-width: 500px) {
      font-size: 0.6rem;
      padding: 0.8rem 0.2rem;
    }
  }
`;

export const Freight = styled.span`
  color: #686f82;

  padding: 0.5rem;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 12.5rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled(ButtonAnt)`
  ${ButtonCSS}
  color: var(--gray-650);
  background-color: #d7d4d4;
  transition: 0.2s;

  :hover {
    background-color: #a7a6a6;
  }
`;

export const ButtonChange = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: #ff5e00;
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: #ff4700;
    color: white;
  }
`;

interface IStatus {
  active?: string;
}

export const Status = styled.span<IStatus>`
  display: flex;
  justify-content: center;
  ${({ active }) => {
    if (active === "A") {
      return css`
        color: var(--midori-green);
      `;
    } else if (active === "I") {
      return css`
        color: var(--maroon);
      `;
    }
  }}
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled(InfoCircle)`
  color: var(--rainbows-outer-rim);
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;

export const NameProduct = styled.span`
  text-transform: capitalize;
`;
