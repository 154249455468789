import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Table as TableAnt,
  DatePicker as DatePickerAnt,
  Button
} from "antd";
import {
  AddCircleOutline,
  Barcode,
  CreditCard,
  FilterAlt,
  InfoCircle,
  Minus,
  Money,
  MoreHoriz,
  Transfer
} from '../../styles/Icons';

const { RangePicker } = DatePickerAnt;

export const Input = styled(InputAnt)`
  @media screen and (max-width: 768px) {
    margin-bottom: .5rem;
  }
`;

export const RangePickerElement = styled(RangePicker)`
  width: 100%;

  .ant-picker-input > input {
      font-weight: 300;
      font-size: .9rem;
  }
`;

export const ContentEmptyId = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 8%;
`;

export const MinusIcon = styled(Minus)`
  width: 1rem;
  height: 1rem;
`;

export const InfoCapitalized = styled.span`
  text-transform: capitalize;
`;

export const ContentBorderColor = styled.div<{ borderLeft: string }>`
  border-left: 5px solid ${({ borderLeft }) => borderLeft}; 
  border-radius: 0px 5px 5px 0px;
  padding: 1rem; 
  box-sizing: border-box; 
`;


export const DatePicker = styled(DatePickerAnt)`
  width: 100%;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: .9rem !important;
  }
`;

export const Table = styled(TableAnt)`
    max-height: calc(100vh - 220px);
    overflow-y: auto;         
    margin-top: -18px;        
    
    .ant-table table {
        width: 100%;
        text-align: left;
        border-radius: 0;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid var(--gray-25);
        border-radius: 8px;
        margin-top: 1rem;
    }

    .ant-table-thead > tr > th {
      background: var(--white);
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child {
        padding: 0;
    }

    @media screen and (max-width: 1400px) {
      max-height: calc(100vh - 140px);
    }

    @media screen and (max-width: 768px) {
      max-height: 60vh;            
      margin-top: 28%;
    }

    @media screen and (max-width: 500px) {
      max-height: 50vh;
      margin-top: 0;
    }
`;

const commonButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  color: var(--white);

  :hover {
    opacity: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: .5rem;
      :first-child {
        margin-top: .5rem;
      }
  }
  @media screen and (min-width: 1500px) {
    width: 3.9rem;
  }


`;

export const ButtonFilter = styled(Button)`
  ${commonButtonStyles}
  background: var(--orange-350);

  :hover {
    background: var(--orange-350);
    color: var(--white);
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-gradient);
  color: var(--white);
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  text-decoration: none; 
  padding: 0;

  :hover {
    color: var(--white);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.8;
  width: 100%;
  height: 100vh;

  overflow: hidden;
  padding: 1rem 0;

  overflow: scroll;
  overflow-x: hidden;
`;

export const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoContent = styled.div<{ color: string }>`
  display: flex;
  background-color: ${({ color }) => color};
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px 8px 0 0;
  min-width: 10%;

  span{
    color: var(--white);
  }

  margin-left: 1rem;

  :first-child {
    margin: 0;
  }

  @media screen and (max-width: 1024px){
    width: 100%;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 3.7rem;

  margin: 0 0 0.3 0 !important;
`;

export const Col = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddIcon = styled(AddCircleOutline)`
  width: 20px;
  height: 20px;
  fill: white;
`;

export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 0.5rem;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: red !important;
  }
`;

export const TableInfo = styled.table`
  border: 1px solid var(--black);
  background-color: #FFFFFF;
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  margin-top: .4rem;

  th, td {
    border: 1px solid var(--gray-25);  
    padding: 8px;                 
    text-align: center;
  }

  th {
    font-weight: bold;
    font-size: 12px;
  }
`;

export const StyledTd = styled.td<{ color?: string }>`
  color: ${props => props.color || 'inherit'};
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const FilterIcon = styled(FilterAlt)`
  width: 1.3rem;
  height: 1.3rem;
`;

export const ContentTile = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentTileID = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: var(--c64-blue);
  margin-left: 10px;
`;

const iconTableCSS = css`
  width: 20px;
  height: 20px;

  margin-left: 10px;
`;

export const MoneyIcon = styled(Money)`
  ${iconTableCSS}
  color: var(--midori-green);
`;

export const CreditCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--c64-blue);
`;

export const DebitCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--c80-blue);
`;

export const TransferIcon = styled(Transfer)`
 ${iconTableCSS}  
 color: var(--roof-terracotta);
`;

export const BarcodeIcon = styled(Barcode)`
 ${iconTableCSS}  
 color: var(--black);
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(Button)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const ColModal = styled(ColAnt)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;