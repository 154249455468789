import React, { useEffect, useState } from 'react'
import PageContainer from '../../containers/PageContainer'
import { useStoreData } from '../../hooks/useStoreData';
import StoreSelectionPage from '../../components/StoreSelectionPage';
import ShopImg from "../../assets/svg/shop-bill.svg";
import DisabledFlag from '../../components/DisabledFlag';
import { currencyFormater } from '../../services/currencyFormater';
import { Dropdown, Empty, Form, Menu, Modal, notification, Select, Spin, Tooltip } from 'antd';
import GetStore from '../../components/GetStore';
import { verifyPermission } from '../../services/auth';
import CategoryAccountType from "../../models/json/CategoryAccountType.json";

import moment, { Moment } from 'moment';
import { PaymentType } from '../../models/enums/PaymentType';
import { SelectMultipleIcon } from '../../containers/ShoppingList/styles';
import apiMercury from '../../services/apiMercury';
import { AccountPayable } from '../../models/AccountsPayable';
import Centralizer from '../../containers/Centralizer';
import { Page } from '../../models/Page';
import { PaymentAccountType } from '../../models/enums/PaymentAccountStatus';

import { Actions, AddIcon, BarcodeIcon, ButtonFilter, Col, Container, DatePicker, ContentTile, EmptyContent, FilterIcon, InfoCircleIcon, InfoContent, Input, MoneyIcon, MoreIcon, Row, StyledTd, Table, TableInfo, TransferIcon, InfoCapitalized, StyledLink, ContentEmptyId, MinusIcon, ButtonCancel, ButtonSave, ContentModal, ColModal, ContentBorderColor, ContentTileID, RangePickerElement } from './styles';
import { useLocation } from 'react-router-dom';
import { removeAccentsAndLowerCase } from '../../utils/removeAccentsAndCaseSensitive';
import locale from 'antd/es/date-picker/locale/pt_BR';
interface LocationState {
    store_param?: number;
}

const BillsToPay = () => {
    const location = useLocation<LocationState>();

    const [store, setStore] = useState<number | undefined | null>();
    const [shouldSearch, setShouldSearch] = useState<boolean>(true);

    const [accountsPayable, setAccountsPayable] = useState<AccountPayable[]>([]);
    const [loading, setLoading] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const [filteredAccountsPayable, setFilteredAccountsPayable] = useState<AccountPayable[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [selectedDate, setSelectedDate] = useState<{
        initial_date: Moment | null;
        final_date: Moment | null;
    }>({
        initial_date: null,
        final_date: null,
    });

    const { isStoreActive } = useStoreData(store);
    const [paginate, setPaginate] = useState<Page>({
        page: 1,
        size: 10,
        totalElements: 0,
    });

    const { page, size, totalElements } = paginate;

    useEffect(() => {
        if (location.state && location.state.store_param) {
            setStore(location.state.store_param);
        }
    }, [location.state]);

    const getAccountsPayable = async () => {
        let URL = `/accounts-payable?page=${page}&size=${size}`;

        if (selectedDate.initial_date && selectedDate.final_date) {
            URL += `&data_inicial=${selectedDate.initial_date.format("DD-MM-YYYY")}&data_final=${selectedDate.final_date.format("DD-MM-YYYY")}`;
        }
        if (selectedCategory) {
            URL += `&category_id=${selectedCategory}`;
        }
        try {
            setLoading(true);
            const { data: { content, totalElements } } = await apiMercury.get(URL);
            setAccountsPayable(content);
            setFilteredAccountsPayable(content);

            setPaginate((oldValues) => ({ ...oldValues, totalElements }));
        } catch (error) {
            //@ts-ignore
            const _description = error?.response?.data?.error?.message;

            notification.error({
                message: `Oops, ocorreu um erro ao buscar as Contas a Pagar.`,
                description: _description,
                duration: 5,
            });
        } finally {
            setLoading(false);
            setShouldSearch(false);
        }
    }

    useEffect(() => {
        if (store && shouldSearch) getAccountsPayable();
    }, [store, shouldSearch, page]);

    useEffect(() => {
        const filterProviders = () => {
            const filtered = accountsPayable.filter(account =>
                account.provider &&
                removeAccentsAndLowerCase(account.provider.company_name).includes(removeAccentsAndLowerCase(searchValue))
            );
            setFilteredAccountsPayable(filtered || []);
        };

        if (searchValue) {
            filterProviders();
        } else {
            setFilteredAccountsPayable(accountsPayable);
        }
    }, [searchValue, accountsPayable]);

    const deleteBill = (id: number) => {
        Modal.confirm({
            title: `Deletar a conta ID ${id}?`,
            content: "Deseja continuar e deletar essa conta a pagar?",
            okText: "Sim",
            okType: "primary",
            cancelText: "Não",
            centered: true,
            async onOk() {
                try {
                    await apiMercury.delete(`/accounts-payable/${id}`);
                    notification.success({
                        message: `Conta deletada com sucesso.`,
                        description: `A conta Nº ${id} foi excluída.`,
                        duration: 5,
                    });
                    setShouldSearch(true);
                } catch (error) {
                    //@ts-ignore
                    const _description = error?.response?.data?.error?.message;

                    notification.error({
                        message: `Oops, ocorreu um erro ao deletar a conta Nº ${id}.`,
                        description: _description,
                        duration: 5,
                    });
                } finally {
                    setShouldSearch(false);
                }
            }
        })
    }

    const accountsPaymentInfo = (payments) => {
        const today = moment().startOf("day");

        const totals = payments.reduce(
            (acc, item) => {
                const dueDate = moment(item.due_date).startOf("day");
                const amount = +item.payment_amount || 0;

                if (item.payment_status === PaymentAccountType.PAGO) {
                    acc.paidAccount += amount;
                } else if (dueDate.isBefore(today)) {
                    acc.overdueAccount += amount;
                } else if (dueDate.isSame(today, "day")) {
                    acc.today += amount;
                } else {
                    acc.openAccount += amount;
                }

                acc.totalOnPeriod += amount;
                return acc;
            },
            {
                overdueAccount: 0,
                today: 0,
                openAccount: 0,
                paidAccount: 0,
                totalOnPeriod: 0
            }
        );

        return totals;
    };

    const accountPaymentTotals = accountsPaymentInfo(accountsPayable);

    const changeToStatusPaid = async (id: number) => {
        const payload = {
            payment_status: PaymentAccountType.PAGO,
        };
        try {
            await apiMercury.put(`/accounts-payable/${id}`, payload);

            notification.success({
                message: `Status atualizado com sucesso.`,
                description: `A conta Nº ${id} foi marcada como paga.`,
                duration: 5,
            });
            setShouldSearch(true);
        } catch (error) {
            //@ts-ignore
            const _description = error?.response?.data?.error?.message;

            notification.error({
                message: `Oops, ocorreu um erro ao deletar a conta Nº ${id}.`,
                description: _description,
                duration: 5,
            });
        } finally {
            setShouldSearch(false);
        }
    }

    const resetFilter = () => {
        setSelectedDate({
            initial_date: null,
            final_date: null,
        })
        setSelectedCategory(undefined)
        setShouldSearch(true);
    }

    const columns = [
        {
            title: (
                <ContentTileID>
                    ID <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTileID>
            ),
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "descend" as any,
            render: (text, record) => {
                const borderColor = () => {
                    switch (record.payment_status) {
                        case 1:
                            return 'var(--green-400)';
                        case 2:
                            return 'var(--orange-600)';
                        case 3:
                            return 'var(--orange-350)';
                        default:
                            return 'black';
                    }
                };

                return (
                    <ContentBorderColor borderLeft={borderColor()}>
                        <InfoCapitalized>{text}</InfoCapitalized>
                    </ContentBorderColor>
                );
            },
        },
        {
            title: (
                <ContentTile>
                    Data de Vencimento <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "due_date",
            sorter: (a, b) => a.due_date - b.due_date,
            responsive: ["md"] as any,
            key: "due_date",
            render: (text) => (
                <InfoCapitalized>{moment(text).format("DD/MM/YYYY")}</InfoCapitalized>
            ),
        },
        {
            title: (
                <ContentTile>
                    Categoria <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "category.name",
            key: "category.name",
            render: (text, record) => {
                const categoryLabel = CategoryAccountType.find(
                    (category) => category.value === record.category_id
                )?.label;

                return (
                    <InfoCapitalized>
                        {record.purchase_id ? record.category.name : categoryLabel || <ContentEmptyId><MinusIcon /></ContentEmptyId>}
                    </InfoCapitalized>
                );
            },
            sorter: (a, b) => {
                const labelA = CategoryAccountType.find(category => category.value === a.category_id)?.label || "";
                const labelB = CategoryAccountType.find(category => category.value === b.category_id)?.label || "";
                return labelA.localeCompare(labelB);
            },
        },
        {
            title: (
                <ContentTile>
                    Fornecedor <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "provider",
            responsive: ["md"] as any,
            key: "provider",
            render: (text, record) => (
                <InfoCapitalized>{record?.provider?.fantasy_name}</InfoCapitalized>
            ),
            sorter: (a, b) =>
                (a.provider?.fantasy_name || "").localeCompare(b.provider?.fantasy_name || ""),
        },
        {
            title: (
                <ContentTile>
                    Observação <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "observation",
            responsive: ["md"] as any,
            key: "observation",
            render: (text) => (
                <InfoCapitalized>{text}</InfoCapitalized>
            ),
            sorter: (a, b) =>
                (a.observation || "").localeCompare(b.observation || ""),
        },
        {
            title: (
                <ContentTile>
                    Valor <Tooltip title="Valor em reais da compra. O ícone ao lado indica o método de pagamento"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "Valor",
            key: "Valor",
            render: (text, record) => {
                const paymentMethod = record?.payment_method;

                const renderIcon = () => {
                    switch (paymentMethod) {
                        case PaymentType.Dinheiro:
                            return <MoneyIcon />;
                        case PaymentType.Transferência:
                            return <TransferIcon />;
                        case PaymentType.Boleto:
                            return <BarcodeIcon />;
                        default:
                            return (
                                <Tooltip title="Múltiplos meios de pagamento">
                                    <SelectMultipleIcon />
                                </Tooltip>
                            );
                    }
                };

                return (
                    <Tooltip title={currencyFormater(+record?.payment_amount)}>
                        <ContentTile>
                            R$ {currencyFormater(+record?.payment_amount)} {renderIcon()}
                        </ContentTile>
                    </Tooltip>
                );
            },
            sorter: (a, b) => a.payment_amount - b.payment_amount,
        },
        {
            title: (
                <ContentTile>
                    ID de Origem <Tooltip title="Identificador numérico sequencial"><InfoCircleIcon /></Tooltip>
                </ContentTile>
            ),
            dataIndex: "purchase_id",
            responsive: ["md"] as any,
            key: "purchase_id",
            render: (text) => (
                <InfoCapitalized>{text ? text : <ContentEmptyId><MinusIcon /></ContentEmptyId>}</InfoCapitalized>
            ),
        },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <Actions>
                    <Dropdown
                        overlay={
                            <Menu>
                                {record?.payment_status !== PaymentAccountType.PAGO && (
                                    <Menu.Item
                                        onClick={() => {
                                            changeToStatusPaid(record?.id)
                                        }}
                                    >
                                        <span>Dar baixa</span>
                                    </Menu.Item>
                                )}

                                <Menu.Item>
                                    <span
                                        onClick={() => deleteBill(record.id)}
                                        style={{ color: "red" }}
                                    >
                                        Excluir conta
                                    </span>
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomCenter"
                        arrow
                    >
                        <MoreIcon />
                    </Dropdown>
                </Actions>
            ),
        },
    ]

    const handleTableChange = (_page: number) => {
        setPaginate((oldValues) => ({ ...oldValues, page: _page }));
        setShouldSearch(true);
    };

    return (
        <PageContainer route="Contas a Pagar" >
            <Container>
                {!store ? (
                    <StoreSelectionPage
                        title="Selecione uma loja para continuar"
                        Img={ShopImg}
                        store={store}
                        setStore={setStore}
                        setShouldSearch={setShouldSearch}
                    />
                ) : loading ? (
                    <Centralizer>
                        <Spin />
                    </Centralizer>
                ) : (
                    <>
                        <DisabledFlag isStoreActive={isStoreActive} />
                        <Row gutter={10}>
                            <Col sm={5} xs={24}>
                                <Input
                                    placeholder="Buscar por fornecedor"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </Col>
                            <Col sm={6} xs={24}>
                                <GetStore
                                    defaultValue={store}
                                    handleChange={(id) => {
                                        setStore(id);
                                        setShouldSearch(true);
                                    }}
                                />
                            </Col>
                            <Col sm={1} xs={24}>
                                <Tooltip title={"Filtro"}>
                                    <ButtonFilter onClick={() => setOpenFilterModal(true)}>
                                        <FilterIcon />
                                    </ButtonFilter>
                                </Tooltip>
                            </Col>
                            {!isStoreActive && verifyPermission("royalty.add") && (
                                <Col sm={1} xs={24} style={{ padding: 0 }}>
                                    <Tooltip title="Adicionar nova conta">
                                        <StyledLink
                                            to={{
                                                pathname: '/new-bill-to-pay',
                                                state: { store, accountsPayable }
                                            }}
                                        >
                                            <AddIcon />
                                        </StyledLink>
                                    </Tooltip>
                                </Col>
                            )}
                        </Row>

                        {store && (
                            <>
                                {accountsPayable.length !== 0 ? (
                                    <>
                                        <TableInfo>
                                            <thead>
                                                <tr>
                                                    <th>Vencidos</th>
                                                    <th>Vencem Hoje</th>
                                                    <th>Em Aberto</th>
                                                    <th>Pagos</th>
                                                    <th>Total do Período</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <StyledTd color="var(--orange-600)">R$ {currencyFormater(accountPaymentTotals.overdueAccount)}</StyledTd>
                                                    <StyledTd color="var(--orange-600)">R$ {currencyFormater(accountPaymentTotals.today)}</StyledTd>
                                                    <StyledTd>R$ {currencyFormater(accountPaymentTotals.openAccount)}</StyledTd>
                                                    <StyledTd color="var(--orange-350)">R$ {currencyFormater(accountPaymentTotals.paidAccount)}</StyledTd>
                                                    <StyledTd color="var(--orange-350)">R$ {currencyFormater(accountPaymentTotals.totalOnPeriod)}</StyledTd>
                                                </tr>
                                            </tbody>
                                        </TableInfo>
                                        <Table
                                            columns={columns}
                                            loading={loading}
                                            pagination={{
                                                current: page,
                                                pageSize: size,
                                                total: totalElements,
                                                showSizeChanger: false,
                                                onChange: handleTableChange,
                                            }}
                                            dataSource={filteredAccountsPayable.map((entity) => ({
                                                ...entity,
                                                key: entity.id,
                                            }))}
                                            onChange={(pagination) => {
                                                setPaginate((prev) => ({
                                                    ...prev,
                                                    page: pagination.current || 1,
                                                }));
                                            }}
                                        />
                                    </>
                                ) : (
                                    <EmptyContent>
                                        <Empty description="Nenhuma conta a pagar" />
                                    </EmptyContent>
                                )}
                            </>
                        )}
                    </>
                )}
            </Container>

            <Modal
                title={"Filtros"}
                centered
                visible={openFilterModal}
                closable={false}
                width={450}
                footer={[
                    <ButtonCancel onClick={() => {
                        setOpenFilterModal(false);
                        resetFilter();
                    }}>
                        Limpar Filtro
                    </ButtonCancel>,
                    <ButtonSave
                        loading={loading}
                        onClick={() => {
                            setShouldSearch(true);
                            setOpenFilterModal(false);
                            getAccountsPayable();
                        }}
                    >
                        Buscar
                    </ButtonSave>
                ]}
            >
                <ContentModal>
                    <Row>
                        <Col xs={24}>
                            <Form.Item label={"Data de Vencimento"}>
                                <RangePickerElement
                                    placeholder={["Data inicial", "Data final"]}
                                    locale={locale}
                                    format="DD/MM/YYYY"
                                    value={
                                        selectedDate.initial_date && selectedDate.final_date
                                            ? [selectedDate.initial_date, selectedDate.final_date]
                                            : null
                                    }
                                    onChange={(dates) => {
                                        if (dates) {
                                            setSelectedDate({
                                                initial_date: dates[0],
                                                final_date: dates[1],
                                            });
                                        } else {
                                            setSelectedDate({
                                                initial_date: null,
                                                final_date: null,
                                            });
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <Form.Item label={"Categoria"} style={{ flexDirection: 'column', width: '100%' }}>
                                <Select
                                    showSearch
                                    placeholder="Selecione uma categoria"
                                    optionFilterProp="children"
                                    value={selectedCategory}
                                    onChange={(value) => setSelectedCategory(value)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toString().toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {CategoryAccountType.map((productCategory) => (
                                        <Select.Option
                                            value={productCategory.value}
                                            key={productCategory.value}
                                            label={productCategory.label}
                                        >
                                            {productCategory.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </ContentModal>
            </Modal>

        </PageContainer >
    );
}

export default BillsToPay