import styled, { css } from 'styled-components';
import { Select as SelectAnt, Button as ButtonAnt } from 'antd';

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ContentPayment = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalPayment = styled.span`
  font-size: 1.2rem;
  color: var(--gray-530);
`;