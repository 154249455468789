import styled from "styled-components";
import { PlusCircle } from "../../styles/Icons";

import {
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Button as ButtonAnt,
  Form as FormAnt,
  Modal as ModalAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ModalRegistration = styled(ModalAnt)`
  .ant-modal-body {
    padding: 1rem 1.5rem 0rem 1.5rem;
    overflow: scroll;
    input {
      height: 3.25rem;
    }
  }
  .ant-modal-footer {
    padding: 0 1rem;
  }
`;

export const Form = styled(FormAnt)``;

export const Content = styled.div`
  height: 90vh;
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

export const TopContent = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 15vh;
  width: 100%;

  @media only screen and (max-width: 700px) {
    input {
      height: 3.25rem;
    }
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: 3.25rem;

  padding: 1rem 0.95rem 1rem 1.2rem;
  background-color: var(--orange-350);
  border-radius: 5px;

  font-size: 0.9rem;
  font-weight: 400;
  color: white;

  cursor: pointer;
  span {
    margin-right: 3%;
  }
  @media only screen and (max-width: 1120px) {
    width: 100%;
    span {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 580px) {
    span {
      display: none;
    }
  }
`;

export const AddIcon = styled(PlusCircle)`
  width: 1.5rem;
  height: 1.5rem;
  @media only screen and (max-width: 1120px) {
    display: none;
  }
  @media only screen and (max-width: 575px) {
    display: flex;
  }
`;

export const DrawerContent = styled.div`
  width: 100%;
  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: var(--orange-600);
    font-size: 1rem;
    line-height: 1;
    content: "*";
  }
`;

export const Col = styled(ColAnt)`
  font-weight: 600;
  padding: 2px;
`;
export const Row = styled(RowAnt)``;

export const Input = styled(InputAnt)`
  height: 3.25rem;
  width: 100%;

  border-radius: 4px;

  &::placeholder {
    color: var(--gray-45);
  }
  :hover,
  :focus,
  :active {
    box-shadow: 0 0 0 0;
    outline: none;
    border: 1px solid var(--gray-45);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  margin: 1.3rem 0;
  width: 30%;
  height: 3.25rem;
  background: var(--orange-350);
  color: white;
  :hover,
  :focus,
  :active {
    background: var(--orange-350);
    color: white;
    border: 1px solid var(--orange-350);
    opacity: 80%;
  }
`;
export const ButtonCancel = styled(ButtonAnt)`
  margin: 1.3rem 0;
  width: 30%;
  height: 3.25rem;
  :hover,
  :focus,
  :active {
    color: var(--blue-900);
  }
`;

export const Spin = styled.img``;
