import styled from 'styled-components';
import {
    Modal as ModalAnt,
    Row as RowAnt,
    Col as ColAnt,
} from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    overflow: auto;
    padding: 0 24px 24px;
  }
`;

export const NameContent = styled.span`
  text-transform: capitalize;
`;

export const ModalTitle = styled.h1`
  font-size: 2.5rem;

  @media only screen and (max-width: 578px) {
    font-size: 1.75rem;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const ContentModalBody = styled(RowAnt)``;

export const ColModal = styled(ColAnt)`
  font-weight: 600;
  padding: 2px;
`;