import styled, { css } from "styled-components";

import {
  Drawer as DrawerAnt,
  Tabs as TabsAnt,
  Form as FormAnt,
  Input as InputAnt,
  Select as SelectAnt,
  Row as RowAnt,
  Col as ColAnt,
  Checkbox as CheckboxAnt,
  Switch as SwitchAnt,
  Button as ButtonAnt,
} from "antd";
import { InfoCircle } from "../../../styles/Icons";

const { TextArea } = InputAnt;

export const Drawer = styled(DrawerAnt)`
  .ant-drawer-footer {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-row .ant-form-item {
    display: flex;
    flex-direction: column !important;
  }
  .ant-input-affix-wrapper {
    padding: 0 0.5rem;
    border-radius: 0.5rem;
  }
`;

export const Title = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--gray-45);
  margin-bottom: 1.2rem;
`;

export const Tabs = styled(TabsAnt)`
  overflow-y: auto;
  .ant-row .ant-form-item {
    margin: 0;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const Form = styled(FormAnt)``;

export const Input = styled(InputAnt)``;

export const TextAreaInput = styled(TextArea)``;

export const Switch = styled(SwitchAnt)`
  margin: 0 0.5rem;
`;

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--orange-350);
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  width: 8rem;

  :hover,
  :active,
  :focus {
    color: var(--white);
    background: var(--orange-350);
    border: none;
    box-shadow: none;
    opacity: 70%;
  }
`;

export const Button = styled(ButtonAnt)`
  ${buttonCss}
`;

export const Select = styled(SelectAnt)``;

export const Checkbox = styled(CheckboxAnt)``;

export const IconInfo = styled(InfoCircle)`
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
  color: var(--gray-600);
`;
