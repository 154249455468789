import styled from "styled-components";
import { Col as ColAnt, Checkbox as CheckboxAnt } from "antd";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  .ant-table-body {
    overflow-y: scroll;
    max-height: 19rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }
  .ant-table-container {
    border: 1px solid var(--gray-75);
    padding: 0.3rem;

    .ant-table-cell {
      background: white;
      color: var(--gray-45);
      @media only screen and (max-width: 578px) {
        padding: 0.2rem;
        font-size: 0.7rem;
      }
    }

    @media only screen and (max-width: 578px) {
      .ant-table-column-sorters {
        padding: 0;
      }
    }
  }
  .ant-select-selection-item {
    width: 6rem;
  }
`;

export const ContentCheckbox = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const Checkbox = styled(CheckboxAnt)`
  zoom: 1.3;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--orange-350);
    border-color: var(--orange-350);
  }

  .ant-checkbox-inner {
    border: 1px solid var(--gray-650);

    :hover {
      border: 1px solid var(--gray-650);
    }
  }
`;

export const Col = styled(ColAnt)``;

export const LabelName = styled.label`
  text-transform: capitalize;
  font-weight: 400;
  @media only screen and (max-width: 578px) {
    margin-left: 5px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
