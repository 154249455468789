import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import apiMercury from "../../services/apiMercury";

import { Purchase } from "../../models/Purchase/Purchase";

import Centralizer from "../../containers/Centralizer";

import { notification, Spin } from "antd";

import {
  ButtonCancel,
  ButtonSave,
  Container,
  Modal,
  Table,
  TableContainer,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  stockPurchaseID: number | undefined;
}

const ModalImportStock: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  stockPurchaseID,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [purchase, setpurchase] = useState<Purchase>();
  const [importList, setImportList] = useState<any[]>([]);
  const [notImportList, setNotImportList] = useState<any[]>([]);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      setLoading(true);
      const {
        data: { content },
      } = await apiMercury.get(`/purchases/${stockPurchaseID}`);

      setpurchase(content);
      const importList = content?.purchasesItems?.filter(
        (item) => item?.product?.category?.is_stock
      );
      setImportList(importList);

      const notImportList = content?.purchasesItems?.filter(
        (item) => !item?.product?.category?.is_stock
      );
      setNotImportList(notImportList);
      setLoading(false);
    };
    if (visible && stockPurchaseID) {
      fetchProductsByCategory();
    }
  }, [visible, stockPurchaseID]);

  const payloadConstructor = () => {
    let payload: any[] = [];

    importList?.forEach(
      (product, index) =>
        (payload[index] = {
          store_id: purchase?.store_id,
          product_id: product?.product_id,
          quantity: purchase?.already_imported_as_stock
            ? -+product?.quantity
            : +product?.quantity,
        })
    );

    return payload;
  };

  const stockAdd = async () => {
    setLoading(true);
    const payload = await payloadConstructor();
    try {
      await api.post(`/products_store/stock`, payload);

      await apiMercury.put(`/purchases/${purchase?.id}`, {
        already_imported_as_stock: !purchase?.already_imported_as_stock,
      });
      setLoading(false);
      setShouldSearch(true);
      setVisible(false);
    } catch (error) {
      //@ts-ignore
      const message = error?.response?.data?.message;

      notification.error({
        message: message || "Erro ao fazer a atualização",
        duration: 5,
      });
    }
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "product.name",
      key: "product.name",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Modal
      title={
        purchase?.already_imported_as_stock
          ? "Estornar do Estoque"
          : "Importar para o Estoque"
      }
      centered
      visible={visible}
      closable={false}
      width={900}
      footer={[
        <ButtonCancel onClick={() => setVisible(false)}>Cancelar</ButtonCancel>,
        <ButtonSave
          loading={loading}
          disabled={!importList.length}
          onClick={() => stockAdd()}
        >
          Enviar
        </ButtonSave>
      ]}
    >
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Container>
          <>
            {importList.length > 0 && (
              <TableContainer
                style={{
                  width: `${(
                    purchase?.already_imported_as_stock ||
                    !notImportList.length) &&
                    "100%"
                  }`,
                }}
              >
                <h3 className="import">
                  Itens que{" "}
                  {purchase?.already_imported_as_stock
                    ? "serão estornados"
                    : "podem ser importados"}
                </h3>
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={importList.map((entity) => ({
                    ...entity,
                    key: entity.id,
                  }))}
                  scroll={{ y: 350 }}
                  pagination={false}
                />
              </TableContainer>
            )}
            {!purchase?.already_imported_as_stock &&
              notImportList.length > 0 && (
                <TableContainer
                  style={{
                    width: `${!importList.length && "100%"}`,
                  }}
                >
                  <h3 className="export">Itens que não podem ser importados</h3>
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={notImportList.map((entity) => ({
                      ...entity,
                      key: entity.id,
                    }))}
                    scroll={{ y: 350 }}
                    pagination={false}
                  />
                </TableContainer>
              )}
          </>
        </Container>
      )}
    </Modal>
  );
};

export default ModalImportStock;
