import styled, { css } from "styled-components";

import {
  Modal,
  Row as RowAnt,
  Col as ColAnt,
  Select as SelectAnt,
  Form as FormAnt,
  Button as ButtonAnt,
} from "antd";

export const Container = styled(Modal)`
  .ant-modal-body {
    padding-top: 0;
  }
  * .ant-form-vertical .ant-form-item-label > label {
    color: var(--gray-45);
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const Form = styled(FormAnt)`
  .ant-form-item {
    margin-bottom: 1rem;
  }

  .full-value-container {
    display: flex;
    justify-content: end;
    width: 100%;
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const ButtonAddFullValue = styled(ButtonAnt)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3.25rem;
  height: 3.25rem;

  color: white;
  background-color: var(--orange-350);

  border-radius: 0.2rem;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const TotalPayment = styled.span`
  font-size: 1.2rem;
  color: var(--gray-530);
`;
