import styled, { css } from 'styled-components';

import {
  Row as RowAnt,
  Col as ColAnt,
  Form as FormAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
  Select as SelectAnt,
  Button as ButtonAnt
} from 'antd';
import { InfoCircle, TrashIconA, TrashFeather, FiletypePdf } from '../../../../styles/Icons';

const Icon = css`
    width: 1rem;
    height: 1rem;
`;

export const TrashIcon = styled(TrashFeather)`
    ${Icon}
    width: 1.5rem;
    height: 1.5rem;
    color: var(--maroon);
    cursor: pointer;
`;

export const TrashIconUpload = styled(TrashIconA)`
    ${Icon}
    width: 1rem;
    height: 1rem;
    color: var(--maroon);
    cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const Row = styled(RowAnt)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const ContentFiles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentFilesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: .5rem;

  padding: .5rem;

  &:hover {
    background-color: var(--white-100); 
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Col = styled(ColAnt)`
  height: 5.1rem;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

export const ColAnexo = styled(ColAnt)`
`;

export const ColIcon = styled(ColAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Form = styled(FormAnt)`
    width: 100%;
    
    .ant-form-item-label > label {
        font-weight: 400 !important;
    }
`;

export const TitlePage = styled.span`
    font-size: 1.1rem;
    color: var(--gray-650);
    margin-bottom: .2rem;
    font-weight: 500;
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: start;
`;

interface IPropsButton {
  color: string;
  backgroundColor: string;
  border: string;
  borderColor?: string;
}
export const Button = styled(ButtonAnt) <IPropsButton>`
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;

    width: 15rem;
    border: ${(props) => props.border};
    border-color: ${(props) => props.borderColor};

    padding: 1.5rem;
    border-radius: 5px;

    :first-child {
        margin-right: 1rem;
    }

    :hover, :focus {
        color: ${(props) => props.color};
        background-color: ${(props) => props.backgroundColor};
        opacity: 80%;
    }
`;

export const NewRowButton = styled(ButtonAnt)`
    display: flex;
    align-items: start;
    justify-content: start;

    background-color: transparent;
    border: none;
    font-size: .9rem;
    box-shadow: none;

    color: var(--gray-170);
    font-weight: 500;

    padding: 0;
    margin: 0;
`;

export const ContentPDF = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: .8rem;
    color: var(--gray-600);
    font-size: .9rem;
  }
`;

export const ContentImage = styled.img`
  width: 10rem;
  height: 10rem; 
  margin: .5rem;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 5px;

  cursor: pointer;
  color: var(--c64-blue);
`;

export const FiletypePdfIcon = styled(FiletypePdf)`
  width: 25px;
  height: 25px;

  cursor: pointer;
  color: var(--gray-200);
`;

export const UploadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;

  input[type="file"]::file-selector-button {
    border: none;
    background: var(--orange-gradient);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 100%;
  }
`;