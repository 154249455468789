import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";
import { verifyPermission } from "../../services/auth";

import { DREModel } from "../../models/DREModel";
import { CategoryModel } from "../../models/CategoryModel";

import CategoryList from "../../containers/CategoryList";
import DREList from "../../containers/DREList";
import PageContainer from "../../containers/PageContainer";

import "moment/locale/pt-br";

import { Col, Form, Modal, notification, Row, Tooltip } from "antd";

import {
  AddIcon,
  Button,
  CancelButton,
  Container,
  Input,
  Select,
  TopSide,
  ContentFooter,
  ButtonAddCategory,
  OptionInfoContainer,
  OptionLabel,
  Option,
  ContentDRETable,
  ContentDRETopSide,
  ButtonModal,
  Checkbox,
} from "./styles";

interface IProps extends RouteComponentProps { }

const Category: React.FC<IProps> = () => {
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [DREs, setDREs] = useState<DREModel[]>([]);
  const [isStock, setIsStock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [visible, setVisible] = useState(false);
  const [dreVisible, setDREVisible] = useState(false);
  const [dreTableVisible, setTableDREVisible] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState<
    CategoryModel[] | undefined
  >(undefined);
  const [filteredDre, setFilteredDre] = useState<DREModel[] | undefined>(
    undefined
  );
  const [category, setCategory] = useState("");
  const [dre, setDre] = useState("");

  const [form] = Form.useForm();
  const [formDRE] = Form.useForm();

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await api.get(`/product_categories?loadDre=true`);
        {
          verifyPermission("category.restore")
            ? setCategories(data)
            : setCategories(data.filter((category) => !category?.deleted_at));
        }

        setShouldSearch(false);
        setLoading(false);
      } catch (error) {
        const _message = "Houve um erro ao realizar busca";

        //@ts-ignore
        const _description = error?.response?.data?.error?.message;
        notification.error({
          message: _message,
          description: _description,
          duration: 5,
        });
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };

    if (shouldSearch) {
      fetchCategory();
      setShouldSearch(false);
    }

    if (category) {
      const filteredCategories = categories?.filter((_category) =>
        _category.name.toLowerCase().includes(category.toLowerCase())
      );

      setFilteredCategory(filteredCategories);
    } else {
      setFilteredCategory(undefined);
    }
  }, [shouldSearch, categories, category]);

  useEffect(() => {
    const fetchDRE = async () => {
      setLoading(true);
      try {
        const {
          data: { content },
        } = await api.get(`/dre`);
        {
          verifyPermission("category.restoreDre")
            ? setDREs(content)
            : setDREs(content.filter((DRE) => !DRE?.deleted_at));
        }
        setDREs(content);
        setShouldSearch(false);
        setLoading(false);
      } catch (error) {
        const _message = "Houve um erro ao realizar busca";

        //@ts-ignore
        const _description = error?.response?.data?.error?.message;
        notification.error({
          message: _message,
          description: _description,
          duration: 5,
        });
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };

    if (shouldSearch) {
      fetchDRE();
      setShouldSearch(false);
    }

    if (dre) {
      const filteredDres = DREs?.filter((_dre) =>
        _dre.name.toLocaleLowerCase().includes(dre.toLocaleLowerCase())
      );

      setFilteredDre(filteredDres);
    } else {
      setFilteredDre(DREs);
    }
  }, [shouldSearch, DREs, dre]);

  const handleAddCategory = async () => {
    await form.validateFields();
    const payload = form.getFieldsValue();
    const method = payload.id ? "put" : "post";
    const url = payload.id
      ? `/product_categories/${payload.id}`
      : `/product_categories`;
    setLoading(true);
    try {
      await api[method](url, {
        ...payload,
        name: payload.name.toLowerCase(),
        is_stock: isStock,
      });

      if (payload.id) {
        form.setFieldsValue({ dre_id: payload?.name });
        notification.success({
          message: "Categoria editada com sucesso",
          duration: 5,
        });
      } else {
        notification.success({
          message: "Nova categoria cadastrada com sucesso",
          duration: 5,
        });
      }

      setVisible(false);
      setShouldSearch(true);
      form.resetFields();
      setIsStock(false);
    } catch (error) {
      const _message = payload.id
        ? "Falha ao editar categoria!"
        : "Falha ao adicionar categoria!";

      const description =
        //@ts-ignore
        error?.response?.data?.error?.message;

      notification.error({
        message: _message,
        description: description,
        duration: 5,
      });
    } finally {
      setLoading(false);
      setShouldSearch(false);
    }
  };

  const setEditCategory = async (id: number) => {
    setVisible(true);
    const categoriesField = categories.find((category) => category.id === id);

    setIsStock(categoriesField ? categoriesField.is_stock : false);
    form.setFieldsValue({
      ...categoriesField,
    });
  };

  const handleAddDRE = async () => {
    await formDRE.validateFields();
    const payload = formDRE.getFieldsValue();
    const method = payload.id ? "put" : "post";
    const url = payload.id ? `/dre/${payload.id}` : `/dre`;

    setLoading(true);
    try {
      await api[method](url, { ...payload, name: payload.name.toLowerCase() });
      if (payload.id) {
        form.setFieldsValue({ dre_id: payload?.name });

        notification.success({
          message: "DRE editado com sucesso",
          duration: 5,
        });
      } else {
        notification.success({
          message: "Novo DRE cadastrado com sucesso",
          duration: 5,
        });
      }
      setDREVisible(false);
      setShouldSearch(true);
      formDRE.resetFields();
    } catch (error) {
      const _message = "Falha ao adicionar DRE!";

      const description =
        //@ts-ignore
        error?.response?.data?.error?.message;

      notification.error({
        message: _message,
        description: description,
        duration: 5,
      });
    } finally {
      setLoading(false);
      setShouldSearch(false);
    }
  };

  const setEditDRE = async (id: number) => {
    setDREVisible(true);
    setShouldSearch(false);

    const dreFields = DREs.find((dre) => dre.id === id);

    formDRE.setFieldsValue({
      ...dreFields,
    });
  };

  return (
    <PageContainer route="Categoria">
      <Container>
        <Modal
          destroyOnClose={true}
          title={
            formDRE.getFieldValue("id") ? "Editar DRE" : "Cadastrar novo DRE"
          }
          centered
          visible={dreVisible}
          closable={false}
          onOk={() => setDREVisible(false)}
          onCancel={() => setDREVisible(false)}
          width={window.outerWidth > 768 ? "40%" : "85%"}
          footer={[
            <ContentFooter key="footer-dre">
              <CancelButton
                key="back"
                type="link"
                onClick={() => {
                  setDREVisible(false);
                  formDRE.resetFields();
                }}
              >
                Cancelar
              </CancelButton>

              <ButtonModal
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleAddDRE}
              >
                {formDRE.getFieldValue("id") ? "Salvar DRE" : "Cadastrar DRE"}
              </ButtonModal>
            </ContentFooter>,
          ]}
        >
          <Form layout="vertical" form={formDRE}>
            <Row gutter={12}>
              <Col sm={0} xs={0}>
                <Form.Item name="id">
                  <Input name="id" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input name="name" />
                </Form.Item>
              </Col>

              <Col sm={12} xs={24}>
                <Form.Item
                  label="Ordenação"
                  name="sort"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                    {
                      pattern: /^\d+$/,
                      message: "Apenas números inteiros e positivos",
                    },
                  ]}
                >
                  <Input name="sort" type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          destroyOnClose
          title="DRE"
          centered
          visible={dreTableVisible}
          closable={true}
          onOk={() => setTableDREVisible(false)}
          onCancel={() => {
            setTableDREVisible(false);
            setDre("");
          }}
          width={window.outerWidth > 768 ? "60%" : "85%"}
          footer={false}
        >
          <ContentDRETable>
            <ContentDRETopSide gutter={12}>
              <Col lg={10} md={24}>
                <Input
                  placeholder="Buscar"
                  onChange={({ target: { value } }) => setDre(value)}
                  style={{ width: "100%" }}
                />
              </Col>

              {verifyPermission("category.createDre") && (
                <Col lg={5} md={24}>
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={() => setDREVisible(true)}
                  >
                    Cadastrar DRE
                  </Button>
                </Col>
              )}
            </ContentDRETopSide>
          </ContentDRETable>
          <DREList
            setEditDre={setEditDRE}
            setShouldSearch={setShouldSearch}
            loading={loading}
            setLoading={setLoading}
            dre={filteredDre || DREs}
            setDre={setDREs}
          />
        </Modal>

        <Modal
          title={
            form.getFieldValue("id")
              ? "Editar categoria"
              : "Cadastrar categoria"
          }
          centered
          visible={visible}
          closable={false}
          onCancel={() => setVisible(false)}
          width={window.outerWidth > 768 ? "40%" : "85%"}
          footer={[
            <ContentFooter key="footer-category">
              <CancelButton
                key="back"
                type="link"
                onClick={() => {
                  setVisible(false);
                  form.resetFields();
                }}
              >
                Cancelar
              </CancelButton>

              <ButtonModal
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleAddCategory}
              >
                Salvar
              </ButtonModal>
            </ContentFooter>,
          ]}
          destroyOnClose
        >
          <Form layout="vertical" form={form}>
            <Row gutter={12}>
              <Col sm={0} xs={0}>
                <Form.Item name="id">
                  <Input name="id" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Input name="name" />
                </Form.Item>
              </Col>

              <Col sm={12} xs={24}>
                <Form.Item
                  label="Código DRE"
                  name="dre_id"
                  rules={[{ required: true, message: "Campo obrigatório" }]}
                >
                  <Select
                    placeholder="Escolha a Opção"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() || "").includes(input)
                    }
                    optionFilterProp="children"
                  >
                    {DREs.filter((types) => !types?.deleted_at)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((types) => (
                        <Option
                          value={types.id}
                          key={types.id}
                          label={types.name}
                        >
                          <OptionInfoContainer>
                            <OptionLabel>{types.name}</OptionLabel>
                          </OptionInfoContainer>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24}>
                <Checkbox
                  checked={isStock}
                  onChange={() => {
                    setIsStock(!isStock);
                  }}
                >
                  <Tooltip
                    title={
                      "Quando selecionado habilita a importação da categoria ao estoque"
                    }
                  >
                    <span>Importável</span>
                  </Tooltip>
                </Checkbox>
              </Col>
            </Row>
          </Form>
        </Modal>

        <TopSide gutter={12}>
          <Col sm={6} xs={24}>
            <Input
              placeholder="Buscar"
              onChange={({ target: { value } }) => setCategory(value)}
            />
          </Col>
          {verifyPermission("category.create") && (
            <Col md={4} sm={8} xs={24}>
              <ButtonAddCategory onClick={() => setVisible(true)}>
                Adicionar categoria
                <AddIcon />
              </ButtonAddCategory>
            </Col>
          )}

          <Col sm={3} xs={24}>
            <ButtonAddCategory onClick={() => setTableDREVisible(true)}>
              DRE
            </ButtonAddCategory>
          </Col>
        </TopSide>

        <CategoryList
          setEditCategory={setEditCategory}
          setShouldSearch={setShouldSearch}
          loading={loading}
          setLoading={setLoading}
          category={filteredCategory || categories}
          setCategory={setCategories}
        />
      </Container>
    </PageContainer>
  );
};

export default withRouter(Category);
