export function useResponsiveSizeTable(maxWidth) {
  if (maxWidth.height >= 821) {
    return 580;
  }
  if (820 >= maxWidth.height && maxWidth.height >= 701) {
    return 450;
  }
  if (700 >= maxWidth.height && maxWidth.height >= 661) {
    return 400;
  }
  if (660 >= maxWidth.height && maxWidth.height >= 571) {
    return 340;
  }
  if (570 >= maxWidth.height) {
    return 260;
  }

  return useResponsiveSizeTable as any;
}
