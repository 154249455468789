import styled from "styled-components";
import {
  Col as ColAnt,
  Row as RowAnt,
  Drawer as DrawerAnt,
  Input as InputAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
  Modal as ModalAnt,
} from "antd";
import { AddCircleOutline, Download, UploadIcon } from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .ant-col {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  text-align: center;
`;

export const Row = styled(RowAnt)`
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 3.7rem;
  margin-bottom: 0.3rem;
`;

export const RowModal = styled(RowAnt)`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  overflow-y: auto;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 3.25rem;

  color: white;

  border-radius: 5px;
  justify-content: center;
  cursor: pointer;

  background: var(--orange-gradient);
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-top: 0.5rem;
  }
`;

export const AddButton2 = styled.button`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  margin-left: -8px;
  background-color: var(--orange-350);

  @media only screen and (max-width: 1400px) {
    font-size: 15px;
    height: 35px;
    font-size: 0.8rem;
  }
`;

export const AddIcon = styled(AddCircleOutline)`
  width: 20px;
  height: 20px;
  fill: white;
`;

export const DownloadIcon = styled(Download)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 7px;
  fill: white;
`;

export const Col = styled(ColAnt)`
  display: flex;
  justify-content: flex-end;

  width: 100%;
`;

export const ColModal = styled(ColAnt)`
  font-weight: 600;
  padding: 2px;
`;

export const ModalTitle = styled.h1`
  font-size: 2.5rem;

  @media only screen and (max-width: 578px) {
    font-size: 1.75rem;
  }
`;

export const Drawer = styled(DrawerAnt)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
  .ant-drawer-content-wrapper {
    @media only screen and (max-width: 578px) {
      width: 300px !important;
    }
  }
`;

export const DrawerContent = styled.div`
  width: 100%;
  @media only screen and (max-width: 700px) {
    height: 100%;
  }
`;

export const Input = styled(InputAnt)`
  border-radius: 4px;

  &::placeholder {
    color: var(--gray-45);
  }
  :hover,
  :focus,
  :active {
    box-shadow: 0 0 0 0;
    outline: none;
    border: 1px solid #ccc;
  }
`;

export const DrawerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 60%;
`;

export const ButtonCancelModal = styled(ButtonAnt)`
  height: 3.25rem;
  border: none;
  box-shadow: none;
  margin-top: 0;

  @media only screen and (max-width: 578px) {
    width: 100%;
    border-radius: 5px;
    width: 100%;
    color: var(--gray-45);
    border: 1px solid var(--gray-45);
    background-color: white;
    margin-top: 1rem;
  }
`;

export const SaveButton = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  width: 11.3rem;
  height: 3.25rem;

  color: white;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;

  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--orange-350);

  @media only screen and (max-width: 578px) {
    width: 100%;
    color: var(--orange-350);
    border: 1px solid var(--orange-350);
    background-color: white;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

interface IPropsUpload {
  disableUploadField?: boolean;
}

export const ModalRegister = styled(ModalAnt)<IPropsUpload>`
  .ant-modal-body {
    max-height: 30rem;
    overflow: auto;
  }

  input {
    height: 3.25rem;
  }

  .ant-form-vertical .ant-form-item-label > label {
    color: var(--gray-45);
  }

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3.25rem;
  }
  .uploadTamMaxText {
    display: none;
  }
  .containerUpload {
    height: 3.25rem;
  }
  @media only screen and (max-width: 950px) {
    .ant-form-vertical .ant-form-item-label > label {
      height: 2.5rem;
    }
  }
  @media only screen and (max-width: 575px) {
    .ant-modal-body {
      max-height: 15rem;
      padding-top: 0;
    }
    .ant-modal-footer {
      background: none;
    }
    .ant-modal-footer button + button {
      margin: 0;
    }
    .ant-form-vertical .ant-form-item-label > label {
      height: 1rem;
    }
  }
`;

export const IconUploadComp = styled(UploadIcon)`
  width: 20px;
  height: 20px;
  margin-right: 0.2rem;
  fill: var(--orange-350);
`;

export const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UploadContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    color: var(--maroon);
  }
`;

export const TotalPaymentContent = styled.div`
  display: flex;
  background-color: var(--orange-350);
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px 8px 0 0;
  width: 15%;

  span {
    color: var(--white);
    font-weight: bold;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 1rem;
  border-top: 1px solid var(--fading-sunset);
`;

export const ContainerPagination = styled.div`
  display: flex;
  margin-top: 1rem;
`;
