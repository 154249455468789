import styled from "styled-components";

import { Modal as ModalAnt, Table as TableAnt } from "antd";

import { Trash } from "../../../styles/Icons";

export const Container = styled(ModalAnt)`
  .paymentValue {
    font-size: 1rem;
    font-weight: 500;
    color: var(--blue-99);
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`
  padding: 0.2rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
`;

export const InfoTotalPayment = styled.span`
  color: var(--blue-900);
  font-weight: 500;
  font-size: 1rem;
`;

export const ButtonRemovePayment = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;

  user-select: none;
  background: var(--orange-350);

  border-radius: 0.3rem;

  :hover {
    color: var(--maroon);
  }
`;

export const TrashIcon = styled(Trash)`
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--white-10);
`;
