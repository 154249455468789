import React, { useState, useEffect } from "react";
import GlobalStyles from "./styles/GlobalStyles";
import apiAuth from "./services/apiAuth";
import { setAccess } from "./services/auth";
import Routes from "./routes";

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function refreshPermissions() {
      try {
        const {
          data: { modules, permissions },
        } = await apiAuth.get("/user/access");
        setAccess(JSON.stringify(modules), JSON.stringify(permissions));
        console.log("Success to refresh user permissions");
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    refreshPermissions();
  }, []);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <Routes />
          <GlobalStyles />
        </>
      )}
    </>
  );
};

export default App;
