import styled from "styled-components";

import { Bar as BarNivo } from "@nivo/bar";

import { ArrowLeft } from "../../../../styles/Icons";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 10%;

  .range-date-content {
    width: 20%;
  }
`;

export const GoBackButton = styled.button`
  display: flex;
  align-items: center;
  width: 10%;
  height: 60%;
  background-color: white;
  border-radius: 15px;
  justify-content: space-evenly;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
`;

export const TotalInfo = styled.span`
  font-weight: 500;
  font-size: 1.5rem;
  margin-right: 10px;
  color: var(--gray-45);

  span {
    color: var(--orange-350);
  }

  @media only screen and (max-width: 1300px) {
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 1150px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 900px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 575px) {
    font-size: 1rem;
  }
`;

export const ChartContainer = styled.div``;

export const ArrowLeftIcon = styled(ArrowLeft)`
  width: 1rem;
  height: 1rem;
  color: orange;
`;

export const Bar = styled(BarNivo)``;

export const TableTooltip = styled.table`
  display: table;
`;

export const TableTooltipTH = styled.th`
  padding: 0.3rem;
  border: 1px solid var(--gray-25);

  font-size: 1rem;
  font-weight: 500;
  color: var(--blue-900);
  text-transform: capitalize;
`;

export const TableTooltipTD = styled.td`
  border: 1px solid var(--gray-25);
  padding: 0.3rem;

  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-45);
  text-transform: capitalize;
`;

export const IconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;

  img {
    width: 100%;
    height: 100%;
  }
`;
