import React, { useState, useEffect } from "react";

import { withRouter, RouteComponentProps } from "react-router";

import moment from "moment";

import apiMercury from "../../../../services/apiMercury";
import { currencyFormater } from "../../../../services/currencyFormater";

import {
  PurchaseReport as PurchaseReportModel,
  CategoryReport,
} from "../../models/PurchaseReport";

import { useWindowSize } from "../../../../hooks/useWindowSize";

import PageContainer from "../../../../containers/PageContainer";

import RangeDataField from "../../../../components/RangeDataField";

import EmptyProduct from "../../../../assets/svg/nodatapurchase.svg";

import { Empty } from "antd";

import {
  Container,
  Header,
  Content,
  GoBackButton,
  ArrowLeftIcon,
  TotalInfo,
  ChartContainer,
  Bar,
  TableTooltip,
  TableTooltipTH,
  TableTooltipTD,
  IconContent,
} from "./styles";

interface MatchParams {
  store_id?: string;
}
interface IProps extends RouteComponentProps<MatchParams> { }

const PurchaseReport: React.FC<IProps> = ({ history, match }) => {
  const { store_id } = match.params;

  const [purchaseReport, setPurchaseReport] = useState<PurchaseReportModel>();

  const [date, setDate] = useState({
    data_inicial: moment(),
    data_final: moment().add(1, "day"),
  });

  const responsiveWindow = useWindowSize();

  const responsiveChart = (value: string) => {
    let _responsive = { height: 0, width: 0 };

    _responsive.height = responsiveWindow.height * 0.8;

    _responsive.width = responsiveWindow.width * 0.8;

    return value === "height" ? _responsive.height : _responsive.width;
  };

  useEffect(() => {
    const fecthReport = async () => {
      const { data_inicial, data_final } = {
        data_inicial: moment(date.data_inicial).format("DD/MM/YYYY"),
        data_final: moment(date.data_final).format("DD/MM/YYYY"),
      };

      const {
        data: { content },
      } = await apiMercury.get(
        `/purchases/${store_id}/report?data_inicial=${data_inicial}&data_final=${data_final}`
      );

      setPurchaseReport(content);
    };

    fecthReport();
  }, [date.data_inicial, date.data_final, store_id]);

  const chartData = purchaseReport?.categories.map(
    (category: CategoryReport) => ({
      id: category.id,
      total: category.total,
      category: category.name.charAt(0).toUpperCase() + category.name.slice(1),
      products: category.products,
    })
  );
  const data = chartData?.sort((a, b) => a?.total - b?.total);

  return (
    <PageContainer route="Relatório de compras">
      <Container>
        <Header>
          <GoBackButton
            onClick={() =>
              history.push({
                pathname: "/shop",
                state: { store_param: store_id },
              })
            }
          >
            <ArrowLeftIcon />
            Voltar
          </GoBackButton>

          <div className="range-date-content">
            <RangeDataField data={date} setData={setDate} />
          </div>
        </Header>
        <Content>
          <TotalInfo>
            Total no período
            <span> R$ {currencyFormater(purchaseReport?.total)}</span>
          </TotalInfo>
          {data?.length ? (
            <ChartContainer>
              {data && (
                <Bar
                  data={data}
                  layout="horizontal"
                  margin={{ bottom: 50, left: 200 }}
                  height={responsiveChart("height")}
                  width={responsiveChart("width")}
                  key={"id"}
                  keys={["total"]}
                  indexBy="category"
                  colors={"var(--orange-350)"}
                  padding={0.7}
                  enableGridX={true}
                  enableGridY={true}
                  axisTop={{
                    tickSize: 0,
                    tickPadding: 10,
                  }}
                  axisBottom={{
                    format: (value) => `R$ ${currencyFormater(Number(value))}`,
                    tickValues: 5,
                    tickSize: 0,
                    tickPadding: 10,
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 10,
                  }}
                  labelFormat={(value) => "R$ " + currencyFormater(+value)}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  theme={{
                    labels: {
                      text: {
                        textTransform: "capitalize",
                        fontSize: "0.9rem",
                      },
                    },
                  }}
                  tooltip={(_dataBar, index) => {
                    return (
                      <TableTooltip key={index}>
                        <thead>
                          <tr>
                            <TableTooltipTH>Nome</TableTooltipTH>
                            <TableTooltipTH>Valor</TableTooltipTH>
                          </tr>
                        </thead>
                        {data
                          .find(
                            (_category) =>
                              _category.category === _dataBar.data.category
                          )
                          ?.products.map((_product) => (
                            <tbody>
                              <tr>
                                <TableTooltipTD>{_product.name}</TableTooltipTD>
                                <TableTooltipTD>
                                  R$ {currencyFormater(+_product.total)}
                                </TableTooltipTD>
                              </tr>
                            </tbody>
                          ))}
                      </TableTooltip>
                    );
                  }}
                />
              )}
            </ChartContainer>
          ) : (
            <IconContent>
              <Empty
                description="Nenhuma compra encontrada durante o período de tempo selecionado. Por favor selecione um outro período."
                image={EmptyProduct}
                imageStyle={{
                  height: 500,
                }}
              />
            </IconContent>
          )}
        </Content>
      </Container>
    </PageContainer>
  );
};

export default withRouter(PurchaseReport);
