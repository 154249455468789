import styled, { css } from "styled-components";
import { Button as ButtonAnt, Modal as ModalAnt, Radio as RadioAnt } from "antd";
import { WarningOutline } from "../../../styles/Icons";

const ButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    border-radius: .5rem;
    padding: 1.5rem;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Radio = styled(RadioAnt)`
    margin-bottom: .5rem;
`;

export const FooterDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
`;

export const Modal = styled(ModalAnt)`
    .ant-modal-body {
        padding: 0 1.5rem 1.5rem;
    }
`;

export const Button = styled(ButtonAnt)`
  ${ButtonStyle}
  background-color: var(--orange-350);
  color: var(--white);

  :hover,
  :focus {
    background-color: var(--orange-350);
    color: var(--white);
    opacity: 80%;
  }
`;
export const ButtonCancel = styled(ButtonAnt)`
  ${ButtonStyle}
  background-color: transparent;
  color: var(--gray-45);
  :hover,
  :focus {
    background-color: transparent;
    color: var(--gray-45);
    opacity: 80%;
  }
`;

export const ContentHeaderTitle = styled.div`
    display: flex;
    align-items: center;
`;
export const WarningIcon = styled(WarningOutline)`
    color: var(--orange-350);
`;