import styled, { css } from 'styled-components';
import { Button as ButtonAnt, Table as TableAnt } from 'antd';

import { Trash } from '../../../styles/Icons'

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;
export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const Table = styled(TableAnt)``;

export const TrashIcon = styled(Trash)`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  color: var(--orange-600);
`;
