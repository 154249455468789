import styled from "styled-components";

import {
  Modal as ModalAnt,
  Col as ColAnt,
  Row as RowAnt,
  Table as TableAnt,
} from "antd";
import { AuditType } from "../../containers/CashHistoryList";

interface IProps {
  type: AuditType;
}

export const StatusChange = styled.div<IProps>`
  color: var(--black-opaco);
  font-size: 1rem;
  display: flex;
  align-items: center;

  ${({ type, children }) =>
    type === AuditType.sale && children === "Restaurada"
      ? `color: var(--clear-chill);`
      : type === AuditType.sale
      ? `color: var(--maroon);`
      : type === AuditType.cash_history
      ? `color: var(--golden-beryl-yellow);`
      : type === AuditType.balance_history
      ? `color: var(--midori-green);`
      : `color: var(--black-opaco);`}
`;

export const Modal = styled(ModalAnt)`
  width: 70%;
  * .ant-modal-header {
    padding: 2rem;
  }
  .ant-modal-body {
    max-height: 25rem;
    padding: 0 2rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-footer {
    margin-top: 1rem;
    height: 5.25rem;
    padding: 1rem;
    button {
      width: 12rem;
      height: 3.25rem;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1366px) {
    * .ant-modal-header {
      padding: 1rem;
    }

    .ant-modal-title {
      font-size: 1.5rem;
    }
    .ant-modal-footer button {
      width: 8rem;
      height: 3rem;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Row = styled(RowAnt)``;

export const Col = styled(ColAnt)`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-height: 3.35rem;
  border-bottom: 1px solid var(--gray-25);
  font-size: 1rem;
  font-weight: 500;

  @media only screen and (max-width: 578px) {
    min-height: 2.3rem;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }
`;
export const Item = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  min-height: 5.3rem;
  color: var(--gray-45);
  font-size: 1rem;
  font-weight: 400;

  @media only screen and (max-width: 578px) {
    min-height: 3.3rem;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 0.9rem;
  }
`;

export const ContainerDescription = styled.div`
  overflow: scroll;
  text-overflow: ellipsis;
  padding: 1rem 0 2rem;
  min-height: 2rem;
  font-size: 1rem;
  .title {
    font-weight: 500;
    color: var(--blue-900);
  }
  .obs {
    font-weight: 400;
    color: var(--gray-45);
  }
`;

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Table = styled(TableAnt)`
  max-height: 25rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  overflow: auto;

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
  }
`;
