import styled, { css } from "styled-components";

import { ArrowNarrowRight } from "../../../styles/Icons";

import {
  Modal as ModalAnt,
  Table as TableAnt,
  Button as ButtonAnt,
} from "antd";

export const Container = styled(ModalAnt)``;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 12.5rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonChange = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

interface IProps {
  newvalue: string | number;
  oldvalue: string | number;
}

export const StatusChange = styled.div<IProps>`
  color: var(--black-opaco);
  font-size: 1rem;

  ${({ newvalue, oldvalue }) => {
    if (!oldvalue && newvalue) {
      return css`
        color: var(--midori-green);
      `;
    }

    if (oldvalue && !newvalue) {
      return css`
        color: var(--maroon);
      `;
    }

    return css`
      color: var(--golden-beryl-yellow);
    `;
  }};
`;

export const ArrowRightIcon = styled(ArrowNarrowRight)`
  width: 1.1rem;
  height: 1.1rem;
  margin: 0 0.5rem;
  @media only screen and (max-width: 578px) {
    width: 0.9rem;
    height: 0.9rem;
  }
`;

export const Table = styled(TableAnt)`
  max-height: 25rem;
  padding: 0.2rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;
  border: 1px solid var(--gray-25);

  overflow: auto;

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--blue-900);
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem;
    @media only screen and (max-width: 800px) {
      font-size: 0.8rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 500;
    font-size: 1rem;
    text-overflow: ellipsis;

    overflow: hidden;
    overflow-wrap: unset;

    padding: 0.5rem;

    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
      padding: 0.3rem;
    }
    @media only screen and (max-width: 575px) {
      height: 3.25rem;
      max-height: 3.25rem;
    }
    @media only screen and (max-width: 500px) {
      padding: 0.2rem;
    }
  }
`;
