import { Empty, Spin } from "antd";
import React, { Dispatch, SetStateAction } from "react";

import { Sale } from "../../models/Sale";
import useEscapeKey from "../../utils/useEscapeKey";
import { currencyFormater } from "../../services/currencyFormater";

import {
  ButtonCancel,
  ButtonOk,
  Container,
  ContentFooter,
  Table,
  Modal,
  IdText,
  ContentAmountAndDiscount,
  ContentTopInformation,
} from "./styles";
import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  cashHistorySale: Sale | null;
  types: number[];
  loading: boolean;
  countryPY?: boolean;
  cashHistories?: CashHistoriesModel[];
}

const ModalSaleDetail: React.FC<IProps> = ({
  visible,
  setVisible,
  cashHistorySale,
  types,
  loading,
  countryPY,
  cashHistories,
}) => {
  const handleModalToggle = () => {
    setVisible(!visible);
  };

  useEscapeKey({ setVisible: () => setVisible(false) });

  const paymentMethodCodes = {
    money: 0,
    credit_card: 1,
    debit_card: 2,
    ticket: 3,
    online: 4,
    pix: 6,
  };

  const getPaymentMethod = (item, paymentMethodCodes) => {
    let paymentMethod = "";

    if (item === paymentMethodCodes.money) {
      paymentMethod = "Dinheiro";
    } else if (item === paymentMethodCodes.credit_card) {
      paymentMethod = "Cartão de crédito";
    } else if (item === paymentMethodCodes.debit_card) {
      paymentMethod = "Cartão de débito";
    } else if (item === paymentMethodCodes.ticket) {
      paymentMethod = "Ticket";
    } else if (item === paymentMethodCodes.online) {
      paymentMethod = "Pagamento online";
    } else {
      paymentMethod = "Pagamento PIX";
    }

    return <div>{paymentMethod}</div>;
  };

  const currencySymbols = {
    0: "Gs.",
    1: "$",
    2: "R$",
    3: "AR$",
  };

  const currencyTypes = {
    0: "Guarani",
    1: "Dólar",
    2: "Real",
    3: "Peso Argentino",
  };

  const currencyCodes = {
    0: "PYG",
    1: "USD",
    2: "BRL",
    3: "ARS",
  };

  const getCurrencyType = (currencyCode) => {
    return currencyTypes[currencyCode] || "Desconhecido";
  };
  const getExchangeRate = (sale) => {
    if (!sale || !cashHistories) return 1;

    const cashHistory = cashHistories.find(
      (history) => history.id === sale.cash_history_id
    );

    return cashHistory?.pyg_exchange_rate || 1;
  };

  const exchangeRate = getExchangeRate(cashHistorySale);

  const formatAmount = (amount, typeCurrency) => {
    const currencyCode = currencyCodes[typeCurrency] || "Unknown";
    if (currencyCode !== "PYG") {
      return currencyFormaterPYGBRL(amount, currencyCode);
    } else {
      return currencyFormaterPYGBRL(amount, currencyCode);
    }
  };
  const columns = [
    {
      title: "Itens",
      dataIndex: "name",
      key: "name",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: (text, record) => <span>{record.quantity}</span>,
    },
    {
      title: "Valor",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        const exchangeRate = getExchangeRate(cashHistorySale);
        const totalInCurrency = countryPY
          ? record.total / +exchangeRate
          : record.total;
        const currencyCode = countryPY ? "PYG" : "BRL";
        const currencySymbol = countryPY ? "Gs." : "R$";
        return (
          <span>{`${currencySymbol} ${currencyFormaterPYGBRL(
            totalInCurrency,
            currencyCode
          )}`}</span>
        );
      },
    },
  ];
  const columnsPayments = [
    {
      title: "Tipo de pagamento",
      dataIndex: "type",
      key: "type",
      render: (_, record) => getPaymentMethod(record.type, paymentMethodCodes),
    },
    ...(countryPY
      ? [
          {
            title: "Moeda",
            dataIndex: "type_currency",
            key: "type_currency",
            render: (item) => getCurrencyType(item),
          },
        ]
      : []),

    ...(!countryPY
      ? [
          {
            title: "Código NSU",
            dataIndex: "code_nsu",
            key: "code_nsu",
            render: (item) => item,
          },
        ]
      : []),
    ...(!countryPY
      ? [
          {
            title: "Status TEF",
            dataIndex: "tef_status_payment",
            key: "tef_status_payment",
            render: (item, record) =>
              record.code_nsu ? (
                item === 0 ? (
                  <span>Aprovada</span>
                ) : (
                  <span>Cancelada</span>
                )
              ) : (
                "Venda realizada sem TEF"
              ),
          },
        ]
      : []),
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: (payment, record) => {
        const convertedAmount = countryPY
          ? payment / (record.exchange_rate || 1)
          : payment;
        const typeCurrency = record.type_currency;
        const currencySymbol = currencySymbols[typeCurrency] || "R$";
        return (
          <span>{`${currencySymbol} ${formatAmount(
            convertedAmount,
            typeCurrency
          )}`}</span>
        );
      },
    },
    ...(countryPY
      ? [
          {
            title: "Valor em Guarani",
            dataIndex: "amount",
            key: "amount",
            render: (payment, record) => {
              const exchangeRate = getExchangeRate(cashHistorySale);

              const convertedAmount = countryPY
                ? payment / +exchangeRate
                : payment;
              const currencyCode = "PYG";

              return (
                <span>{`Gs. ${currencyFormaterPYGBRL(
                  convertedAmount,
                  currencyCode
                )}`}</span>
              );
            },
          },
        ]
      : []),
  ];
  return (
    <Modal
      title="Detalhes da venda"
      centered
      visible={visible}
      closable={false}
      width={"80%"}
      footer={
        <ContentFooter>
          <ButtonCancel type="link" onClick={handleModalToggle}>
            Cancelar
          </ButtonCancel>

          <ButtonOk onClick={() => setVisible(false)}>Concluir</ButtonOk>
        </ContentFooter>
      }
    >
      {loading ? (
        <Spin />
      ) : cashHistorySale?.items ? (
        <Container>
          <ContentTopInformation>
            <IdText>
              Identificação: <span>{cashHistorySale?.id}</span>
            </IdText>
            <ContentAmountAndDiscount>
              <>
                <span>{`Desconto: ${
                  countryPY ? "Gs." : "R$"
                } ${currencyFormaterPYGBRL(
                  countryPY
                    ? +cashHistorySale?.discount / +exchangeRate
                    : +cashHistorySale?.discount,
                  countryPY ? "PYG" : "BRL"
                )}`}</span>
                <span>{`Troco: ${
                  countryPY ? "Gs." : "R$"
                } ${currencyFormaterPYGBRL(
                  countryPY
                    ? +cashHistorySale?.change_amount / +exchangeRate
                    : +cashHistorySale?.change_amount,
                  countryPY ? "PYG" : "BRL"
                )}`}</span>
              </>
            </ContentAmountAndDiscount>
          </ContentTopInformation>

          <Table
            rowKey={(record: any) => record.id}
            loading={loading}
            columns={columns}
            pagination={false}
            dataSource={cashHistorySale?.items.map((entity) => ({
              ...entity,
              key: entity.id,
            }))}
          />
          <Table
            style={{ marginTop: "2rem" }}
            loading={loading}
            columns={columnsPayments}
            pagination={false}
            dataSource={cashHistorySale?.payments.map((entity) => ({
              ...entity,
              key: entity.id,
            }))}
          />
        </Container>
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default ModalSaleDetail;
