import React, { useState } from 'react'
import api from '../../services/api';
import { ITruck } from '../../models/Truck'
import { Divider, Form, Modal, Spin, message } from 'antd'
import {
    ColHeader,
    Container,
    Input,
    Row,
    Select,
    Option,
    Table,
    SpaceAnt,
    Button,
    AddIcon,
    Actions,
    TrashIcon,
    EditIcon
} from './styles'

interface ITruckInfo {
    trucks: ITruck[];
    setVisibleAddTruck: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setShouldLoad: React.Dispatch<React.SetStateAction<boolean>>
}

enum CargoType {
    Gelada = 0,
    Seca = 1,
    Mista = 2
}

const Trucks: React.FC<ITruckInfo> = ({ trucks, loading, setShouldLoad }) => {
    const [form] = Form.useForm();

    const [loadingAdd, setLoadingAdd] = useState(false)
    const [showForm, setShowForm] = useState(false);
    const [updateTruckInfo, setUpdateTruckInfo] = useState(null);
    const [infoTruck, setInfoTruck] = useState({
        name: undefined,
        license_plate: undefined,
        cargo_type: undefined,
        weight_capacity: undefined,
    });

    const handleFieldChange = (fieldName: string, value: any) => {
        setInfoTruck(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const addTruck = async () => {
        try {
            setLoadingAdd(true)
            if (!infoTruck.name || !infoTruck.license_plate || infoTruck.cargo_type === undefined ||  !infoTruck.weight_capacity ) {
                message.error("Por favor, preencha todos os campos antes de adicionar o caminhão");
                return;
            }
            const payload = {
                name: infoTruck.name,
                license_plate: infoTruck.license_plate,
                cargo_type: infoTruck.cargo_type,
                weight_capacity: infoTruck.weight_capacity,
                ...form.getFieldsValue()
            }
            await api.post(`/trucks`, payload);
            message.success("Caminhão adicionado com sucesso");

            setInfoTruck({
                name: undefined,
                license_plate: undefined,
                cargo_type: undefined,
                weight_capacity: undefined
            })
            form.resetFields();
            setShouldLoad(true);
        } catch (error) {
            //@ts-ignore
            const _description = error.message;
            message.error({
                message: "Oops! Não foi possível adicionar o caminhão",
                description: error
                    ? _description
                    : "Falha ao Adicionar o caminhão",
                duration: 5,
            });
        } finally {
            setLoadingAdd(false)
        }
    }

    const deleteTruck = async (id: number) => {
        Modal.confirm({
            title: "Deletar caminhão",
            content: "Tem certeza que gostaria de deletar esse caminhão?",
            okText: "Sim",
            okType: "default",
            cancelText: "Não",
            centered: true,
            onOk: async () => {
                try {
                    await api.delete(`/trucks/${id}`);
                    message.success("Caminhão deletado com sucesso");
                    setShouldLoad(true);
                } catch (error) {
                    //@ts-ignore
                    const _description = error.message;
                    message.error({
                        message: "Oops! Não foi possível deletar o caminhão",
                        description: error
                            ? _description
                            : "Falha ao deletar o caminhão",
                        duration: 5,
                    });
                }
            }
        })
    }

    const updateTruck = async (id: number) => {
        try {

            await api.put(`/trucks/${id}`, {
                name: infoTruck.name,
                license_plate: infoTruck.license_plate,
                cargo_type: infoTruck.cargo_type,
                weight_capacity: infoTruck.weight_capacity
            });
            message.success("Campo atualizado com sucesso");
            setShouldLoad(true);

            setUpdateTruckInfo(null)
        } catch (error) {
            //@ts-ignore
            const _description = error.message;
            message.error({
                message: "Oops! Não foi possível atualizar o campo",
                description: error
                    ? _description
                    : "Falha ao atualizar o campo",
                duration: 5,
            });
        }
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: '5%',
            align: "center" as const,
            key: "id",
        },
        {
            title: "Caminhão",
            dataIndex: "name",
            align: "center" as const,
            key: "name",
            render: (text, record) => (
                updateTruckInfo === record.id ? (
                    <Input
                        value={record.id === updateTruckInfo ? infoTruck.name : record.name}
                        onChange={(e) => handleFieldChange('name', e.target.value)}
                    />
                ) : text
            )
        },
        {
            title: "Placa",
            dataIndex: "license_plate",
            align: "center" as const,
            key: "license_plate",
            render: (text, record) => (
                updateTruckInfo === record.id ? (
                    <Input
                        value={record.id === updateTruckInfo ? infoTruck.license_plate : record.license_plate}
                        onChange={(e) => handleFieldChange('license_plate', e.target.value)}
                    />
                ) : text
            )
        },
        {
            title: "Carga",
            dataIndex: "cargo_type",
            align: "center" as const,
            key: "cargo_type",
            render: (text, record) => (
                updateTruckInfo === record.id ? (
                    <Select
                        value={record.id === updateTruckInfo ? infoTruck.cargo_type : CargoType[record.cargo_type]}
                        onChange={(value) => handleFieldChange('cargo_type', value)}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(CargoType).map(key => (
                            <Option key={CargoType[key]} value={CargoType[key]}>
                                {key}
                            </Option>
                        ))}
                    </Select>
                ) : CargoType[text]
            )
        },
        {
            title: "Capacidade",
            dataIndex: "weight_capacity",
            align: "center" as const,
            key: "weight_capacity",
            render: (text, record) => (
                updateTruckInfo === record.id ? (
                    <Input
                        value={record.id === updateTruckInfo ? infoTruck.weight_capacity : record.weight_capacity}
                        onChange={(e) => handleFieldChange('weight_capacity', e.target.value)}
                    />
                ) : text
            )
        },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <Actions>
                    <TrashIcon onClick={() => deleteTruck(record?.id)} />
                    {updateTruckInfo === record.id ? (
                        <Button onClick={() => updateTruck(record.id)} background='transparent' border='none' color='var(--orange-350)'>Salvar</Button>
                    ) : (
                        <EditIcon onClick={() => {
                            setUpdateTruckInfo(record.id);
                            setInfoTruck({
                                name: record.name,
                                license_plate: record.license_plate,
                                cargo_type: record.cargo_type,
                                weight_capacity: record.weight_capacity
                            });
                        }} />
                    )}
                </Actions>
            )
        }]

    return (
        <Container>
            <Divider orientation='left'>
                <h3>Cadastrar caminhão</h3>
            </Divider>

            {loadingAdd ? <Spin /> : (<> {
                showForm ? (
                    <Form layout="vertical" hideRequiredMark>
                        <Row gutter={16}>
                            <ColHeader xs={6}>
                                <Form.Item
                                    name="name"
                                    label="Caminhão"
                                    rules={[{ required: true, message: 'Preencha o nome' }]}
                                >
                                    <Input
                                        name="name"
                                        placeholder="Digite o tipo do caminhão"
                                        onChange={(e) => handleFieldChange("name", e.target.value)}
                                    />
                                </Form.Item>
                            </ColHeader>
                            <ColHeader xs={6}>
                                <Form.Item
                                    name="license_plate"
                                    label="Placa"
                                    rules={[{ required: true, message: 'Preencha a placa' }]}
                                >
                                    <Input
                                        name="license_plate"
                                        placeholder="Digite o tipo do caminhão"
                                        onChange={(e) => handleFieldChange("license_plate", e.target.value)}
                                    />
                                </Form.Item>
                            </ColHeader>
                            <ColHeader xs={6}>
                                <Form.Item
                                    name="cargo_type"
                                    label="Tipo de carga"
                                    rules={[{ required: true, message: 'Preencha a placa' }]}
                                >
                                    <Select
                                        filterOption={(input, option) =>
                                            option?.children.props.children[0].props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        placeholder="Selecionar tipo de carga"
                                        onChange={(value) => handleFieldChange("cargo_type", value)}
                                        style={{ width: '100%' }}
                                    >
                                        <Option key={1} value={1}>
                                            Carga seca
                                        </Option>
                                        <Option key={0} value={0}>
                                            Carga gelada
                                        </Option>
                                        <Option key={2} value={2}>
                                            Carga mista
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </ColHeader>
                            <ColHeader xs={6}>
                                <Form.Item
                                    name="weight_capacity"
                                    label="Capacidade de carga (KG)"
                                    rules={[{ required: true, message: 'Preencha com a capacidade do caminhão' }]}
                                >
                                    <Input
                                        suffix="KG"
                                        name="weight_capacity"
                                        type='number'
                                        placeholder="Capacidade de carga(KG)"
                                        onChange={(e) => handleFieldChange("weight_capacity", e.target.value)}
                                    />
                                </Form.Item>
                            </ColHeader>
                        </Row>
                        <SpaceAnt>
                            <Button onClick={() => setShowForm(!showForm)} background='var(--transparent)' border='none' color='var(--gray-25)'>Fechar</Button>
                            <Button onClick={() => addTruck()} icon={<AddIcon />} background='var(--orange-350)' border='none' color='var(--white)'>Adicionar</Button>
                        </SpaceAnt>
                    </Form >
                ) :
                    (
                        <Button
                            background='var(--transparent)'
                            border='none'
                            color='var(--gray-45)'
                            onClick={() => setShowForm(true)}
                        >
                            Mostrar formulário
                        </Button>
                    )}</>)}



            <Divider orientation='left'>
                <h3>Caminhões já cadastrados</h3>
            </Divider>
            <Table
                loading={loading}
                columns={columns}
                dataSource={trucks.map((entity) => ({
                    ...entity,
                    key: entity.id,
                }))}
            />
        </Container >
    )
}

export default Trucks