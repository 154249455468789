import styled from "styled-components";

import { ThreeDots } from "../../../styles/Icons";

import {
  Modal as ModalAnt,
  Table as TableAnt,
  Dropdown as DropdownAnt,
  Menu as MenuAnt,
  Button as ButtonAnt,
} from "antd";

export const Container = styled(ModalAnt)``;

export const InvoiceInfo = styled.div`
  display: flex;
  width: 100%;
  height: 20%;
  flex-direction: column;
`;

export const ListContent = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
`;

export const Table = styled(TableAnt)`
  width: 100%;
  .valid_row {
    background-color: #00b70038;
  }
  .unvalid_row {
    background-color: #ff000052;
  }
`;

export const Dropdown = styled(DropdownAnt)``;

export const Menu = styled(MenuAnt)``;

export const ThreeDotsIcon = styled(ThreeDots)`
  width: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled(ButtonAnt)`
  width: 10%;
  height: 3.2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--orange-350);
  color: var(--white);
  margin-bottom: 10px;

  :hover,
  :focus {
    background-color: var(--orange-350);
    color: var(--white);
    opacity: 70%;
  }
`;
