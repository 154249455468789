import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import moment from "moment";

import janusApi from "../../../services/apiAuth";

import {
  CreateCompanyInvoice,
  CreateCompanyInvoiceTable,
  Checkbox,
  Row,
  Input,
  MonetaryInput,
  DatePicker,
} from "./styles";

import { ColumnsType } from "antd/lib/table";

import { Store as StoreModel } from "../../../models/Store/Store";

type CompanyInvoiceData = {
  id: number;
  company_name: string;
  marketing: number;
  royalts: number;
  system: number;
  email: string;
  reference_date?: string;
  nfse_royalts_invoice: boolean;
  nfse_system_invoice: boolean;
  debit_invoice: boolean;
  company: StoreModel;
};

type IProps = {
  openCreateCompanyInvoice: boolean;
  setOpenCreateCompanyInvoice: Dispatch<SetStateAction<boolean>>;
  setShouldFetchInvoices: Dispatch<SetStateAction<boolean>>;
};

const dateFormat = "DD/MM/YYYY";

const CreateInvoiceRequest: React.FC<IProps> = ({
  openCreateCompanyInvoice,
  setOpenCreateCompanyInvoice,
  setShouldFetchInvoices,
}) => {
  const [sendingRequest, setSendingRequest] = useState(false);

  const [referenceDate, setReferenceDate] = useState(
    moment(new Date()).format(dateFormat)
  );

  const [stores, setStores] = useState<StoreModel[]>([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [storesInvoice, setStoresInvoice] = useState<CompanyInvoiceData[]>([]);

  useEffect(() => {
    async function fetchStores() {
      const {
        data: { content },
      } = await janusApi.get("/company");
      setStores(content);
      setStoresInvoice(
        content.map((_store) => ({
          id: _store.id,
          key: _store.id,
          company_name: _store.company_name,
          marketing: +(_store.marketing || 0),
          royalts: +(_store.royalts || 0),
          system: +(_store.software || 0),
          email: _store.email,
          nfse_royalts_invoice: false,
          nfse_system_invoice: false,
          debit_invoice: false,
          company: _store,
        }))
      );
    }
    if (openCreateCompanyInvoice && !stores.length) {
      fetchStores();
    }

    if (openCreateCompanyInvoice && stores.length) {
      setStoresInvoice(
        stores.map((_store) => ({
          id: _store.id,
          key: _store.id,
          company_name: _store.company_name,
          marketing: +(_store.marketing || 0),
          royalts: +(_store.royalts || 0),
          system: +(_store.software || 0),
          email: _store.email,
          nfse_royalts_invoice: false,
          nfse_system_invoice: false,
          debit_invoice: false,
          company: _store,
        }))
      );
    }
  }, [openCreateCompanyInvoice]);

  const handleFilter = (_stores: CompanyInvoiceData[]) => {
    if (filterSearch.length) {
      return _stores
        .filter((_store) => _store.company_name.includes(filterSearch))
        .sort((a, b) => a.id - b.id);
    }

    return _stores.sort((a, b) => a.id - b.id);
  };

  const handleRowClass = (_store: CompanyInvoiceData) => {
    if (
      _store.nfse_royalts_invoice &&
      _store.nfse_system_invoice &&
      _store.debit_invoice
    ) {
      if (_store.royalts > 0 && _store.system > 0 && _store.marketing > 0)
        return "valid_row";
      return "unvalid_row";
    }

    if (
      _store.nfse_royalts_invoice &&
      _store.nfse_system_invoice &&
      !_store.debit_invoice
    ) {
      if (_store.royalts > 0 && _store.system > 0) return "valid_row";
      return "unvalid_row";
    }

    if (
      _store.nfse_royalts_invoice &&
      !_store.nfse_system_invoice &&
      _store.debit_invoice
    ) {
      if (_store.royalts > 0 && _store.marketing > 0) return "valid_row";
      return "unvalid_row";
    }

    if (
      !_store.nfse_royalts_invoice &&
      _store.nfse_system_invoice &&
      _store.debit_invoice
    ) {
      if (_store.system > 0 && _store.marketing > 0) return "valid_row";
      return "unvalid_row";
    }
    if (_store.nfse_royalts_invoice) {
      if (_store.royalts > 0) return "valid_row";
      return "unvalid_row";
    }

    if (_store.nfse_system_invoice) {
      if (_store.system > 0) return "valid_row";
      return "unvalid_row";
    }

    if (_store.debit_invoice) {
      if (_store.marketing > 0) return "valid_row";
      return "unvalid_row";
    }

    return "";
  };

  const handleCheckbox = (store_id: any, prop: string) => {
    const storeIndex = storesInvoice.findIndex(
      (_store) => _store.id === store_id
    );

    let storeToUpdate = storesInvoice[storeIndex];

    storeToUpdate[prop] = !storeToUpdate[prop];

    setStoresInvoice([
      ...storesInvoice.filter((_store) => _store.id !== storeToUpdate.id),
      storeToUpdate,
    ]);
  };

  const handleAllCheckbox = (prop: string, invoice: string) => {
    const isAllChecked = storesInvoice.every((_store) => _store[prop]);
    if (isAllChecked) {
      setStoresInvoice(
        storesInvoice.map((_store) => ({
          ..._store,
          [prop]: false,
        }))
      );
    } else {
      setStoresInvoice(
        storesInvoice.map((_store) => ({
          ..._store,
          [prop]: true,
        }))
      );
    }
  };

  const handleChangeAmount = (
    value: number,
    prop: string,
    row: CompanyInvoiceData
  ) => {
    const storeIndex = storesInvoice.findIndex(
      (_store) => _store.id === row.id
    );

    let storeToUpdate = storesInvoice[storeIndex];

    storeToUpdate[prop] = value;

    setStoresInvoice([
      ...storesInvoice.filter((_store) => _store.id !== storeToUpdate.id),
      storeToUpdate,
    ]);
  };

  const createInvoiceEvent = async () => {
    try {
      setSendingRequest(true);
      let request = storesInvoice.filter((storeInvoice) => {
        if (
          storeInvoice.nfse_royalts_invoice ||
          storeInvoice.nfse_system_invoice ||
          storeInvoice.debit_invoice
        )
          return true;

        return false;
      });

      request = request.map((item) => ({
        ...item,
        reference_date: referenceDate,
      }));

      await janusApi.post("/company-invoice", request);
      setShouldFetchInvoices(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSendingRequest(false);
    }

    setOpenCreateCompanyInvoice(false);
  };

  const CreateCompanyInvoiceTableColumns: ColumnsType = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Nome",
      dataIndex: "company_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Valor Royalts",
      dataIndex: "royalts",
      align: "center",
      render: (value, row) => (
        <MonetaryInput
          defaultValue={value}
          getValue={(newValue) =>
            handleChangeAmount(newValue, "royalts", row as CompanyInvoiceData)
          }
        />
      ),
    },
    {
      title: "Valor Sistema",
      dataIndex: "system",
      align: "center",
      render: (value, row) => (
        <MonetaryInput
          defaultValue={value}
          getValue={(newValue) =>
            handleChangeAmount(newValue, "system", row as CompanyInvoiceData)
          }
        />
      ),
    },
    {
      title: "Valor Marketing",
      dataIndex: "marketing",
      align: "center",
      render: (value, row) => (
        <MonetaryInput
          defaultValue={value}
          getValue={(newValue) =>
            handleChangeAmount(newValue, "marketing", row as CompanyInvoiceData)
          }
        />
      ),
    },
    // {
    //   title: (
    //     <Row>
    //       <Checkbox
    //         style={{ marginRight: "5px" }}
    //         onClick={() => handleAllCheckbox("nfse_royalts_invoice", "royalts")}
    //         checked={storesInvoice.every(
    //           (_store) => _store.nfse_royalts_invoice
    //         )}
    //       />
    //       <p>NFS-E Royalts</p>
    //     </Row>
    //   ),
    //   key: "nfse_royalts_invoice",
    //   render: (row) => (
    //     <Checkbox
    //       onClick={() => handleCheckbox(row.id, "nfse_royalts_invoice")}
    //       checked={row.nfse_royalts_invoice}
    //     />
    //   ),
    //   align: "center",
    // },
    // {
    //   title: (
    //     <Row>
    //       <Checkbox
    //         style={{ marginRight: "5px" }}
    //         onClick={() => handleAllCheckbox("nfse_system_invoice", "system")}
    //         checked={storesInvoice.every(
    //           (_store) => _store.nfse_system_invoice
    //         )}
    //       />
    //       <p>NFS-E Sistema</p>
    //     </Row>
    //   ),
    //   key: "nfse_system_invoice",
    //   render: (row) => (
    //     <Checkbox
    //       onClick={() => handleCheckbox(row.id, "nfse_system_invoice")}
    //       checked={row.nfse_system_invoice}
    //     />
    //   ),
    //   align: "center",
    // },
    {
      title: (
        <Row>
          <Checkbox
            style={{ marginRight: "5px" }}
            onClick={() => handleAllCheckbox("debit_invoice", "marketing")}
            checked={storesInvoice.every((_store) => _store.debit_invoice)}
          />
          <p>Nota Débito</p>
        </Row>
      ),
      key: "debit_invoice",
      render: (row) => (
        <Checkbox
          onClick={() => handleCheckbox(row.id, "debit_invoice")}
          checked={row.debit_invoice}
        />
      ),
      align: "center",
    },
  ];

  return (
    <CreateCompanyInvoice
      visible={openCreateCompanyInvoice}
      closable={false}
      okText={"Emitir"}
      cancelText={"Cancelar"}
      destroyOnClose
      onOk={() => createInvoiceEvent()}
      onCancel={() => setOpenCreateCompanyInvoice(false)}
      confirmLoading={sendingRequest}
      width={"95%"}
    >
      <Input
        placeholder="Buscar loja"
        value={filterSearch}
        onChange={({ target: { value } }) => setFilterSearch(value || "")}
      />
      <DatePicker
        defaultValue={moment(new Date())}
        format={dateFormat}
        onChange={(value) =>
          setReferenceDate(value?.format(dateFormat) as string)
        }
      />
      <CreateCompanyInvoiceTable
        loading={!stores.length}
        dataSource={handleFilter(storesInvoice)}
        columns={CreateCompanyInvoiceTableColumns as any}
        rowClassName={(row) => handleRowClass(row as CompanyInvoiceData)}
        size="small"
        pagination={{
          showSizeChanger: false,
          pageSize: stores.length,
          total: stores.length,
        }}
      />
    </CreateCompanyInvoice>
  );
};

export default CreateInvoiceRequest;
