import React, { Dispatch, SetStateAction, useState } from 'react';
import { verifyPermission } from "../../services/auth";

import { Dropdown, Menu, Tooltip } from 'antd';

import { useWindowSize } from '../../hooks/useWindowSize';
import { useResponsiveSizeTable } from '../../hooks/useResponsiveSizeTable';

import LinkDriverModal from './Modals/LinkDriverModal';
import InfoRouteModal from './Modals/InfoRouteModal';
import ScriptingModal from './Modals/ScriptingModal';
import OcorrencyAudit from './Modals/OcorrencyAudit';

import { CompanyInfo, DeliveryRoute, DriveInfo } from '../../models/DeliveryRoute';
import { Status } from '../../models/enums/cargoType';
import { User } from '../../models/User/User';
import { ITruck } from '../../models/Truck';
import { MoreIcon } from '../WasteList/styles';

import { Actions, Container, Table, TextCapitalize, TruckIcon } from './styles';

interface IProps {
    visible: boolean;
    loading: boolean;
    drivers: User[];
    trucks: ITruck[];
    routes: DeliveryRoute[];
    deleteRoute: (id: number) => void;
    cancelRoute: (id: number) => void
    setVisible: Dispatch<SetStateAction<boolean>>;
    setShouldSearch: Dispatch<SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const statusMap = {
    [Status.ATRIBUIDA]: 'Atribuída',
    [Status.EM_ROTA]: 'Em rota',
    [Status.CANCELADA]: 'Cancelada',
    [Status.FINALIZADA]: 'Finalizada',
    [Status.CRIADA]: 'Criada',
    [Status.ROTEIRIZADA]: 'Roteirizada',
};

const RouterList: React.FC<IProps> = ({
    loading,
    setLoading,
    deleteRoute,
    setShouldSearch,
    routes,
    drivers,
    trucks,
    cancelRoute
}) => {
    const [showDriverModal, setShowDriverModal] = useState(false);
    const [showInfoRouteModal, setShowInfoRouteModal] = useState(false);
    const [showScripting, setShowScripting] = useState(false);
    const [showOcorrencyAudit, setShowOcorrencyAudit] = useState(false);
    const responsiveWindow = useWindowSize();
    const [allItems, setAllItems] = useState([]);
    const [selectedRoute, setSelectedRoute] = useState<DeliveryRoute | null>(null);
    const [selectedRouteInfo, setSelectedRouteInfo] = useState<DriveInfo | null>(null);
    const [selectedRouteStore, setSelectedRouteStore] = useState<CompanyInfo[] | null>([]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: '5%',
            align: "center" as const,
            key: "id",
            render: (_, record) => (
                <span>{record?.deliveryRoute?.id}</span>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center" as const,
            key: "status",
            render: (_, record) => {
                const deliveryRouteStatus = record?.deliveryRoute?.status;
                return <span>{statusMap[deliveryRouteStatus] || "Criada"}</span>

            },
        },
        {
            title: "Motorista",
            dataIndex: "driver",
            align: "center" as const,
            key: "driver",
            render: (_, record) => (
                <span>{record?.driveInfo?.name ? record?.driveInfo?.name : "-"}</span>
            )
        },
        {
            title: "Caminhão",
            dataIndex: "truck_info",
            align: "center" as const,
            key: "truck",
            render: (_, record) => (
                <span>{record?.deliveryRoute?.truck_info ? record?.deliveryRoute?.truck_info : "-"}</span>
            )
        },
        {
            title: "Última loja entregue",
            dataIndex: "last_delivered",
            align: "center" as const,
            key: "last_delivered",
            render: (_, record) => {
                const routeStatus = record?.deliveryRoute?.status;
                const deliveryRouteItems = record?.deliveryRoute?.deliveryRouteItem || [];

                if (routeStatus === Status.ATRIBUIDA || routeStatus === Status.ROTEIRIZADA || routeStatus === Status.CRIADA || routeStatus === null || routeStatus === Status.CANCELADA) {
                    return "-";
                }

                const nextItems = record?.deliveryRoute?.deliveryRouteItem.filter((item) => item)
                const lastStore = deliveryRouteItems.slice().reverse().find(item => item.status === 4);

                if (nextItems[0]?.finished_at === null) return (
                    <Tooltip title="Primeira rota em andamento">
                        <TruckIcon />
                    </Tooltip>
                )
                if (lastStore) {
                    const companyName = record?.companiesInfo.find(company => company.id === lastStore.company_id)?.company_name;
                    return <TextCapitalize>{companyName}</TextCapitalize>;
                }
            }
        },
        {
            title: "Próxima loja",
            dataIndex: "next_store",
            align: "center" as const,
            key: "next_store",
            render: (_, record) => {
                const routeStatus = record?.deliveryRoute?.status;
                const deliveryRouteItems = record?.deliveryRoute?.deliveryRouteItem || [];

                if (routeStatus === Status.ATRIBUIDA || routeStatus === Status.ROTEIRIZADA || routeStatus === Status.CRIADA || routeStatus === null || routeStatus === Status.CANCELADA) {
                    return "-";
                }

                if (routeStatus === Status.FINALIZADA) {
                    return <span>Finalizada</span>;
                }

                if (routeStatus === Status.ATRIBUIDA) {
                    const firstStoreName = deliveryRouteItems.length > 0 ? record?.companiesInfo.find(company => company.id === deliveryRouteItems[0].company_id)?.company_name : 'Não informada';
                    return <TextCapitalize>{firstStoreName}</TextCapitalize>;
                }

                const nextStoreIndex = deliveryRouteItems.findIndex(item => item.status === Status.EM_ROTA);
                if (nextStoreIndex !== -1) {
                    const nextStoreName = record?.companiesInfo.find(company => company.id === deliveryRouteItems[nextStoreIndex].company_id)?.company_name;
                    return <TextCapitalize>{nextStoreName}</TextCapitalize>;
                }

                return <span>-</span>;
            }
        },
        {
            title: "Sequência",
            dataIndex: "sequence",
            align: "center" as const,
            key: "sequence",
            render: (_, record) => {
                if (record?.deliveryRoute?.status === Status.ATRIBUIDA || record?.deliveryRoute?.status === Status.ROTEIRIZADA || record?.deliveryRoute?.status === Status.CRIADA || record?.deliveryRoute?.status === Status.CANCELADA) {
                    return "-";
                }
                return (
                    <span>
                        {record?.finishedItems?.length} / {record?.deliveryRoute?.deliveryRouteItem.length}
                    </span>
                );
            },
        },
        {
            title: "Quantidade",
            dataIndex: "quantity",
            align: "center" as const,
            key: "quantity",
            render: (_, record) => {
                return <span>{record?.deliveryRoute?.total_quantity}</span>;
            }
        },
        {
            title: "Peso",
            align: "center" as const,
            key: "weight",
            render: (_, record) => {
                return <span>{Number(record?.deliveryRoute?.total_weight).toFixed(2)} kg</span>;
            }
        },
        {
            title: "Tipo de carga",
            dataIndex: "cargo_type",
            align: "center" as const,
            key: "cargo_type",
            render: (text, record) => {
                if (record?.deliveryRoute?.cargo_type === 0) return <span>Carga gelada</span>
                if (record?.deliveryRoute?.cargo_type === 1) return <span>Carga seca</span>
                if (record?.deliveryRoute?.cargo_type === 2) return <span>Carga mista</span>
                return <TextCapitalize>N/A</TextCapitalize>
            }
        },
        {
            title: "Ações",
            key: "action",
            render: (text, record) => (
                <Actions>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        setShowInfoRouteModal(true)
                                        setSelectedRoute(record)
                                        setSelectedRouteInfo(record?.driveInfo)
                                        setSelectedRouteStore(record?.companiesInfo)
                                        setAllItems(record)
                                    }}
                                >
                                    <span>Informações da rota</span>
                                </Menu.Item>

                                <Menu.Item
                                    disabled={record?.deliveryRoute?.status === Status.FINALIZADA || record?.deliveryRoute?.status === Status.EM_ROTA}
                                    onClick={() => {
                                        setShowScripting(true);
                                        setSelectedRoute(record);
                                        setSelectedRouteInfo(record?.driveInfo);
                                        setSelectedRouteStore(record?.companiesInfo);
                                    }}
                                >
                                    <Tooltip title={record?.deliveryRoute?.status === Status.FINALIZADA || record?.deliveryRoute?.status === Status.EM_ROTA && 'Este campo está desabilitado porque está em rota ou finalizado'}>
                                        <span>Incluir ou editar roteirização</span>
                                    </Tooltip>
                                </Menu.Item>

                                <Menu.Item
                                    onClick={() => {
                                        setShowDriverModal(true)
                                        setSelectedRoute(record)
                                    }}
                                    disabled={record?.deliveryRoute?.status !== Status.ROTEIRIZADA && record?.deliveryRoute?.status !== Status.ATRIBUIDA}
                                >
                                    <Tooltip title={(record?.deliveryRoute?.status !== Status.ROTEIRIZADA && record?.deliveryRoute?.status !== Status.ATRIBUIDA) && "Este campo está desabilitado porque está em rota ou finalizado"}>
                                        <span>Atribuir motorista e caminhão</span>
                                    </Tooltip>
                                </Menu.Item>

                                <Menu.Item
                                    onClick={() => {
                                        setShowOcorrencyAudit(true)
                                        setSelectedRoute(record)
                                    }}
                                >
                                    <span>Auditoria de ocorrência</span>
                                </Menu.Item>
                                {verifyPermission("routes.cancel_route") && (
                                    <Menu.Item
                                        disabled={record?.deliveryRoute?.status === Status.FINALIZADA || record?.deliveryRoute?.status === Status.CANCELADA}
                                        onClick={() => {
                                            cancelRoute(record?.deliveryRoute?.id);
                                        }}
                                    >
                                        <span style={{ color: "var(--orange-350)" }}>
                                            Cancelar rota
                                        </span>
                                    </Menu.Item>
                                )}

                                {verifyPermission("routes.delete_route") && (
                                    <Menu.Item
                                        disabled={record?.deliveryRoute?.status === Status.FINALIZADA || record?.deliveryRoute?.status === Status.CANCELADA}
                                        onClick={() => {
                                            deleteRoute(record?.deliveryRoute?.id);
                                        }}
                                    >
                                        <span style={{ color: "red" }}>Deletar rota</span>
                                    </Menu.Item>
                                )}

                            </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomCenter"
                        arrow
                    >
                        <MoreIcon />
                    </Dropdown>
                </Actions>
            ),
        },
    ];

    const getRowClassName = (record) => {
        if (record?.deliveryRoute?.status === Status.CANCELADA) {
            return 'canceledRoute';
        }
        if (record?.deliveryRoute?.status === Status.FINALIZADA) {
            return 'finishedRoute';
        }
        return '';
    };

    return (
        <>
            <Container>
                <Table
                    loading={loading}
                    columns={columns}
                    scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
                    dataSource={routes.map((entity) => ({
                        ...entity,
                        key: entity.id,
                    }))}
                    rowClassName={getRowClassName}
                />
            </Container>

            <InfoRouteModal
                routes={routes}
                selectedRoute={selectedRoute}
                visible={showInfoRouteModal}
                setVisible={setShowInfoRouteModal}
                selectedRouteInfo={selectedRouteInfo}
                selectedRouteStore={selectedRouteStore}
                allItems={allItems}
            />

            <ScriptingModal
                setLoading={setLoading}
                visible={showScripting}
                setVisible={setShowScripting}
                selectedRouteInfo={selectedRouteInfo}
                selectedRouteStore={selectedRouteStore}
                selectedRoute={selectedRoute}
                setShouldSearch={setShouldSearch}
            />

            <OcorrencyAudit
                visible={showOcorrencyAudit}
                setVisible={setShowOcorrencyAudit}
                selectedRoute={selectedRoute}
            />

            <LinkDriverModal
                visible={showDriverModal}
                setVisible={setShowDriverModal}
                drivers={drivers}
                trucks={trucks}
                routes={routes}
                selectedRoute={selectedRoute}
                setShouldSearch={setShouldSearch}
            />
        </>
    )
}

export default RouterList;
