import React, { Dispatch, SetStateAction, useState } from "react";

import moment from "moment";
import { Table } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { verifyPermission } from "../../services/auth";

import ModalEdit from "../../pages/Waste/ModalEdit";
import { ProductStoreWasteModel } from "../../models/Waste";
import ModalImage from "../../pages/Waste/ModalImage";
import { currencyFormater } from "../../services/currencyFormater";

import { Actions, Reason, Dropdown, Menu, MoreIcon } from "./styles";

interface IProps extends RouteComponentProps {
  wasteProducts;
  loading: boolean;
  deleteWaste: (id: number) => void;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  isStoreActive: boolean | undefined;
}

const WasteList: React.FC<IProps> = ({
  wasteProducts,
  loading,
  deleteWaste,
  setShouldSearch,
  isStoreActive
}) => {
  const [visible, setVisible] = useState(false);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editWaste, setEditWaste] = useState<
    ProductStoreWasteModel | undefined
  >(undefined);

  const [selectedProducts, setSelectedProducts] = useState<
    ProductStoreWasteModel[]
  >([]);


  const column = [
    {
      title: "Data e hora",
      dataIndex: "created_at",
      responsive: ["md"] as any,
      key: "created_at",
      render: (text, record) => (
        <>
          {moment(record.created_at, "DD/MM/YYYY HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        </>
      ),
      sorter: (dateA, dateB) =>
        moment(dateA.created_at, "DD/MM/YYYY HH:mm:ss").diff(
          moment(dateB.created_at, "DD/MM/YYYY HH:mm:ss")
        ),
    },
    {
      title: "Categoria",
      dataIndex: "category_name",
      key: "category_name",
      responsive: ["md"] as any,
      ellipsis: true,

      render: (text, record) => (
        <span>
          {record.category_name}
        </span>
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,

      render: (text, record) => (
        <span>
          {record?.name}
        </span>
      ),
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",

      render: (text, record) => (
        <span>
          {record.quantity} {record.unity === 1 ? "KG" : "UN"}
        </span>
      ),
    },
    {
      title: "Justificativa",
      dataIndex: "reason",
      key: "reason",
      width: "20%",
      responsive: ["md"] as any,

      ellipsis: true,
      render: (text) => <Reason>{text}</Reason>,
    },
    {
      title: "Valor do Produto",
      dataIndex: "price_sell",
      key: "price_sell",
      render: (text, record) => (
        <span>R$ {currencyFormater(+record?.price_sell)}</span>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (text, record) => (
        <Actions>
          {!isStoreActive && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setSelectedProducts(record);
                      setVisible(true);
                    }}
                  >
                    <span>Visualizar Imagem</span>
                  </Menu.Item>
                  {verifyPermission("waste.edit") && (
                    <Menu.Item
                      onClick={() => {
                        setEditModalVisible(true);
                        setEditWaste(record);
                      }}
                    >
                      <span>Editar</span>
                    </Menu.Item>
                  )}
                  {verifyPermission("waste.delete") && (
                    <Menu.Item
                      onClick={() => {
                        deleteWaste(record.id);
                      }}
                    >
                      <span style={{ color: "var(--orange-600)" }}>Excluir</span>
                    </Menu.Item>
                  )}

                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <MoreIcon />
            </Dropdown>
          )}
        </Actions>
      ),
    },
  ];

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Table
        loading={loading}
        columns={column}
        dataSource={wasteProducts.map(waste =>
          waste.products_store_waste.map(product => ({
            ...product,
            name: waste?.product?.name,
            category_id: waste?.product?.category_id,
            category_name: waste?.product?.category?.name,
          }))
        ).flat()}
        rowKey={(item: ProductStoreWasteModel) => item.id.toString()}
      />

      <ModalImage
        visible={visible}
        products={selectedProducts}
        closeModal={closeModal}
      />

      <ModalEdit
        setShouldSearch={setShouldSearch}
        editWaste={editWaste}
        setEditWaste={setEditWaste}
        visible={editModalVisible}
        setEditModalVisible={setEditModalVisible}
      />
    </>
  );
};

export default withRouter(WasteList);
