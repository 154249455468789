import React, { useState, useEffect } from "react";

import moment from "moment";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";
import { useStoreData } from "../../hooks/useStoreData";

import { ProductStore as ProductStoreModel } from "../../models/ProductStore";
import { ProductCategory } from "../../models/ProductCategory";
import { RestrictedProducts } from "../../models/enums/RestrictedProducts";

import GetStore from "../../components/GetStore";
import StoreSelectionPage from "../../components/StoreSelectionPage";
import DisabledFlag from "../../components/DisabledFlag";

import PageContainer from "../../containers/PageContainer";
import StockList from "../../containers/StockList";
import Centralizer from "../../containers/Centralizer";

import { Menu, Dropdown, Spin, notification, message, Modal, Empty } from "antd";

import StockImg from "../../assets/svg/stock3.svg";

import {
  Container,
  Content,
  Col,
  Row,
  Input,
  ShortCurtContent,
  ProductStocksContent,
  CheckboxMenuGroup,
  CheckboxMenu,
  ButtonSearch,
  SpinContainer,
  ProductStocks,
  FilterMenu,
  FilterIcon,
  Button,
  FiletypeCsvIcon,
  PdfIcon,
  ContentButton,
} from "./styles";

interface IProp extends RouteComponentProps { }

const Stock: React.FC<IProp> = () => {
  const [productsStock, setProductsStock] = useState<ProductStoreModel[]>(
    [] as ProductStoreModel[]
  );
  const [filteredProducts, setFilteredProducts] = useState<
    ProductStoreModel[] | undefined
  >(undefined);
  const [store, setStore] = useState<number | undefined | null>();
  const [loading, setLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [allProductCategories, setAllProductCategories] = useState<
    ProductCategory[]
  >([]);
  const [filterCategory, setFilterCategory] = useState<number[]>([]);
  const [fileType, setFileType] = useState<string>("");

  const { isStoreActive } = useStoreData(store);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setLoading(true);
        const {
          data: { content },
        } = await api.get(`/products_store/store/${store}?stockProducts=true`);

        setProductsStock(
          content.filter(
            (data) =>
              data.product.category_id !== RestrictedProducts.SELFSERVICE &&
              data.product.category_id !== RestrictedProducts.DELIVERY
          )
        );
        setLoading(false);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };

    if (shouldSearch && (store || productsStock)) {
      fetchShop();
      setShouldSearch(false);
    }
  }, [store, productsStock, shouldSearch]);

  useEffect(() => {
    const fecthProductCategory = async () => {
      try {
        const {
          data: { data },
        } = await api.get(`/product_categories`);

        setAllProductCategories(
          data.filter(
            (_data) =>
              _data.id !== RestrictedProducts.SELFSERVICE &&
              _data.id !== RestrictedProducts.DELIVERY
          )
        );
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    if (!allProductCategories?.length && menuVisible) {
      fecthProductCategory();
    }
  }, [allProductCategories, menuVisible]);

  const fetchFiltered = async () => {
    try {
      if (filterCategory.length !== 0) {
        const {
          data: { content },
        } = await api.get(
          `/products_store/store/${store}/category?category_id=${filterCategory}`);
        setProductsStock(content);
      } else {
        setShouldSearch(true);
      }
    } catch (error) {
      notification.error({
        message: "Oops! Não foi possível realizar a busca.",
        duration: 5,
      });
    }
  };

  const findProduct = ({ target: { value } }) => {
    const filteredProducts = productsStock.filter((product) =>
      product?.product?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
  };

  const getTotalAcaisAndSorvetes = (
    productsStock: ProductStoreModel[],
    category: string
  ) => {
    return productsStock
      .filter((_category) => _category.product?.category?.name === category)
      .reduce(
        (total, _category) =>
          total + +(_category?.quantity ? _category?.quantity : 0),
        0
      );
  };

  const getFile = async (
    type: "pdf" | "csv"
  ): Promise<void> => {
    setFileType(type);
  
    Modal.confirm({
      title: "Atenção",
      content: `Deseja realmente gerar o ${type === "pdf" ? "PDF" : "CSV"
        } deste pedido?`,
      className: "modalConfirm",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      width: 500,
      centered: true,
      async onOk() {
        try {
          setLoading(true);
  
          const endpoint = type === "pdf" ? "pdf" : "csv"; 
  
          const { data: file } = await api.get(`/products_store/${store}/${endpoint}`, {
            responseType: "blob",
          });
  
          const url = window.URL.createObjectURL(
            new Blob([file], {
              type: type === "pdf" ? "application/pdf" : "text/csv",
            })
          );
  
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          
          if (type === "csv") {
            link.download = `Estoque-${moment(new Date()).format('DD/MM/YYYY')}.csv`;
          }
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;
          notification.error({
            message: "Falha ao gerar arquivo.",
            description: _description,
            duration: 5,
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <ButtonSearch onClick={() => fetchFiltered()}>Filtrar</ButtonSearch>

        <CheckboxMenuGroup
          onChange={(status) => setFilterCategory(status as number[])}
        >
          {allProductCategories.map((category) => (
            <CheckboxMenu
              key={category.id}
              value={category.id}
              onChange={({ target: { value } }) => setFilterCategory(value)}
            >
              {category.name}
            </CheckboxMenu>
          ))}
        </CheckboxMenuGroup>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageContainer route="Estoque">
      {!store ? (
        <StoreSelectionPage
          title="Para gerenciar seu estoque"
          Img={StockImg}
          store={store}
          setStore={setStore}
          setShouldSearch={setShouldSearch}
        />
      ) : (
        <Container>
          <DisabledFlag isStoreActive={isStoreActive} />
          <Row gutter={8}>
            <Col sm={12} xs={24}>
              <ProductStocksContent>
                {store && (
                  <>
                    <ProductStocks sm={8} xs={8}>
                      <span>
                        {getTotalAcaisAndSorvetes(productsStock, "acai")} Caixas
                        açaí
                      </span>
                      |
                    </ProductStocks>

                    <ProductStocks sm={8} xs={8}>
                      <span>
                        {getTotalAcaisAndSorvetes(productsStock, "sorvetes")}{" "}
                        Caixas sorvete
                      </span>
                      |
                    </ProductStocks>

                    <ProductStocks sm={8} xs={8}>
                      <span>
                        {getTotalAcaisAndSorvetes(productsStock, "sorvetes") +
                          getTotalAcaisAndSorvetes(productsStock, "acai")}{" "}
                        Total de caixas
                      </span>
                    </ProductStocks>
                  </>
                )}
              </ProductStocksContent>
            </Col>

            <Col sm={12} xs={24}>
              <ShortCurtContent gutter={3}>
                <Col sm={4} xs={12}>
                  <ContentButton>
                    <Button onClick={() => getFile("csv")} disabled={isStoreActive}>
                      <FiletypeCsvIcon />
                    </Button>

                    <Button onClick={() => getFile("pdf")} disabled={isStoreActive}>
                      <PdfIcon />
                    </Button>
                  </ContentButton>
                </Col>
                <Col sm={8} xs={12}>
                  <GetStore
                    defaultValue={store}
                    handleChange={(id) => {
                      setShouldSearch(true);
                      setStore(id);
                    }}
                  />
                </Col>

                <Col sm={6} xs={20}>
                  <Input placeholder="Nome do produto" onChange={findProduct} />
                </Col>
                <Col sm={1} xs={2}>
                  <Dropdown
                    overlay={menu}
                    onVisibleChange={(state) => setMenuVisible(state)}
                    visible={menuVisible}
                    trigger={["click"]}
                  >
                    <FilterMenu>
                      <FilterIcon />
                    </FilterMenu>
                  </Dropdown>
                </Col>
              </ShortCurtContent>
            </Col>
          </Row>

          {shouldSearch ? (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          ) : (
            <Content>
              {productsStock.length ? (
                <StockList
                  loading={loading}
                  setLoading={setLoading}
                  filteredProducts={filteredProducts}
                  products={productsStock}
                  setProductsStock={setProductsStock}
                  isStoreActive={isStoreActive}
                />
              ) : (
                <Centralizer>
                  <Empty description="Nenhum produto encontrado" />
                </Centralizer>
              )}
            </Content>
          )}
        </Container>
      )}
    </PageContainer>
  );
};

export default withRouter(Stock);
