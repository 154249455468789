import styled from "styled-components";
import {
  Modal as ModalAnt,
  Tabs as TabsAnt,
  Col as ColAnt,
  Button as ButtonAnt,
  Form as FormAnt,
} from "antd";

export const ContentUnity = styled.div`
  padding: 0.8rem 0.6rem;
  border: 1px solid var(--gray-300);
  background-color: var(--gray-250);
  height: 100%;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  cursor: not-allowed;

  p {
    margin: 0;
    font-size: 0.9rem;
    cursor: not-allowed;
  }
`;

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    padding: 0 2.8rem 0.6rem 2.8rem;
  }
`;
export const Form = styled(FormAnt)`
  overflow-y: scroll;
  max-height: 20rem;

  .formTopContent {
    height: 1rem;

    @media only screen and (max-width: 578px) {
      height: 3rem;
    }
  }
  .ant-input {
    height: 3.25rem;

    :disabled {
      color: var(--gray-300);
    }
    @media only screen and (max-width: 578px) {
      height: 3.25rem;
    }
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: var(--orange-600);
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
`;

export const Tabs = styled(TabsAnt)`
  .formTitle {
    @media only screen and (max-width: 578px) {
      font-size: 1rem;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--orange-350);
  }

  .ant-tabs-ink-bar {
    background: var(--orange-350);
  }
`;

export const Col = styled(ColAnt)`
  padding: 3px;
`;

export const CancelButton = styled(ButtonAnt)``;

export const Button = styled(ButtonAnt)`
  border-radius: 0.2rem;
  height: 3.2rem;
  width: 12.7rem;
  margin: 1rem 0;

  :hover,
  :active,
  :enabled {
    background: var(--orange-350);
    border: 1px solid var(--orange-gradient);
    color: white;
    opacity: 80%;
  }
`;

export const Country = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const ContainerCountry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CountryImg = styled.div`
  margin-right: 3rem;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.2rem;

  padding: 0.5rem;
  border: 1px solid var(--orange-450);
  border-radius: 5px;

  height: 3rem;

  span {
    font-size: 1rem;
  }
`;
