import styled from "styled-components";
import { Col, Row, Checkbox } from "antd";

export const Container = styled(Row)`
  @media only screen and (max-width: 578px) {
    border-bottom: 1px solid #0000006b;
  }
`;

export const Column = styled(Col)`
  border-bottom: 1px solid #0000006b;
  margin-bottom: 10px;
  @media only screen and (max-width: 578px) {
    border-bottom: none;
  }
`;

export const Check = styled(Checkbox)`
  zoom: 1.5;
  margin-left: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 578px) {
    margin-left: 5px;
    zoom: 2;
  }
`;

export const Title = styled.label`
  font-size: 18px;
  font-weight: 500;
  color: grey;
`;

export const Description = styled.label`
  font-size: 18px;
`;
