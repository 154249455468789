import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Form, Modal, Radio, Row, notification } from "antd";
import {
  ButtonCancelModal,
  ColModal,
  ContainerUpload,
  ContentModal,
  ContentModalBody,
  FormUpload,
  Input,
  ModalTitle,
  SaveButton,
  UploadWaste,
} from "./styles";
import { ProductStoreWasteModel } from "../../../models/Waste";
import MonetaryInput2 from "../../../components/MonetaryInput2";
import { Options } from "../../../models/enums/WasteCategory";
import api from "../../../services/api";
import apiS3Handler from "../../../services/apiS3Handler";

interface IProps {
  editWaste?: ProductStoreWasteModel;
  setEditWaste: Dispatch<SetStateAction<ProductStoreWasteModel | undefined>>;
  visible: boolean;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

interface Files {
  path: string;
  name: string;
  size: number;
  type: string;
}

const ModalEdit: React.FC<IProps> = ({
  visible,
  editWaste,
  setEditWaste,
  setShouldSearch,
  setEditModalVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState(Options.Quilograma);

  const [showOtherInput, setShowOtherInput] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>();

  const [price, setPrice] = useState<number>();
  const [image, setImage] = useState<File | null>(null);

  const options = [
    "Produto quebrado",
    "Montagem errada",
    "Consumo de franqueados",
    "Consumo de funcionário",
    "Devolução de cliente",
    "Consumo influencer",
    "Fruta passada",
    "Produto vencido",
    "Açai/sorvete cristalizado",
    "Cascas",
    "Bonificação de cliente (voucher)",
    "Outros",
  ];

  useEffect(() => {
    const edit = () => {
      form.setFieldsValue({
        quantity: editWaste?.quantity,
        unity: editWaste?.unity,
      });
      setPrice(+(editWaste?.price_sell || 0));
      if (editWaste) {
        const _options = options.findIndex((index) =>
          index.includes(editWaste.reason)
        );
        if (_options !== -1) {
          form.setFieldsValue({
            reason: options[_options],
          });
        } else {
          form.setFieldsValue({
            reason: options[11],
            motivo: editWaste.reason,
          });
          setShowOtherInput(true);
        }
      }
    };
    if (visible) {
      edit();
    } else {
      setShowOtherInput(false);
      setEditWaste(undefined);
      form.resetFields();
    }
  }, [visible]);

  const onFinish = async () => {
    await form.validateFields();
    setLoading(true);
    try {
      const payload = form.getFieldsValue();

      if (image) {
        const imgToUpload = new FormData();
        imgToUpload.append("file", image);

        const {
          data: { location, key },
        } = await apiS3Handler.post(
          "/s3-upload/upload/waste-files",
          imgToUpload
        );
        payload.s3_key = key;
        payload.url_file = location;
      }

      payload.price_sell = price;
      payload.reason = showOtherInput ? payload.motivo : payload.reason;
      delete payload.motivo;
      await api.put(`/product_store_waste/${editWaste?.id}`, payload);
      notification.success({
        message: "Desperdício editado!",
        description: "O desperdício selecionado foi editado com sucesso.",
        duration: 5,
      });

      setShouldSearch(true);
      setEditModalVisible(false);
    } catch (error) {
      //@ts-ignore
      const _description = error.message;
      console.log("Erro ao enviar para s3", error);
      notification.error({
        message: "Oops! Não foi possível editar o desperdício",
        description: error ? _description : "Falha ao editar o desperdício.",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = (files: Files): void => {
    const uploadedFile = files[0];
    setImage(uploadedFile);
    setUploadedFiles(uploadedFile);
  };

  return (
    <Modal
      title={<ModalTitle>Editar Disperdicio</ModalTitle>}
      destroyOnClose={true}
      visible={visible}
      closable={false}
      onCancel={() => setEditModalVisible(false)}
      width={"70%"}
      centered
      footer={[
        <ContentModal>
          <ButtonCancelModal
            type="link"
            onClick={() => setEditModalVisible(false)}
          >
            <span>Cancelar</span>
          </ButtonCancelModal>
          <SaveButton loading={loading} onClick={() => onFinish()}>
            Salvar
          </SaveButton>
        </ContentModal>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <ColModal sm={6}>
            <Form.Item
              label="Unidade de medida"
              name="unity"
              rules={[{ required: true, message: "Selecione uma unidade" }]}
            >
              <Radio.Group
                onChange={(e) => setValue(e.target.value)}
                value={value}
                name="unity"
                style={{ alignItems: "center" }}
                defaultValue={Options.Quilograma}
              >
                <Radio value={Options.Quilograma} checked>
                  Quilograma
                </Radio>
                <Radio
                  value={Options.Unidade}
                  disabled={editWaste?.category_id === 10}
                >
                  Unidade
                </Radio>
              </Radio.Group>
            </Form.Item>
          </ColModal>
        </Row>
        <ContentModalBody>
          <ColModal sm={12}>
            <Form.Item label="Produto" name="name">
              <Input
                type="string"
                name="name"
                disabled={true}
                defaultValue={editWaste?.name}
              />
            </Form.Item>
          </ColModal>

          <ColModal sm={6}>
            <Form.Item label="Quantidade" name="quantity">
              <Input name="quantity" placeholder="Digite aqui" type="number" />
            </Form.Item>
          </ColModal>

          <ColModal sm={6}>
            <Form.Item label="Valor" name="price_sell">
              <MonetaryInput2
                getValue={(e) => {
                  setPrice(e);
                }}
                defaultValue={price}
              />
            </Form.Item>
          </ColModal>

          <ColModal sm={24}>
            <Form.Item
              label="Selecione um motivo"
              name="reason"
              rules={[{ required: true, message: "Selecione um motivo" }]}
            >
              <Radio.Group
                name="reason"
                onChange={(e) => {
                  setShowOtherInput(e.target.value === "Outros");
                }}
              >
                {options.map((option, index) => (
                  <Radio key={index} value={option}>
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </ColModal>

          {showOtherInput && (
            <ColModal sm={12}>
              <Form.Item
                label="Digite o motivo"
                name="motivo"
                rules={[{ required: true, message: "Descreva o motivo" }]}
              >
                <Input name="motivo" autoFocus={true} />
              </Form.Item>
            </ColModal>
          )}
          <Col sm={24}>
            <ContainerUpload>
              <UploadWaste
                onUpload={handleUpload}
                sizeFiles={25}
                fileName={uploadedFiles?.name}
              ></UploadWaste>
            </ContainerUpload>
          </Col>
        </ContentModalBody>
      </Form>
    </Modal>
  );
};

export default ModalEdit;
