import styled, { css } from "styled-components";

import { Edit, TrashRestore, Add } from "../../styles/Icons";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  .ant-table-container {
    padding: 0.3rem;
    .ant-table-thead {
      .ant-table-cell {
        color: var(--blue-900);
      }
    }
    .ant-table-cell {
      background: white;
      color: var(--blue-980);
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .ant-select-selection-item {
    width: 6rem;
  }
`;
export const ActionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconCSS = css`
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
`;

export const IconContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditIcon = styled(Edit)`
  ${IconCSS}
  margin-right: 0.9rem;
  color: var(--orange-350);
  :hover {
    color: var(--black);
  }
`;

export const DeleteIcon = styled(TrashRestore)`
  ${IconCSS}
  width: 1.6rem;
  height: 2.8rem;
  color: var(--gray-45);
  :hover {
    color: red;
  }
`;

export const NotData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 578px) {
    flex-direction: column;
  }
`;

export const NotDataImg = styled.img`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 578px) {
    width: 100%;
  }
`;

export const NoDataInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 15%;
`;

export const InfoHeader = styled.label`
  font-size: 80px;
  font-weight: bold;
  color: orange;
`;

export const InfoMiddle = styled.label`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0.9rem;
  @media only screen and (max-width: 578px) {
    font-size: 20px;
  }
`;

export const AddButton = styled.button`
  margin-right: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1.65rem;
  font-weight: 600;
  padding: 0.18rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgb(253, 193, 104) 0%,
    rgb(251, 128, 128) 100%
  );

  @media only screen and (max-width: 578px) {
    font-size: 0.9rem;
  }
`;

export const AddIcon = styled(Add)`
  width: 1.6rem;
  height: 1.6rem;
  fill: white;

  @media only screen and (max-width: 578px) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
