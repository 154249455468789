import React, { useState, useEffect } from "react";

import api from "../../services/api";
import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";
import { useStoreData } from "../../hooks/useStoreData";

import GetStore from "../../components/GetStore";
import StoreSelectionPage from "../../components/StoreSelectionPage";
import DisabledFlag from "../../components/DisabledFlag";

import Centralizer from "../../containers/Centralizer";
import PageContainer from "../../containers/PageContainer";
import ProductFranchiseeList from "../../containers/ProductFranchiseeList";
import AddProductFranchiseeList from "../../containers/AddProductFranchiseeList";

import { CategoryModel } from "../../models/CategoryModel";
import { Product as ProductModal } from "../../models/Product";
import { ProductStore as ProductStoreModel } from "../../models/ProductStore";

import { Empty, message, Radio } from "antd";

import iconStatus from "../../assets/svg/iconStatus.svg";
import productFranchisee from "../../assets/svg/productFranchisee.svg";
import SpinIcon from "../../assets/svg/spin.svg";

import ModalAuditProductFranchisee from "./Modal";

import {
  Container,
  TopSide,
  Content,
  Row,
  Col,
  ButtonAdd,
  Input,
  Modal,
  ModalFooter,
  ButtonCancel,
  LabelInfo,
  ButtonAdd2,
  ContentModal,
  HeaderModal,
  Label2,
  Spin,
  ModalHeader,
  ModalBody,
  RowModal,
  Select,
  ContentRadio,
  RadioItem,
  ButtonOpenFranchiseeModal,
} from "./styles";
import { Page } from "../../models/Page";

const { Option } = Select;

type ProductAdd = {
  store_id: number | undefined;
  product_id: number | undefined;
  price_unit: number;
  cod_ncm: string | undefined;
};

export type ProductAudit = ProductModal & {
  id: number;
  store_id: number;
  user_name: string;
  field: string;
  old_value: string;
  new_value: string;
  user_id: number;
  product_store_id: number;
  created_at: string;
  deleted_at: string | null;
  product_store: {
    id: number;
    product: {
      category_id: number;
      id: number;
      name: string;
    };
  };
};

const ProductFranchisee: React.FC = () => {
  const [productsFranchisee, setProductsFranchisee] = useState<
    ProductStoreModel[] | undefined
  >([]);
  const [filteredProducts, setFilteredProducts] = useState<
    ProductStoreModel[] | undefined
  >();
  const [filteredProductsModal, setFilteredProductsModal] = useState<
    ProductStoreModel[] | undefined
  >([]);
  const [products, setProducts] = useState<ProductModal[]>([]);
  const [productsAdd, setProductsAdd] = useState<ProductAdd[]>([]);
  const [store, setStore] = useState<number | undefined | null>();
  const [storeInfo, setStoreInfo] = useState<any | undefined | null>();
  const [categorySelect, setCategorySelect] = useState<number | null>();
  const [loading, setLoading] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [shouldAuditSearch, setShouldAuditSearch] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
  const [productFilterName, setProductFilterName] = useState<string>("");

  const [categories, setCategories] = useState<CategoryModel[]>();

  const [selectedStatus, setSelectedStatus] = useState<number | undefined>(
    undefined
  );

  const [openProductFranchiseeModal, setOpenProductFranchiseeModal] =
    useState(false);
  const [auditProductFranchisee, setAuditProductFranchisee] = useState<
    ProductAudit[]
  >([]);
  const [reloadAuditProductFranchisee, setReloadAuditProductFranchisee] =
    useState(false);

  const [paginationTable, setPaginationTable] = useState({
    current: 1,
    pageSize: 10,
  });

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });
  const { page, size } = paginate;
  const { isStoreActive } = useStoreData(store);

  const sortProductsById = (products) => {
    return products.sort(
      (firstProduct, secondProduct) =>
        firstProduct.product_id - secondProduct.product_id
    );
  };

  useEffect(() => {
    const fetchProductsStoreFranchisee = async () => {
      setLoading(true);
      try {
        const {
          data: { content },
        } = await api.get(`/products_store/store/${store}?withDeleted=true`);
        setProductsFranchisee(sortProductsById(content));
        setFilteredProducts(sortProductsById(content));

        const {
          data: { content: franchise },
        } = await api.get(`/products/franchisees/${store}`);
        setProducts(sortProductsById(franchise));
        setFilteredProductsModal(sortProductsById(franchise));
      } catch (error) {
        const _message =
          //@ts-ignore
          error?.response?.data?.message;
        message.error(_message || "Houve um erro ao realizar busca");
      } finally {
        setLoading(false);
        setShouldSearch(false);
      }
    };

    if (store && shouldSearch) {
      fetchProductsStoreFranchisee();
    }
  }, [store, shouldSearch]);

  useEffect(() => {
    const fetchCategories = async () => {
      const {
        data: { data: productsCategories },
      } = await api.get("/product_categories");

      setCategories(productsCategories);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchAuditInfo = async () => {
      setReloadAuditProductFranchisee(true);
      try {
        const {
          data: { content, totalElements },
        } = await api.get(
          `/products_store/audit/${store}?page=${page}&size=${size}`
        );
        setAuditProductFranchisee(content);
        setPaginate((oldValues) => ({ ...oldValues, totalElements }));
      } catch (error) {
        const _message =
          //@ts-ignore
          error?.response?.data?.message;
        message.error(_message || "Houve um erro ao realizar busca");
      } finally {
        setReloadAuditProductFranchisee(false);
        setShouldAuditSearch(false);
      }
    };

    if (store) fetchAuditInfo();
  }, [store, page, shouldAuditSearch, shouldSearch]);

  const handleAdd = async () => {
    setLoading(true);
    try {
      await api.post(`/products_store`, productsAdd);
      setProductsAdd([]);
      message.success("Produto(s) adicionado(s) com sucesso");
      setIsModalVisible(false);
      setIsModalVisibleAdd(false);
      setShouldSearch(true);
    } catch (e) {
      const _message =
        //@ts-ignore
        e?.response?.data?.message || "Falha ao adicionar produto";
      message.error(_message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const findProductFilter = () => {
      let _productsFilter = productsFranchisee?.map((item) => item) || [];
      if (categorySelect) {
        _productsFilter = _productsFilter?.filter(
          (product) => product.product?.category_id === categorySelect
        );
      }
      if (selectedStatus !== undefined) {
        _productsFilter = _productsFilter?.filter((product) =>
          selectedStatus === 1
            ? product.product_is_enable
            : !product.product_is_enable
        );
      }
      if (productFilterName) {
        const searchValue = removeAccentsAndLowerCase(productFilterName);
        _productsFilter = _productsFilter?.filter((product) =>
          removeAccentsAndLowerCase(product?.product?.name).includes(
            searchValue
          )
        );
      }
      setFilteredProducts(_productsFilter);
      setPaginationTable({ ...paginationTable, current: 1 });
    };
    findProductFilter();
  }, [categorySelect, shouldSearch, selectedStatus, productFilterName]);

  const findProductModal = ({ target: { value } }) => {
    const searchValue = removeAccentsAndLowerCase(value);
    const filter = products.filter((product) =>
      removeAccentsAndLowerCase(product?.name).includes(searchValue)
    );
    setFilteredProductsModal(filter);
  };

  return (
    <PageContainer route="Produtos do Gestor">
      {!store ? (
        <StoreSelectionPage
          title="Para cadastrar produtos no gestor"
          Img={productFranchisee}
          store={store}
          setStore={setStore}
          setStoreInfo={setStoreInfo}
          setShouldSearch={setShouldSearch}
        ></StoreSelectionPage>
      ) : (
        <Container>
          <DisabledFlag isStoreActive={isStoreActive} />

          <TopSide>
            <Row gutter={8}>
              <Col sm={5} xs={24}>
                <GetStore
                  defaultValue={store}
                  handleChange={(id) => {
                    setStore(id);
                    setShouldSearch(true);
                  }}
                  handleChangeStore={(value) => setStoreInfo(value)}
                />
              </Col>
              <Col sm={5} xs={24}>
                <Select
                  loading={loading}
                  placeholder={"Buscar por categoria"}
                  onChange={(value) => setCategorySelect(+value)}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {categories
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((category) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col sm={5} xs={10}>
                <Input
                  placeholder="Digite o nome do produto"
                  onChange={(e) => setProductFilterName(e.target.value)}
                />
              </Col>
              <Col sm={5} xs={24}>
                <ContentRadio>
                  <label htmlFor="status">Status no gestor:</label>
                  <Radio.Group
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <RadioItem>
                      <Radio value={1}>Habilitado</Radio>
                      <Radio value={0}>Desabilitado</Radio>
                      <Radio value={undefined} checked>
                        Sem seleção
                      </Radio>
                    </RadioItem>
                  </Radio.Group>
                </ContentRadio>
              </Col>
              <Col sm={4} xs={24}>
                <ButtonOpenFranchiseeModal
                  title="Auditoria"
                  onClick={() => setOpenProductFranchiseeModal(true)}
                >
                  Auditoria da loja
                </ButtonOpenFranchiseeModal>
              </Col>
            </Row>
          </TopSide>
          <Content>
            {loading ? (
              <Centralizer>
                <Spin src={SpinIcon} />
              </Centralizer>
            ) : (
              <>
                {productsFranchisee?.length !== 0 ? (
                  <ProductFranchiseeList
                    loading={loading}
                    products={filteredProducts || productsFranchisee}
                    setShouldUpdated={setShouldSearch}
                    setProducts={setProductsFranchisee}
                    setFilteredProducts={setFilteredProducts}
                    isStoreActive={isStoreActive}
                    storeInfo={storeInfo}
                    pagination={paginationTable}
                    setPagination={setPaginationTable}
                  ></ProductFranchiseeList>
                ) : (
                  <Centralizer>
                    <Empty description="Nenhum produto encontrado" />
                  </Centralizer>
                )}
              </>
            )}
          </Content>

          <Modal
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            width={"90%"}
            closable={false}
            centered
            footer={[
              <ModalFooter>
                <ButtonCancel
                  className="button-cancel"
                  onClick={() => setIsModalVisible(false)}
                >
                  Cancelar cadastro
                </ButtonCancel>

                <ButtonAdd
                  disabled={productsAdd.length === 0}
                  onClick={() => {
                    setIsModalVisibleAdd(true);
                  }}
                >
                  Adicionar {productsAdd.length}{" "}
                  {productsAdd.length === 1 ? "item" : "itens"}
                </ButtonAdd>
              </ModalFooter>,
            ]}
          >
            <>
              <ModalHeader>
                <RowModal gutter={4}>
                  <h1>Cadastrar novo produto</h1>
                  <Col sm={4} xs={0}>
                    <Input
                      placeholder="Digite o nome do produto"
                      onChange={findProductModal}
                    />
                  </Col>
                </RowModal>
              </ModalHeader>
              <ModalBody>
                {products.length ? (
                  <AddProductFranchiseeList
                    setProductsAdd={setProductsAdd}
                    storeId={store}
                    loading={loading}
                    products={filteredProductsModal || products}
                  />
                ) : (
                  <Centralizer>
                    <Empty description="Nenhum produto disponível para adicionar à loja" />
                  </Centralizer>
                )}
              </ModalBody>
            </>
          </Modal>

          <Modal
            visible={isModalVisibleAdd}
            onCancel={() => setIsModalVisibleAdd(false)}
            width={600}
            closable={false}
            centered
            style={{ paddingBottom: "1rem" }}
            footer={[
              <ModalFooter>
                <ButtonCancel
                  className="button-cancel"
                  onClick={() => setIsModalVisibleAdd(false)}
                >
                  Não
                </ButtonCancel>
                <ButtonAdd2 onClick={() => handleAdd()}>Sim</ButtonAdd2>
              </ModalFooter>,
            ]}
          >
            <ContentModal>
              <HeaderModal>
                <img
                  src={iconStatus}
                  alt="iconStatus"
                  onClick={() => setShouldSearch(true)}
                />
                <Label2>Adicionar produto</Label2>
              </HeaderModal>

              <LabelInfo>
                Deseja continuar e adicionar este produto a loja?
              </LabelInfo>
            </ContentModal>
          </Modal>

          <ModalAuditProductFranchisee
            auditProductFranchisee={auditProductFranchisee}
            openProductFranchiseeModal={openProductFranchiseeModal}
            reloadAuditProductFranchisee={reloadAuditProductFranchisee}
            setOpenProductFranchiseeModal={setOpenProductFranchiseeModal}
            paginate={paginate}
            setPaginate={setPaginate}
            setShouldAuditSearch={setShouldAuditSearch}
          />
        </Container>
      )}
    </PageContainer>
  );
};

export default ProductFranchisee;
