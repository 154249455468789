import styled, { css } from "styled-components";

import { CancelCircle } from "../../styles/Icons";

import { Row as RowAnt } from "antd";

interface IContainer {
  width?: number | string;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  @media only screen and (max-width: 578px) {
    width: 95%;
  }
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  margin-bottom: 10px;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  padding: 25px 0 10px 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
`;

export const Row = styled(RowAnt)`
  width: 100%;
`;

export const CancelIcon = styled(CancelCircle)`
  width: 20px;
  height: 20px;
  color: red;
  cursor: pointer;
`;
