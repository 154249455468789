import styled, { css } from "styled-components";

import { Modal, Button } from "antd";

export const Container = styled(Modal)`
  .dropzone {
    height: 30%;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
  }

  .upload-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    margin-top: 2%;
    max-height: 15rem;
  }

  .upload-file {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1%;
    margin-top: 1%;

    span {
      color: var(--gray-999);
      font-weight: bold;
    }

    button {
      font-size: 1rem;
      color: red;

      :hover {
        text-decoration: underline;
      }
    }
  }
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 20%;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ButtonSave = styled(Button)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;
