import styled, { css } from "styled-components";

import { Filter, FileRemoveOutline, Download, CashRegister } from "../../styles/Icons";

import {
  Button as ButtonAnt,
  Menu as MenuAnt,
  Row as RowAnt,
  Col as ColAnt,
  Select as SelectAnt,
  Checkbox as CheckboxAnt,
  DatePicker as DatePickerAnt,
  Input,
} from "antd";
const { Option: OptionAnt } = SelectAnt;
const { TextArea } = Input;

export const Textarea = styled(TextArea)`
  height: 3.7rem;
  width: 20%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  height: 90%;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 1400px) {
    margin-top: 1%;
  }
  @media screen and (max-width: 500px) {
    height: 80%;
    overflow: scroll;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.8rem;

  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;

export const ButtonRow = styled(RowAnt)`
  padding: 0;
  margin: 0;

  @media screen and (max-width: 800px) {
    margin-top: 0.8rem;
  }
  @media screen and (max-width: 580px) {
    margin: 0.9rem 0 0 0;
    display: flex;
    justify-content: start;
    align-items: center;

    margin-left: 0 !important;
  }
`;

interface IMenu {
  disabled: boolean;
}

export const FilterMenu = styled.a<IMenu>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 5px;

  background: var(--orange-350);
  color: white;

  :hover {
    color: white;
    opacity: 80%;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        background: var(--white-10);
      `;
    }
  }}
`;

export const FilterIcon = styled(Filter)`
  width: 20px;
  height: 20px;
`;

export const Col = styled(ColAnt)``;

export const CheckboxMenuGroup = styled(CheckboxAnt.Group)`
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
`;
export const Menu = styled(MenuAnt)`
  padding: 0;

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 0;
  }
`;

const button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  border: 0;
  background: var(--orange-350);
  color: white;

  :focus,
  :hover {
    background: var(--orange-350);
    opacity: 80%;
    border: none;
    color: var(--white-25);
  }
`;

export const CheckboxMenu = styled(CheckboxAnt)`
  zoom: 1.2;
  font-size: 12px;
  border-bottom: 1px solid var(--gray-25);
  padding: 2% 1rem;
`;

export const Select = styled(SelectAnt)`
  width: 100%;
`;

export const Header = styled(RowAnt)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 9%;
  min-height: 3rem;

  @media only screen and (max-width: 800px) {
    height: 20%;
  }
`;

export const ColMinimum = styled(ColAnt)`
  min-width: 3.5rem;
`;

export const ButtonNFCe = styled(button)`
  width: 3.25rem;
  height: 3.25rem;
`;

export const NFCeIcon = styled(FileRemoveOutline)`
  width: 20px;
  height: 20px;
`;

export const ButtonDownloadCSV = styled(button)`
  width: 3.25rem;
  height: 3.25rem;
`;

export const DownloadIcon = styled(Download)`
  width: 20px;
  height: 20px;
`;

export const CashRegisterIcon = styled(CashRegister)`
  width: 20px;
  height: 20px;
`;

export const ButtonDownload = styled(button)`
  background: var(--orange-gradient);
  color: white;
  min-width: 10.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  :focus,
  :hover {
    background: var(--orange-gradient);
  }

  @media only screen and (max-width: 1200px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 840px) {
    font-size: 0.6rem;
  }
`;

export const Option = styled(OptionAnt)``;
