import React, { Dispatch, SetStateAction } from "react";

import { verifyPermission } from "../../services/auth";

import { StandardFreight } from "../../models/StandardFreight";

import { Table, Tooltip } from "antd";

import NotDataSVG from "../../assets/svg/nodata.svg";

import {
  Container,
  EditIcon,
  DeleteIcon,
  IconContent,
  NotData,
  NotDataImg,
  NoDataInfo,
  InfoHeader,
  InfoContainer,
  InfoMiddle,
  AddButton,
  AddIcon,
  ActionsHeader,
} from "./styles";
interface IProps {
  setVisible: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  freights: StandardFreight[];
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
}

const StandardFreightList: React.FC<IProps> = ({
  loading,
  freights,
  setVisible,
  handleUpdate,
  handleDelete,
}) => {
  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      width: "40%",
      sorter: (FreightId1, FreightId2) => FreightId2?.id - FreightId1?.id,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Mínimo",
      dataIndex: "distance_min",
      key: "distance_min",
      width: "15%",
      responsive: ["sm" as any],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Máximo",
      dataIndex: "distance_max",
      key: "distance_max",
      width: "15%",
      responsive: ["sm" as any],
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      width: "20%",
      render: (text) => <span>{text}%</span>,
    },
    {
      title: <ActionsHeader>Ações</ActionsHeader>,
      width: "10%",
      render: (text, record) => (
        <IconContent>
          {verifyPermission("standard_freight.edit") && (
            <Tooltip title={"Editar"}>
              <EditIcon
                onClick={() => {
                  handleUpdate(record.id);
                  setVisible(true);
                }}
              />
            </Tooltip>
          )}
          {verifyPermission("standard_freight.delete") && (
            <Tooltip title={"Excluir"}>
              <DeleteIcon onClick={() => handleDelete(record.id)} />
            </Tooltip>
          )}
        </IconContent>
      ),
    },
  ];

  return (
    <Container>
      {freights.length ? (
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          dataSource={freights.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
        />
      ) : (
        <NotData>
          <NoDataInfo>
            <InfoContainer>
              <InfoHeader>Oops!</InfoHeader>
              <InfoMiddle>Nenhum critério cadastrado</InfoMiddle>
              <AddButton onClick={() => setVisible(true)}>
                <AddIcon />
                Adicionar Novo
              </AddButton>
            </InfoContainer>
          </NoDataInfo>
          <NotDataImg src={NotDataSVG} />
        </NotData>
      )}
    </Container>
  );
};

export default StandardFreightList;
