import styled from "styled-components";
import { Row as RowAnt, Col as ColAnt } from "antd";
export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const THeaderContainer = styled.div`
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid var(--gray-75);
`;

export const THead = styled(RowAnt)`
  width: 100%;
  font-size: 14px;
  padding: 0 1rem;
`;

export const Col = styled(ColAnt)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;


  @media only screen and (max-width: 578px) {
    display: ${({ xs }) => xs === 0 && "none"};
  }
`;

export const HeaderInfo = styled.label`
  color: var(--blue-900);
  font-weight: 500;

  @media only screen and (max-width: 578px) {
    font-size: 14px;
  }
`;

export const TBody = styled.div`
  max-width: 100%;
  padding: 0 1rem;
  margin-bottom: 5px;
  overflow-y: scroll;
  border: 1px solid var(--gray-75);
  border-top: 0;
  border-radius: 0px 0px 5px 5px;
  background: transparent;
  overflow: auto;
  height: 90%;
  @media only screen and (max-width: 578px) {
    height: 81%;
  }
  label {
    font-size: 16px;
  }

`;

export const Spin = styled.img``;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
