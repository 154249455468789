import styled from "styled-components";

import { Modal as ModalAnt } from "antd";

export const Modal = styled(ModalAnt)`
  margin-top: 1rem;
  .ant-modal-body {
    height: 30rem;
    overflow: hidden !important;
  }
  .ant-modal-content {
    height: 100%;
    overflow: hidden !important;
  }

  .ant-modal-header {
    padding: 16px 53px;
  }

  iframe {
    width: 100%;
    height: 30rem;
    overflow: hidden !important;
  }
`;

export const Button = styled.button`
  width: 3.5rem;
  height: 2rem;

  background-color: var(--orange-350);
  color: white;

  border-radius: 4px;

  :hover {
    background-color: var(--orange-450);
  }
`;

export const ContentModal = styled.div`
  width: 100%
`;
