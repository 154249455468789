import React, { useState, useEffect } from "react";
import SideMenu from "../../components/SideMenu";
import { getToken, verifyPermission } from "../../services/auth";
import {
  Container,
  MenuContainer,
  PageContainer,
  Page,
  ButtonChat,
  ChatIframe,
  IFrameDiv,
  Badge,
} from "./styles";

const Layout: React.FC = ({ children }) => {
  const [isVisible] = useState<boolean>(true);
  const [isVisibleChat, setIsVisibleChat] = useState<boolean>(false);

  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { type, value } = event.data;
      if (type === "unreadMessages") {
        setUnreadMessages(value);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <Container onClick={() => setIsVisibleChat(false)}>
        {isVisible && (
          <MenuContainer>
            <SideMenu />
          </MenuContainer>
        )}
        <PageContainer>
          <Page>{children}</Page>
        </PageContainer>
      </Container>
      {verifyPermission("view_support") && (
        <>
          <ButtonChat onClick={() => setIsVisibleChat(!isVisibleChat)}>
            <span>Suporte</span>
          </ButtonChat>
          {unreadMessages ? <Badge>{unreadMessages}</Badge> : <></>}
          <IFrameDiv
            style={isVisibleChat ? { display: "flex" } : { display: "none" }}
          >
            <ChatIframe
              id="iframeChat"
              src={`${process.env.REACT_APP_SUPPORT_URL}/login/${getToken()}`}
              allow="microphone"
            ></ChatIframe>
            <button onClick={() => setIsVisibleChat(false)}>X</button>
          </IFrameDiv>
        </>
      )}
    </>
  );
};

export default Layout;
