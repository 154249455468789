import React, { useState } from 'react'

import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

import { Status } from '../../../models/enums/cargoType';
import { CompanyInfo, DeliveryRouteItem, Props } from '../../../models/DeliveryRoute';

import { Modal } from 'antd';
import { Button, Container, ContentInfo, MapPinIcon, ContentButton, SuccessText } from './styles'

interface IInfoProps {
    selectedDriverInfo: DeliveryRouteItem | null
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
    driver: CompanyInfo | null
    inRouteDelivery: (id: number) => Promise<void>
    completeDelivery: (id: number) => Promise<void>
    sendComment: (id: number, comment: string) => Promise<void>
    loading: boolean;
    driverInfo: Props[];
}

const InfoScreen: React.FC<IInfoProps> = ({ loading, selectedDriverInfo, driverInfo, setOpenDrawer, driver, inRouteDelivery, completeDelivery, sendComment }) => {
    const [showTextArea, setShowTextArea] = useState(false);
    const [commentText, setCommentText] = useState("");

    const openMap = () => {
        if (driver) {
            const { address, cep, city, state_registration } = driver;
            const encodedAddress = encodeURIComponent(`${address}, ${city} - ${state_registration}`);
            window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`);
        }
    };

    const isPreviousRouteFinished = () => {
        const getLastFinishedIndex = driverInfo[0]?.deliveryRouteItem.map((item) => item).filter((item) => item?.finished_at === null)
        return getLastFinishedIndex[0]?.sequence
    };


    return (
        <>
            <Container>
                <ContentInfo>

                    <>
                        <div className="content-column">
                            <span className="bold">Sequência</span>
                            <span>{selectedDriverInfo?.sequence}</span>
                        </div>
                        <div className="content-column">
                            <span className="bold">Loja</span>
                            <span>{driver?.company_name}</span>
                        </div>
                        <div className="content-column">
                            <span className="bold">Data de início</span>

                            <span>{moment(selectedDriverInfo?.updated_at, "DD-MM-YYYY HH:mm:ss").subtract(3, 'hours').format("DD-MM-YYYY HH:mm:ss")}</span>
                        </div>
                        <div className="content-column">
                            <span className="bold">Data de entrega</span>
                            <span>{selectedDriverInfo?.finished_at ? selectedDriverInfo?.finished_at : "Não finalizada"}</span>
                        </div>
                        <div className="content-column">
                            <span className="bold">Endereço</span>
                            <span>{driver?.address}, CEP: {driver?.cep} {driver?.city} - {driver?.state_registration}</span>
                        </div>
                        <div className="content-column">
                            <Button
                                backgroundColor='#59d8ff'
                                onClick={openMap}
                            >
                                <span>Abrir mapa</span>
                                <MapPinIcon />
                            </Button>
                        </div>
                    </>

                </ContentInfo>

                {selectedDriverInfo && (
                    selectedDriverInfo?.status !== Status.FINALIZADA ? (
                        <Button
                            style={{ height: '3rem' }}
                            disabled={selectedDriverInfo?.sequence !== isPreviousRouteFinished() || loading}
                            backgroundColor={selectedDriverInfo.status === Status.EM_ROTA ? '#5EB2FF' : selectedDriverInfo.status === Status.FINALIZADA ? 'green' : 'var(--orange-350)'}
                            onClick={() => {
                                if (!loading) {
                                    selectedDriverInfo?.status === Status.EM_ROTA
                                        ? completeDelivery(selectedDriverInfo?.id)
                                        : inRouteDelivery(selectedDriverInfo.id)
                                    setOpenDrawer(false)
                                }
                            }}
                        >
                            <span className="text-info">

                                {selectedDriverInfo?.status === Status.EM_ROTA ? 'Confirmar sua chegada' : 'Iniciar a entrega'}
                            </span>
                        </Button>
                    ) : <SuccessText>Entrega confirmada pelo motorista</SuccessText>
                )}

                {selectedDriverInfo?.comment === null ? (
                    <Button
                        style={{ height: '3rem', color: 'var(--white)', marginTop: '1rem' }}
                        backgroundColor={'#000'}
                        onClick={() => setShowTextArea(true)}
                        disabled={loading}
                    >
                        <span className="text-info">
                            Relatar problema
                        </span>
                    </Button>
                ) : null}

                <Modal
                    title="Relatar problema"
                    destroyOnClose
                    centered
                    closable
                    visible={showTextArea}
                    onCancel={() => setShowTextArea(false)}
                    width={window.outerWidth < 800 ? '90%' : '20%'}
                    footer={[
                        <ContentButton key="buttons">
                            <Button backgroundColor='var(--gray-45)' key="cancel" onClick={() => setShowTextArea(false)}>
                                <span style={{ color: 'var(--white)' }}>Cancelar</span>
                            </Button>

                            <Button
                                backgroundColor='var(--orange-350)'
                                key="ok"
                                onClick={() => {
                                    if (selectedDriverInfo?.id !== undefined) {
                                        sendComment(selectedDriverInfo.id, commentText);
                                        setShowTextArea(false)
                                    }
                                }}
                            >
                                <span>Enviar</span>
                            </Button>
                        </ContentButton>
                    ]}
                >
                    <TextArea
                        rows={10}
                        maxLength={100}
                        id='trouble'
                        name='trouble'
                        onChange={(e) => setCommentText(e.target.value)}
                    />
                </Modal>
            </Container >
        </>
    )
}

export default InfoScreen