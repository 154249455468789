import styled from 'styled-components';
import { Image, MapPin } from '../../../styles/Icons'
import { Button as ButtonAnt } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: scroll;
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;

    span {
        font-size: 1.2rem;
    }
`;

export const ContentInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    align-items: center;
    justify-content: start;

    .bold {
        font-weight: 700;
    }

    span{
        font-size: 1.2rem;
        color: var(--gray-45)
    }

    .content-column {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;

        text-transform: capitalize;
    }
`;

export const ContentButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

interface IButtonProps {
    backgroundColor: string;
}
export const Button = styled(ButtonAnt) <IButtonProps>`
    width: 100%;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    border-radius: 5px;

    background-color: ${props => props.backgroundColor};
    color: var(--white);
    span{
        color: var(--white);
    }

    .text-info {
        font-size: 2rem;

        @media screen and (max-width: 768px){
            font-size: 1.2rem;
        }
    }

    :hover, :focus {
      background-color: ${props => props.backgroundColor};
      color: var(--white);
      opacity: 70%;
    }
`;

export const ImageIcon = styled(Image)`
    width: 1rem;
    height: 1rem;
    margin-left: .5rem;
    color: var(--white);
`;
export const MapPinIcon = styled(MapPin)`
    width: 1rem;
    height: 1rem;
    margin-left: .5rem;
    color: var(--white);
`;

export const Box = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    border: 1px solid var(--gray-25);
    border-radius: 5px;

    div {
        display: flex;
        flex-direction: column;
    }
`;

export const SuccessText = styled.span`
    font-size:1.1rem;
    text-align: center;
    color: var(--green-400);
    margin-bottom: 1rem;
    border: 1px solid var(--green-400);
    border-radius: 5px;
    padding: 1rem;
`;
