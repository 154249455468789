import styled, { css } from "styled-components";

import { EditAlt, InfoCircle, Trash, UploadIcon } from "../../../styles/Icons";

import {
  Modal as ModalAnt,
  Form as FormAnt,
  Input as InputAnt,
  Select as SelectAnt,
  Row as RowAnt,
  Col as ColAnt,
  Checkbox as CheckboxAnt,
  Switch as SwitchAnt,
  Button as ButtonAnt,
} from "antd";

const { TextArea } = InputAnt;

export const Container = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1.3rem;
  }

  .ant-modal-body {
    padding: 0 2rem 1rem;
    overflow-y: auto;

    .ant-form-item {
      margin: 0;
    }
    .ant-input {
      :disabled {
        background: var(--gray-49);
      }
    }
    .ant-input-affix-wrapper {
      padding: 0;
      height: 3.2rem;
      input.ant-input {
        height: 3.1rem;
      }
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h2 {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .space {
    margin-top: 2rem;
  }
  .rowEnd {
    display: flex;
    justify-content: flex-end;
  }
  .addButton {
    background: none;
    border: none;
    box-shadow: none;
    color: var(--orange-350);

    :hover {
      background: none;
      border: none;
      box-shadow: none;
      color: var(--orange-350);
    }
  }
  .textAreaDiv {
    .ant-row .ant-form-item {
      display: flex;
      flex-direction: column !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .header {
      flex-direction: column;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 1rem 0;
`;

export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const Form = styled(FormAnt)``;

export const Input = styled(InputAnt)``;

export const TextAreaInput = styled(TextArea)``;

export const Switch = styled(SwitchAnt)`
  margin: 0 0.5rem;
`;

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--orange-350);
  border: none;
  box-shadow: none;
  border-radius: 0.5rem;
  height: 3.5rem;
  width: 100%;

  :hover,
  :active,
  :focus {
    color: var(--white);
    background: var(--orange-350);
    border: none;
    box-shadow: none;
    opacity: 70%;
  }
`;

export const Button = styled.button`
  ${buttonCss}
`;

export const ButtonCancel = styled(ButtonAnt)`
  ${buttonCss}
  background: var(--white);
  color: var(--orange-350);
  border: 1px solid var(--orange-350);

  :hover,
  :active,
  :focus {
    background: var(--white);
    color: var(--orange-350);
    border: 1px solid var(--orange-350);
  }
`;

export const Select = styled(SelectAnt)``;

export const Checkbox = styled(CheckboxAnt)``;

export const ContainerItems = styled.div`
  .ant-select-selector {
    border-radius: 0 !important;
  }
  .ant-input {
    border-radius: 0 !important;
  }
`;

export const IconInfo = styled(InfoCircle)`
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
  color: var(--gray-600);
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.25rem;
  border: 1px solid var(--gray-270);
`;

const IconCSS = css`
  width: 1.5rem;
  height: 1.5rem;
  color: var(--orange-350);
  cursor: pointer;
`;

export const IconTrash = styled(Trash)`
  ${IconCSS};
`;

export const IconEdit = styled(EditAlt)`
  ${IconCSS};
`;

export const ModalUploadNfe = styled(ModalAnt)`
  .ant-modal-header {
    padding: 1rem 1rem 0;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 1rem 1rem;

    h3 {
      color: var(--gray-45);
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;

interface IInputXML {
  activedXML?: boolean;
}

export const ContainerUploadXML = styled.div<IInputXML>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 8.25rem;
  border-radius: 0.5rem;

  input[type="file"] {
    background-color: transparent;
    color: transparent;
    border: 1px dashed var(--orange-350);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  input[type="file"]::file-selector-button {
    background-color: transparent;
    color: transparent;
    border: none;
  }

  span {
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    color: var(--gray-45);
    font-size: 0.9rem;
    font-weight: 500;
    bottom: 2rem;
    width: 100%;
    z-index: 0;
  }

  ${({ activedXML }) => {
    if (activedXML) {
      return css`
        input[type="file"] {
          background: var(--orange-350);
        }
        span {
          color: var(--white);
          z-index: 2;
        }
      `;
    }
  }}

  @media only screen and (max-width: 575px) {
    height: 12rem;

    span {
      bottom: 4rem;
    }
  }
`;

export const UploadXMLIcon = styled(UploadIcon)<IInputXML>`
  position: relative;
  height: 2rem;
  width: 2rem;
  top: 3rem;
  color: var(--orange-350);
  z-index: 0;

  ${({ activedXML }) => {
    if (activedXML) {
      return css`
        color: var(--white);
        z-index: 2;
      `;
    }
  }}
`;

export const ButtonModalUpload = styled(ButtonAnt)`
  ${buttonCss}
  background: var(--white);
  color: var(--orange-350);
  border: 1px solid var(--orange-350);

  :hover,
  :active,
  :focus {
    background: var(--white);
    color: var(--orange-350);
    border: 1px solid var(--orange-350);
  }
`;
