import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";

import apiS3Handler from "../../../../services/apiS3Handler";

import moment from "moment";
import { Divider, Modal, notification, Tooltip } from "antd";

import OrderPaymentType from "../../../../models/json/OrderPaymentType.json";
import { AccountPayable } from "../../../../models/AccountsPayable";
import { PaymentTypeShop } from "../../../../models/enums/PaymentConditionShop";

import PageContainer from "../../../../containers/PageContainer";

import ModalProvider from "../../../../components/ModalProvider";
import SearchProvider from "../../../../components/SearchProvider";
import MonetaryInput2 from "../../../../components/MonetaryInput2";
import Spinner from "../../../../components/Spinner";
import Upload from "../../../../components/Upload";

import { currencyFormater } from "../../../../services/currencyFormater";
import apiMercury from "../../../../services/apiMercury";

import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import {
  Col,
  Container,
  Row,
  Form,
  TitlePage,
  Input,
  TrashIcon,
  ColIcon,
  DatePicker,
  Select,
  Footer,
  Button,
  NewRowButton,
  ColAnexo,
  InfoCircleIcon,
  ContentFiles,
  ContentFilesRow,
  TrashIconUpload,
  FiletypePdfIcon,
  ContentPDF,
  ContentImage,
} from "./styles";

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

type Item = {
  key: number;
  additional_value: number;
  quantity: number;
  total?: number;
  product_id: number;
  unitary_value: number;
  value_icms: number;
  value_ipi: number;
  observation?: string;
  category_id: number;
  id?: number | null;
};

const paymentCondition = [
  { id: 1, name: "À vista" },
  { id: 2, name: "À prazo" },
];

const NewShop: React.FC<ComponentProps> = ({ location }) => {
  const { state } = location;
  const { record } = state;

  const { store } = state
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [items, setItems] = useState<Item[]>([
    {
      key: Date.now(),
      additional_value: 0,
      quantity: 0,
      product_id: 0,
      unitary_value: 0,
      value_icms: 0,
      value_ipi: 0,
      observation: "",
      category_id: 0,
    },
  ]);
  const [itemsPayable, setItemsPayable] = useState<AccountPayable[]>([
    {
      account_type: 0,
      category_id: null,
      store_id: null,
      provider_id: null,
      payment_amount: null,
      occurrence: null,
      due_date: null,
      payment_method: null,
      issue_date: null,
      month_competence: null,
      observationItem: "",
    },
  ]);
  const [visible, setVisible] = useState(false);

  const [selectedCondition, setSelectedCondition] = useState<number | null>(
    null
  );
  const [installments, setInstallments] = useState<number>(1);
  const [installmentValue, setInstallmentValue] = useState(0);

  const [discountValue, setDiscountValue] = useState<number | null>();
  const [valueFreight, setValueFreight] = useState<number | null>();
  const [totalShop, setTotalShop] = useState<number | null>();

  const [fileList, setFileList] = useState<File[]>([]);

  const [totalItemsValue, setTotalItemsValue] = useState({
    total_icms: 0,
    total_ipi: 0,
    total_shop: 0,
    additional_value: 0,
    total_products: 0,
  });

  const [provider, setProvider] = useState<number | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);

  useEffect(() => {
    if (record) {
      setLoading(true);
      const {
        discount_value,
        value_freight,
        created_at,
        occurrence,
        due_date,
        observation,
        provider_id,
        purchasesItems,
        purchase_installment,
        additional_value,
        accountsPayable,
      } = record;

      form.setFieldsValue({
        occurrence: occurrence,
        due_date: due_date ? moment(due_date) : null,
        observation: observation,
        discount_value: discount_value,
        value_freight: value_freight,
        created_at: created_at ? moment(created_at) : null,
        total_quantidades: calculateTotalQuantities(purchasesItems),
        purchase_installment: purchase_installment,
      });

      setTotalItemsValue((oldValues) => ({
        ...oldValues,
        total_ipi: calculateTotalProductsValue(purchasesItems).ipiTotal,
        total_shop:
          calculateTotalProductsValue(purchasesItems).totalCalculateShop,
        total_icms: calculateTotalProductsValue(purchasesItems).icmsTotal,
        total_products:
          calculateTotalProductsValue(purchasesItems).totalProducts,
        additional_value: +additional_value,
      }));

      setDiscountValue(discount_value);
      setValueFreight(value_freight);
      setProvider(provider_id);
      setSelectedCondition(occurrence);
      setInstallmentValue(installmentValue);

      if (purchasesItems && purchasesItems.length > 0) {
        const mappedItems = purchasesItems.map((item, index) => {
          const totalItemValue =
            parseFloat(item.quantity) * parseFloat(item.unitary_value);
          return {
            key: index,
            product_id: item.product_id,
            quantity: item.quantity,
            unitary_value: item.unitary_value,
            value_icms: item.value_icms,
            value_ipi: item.value_ipi,
            total: totalItemValue + (item?.value_ipi / 100) * totalItemValue,
            observation: item.observation || "",
            category_id: item.category_id,
            id: item.new ? null : item.id,
          };
        });

        setItems([...mappedItems]);
      }

      if (accountsPayable && accountsPayable.length > 0) {
        const mappedItems = accountsPayable.map((item) => {
          return {
            account_type: item?.account_type,
            category_id: item?.category_id,
            store_id: item?.store_id,
            provider_id: item?.provider_id,
            payment_amount: item.payment_amount,
            due_date: item?.due_date,
            payment_method: item.payment_method,
            issue_date: item?.issue_date,
            observationItem: item.observation,
            created_at: item?.created_at,
          };
        });
        setItemsPayable([...mappedItems]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [record, form]);

  useEffect(() => {
    if (!record) {
      const installmentValue = (totalShop || 0) / (installments || 1)

      const newItemsPayable = Array.from(
        { length: installments || 1 },
        (_, index) => ({
          account_type: null,
          category_id: null,
          store_id: null,
          provider_id: null,
          payment_amount: installmentValue,
          occurrence: null,
          due_date: null,
          payment_method: null,
          issue_date: null,
          month_competence: null,
          observationItem: null,
        })
      );

      setItemsPayable(newItemsPayable);
    }
  }, [installments, selectedCondition]);

  const calculateTotalQuantities = (_items) => {
    return _items.reduce((acc, item) => acc + (+item.quantity || 0), 0);
  };

  const calculateTotalProductsValue = (_items) => {
    return _items.reduce(
      (acc, item) => {
        const quantity = +item.quantity || 0;
        const unitaryValue = +item.unitary_value || 0;
        const total = item.total || 0;
        const ipiValue = +item.value_ipi || 0;
        const icmsValue = +item.value_icms || 0;

        acc.totalCalculateShop += total;
        acc.totalProducts += quantity * unitaryValue;
        acc.ipiTotal += ipiValue;
        acc.icmsTotal += icmsValue;

        return acc;
      },
      { totalCalculateShop: 0, totalProducts: 0, ipiTotal: 0, icmsTotal: 0 }
    );
  };

  useEffect(() => {
    const calculateTotalProductsValueItems = () => {
      const totals = calculateTotalProductsValue(items);

      setTotalItemsValue((oldValues) => ({
        ...oldValues,
        total_ipi: totals.ipiTotal,
        total_shop: totals.totalCalculateShop,
        total_icms: totals.icmsTotal,
        total_products: totals.totalProducts,
      }));
    };

    form.setFieldsValue({
      total_quantidades: calculateTotalQuantities(items),
    });
    if (items) {
      calculateTotalProductsValueItems();
    }
  }, [items]);

  const calculateTotalShop = (values) => {
    const { additional_value, total_shop } = values;

    const totalBase = additional_value + total_shop;
    const totalCalculated =
      totalBase - +(discountValue || 0) + +(valueFreight || 0);
    return +totalCalculated.toFixed(2);
  };

  useEffect(() => {
    setTotalShop(Math.max(0, calculateTotalShop(totalItemsValue)));
  }, [totalItemsValue, discountValue, valueFreight]);

  const handleInputChange = (index, name, value) => {
    setItemsPayable((prevItems) => {
      const newItems = [...prevItems];

      if (newItems[index]) {
        newItems[index] = { ...newItems[index], [name]: value };
      }

      return newItems;
    });
  };

  useEffect(() => {
    if (installments > 0) {
      const value = ((totalShop || 0) / installments).toFixed(2);
      setInstallmentValue(+value);
    } else {
      setInstallmentValue(+totalShop!.toFixed(2));
    }
  }, [totalShop, installments]);

  const handleUpload = (files: File[]): void => {
    if (fileList.length + files.length > 5) {
      return notification.warning({
        message: "Limite máximo de imagens excedido",
        description: "O limite de imagens vinculadas é de no máximo 5.",
        duration: 5,
      });
    }

    const sameNames = fileList.filter((uploadedFile) =>
      files.some((file) => file.name === uploadedFile.name)
    );

    if (sameNames.length) {
      return notification.warning({
        message: "Arquivo já selecinado",
        description: "A imagem selecionada já existe na sua lista de upload.",
        duration: 5,
      });
    }

    const _newList = [...fileList, ...files];
    setFileList(_newList);
  };

  const uploadFiles = async () => {
    const result = await Promise.all(
      fileList.map(async (file) => {
        const fileToUpload = new FormData();
        fileToUpload.append("file", file)

        const {
          data: { location, key },
        } = await apiS3Handler.post(
          `/s3-upload/upload/amatech-purchase-files`,
          fileToUpload
        );
        return {
          url_file: location,
          s3_key: key,
        };
      })
    )
    return result || [];
  }

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    const totalPayments = itemsPayable.reduce(
      (acc, item) => acc + +(item.payment_amount!),
      0
    );

    if (Math.abs(Number(totalPayments) - Number(totalShop)) > 0.01) {
      notification.error({
        message: "Erro ao cadastrar compra",
        description: `O valor total das parcelas (${totalPayments.toFixed(
          2
        )}) não coincide com o valor total da compra (${totalShop!.toFixed(
          2
        )}).`,
        duration: 5,
      });
      return;
    }

    let payload = {
      store_id: state?.store,
      due_date: moment(values?.due_date).toISOString(),
      pay_date: null,
      total: +(totalShop || 0),
      provider_id: provider,
      provider: provider,
      name: state.productsCategory.find(
        (product) => product.id === values?.category_id0
      )?.name,
      occurrence: selectedCondition,
      value_freight: +(valueFreight || 0),
      discount_value: +(discountValue || 0),
      is_account_payable: true,
      purchase_installment: values?.purchase_installment,
      observation: values?.observation,
      purchase_date: values?.purchase_date,
      additional_value: totalItemsValue?.additional_value,
      purchaseFiles: await uploadFiles(),
      accountsPayableFiles: await uploadFiles(),

      purchasesItems: items.map((item) => ({
        product_id: item.product_id,
        category_id: item.category_id,
        unitary_value: item.unitary_value,
        quantity: item.quantity,
        value_ipi: +item.value_ipi,
        observation: item.observation || "",
        total: item.total,
        key: 0,
        new: true,
        id: item?.id,
        value_icms: item?.value_icms,
      })),

      accountsPayable: itemsPayable.map((item) => ({
        account_type: 1,
        category_id: 1,
        store_id: state?.store,
        provider_id: provider,
        payment_amount: item.payment_amount,
        due_date: moment(item?.due_date).toISOString(),
        payment_method: item.payment_method,
        issue_date: null,
        month_competence: null,
        observation: item.observationItem,
      })),
    };

    Modal.confirm({
      title: record ? "Editar compra" : "Cadastrar Compra",
      content: record ? "Deseja continuar e editar essa compra?" : "Deseja continuar e cadastrar essa compra?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      centered: true,
      async onOk() {
        try {
          const method = record ? "put" : "post";
          const url = record ? `/purchases/${record.id}` : "/purchases";
          await apiMercury[method](url, payload);

          form.resetFields();
          notification.success({
            message: record ? "Compra alterada com sucesso" : "Compra efetuada com sucesso",
            duration: 5,
          });

          history.push({
            pathname: "/shop",
            state: { store_param: store }
          });
        } catch (error) {
          //@ts-ignore
          const _description = error?.response?.data?.error?.message;

          notification.error({
            message: `Oops, ocorreu um erro ao ${record ? "Editar" : "Cadastrar"
              } a compra, ${_description}`,
            description: _description,
            duration: 5,
          });
        }
      },
    });
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        key: Date.now(),
        additional_value: 0,
        quantity: 0,
        product_id: 0,
        unitary_value: 0,
        value_icms: 0,
        value_ipi: 0,
        observation: "",
        category_id: 0,
      },
    ]);
  };

  const removeItem = (key) => {
    setItems(items.filter((item) => item.key !== key));
  };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index].quantity = +value;
    const _total = +value * newItems[index].unitary_value;
    const _total_ipi = _total * ((newItems[index].value_ipi || 0) / 100);
    newItems[index].total = _total + _total_ipi;
    setItems(newItems);
  };

  const handleValueChange = (index, value) => {
    const newItems = [...items];
    newItems[index].unitary_value = +value;
    const _total = +value * newItems[index].quantity;
    const _total_ipi = _total * ((newItems[index].value_ipi || 0) / 100);
    newItems[index].total = _total + _total_ipi;
    setItems(newItems);
  };

  const handleIpiChange = (value, index) => {
    const newItems = [...items];

    const _total = newItems[index].unitary_value * newItems[index].quantity;
    const _total_ipi_value = ((_total || 0) * (+value || 0)) / 100;

    newItems[index].value_ipi = +value;
    newItems[index].total = _total_ipi_value + (_total || 0);
    setItems(newItems);
  };

  const handleIcmsChange = (index, value) => {
    const newItems = [...items];
    const _total = newItems[index].unitary_value * newItems[index].quantity;
    const _total_icms_value = +value || 0;

    newItems[index].value_icms = _total_icms_value;
    const _total_ipi = _total * ((newItems[index].value_ipi || 0) / 100);
    newItems[index].total = _total + _total_ipi + _total_icms_value;
    setItems(newItems);
  };

  const handleBlur = (index) => {
    const item = items[index];
    const totalAmount = item.unitary_value * item.quantity;
    const newItems = [...items];
    newItems[index] = { ...item, total: totalAmount };
    setItems(newItems);
  };

  const handlePurchasesOrder = (value, name, index) => {
    let _items = [...items];
    _items[index] = { ..._items[index], [name]: value };
    setItems(_items);
  };

  const handlePaymentMethod = (value, name, index) => {
    let _items = [...itemsPayable];
    _items[index] = { ..._items[index], [name]: value };
    setItemsPayable(_items);
  };

  const deleteUploadDocument = async (
    s3Key: string,
    purchaseFileId?: number
  ) => {
    try {
      if (s3Key) {
        await apiS3Handler.delete(`/s3-upload/upload-services/${s3Key}`);
      }

      if (purchaseFileId) {
        await apiMercury.delete(`/purchases/${purchaseFileId}`);
      }

      const updatedFileList =
        record?.purchaseFiles.filter((item) => item.s3_key !== s3Key) || [];

      setFileList(updatedFileList);

      notification.success({
        message: "Arquivo deletado com sucesso",
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: "Erro ao deletar imagem",
        duration: 5,
      });
    }
  };

  return (
    <PageContainer
      content={
        <>
          <Link
            to={{
              pathname: "/shop",
              state: { store_param: store },
            }}
          >
            Voltar
          </Link>
        </>
      }
      route={state.record ? "Editar Compra" : "Adicionar Nova Compra"}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            form={form}
          >
            <Container>
              <Row>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Fornecedor
                        <Tooltip title="Empresa responsável pela venda dos produtos">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="provider_id"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <SearchProvider
                      defaultValue={state.record?.provider_id}
                      handleChange={(value) => setProvider(value)}
                      height="2.5rem"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <NewRowButton onClick={() => setVisible(true)}>
                Cadastrar novo fornecedor
              </NewRowButton>

              <TitlePage>Itens da Compra</TitlePage>
              {items.map((item, index) => (
                <>
                  {index > 0 && (
                    <Divider
                      orientation="left"
                      style={{ marginTop: 0, marginBottom: 0 }}
                    >
                      Item {index + 1}
                    </Divider>
                  )}
                  <Row gutter={12} key={item.key}>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Produto
                            <Tooltip title="Produto da compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`product_id${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Selecione algum produto"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            const cleanedInput =
                              removeAccentsAndLowerCase(input);
                            const cleanedOptionLabel =
                              removeAccentsAndLowerCase(option?.label ?? "");
                            return cleanedOptionLabel.includes(cleanedInput);
                          }}
                          value={item?.product_id || undefined}
                          defaultValue={record && item?.product_id}
                          disabled={record}
                          onChange={(value) => {
                            handlePurchasesOrder(value, "product_id", index);

                            const selectedProduct = state?.productsCategory
                              ?.flatMap((category) => category.products)
                              ?.find((product) => product.id === value);
                            const selectedCategoryProducts =
                              state?.productsCategory?.find(
                                (category) =>
                                  category.id === selectedProduct?.category_id
                              );

                            setSelectedProduct(+selectedCategoryProducts.id);
                          }}
                        >
                          {state.productsCategory?.map((productCategory) =>
                            productCategory.products?.map((product) => (
                              <Select.Option
                                value={product?.id || ""}
                                key={product?.id}
                                label={product.name}
                              >
                                {product.name}
                              </Select.Option>
                            ))
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Categoria
                            <Tooltip title="Tipo do produto">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`category_id${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Selecione uma categoria"
                          disabled={!item?.product_id || record}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={item?.category_id}
                          defaultValue={record && item?.category_id}
                          onChange={(value) => {
                            handlePurchasesOrder(value, "category_id", index);
                          }}
                        >
                          {record ? (
                            <>
                              {state.productsCategory?.map(
                                (productCategory) => (
                                  <Select.Option
                                    value={productCategory.id}
                                    key={productCategory.id}
                                    label={productCategory.name}
                                  >
                                    {productCategory.name}
                                  </Select.Option>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {state.productsCategory
                                ?.filter(
                                  (category) => category.id === selectedProduct
                                )
                                ?.map((productCategory) => (
                                  <Select.Option
                                    value={productCategory.id}
                                    key={productCategory.id}
                                    label={productCategory.name}
                                  >
                                    {productCategory.name}
                                  </Select.Option>
                                ))}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Valor Unitário
                            <Tooltip title="Preço de uma unidade do produto">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`unitary_value${index}`}
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => handleValueChange(index, value)}
                          defaultValue={record && item?.unitary_value}
                          disabled={!item?.product_id}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Quantidade
                            <Tooltip title="Número de itens">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`quantity${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          value={item.quantity}
                          defaultValue={record && item.quantity}
                          placeholder="Informe a qtd"
                          type="number"
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                          onBlur={() => handleBlur(index)}
                          min={0}
                          disabled={!item?.product_id}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            IPI %
                            <Tooltip title="Número de itens">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`value_ipi${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="value_ipi"
                          placeholder="Insira a %"
                          type="number"
                          min={0}
                          value={item.value_ipi || 0}
                          defaultValue={(record && item.value_ipi) || 0}
                          onChange={(e) =>
                            handleIpiChange(e.target.value, index)
                          }
                          suffix={"%"}
                          className="input-percent"
                          disabled={!item?.product_id}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={2} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            ICMS
                            <Tooltip title="Valor do ICMS do produto">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`value_icms${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) => handleIcmsChange(index, value)}
                          defaultValue={record && item?.value_icms}
                          disabled={!item?.product_id}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4} xs={24}>
                      <Form.Item
                        name={`observation${index}`}
                        label={
                          <span>
                            Observação do Item
                            <Tooltip title="Comentário sobre o produto">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                      >
                        <Input
                          placeholder="Insira a observação do item"
                          name="observation"
                          value={item.observation || ""}
                          defaultValue={(record && item.observation) || ""}
                          onChange={(e) =>
                            handlePurchasesOrder(
                              e.target.value,
                              "observation",
                              index
                            )
                          }
                          disabled={!item?.product_id}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={items.length > 1 ? 3 : 4} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Valor Total
                            <Tooltip title="Preço Unitário x Quantidade">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`total${index}`}
                      >
                        <Input
                          disabled
                          readOnly
                          name={`total${index}`}
                          value={currencyFormater(+(item?.total || 0))}
                          placeholder={`R$ ${currencyFormater(
                            +(item?.total || 0)
                          )}`}
                        />
                      </Form.Item>
                    </Col>
                    {items.length > 1 && index !== 0 && !record && (
                      <ColIcon md={1} xs={24}>
                        <Form.Item label=" ">
                          <TrashIcon onClick={() => removeItem(item.key)} />
                        </Form.Item>
                      </ColIcon>
                    )}
                  </Row>
                </>
              ))}
              {!record && (
                <NewRowButton onClick={addItem}>
                  + Adicionar novo item
                </NewRowButton>
              )}

              <TitlePage>Totais da Compra</TitlePage>
              <Row gutter={12}>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Total dos Produtos
                        <Tooltip title="Soma do valor total de todos itens da compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="total"
                  >
                    <Input
                      placeholder={
                        totalItemsValue.total_products
                          ? String(
                            currencyFormater(totalItemsValue.total_products)
                          )
                          : "R$ 0,00"
                      }
                      readOnly
                      disabled
                      value={
                        record &&
                        currencyFormater(totalItemsValue.total_products || 0)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col md={2} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Nº de Itens
                        <Tooltip title="Número de itens da compra (por unidade)">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="numero_itens"
                  >
                    <Input
                      placeholder={String(items.length) || "0"}
                      readOnly
                      disabled
                      value={items.length || "0"}
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Total de Qtde
                        <Tooltip title="Soma das quantidades de todos itens da compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="total_quantidades"
                  >
                    <Input readOnly disabled name="total_quantidades" />
                  </Form.Item>
                </Col>
                <Col md={2} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Total de IPI
                        <Tooltip title="Valor do IPI de todos os produtos em reais">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="total_ipi"
                  >
                    <Input
                      readOnly
                      disabled
                      type="number"
                      placeholder={totalItemsValue.total_ipi.toString()}
                      value={totalItemsValue.total_ipi}
                    />
                  </Form.Item>
                </Col>
                <Col md={2} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Total ICMS
                        <Tooltip title="Valor em reais do ICMS">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="total_icms"
                  >
                    <MonetaryInput2
                      style={{ height: "2.5rem" }}
                      getValue={(value) =>
                        setTotalItemsValue((oldValues) => ({
                          ...oldValues,
                          total_icms: +value,
                        }))
                      }
                      defaultValue={totalItemsValue.total_icms || 0}
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Valor Adicional
                        <Tooltip title="Valor Adicional da Compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="additional_value"
                  >
                    <MonetaryInput2
                      style={{ height: "2.5rem" }}
                      getValue={(value) =>
                        setTotalItemsValue((oldValues) => ({
                          ...oldValues,
                          additional_value: +value,
                        }))
                      }
                      defaultValue={
                        (record && totalItemsValue.additional_value) || 0
                      }
                      initialValue={totalItemsValue.additional_value || 0}
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Desconto
                        <Tooltip title="Qualquer desconto no valor da compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="discount_value"
                  >
                    <MonetaryInput2
                      style={{ height: "2.5rem" }}
                      getValue={(value) => setDiscountValue(+value)}
                      defaultValue={discountValue}
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Frete
                        <Tooltip title="Custo com entrega">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="value_freight"
                  >
                    <MonetaryInput2
                      style={{ height: "2.5rem" }}
                      getValue={(value) => setValueFreight(+value)}
                      defaultValue={record && valueFreight}
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Total da Compra
                        <Tooltip title="Valor Total dos Produtos + ICMS + Valor Adicional - Desconto + Frete">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="total_compra"
                  >
                    <MonetaryInput2
                      style={{ height: "2.5rem" }}
                      getValue={() => { }}
                      defaultValue={totalShop}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <TitlePage>Pagamento</TitlePage>
              <Row gutter={12}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Data de Lançamento
                        <Tooltip title="Dia que a compra foi feita">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="purchase_date"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <DatePicker
                      placeholder="dd/mm/aaaa"
                      format="DD/MM/YYYY HH:mm:ss"
                      name="purchase_date"
                      disabled={record}
                    />
                  </Form.Item>
                </Col>
                <Col md={selectedCondition !== PaymentTypeShop.A_PRAZO ? 12 : 6} xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Condição de Pagamento
                        <Tooltip title="Se a compra é a vista ou a prazo">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                    name="occurrence"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Select
                      placeholder="Selecione uma opção"
                      onChange={(value) => {
                        form.setFieldsValue({ occurrence: value });
                        setSelectedCondition(+value);
                      }}
                      value={form.getFieldValue("occurrence")}
                    >
                      {paymentCondition.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {selectedCondition === PaymentTypeShop.A_PRAZO && (
                  <Col md={6} xs={24}>
                    <Form.Item
                      label={
                        <span>
                          Parcelas
                          <Tooltip title="Número de parcelas">
                            <InfoCircleIcon />
                          </Tooltip>
                        </span>
                      }
                      name="purchase_installment"
                    >
                      <Input
                        placeholder="Número de parcelas"
                        type="number"
                        name="occurrence"
                        onChange={(e) => setInstallments(+e.target.value)}
                        disabled={record}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              {itemsPayable.map((item, index) => (
                <>
                  {index > 0 && (
                    <Divider
                      orientation="left"
                      style={{ marginTop: 0, marginBottom: 0 }}
                    >
                      Parcela {index + 1}
                    </Divider>
                  )}
                  <Row gutter={12} key={index}>
                    <Col md={4} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Data de Vencimento
                            <Tooltip title="Dia que vence a compra">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`due_date${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <DatePicker
                          placeholder="dd/mm/aaaa"
                          format="DD/MM/YYYY"
                          disabled={record}
                          value={moment(item?.due_date)}
                          onChange={(value) =>
                            handlePaymentMethod(
                              moment(value),
                              "due_date",
                              index
                            )
                          }
                          defaultValue={record && moment(item?.due_date)}
                          disabledDate={(current) => {
                            const createdAt = moment(
                              form.getFieldValue("created_at")
                            );
                            return current.isBefore(createdAt, "day");
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={3} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Valor
                            <Tooltip title="Valor do Pagamento">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`payment_amount${index}`}
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                        initialValue={installmentValue}
                      >
                        <MonetaryInput2
                          style={{ height: "2.5rem" }}
                          getValue={(value) =>
                            handleInputChange(value, "payment_amount", index)
                          }
                          defaultValue={
                            record
                              ? item?.payment_amount || 0
                              : installmentValue
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col md={4} xs={24}>
                      <Form.Item
                        name={`payment_method${index}`}
                        label={
                          <span>
                            Forma de Pagamento
                            <Tooltip title="Método de pagamento">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Selecione uma opção"
                          onChange={(value) => {
                            handlePaymentMethod(
                              +value,
                              "payment_method",
                              index
                            );
                          }}
                          value={item?.payment_method!}
                          defaultValue={
                            typeof item?.payment_method === "number"
                              ? item?.payment_method
                              : undefined
                          }
                        >
                          {OrderPaymentType?.map((type) => (
                            <Select.Option value={type.value} key={type.value}>
                              {type.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={13} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Observação
                            <Tooltip title="Comentário sobre o pagamento">
                              <InfoCircleIcon />
                            </Tooltip>
                          </span>
                        }
                        name={`observationItem${index}`}
                      >
                        <Input
                          placeholder="Insira uma observação"
                          value={item?.observationItem || ""}
                          onChange={(e) =>
                            handlePaymentMethod(
                              e.target.value,
                              "observationItem",
                              index
                            )
                          }
                          defaultValue={item?.observationItem || ""}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ))}

              <TitlePage>Observações e Anexos</TitlePage>
              <Row gutter={12}>
                <Col xs={24}>
                  <Form.Item
                    name="observation"
                    label={
                      <span>
                        Observação
                        <Tooltip title="Comentário sobre a compra">
                          <InfoCircleIcon />
                        </Tooltip>
                      </span>
                    }
                  >
                    <Input
                      placeholder="Adicione uma observação"
                      name="observation"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <ColAnexo xs={24}>
                  <Form.Item label="Anexos">
                    <Upload
                      onUpload={(file) => handleUpload(file)}
                      maxFiles={3}
                      fileType="image/*,.pdf,.zip,.rar"
                      dropzoneMsg={"Arraste seu arquivo ou procure aqui!"}
                    />

                    {record?.purchaseFiles &&
                      record.purchaseFiles.length > 0 ? (
                      <ContentFiles>
                        {record.purchaseFiles.map((item, index) => (
                          <ContentFilesRow key={index}>
                            <div>
                              {item.url_file.endsWith(".pdf") ? (
                                <ContentPDF>
                                  <FiletypePdfIcon onClick={item.url_file} />
                                </ContentPDF>
                              ) : item.url_file.endsWith(".jpg") ||
                                item.url_file.endsWith(".jpeg") ||
                                item.url_file.endsWith(".png") ? (
                                <ContentImage
                                  src={item.url_file}
                                  alt={`Purchase File ${index + 1}`}
                                />
                              ) : (
                                <ContentPDF>
                                  <FiletypePdfIcon />
                                  <a
                                    href={item.url_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    visualizar arquivo:{" "}
                                    {item.url_file.split("/").pop()}
                                  </a>
                                </ContentPDF>
                              )}
                            </div>
                            <TrashIconUpload
                              onClick={() =>
                                deleteUploadDocument(item.s3_key, item?.id)
                              }
                            />
                          </ContentFilesRow>
                        ))}
                      </ContentFiles>
                    ) : (
                      fileList && (
                        <ContentFiles>
                          {fileList.map((item, index) => (
                            <ContentFilesRow key={index}>
                              <span>{item?.name}</span>
                              <TrashIconUpload
                                onClick={() => deleteUploadDocument(item?.name)}
                              />
                            </ContentFilesRow>
                          ))}
                        </ContentFiles>
                      )
                    )}
                  </Form.Item>
                </ColAnexo>
              </Row>
            </Container>

            <Footer>
              <Button
                backgroundColor="var(--orange-350)"
                color="var(--white)"
                border="none"
                onClick={() => handleSubmit()}
              >
                Salvar
              </Button>
              <Button
                backgroundColor="transparent"
                color="var(--gray-650)"
                border="1px solid var(--gray-650)"
              >
                Cancelar
              </Button>
            </Footer>
          </Form>
        </>
      )}
      <ModalProvider
        visible={visible}
        setVisible={setVisible}
        providerId={record?.provider_id}
      />
    </PageContainer>
  );
};

export default NewShop;
