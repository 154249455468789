import styled from "styled-components";
import { Button as ButtonAnt } from "antd";

import { InfoCircle, Open, Download } from "../../styles/Icons";
import { Link } from "react-router-dom";

export const Title = styled.h1`
    font-weight: 500;
    font-size: 2rem;
    text-transform: capitalize;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Divider = styled.div`
    ${Content}

    &:not(:first-child) {
        margin-top: 1.1rem;
    }
`;

export const ContentAnexos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const TitleSection = styled.span`
    font-size: 1.1rem;
    font-weight: 500;
`;

export const TitlePaymentInfoSection = styled.span`
    font-size: 1rem;
    margin-bottom: 2px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-50);
`;

export const ContentRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    margin-bottom: .7rem;
`;

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;

    margin-bottom: .7rem;
`;

export const Label = styled.span`
    font-size: 1rem;
    font-weight: 500;
`;

export const ContentItemInfo = styled.div`
  display: flex;
  flex-direction: column;  
`;

export const ContentItemInfoPayment = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ItemUploadInfo = styled.div`
  display: flex;
`;

export const GoToPayment = styled.span`
    font-weight: 400;
    color: var(--gray-550);
    font-size: .9rem;
    text-transform: capitalize;
    display: flex;
    justify-content: space-around;
    align-items: center;

    cursor: pointer;
`;

export const Description = styled.span<{ alignLeft?: boolean }>`
  font-weight: 400;
  color: var(--gray-550);
  font-size: 0.9rem;
  text-transform: capitalize;
  display: flex;
  justify-content: ${({ alignLeft }) => (alignLeft ? "flex-start" : "flex-end")};
`;

export const OpenIcon = styled(Open)`
    width: 20px;
    height: 20px;
    color: var(--orange-350);
`;

export const DownloadIcon = styled(Download)`
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: var(--orange-350);

    cursor: pointer;
`;

export const TableElement = styled.table`
    width: 100%;
    table-layout: fixed; 
    border-collapse: collapse;

    th, td {
        text-align: left;
        padding: 8px; 
        text-transform: capitalize;

        @media screen and (max-width: 768px) {
            padding: 5px; 
        }
    }

    th {
        font-size: 1rem;
        font-weight: 500;
        align-items: center;

        @media screen and (max-width: 768px) {
            font-size: .9rem;
        }
    }

    tr {
        font-weight: 400;
        color: var(--gray-550);
        font-size: .9rem;    
        
        @media screen and (max-width: 768px) {
            font-size: .8rem;
        }
    }
    
    th, td {
        width: auto; 
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;

export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--orange-350);
    color: var(--white);
    width: 12.5rem;
    padding: .9rem 2rem;
    text-decoration: none; 
    border-radius: 10px;

    :hover {
        color: var(--white);
        background: var(--orange-350);
    }
`;

export const Button = styled(ButtonAnt) <{ color: string; backgroundColor: string; border: string }>`
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: ${({ border }) => border};
    width: 12.5rem;

    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: .5rem;

    border-radius: 10px;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 8px;

  cursor: pointer;
  color: var(--c64-blue);
`;