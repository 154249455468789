import styled from 'styled-components';
import { Modal as ModalAnt, Select as SelectAnt } from 'antd'

import { Status } from '../../../../styles/Icons'

export const StatusIcon = styled(Status)`
    width: 1rem;
    height: 1rem;
`;

export const Modal = styled(ModalAnt)``;

export const Select = styled(SelectAnt)`
    height: 3.2rem !important;
    border-radius: 5px !important;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ContentLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    :first-child {
        margin-bottom: 1rem;
    }
`;

export const Title = styled.span`
    font-size: 1.3rem;
    font-weight: 500;
`;

export const Header = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid var(--gray-25);
    border-radius: 5px;

    span {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .orange-span {
        color: var(--orange-350);
    }
`;