import React from 'react'
import { ContentDisabledStore } from './styles';

interface IProps {
    isStoreActive?: boolean;
}

const DisabledFlag: React.FC<IProps> = ({ isStoreActive }) => {
    return (
        <>
            {isStoreActive && (
                <ContentDisabledStore>
                    <span>Loja desabilitada</span>
                </ContentDisabledStore>
            )}
        </>
    )
}

export default DisabledFlag