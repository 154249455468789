import React, { useState, useEffect } from "react";
import moment from "moment";
import { v4 } from "uuid";

import GetStore from "../../components/GetStore";
import SpinIcon from "../../assets/svg/spin.svg";

import odinApi from "../../services/api";
import apiThor from "../../services/apiThor";
import apiMidas from "../../services/apiSalesHandler";
import { getTokenInfo } from "../../services/auth";

import { Tooltip, notification, Row, Col, Table } from "antd";

import {
  Container,
  ActionsContent,
  StoreSelectionContainer,
  Content,
  Empty,
  AlignCenter,
  Spin,
  OpenCloshStoreCashContainer,
  Button,
  Modal,
  ChangeStoreCashStatusTitle,
  ProgressContainer,
  CupomProgressContainer,
  RedeemVoucherContainer,
  VoucherContainer,
  VoucherHistoryContainer,
  CompanyVoucherContainer,
  InputCode,
  CustomerVoucherContent,
  ButtonSave,
  ButtonCancel,
  VoucherBar,
  Count,
  ContainerMain,
  ContainerSearch,
  ProgressLabelsContainer,
  LabelWithCount,
  ProgressBarContainer,
  ProgressBar,
  ProgressTooltip,
  VoucherBarUsed,
} from "./styles";
import PageContainer from "../../containers/PageContainer";

const storagePrefix = "@dashboard:vouchers";

const Cupom: React.FC = () => {
  const [selectedStore, setSelectedStore] = useState<null | number>(null);
  const [storeCash, setStoreCash] = useState<null | {
    code: string;
    cash_id: number;
    history_id: number;
  }>(null);

  const [loading, setLoading] = useState(false);
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const [companyVoucher, setCompanyVoucher] = useState<any>();
  const [userVoucher, setUserVoucher] = useState<any>();
  const [loadingCompanyVoucher, setLoadingCompanyVoucher] = useState(false);

  const [updatingStoreCashStatus, setUpdatingStoreCashStatus] = useState(false);
  const [openStoreCashStatus, setOpenStoreCashStatus] = useState(false);
  const [cupom, setCupom] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [storeCashStatus, setStoreCashStatus] = useState<
    { code: string; is_opended: boolean }[]
  >([]);

  useEffect(() => {
    const storagedData = window.localStorage.getItem(storagePrefix);
    if (storagedData) {
      const { code, cash_id, history_id, store_id } = JSON.parse(storagedData);
      setStoreCash({
        cash_id,
        history_id,
        code,
      });
      setSelectedStore(store_id);
    }
  }, []);

  useEffect(() => {
    if (selectedStore) {
      setCompanyVoucher(null);
      setUserVoucher(null);
      setHasSearched(false);
      clearCupomState();
    }
  }, [selectedStore]);

  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { data: _storeCashStatus },
        } = await odinApi.get(`/store_cashes/${selectedStore}/summary`);
        const storeCashStatusResponse: { code: string; is_opended: boolean }[] =
          [];
        _storeCashStatus.closed.forEach((element) => {
          const code = element.split("-")[1];
          storeCashStatusResponse.push({ code, is_opended: false });
        });
        _storeCashStatus.open.forEach((element) => {
          const code = element.split("-")[1];
          storeCashStatusResponse.push({ code, is_opended: true });
        });
        setStoreCashStatus(
          storeCashStatusResponse.sort(
            (elemA, elemB) => +elemA.code - +elemB.code
          )
        );
      } catch (error) {
        formatAndEmitErrorMessages(error);
      }
    }
    if (selectedStore && openStoreCashStatus && !storeCash) fetch();
  }, [selectedStore, openStoreCashStatus, storeCash]);

  useEffect(() => {
    async function fetch() {
      try {
        setLoadingCompanyVoucher(true);
        const { data } = await apiThor.get(
          `/company/company/${selectedStore}/the-best-day`
        );
        setCompanyVoucher({
          voucher: data.content.voucher,
          customers: data.content.customers,
        });
      } catch (error) {
        formatAndEmitErrorMessages(error);
      } finally {
        setLoadingCompanyVoucher(false);
      }
    }
    if (selectedStore && storeCash) fetch();
  }, [selectedStore, storeCash]);

  const formatAndEmitErrorMessages = (error: any) => {
    if (!error) return;
    const message =
      error.response?.message ||
      error.response?.data?.message ||
      error?.response?.erros ||
      error.response?.mensagem;
    notification.warning({ message });
  };

  const changeStoreCashStatus = async () => {
    try {
      setUpdatingStoreCashStatus(true);
      if (storeCash) {
        await odinApi.put(
          `/store_cashes/${selectedStore}-${storeCash.code}/close`,
          {
            amount_on_close: "0",
          }
        );

        window.localStorage.removeItem(storagePrefix);
        setStoreCash(null);
        setOpenStoreCashStatus(false);
        return notification.success({
          message: "Validações encerradas com sucesso",
        });
      } else {
        if (storeCashStatus.filter((sc) => !sc.is_opended).length <= 0) {
          return notification.warning({
            message: "Esta loja não possui nenhum caixa para abertura",
          });
        }
        const code = storeCashStatus.filter((sc) => !sc.is_opended)[0].code;
        const {
          data: {
            data: { cash_id, history_id, store_id },
          },
        } = await odinApi.put(`/store_cashes/${selectedStore}-${code}/open`, {
          amount_on_open: "0",
        });

        window.localStorage.setItem(
          storagePrefix,
          JSON.stringify({ code, cash_id, history_id, store_id })
        );
        setStoreCash({
          cash_id,
          history_id,
          code,
        });
        setOpenStoreCashStatus(false);
        return notification.success({
          message: "Validações iniciadas com sucesso",
        });
      }
    } catch (error) {
      formatAndEmitErrorMessages(error);
    } finally {
      setUpdatingStoreCashStatus(false);
    }
  };

  const onFetchVoucher = async () => {
    try {
      setLoadingVoucher(true);
      setHasSearched(true);
      const cupomPayload =
        cupom.first + cupom.second + cupom.third + cupom.fourth;
      if (cupomPayload.length !== 8) {
        return notification.warning({
          message: "Digite o voucher corretamente",
        });
      }

      const { data } = await apiThor.get(
        `/customerVoucher/${selectedStore}?hash_code=${cupomPayload.toUpperCase()}`
      );
      notification.success({
        message: "Cupom encontrado com sucesso!",
        description: "Confirme o cupom no botão Validar para ele ser validado.",
      });
      setUserVoucher(data.content);
    } catch (error) {
      formatAndEmitErrorMessages(error);
    } finally {
      setLoadingVoucher(false);
      setHasSearched(false);
    }
  };

  const clearCupomState = () => {
    setCupom({
      first: "",
      second: "",
      third: "",
      fourth: "",
    });
    setUserVoucher(null);
    const previousInput = document.getElementsByName("first")[0];
    previousInput?.focus();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const salePayload = {
        store_id: selectedStore,
        cash_history_id: storeCash?.history_id,
        cash_id: storeCash?.cash_id,
        user_id: getTokenInfo()?.id,
        quantity: 0,
        change_amount: 0,
        type: 0,
        discount: 0,
        ref: v4(),
        items: [],
        payments: [],
        created_at: moment(new Date()).subtract(3, "hours").toISOString(),
        customerVoucher: userVoucher,
        formated_type: "LOJA",
        abstract_sale: false,
        gv_id: null,
      };

      await apiThor.put(
        `/customerVoucher/mark-as-used/${salePayload.customerVoucher.id}`
      );
      await apiMidas.post("/sales", [salePayload]);

      const { data } = await apiThor.get(
        `/company/company/${selectedStore}/the-best-day`
      );
      setCompanyVoucher({
        voucher: data.content.voucher,
        customers: data.content.customers,
      });

      const hashCode = salePayload.customerVoucher.hash_code;

      notification.success({
        message: "Cupom resgatado com sucesso!",
        description: (
          <div>
            O cupom <strong style={{ color: "green" }}>{hashCode}</strong> foi
            resgatado com sucesso!
          </div>
        ),
      });
      clearCupomState();
    } catch (error) {
      formatAndEmitErrorMessages(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCupomState = (value: string, name: string): void => {
    setCupom((oldValues) => ({ ...oldValues, [name]: value }));
    const input = document.getElementsByName(name)[0];
    //@ts-ignore
    if (input?.value?.toString()?.length === 2) {
      const inputs = [
        { name: "first", value: 1 },
        { name: "second", value: 2 },
        { name: "third", value: 3 },
        { name: "fourth", value: 4 },
      ];
      const currentInputIndex = inputs.findIndex((inp) => inp.name === name);
      const nextInputValue =
        inputs[currentInputIndex].value + 1 === 5
          ? 4
          : inputs[currentInputIndex].value + 1;
      const nextInput = document.getElementsByName(
        inputs.find((inp) => inp.value === nextInputValue)?.name as string
      )[0];
      nextInput.focus();
    }
  };

  const handleKeyDown = (key: string, name: string) => {
    if (key === "Backspace") {
      const inputs = [
        { name: "first", value: 1 },
        { name: "second", value: 2 },
        { name: "third", value: 3 },
        { name: "fourth", value: 4 },
      ];
      const currentInputIndex = inputs.findIndex((inp) => inp.name === name);

      const input = document.getElementsByName(name)[0];
      //@ts-ignore
      if (input.value.toString().length === 0) {
        const previousInputValue =
          +inputs[currentInputIndex].value - 1 === 1
            ? 1
            : +inputs[currentInputIndex].value - 1;
        const previousInput = document.getElementsByName(
          inputs.find((inp) => inp.value === previousInputValue)?.name as string
        )[0];
        previousInput?.focus();
      }
    }
  };

  const calculateVoucherPercentages = () => {
    if (!companyVoucher || !companyVoucher.voucher) {
      console.error(
        "companyVoucher ou companyVoucher.voucher não está definido"
      );
      return {
        redeemedPercentage: "0",
        totalRestPercentage: "0",
        redeemedVouchers: 0,
        totalRest: 0,
      };
    }

    const total = companyVoucher.voucher.quantity;
    const redeemedVouchers = companyVoucher.customers.length;
    const totalRest = total - redeemedVouchers;
    const usedVouchers = companyVoucher.customers.filter(
      (customerVoucher) => customerVoucher.voucher_used
    ).length;

    const redeemedPercentage = ((redeemedVouchers / total) * 100).toFixed(1);
    const totalRestPercentage = ((totalRest / total) * 100).toFixed(1);
    const usedVouchersPercentage = (
      (usedVouchers / +redeemedVouchers) *
      100
    ).toFixed(1);

    return {
      redeemedPercentage,
      totalRestPercentage,
      redeemedVouchers,
      totalRest,
      usedVouchers,
      usedVouchersPercentage,
    };
  };

  const {
    redeemedPercentage,
    totalRestPercentage,
    redeemedVouchers,
    totalRest,
    usedVouchers,
    usedVouchersPercentage,
  } = calculateVoucherPercentages();

  const maskCode = (code, isUsed) => {
    return isUsed ? code : code.replace(/X/g, "•");
  };

  const columns = [
    {
      title: "Hash",
      dataIndex: "hash_code",
      key: "hash_code",
      render: (text, record) => (
        <strong>{maskCode(record.hash_code, record.voucher_used)}</strong>
      ),
    },
    {
      title: "Usado",
      dataIndex: "voucher_used",
      key: "voucher_used",
      render: (text, record) => (
        <span
          style={{
            color: record.voucher_used ? "#00cd00" : "#d71818",
          }}
        >
          <strong>{record.voucher_used ? "SIM" : "NÃO"}</strong>
        </span>
      ),
    },
    {
      title: "Data de Resgate",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD/MM/YYYY HH:mm"),
    },
  ];

  const dataSource =
    companyVoucher?.customers
      ?.sort(
        (elemA, elemB) =>
          moment(elemB.created_at).unix() - moment(elemA.created_at).unix()
      )
      .map((customer, index) => ({
        key: index,
        hash_code: customer.hash_code,
        voucher_used: customer.voucher_used,
        created_at: customer.created_at,
      })) || [];

  return (
    <PageContainer route="Cupons">
      <Container>
        <ActionsContent>
          {!storeCash ? (
            <>
              <StoreSelectionContainer>
                <GetStore
                  defaultValue={selectedStore}
                  handleChange={(storeId) => {
                    setSelectedStore(storeId);
                    setStoreCashStatus([]);
                    setCompanyVoucher(null);
                    setUserVoucher(null);
                  }}
                />
              </StoreSelectionContainer>
            </>
          ) : (
            <></>
          )}
          {selectedStore ? (
            <OpenCloshStoreCashContainer>
              <Button onClick={() => setOpenStoreCashStatus(true)}>
                {storeCash
                  ? `Encerrar validações  [${storeCash.code.toUpperCase()}]`
                  : "Iniciar validações"}
              </Button>
            </OpenCloshStoreCashContainer>
          ) : (
            <></>
          )}
        </ActionsContent>
        <Content>
          <>
            {loading ? (
              <AlignCenter>
                <Spin src={SpinIcon} />
              </AlignCenter>
            ) : (
              <>
                {!selectedStore ? (
                  <AlignCenter>
                    <Empty description="Nenhuma loja selecionada" />
                  </AlignCenter>
                ) : (
                  <>
                    {!storeCash ? (
                      <>
                        <AlignCenter>
                          <Empty description="Clique para iniciar as validações para começar a validar os cupons." />
                        </AlignCenter>
                      </>
                    ) : !loadingCompanyVoucher ? (
                      <>
                        {companyVoucher ? (
                          <ContainerMain>
                            <CompanyVoucherContainer>
                              <CupomProgressContainer>
                                <strong>
                                  {companyVoucher.voucher.name.toUpperCase()}
                                </strong>
                              </CupomProgressContainer>
                              <ProgressContainer>
                                <ProgressLabelsContainer>
                                  <LabelWithCount>
                                    <span>Disponíveis</span>
                                    <Count color="var(--midori-green)">
                                      {totalRest}
                                    </Count>
                                  </LabelWithCount>
                                  <LabelWithCount>
                                    {" "}
                                    <span>Resgatados</span>
                                    <Count color="var(--orange-600)">
                                      {redeemedVouchers}
                                    </Count>
                                  </LabelWithCount>
                                  <LabelWithCount>
                                    <span>Usados</span>
                                    <Count color="#4ec1ed">
                                      {usedVouchers}
                                    </Count>
                                  </LabelWithCount>
                                </ProgressLabelsContainer>
                              </ProgressContainer>
                            </CompanyVoucherContainer>
                            <RedeemVoucherContainer>
                              {loadingVoucher ? (
                                <AlignCenter>
                                  <Spin src={SpinIcon} />
                                </AlignCenter>
                              ) : (
                                <>
                                  <VoucherContainer>
                                    <div className="voucher-title">
                                      <span>
                                        Digite o código do cupom abaixo para a
                                        busca:
                                      </span>
                                    </div>
                                    <div className="voucher-content">
                                      {loadingVoucher ? (
                                        <AlignCenter>
                                          <Spin src={SpinIcon} />
                                        </AlignCenter>
                                      ) : userVoucher ? (
                                        <CustomerVoucherContent>
                                          <div className="customer_voucher_title">
                                            {userVoucher.voucher.name}
                                          </div>
                                          <img
                                            className="customer_voucher_image"
                                            src={userVoucher.voucher.url_image}
                                          />
                                        </CustomerVoucherContent>
                                      ) : (
                                        hasSearched && (
                                          <AlignCenter>
                                            <Empty description="Nenhum voucher encontrado" />
                                          </AlignCenter>
                                        )
                                      )}
                                    </div>
                                    <ContainerSearch>
                                      <Row justify="center">
                                        <Col sm={5} xs={5}>
                                          <InputCode
                                            value={cupom.first}
                                            maxLength={2}
                                            name="first"
                                            onChange={({ target: { value } }) =>
                                              handleCupomState(value, "first")
                                            }
                                            onKeyDown={({ key }) =>
                                              handleKeyDown(key, "first")
                                            }
                                            autoFocus
                                            tabIndex={1}
                                          />
                                        </Col>
                                        <Col sm={5} xs={5}>
                                          <InputCode
                                            value={cupom.second}
                                            maxLength={2}
                                            name="second"
                                            onKeyDown={({ key }) =>
                                              handleKeyDown(key, "second")
                                            }
                                            onChange={({ target: { value } }) =>
                                              handleCupomState(value, "second")
                                            }
                                            tabIndex={2}
                                          />
                                        </Col>
                                        <Col sm={5} xs={5}>
                                          <InputCode
                                            value={cupom.third}
                                            maxLength={2}
                                            name="third"
                                            onKeyDown={({ key }) =>
                                              handleKeyDown(key, "third")
                                            }
                                            onChange={({ target: { value } }) =>
                                              handleCupomState(value, "third")
                                            }
                                            tabIndex={3}
                                          />
                                        </Col>
                                        <Col sm={5} xs={5}>
                                          <InputCode
                                            value={cupom.fourth}
                                            maxLength={2}
                                            name="fourth"
                                            onKeyDown={({ key }) =>
                                              handleKeyDown(key, "fourth")
                                            }
                                            onChange={({ target: { value } }) =>
                                              handleCupomState(value, "fourth")
                                            }
                                            tabIndex={4}
                                          />
                                        </Col>
                                      </Row>
                                      <div className="voucher-action">
                                        {userVoucher ? (
                                          <ButtonSave
                                            onClick={onFinish}
                                            loading={loading}
                                          >
                                            Validar
                                          </ButtonSave>
                                        ) : (
                                          <ButtonSave
                                            onClick={onFetchVoucher}
                                            loading={loading}
                                            disabled={
                                              !cupom.first &&
                                              !cupom.second &&
                                              !cupom.third &&
                                              !cupom.fourth
                                            }
                                          >
                                            Buscar
                                          </ButtonSave>
                                        )}
                                        <ButtonCancel
                                          onClick={() => clearCupomState()}
                                        >
                                          {userVoucher ? "Cancelar" : "Limpar"}
                                        </ButtonCancel>
                                      </div>
                                    </ContainerSearch>
                                  </VoucherContainer>
                                  <VoucherHistoryContainer>
                                    <Table
                                      title={() => <h3>Histórico de Cupons</h3>}
                                      columns={columns}
                                      dataSource={dataSource}
                                      pagination={false}
                                      bordered
                                    />
                                  </VoucherHistoryContainer>
                                </>
                              )}
                            </RedeemVoucherContainer>
                          </ContainerMain>
                        ) : (
                          <>
                            <AlignCenter>
                              <Empty description="Nenhum voucher encontrado" />
                            </AlignCenter>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <AlignCenter>
                          <Spin src={SpinIcon} />
                        </AlignCenter>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Content>
      </Container>
      <Modal
        visible={openStoreCashStatus}
        closable={false}
        onCancel={() => setOpenStoreCashStatus(false)}
        cancelText="Cancelar"
        okText={storeCash ? "Encerrar validações" : "Iniciar validações"}
        onOk={changeStoreCashStatus}
        confirmLoading={updatingStoreCashStatus}
      >
        <ChangeStoreCashStatusTitle>
          {storeCash ? "Encerramento de validações" : "Iniciar validações"}
        </ChangeStoreCashStatusTitle>
        {storeCash ? (
          <>
            <div>Para encerrar as validações, clique no botão abaixo.</div>
          </>
        ) : (
          <>
            {storeCashStatus.length ? (
              <>
                <div>
                  A loja possui{" "}
                  <strong>
                    {storeCashStatus.filter((sc) => !sc.is_opended).length}
                  </strong>{" "}
                  caixas disponíveis. Clique no botão abaixo para iniciar as
                  validações.
                </div>
              </>
            ) : (
              <>
                <AlignCenter>
                  <Spin src={SpinIcon} />
                </AlignCenter>
              </>
            )}
          </>
        )}
      </Modal>
    </PageContainer>
  );
};

export default Cupom;
