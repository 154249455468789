export const currencyFormaterPYGBRL = (
  amount: number | undefined,
  currency: string
): string => {
  const decimalPlaces = currency === "PYG" ? 0 : 2;
  const formattedAmount = (amount || 0)
    .toFixed(decimalPlaces)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return formattedAmount;
};
