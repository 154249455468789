import React from "react";

import PageContainer from "../../containers/PageContainer";

import { Container } from "./styles";
import HomeImg from "../../assets/svg/homePage.svg";

const Home: React.FC = () => {
  return (
    <PageContainer route="Página Inicial - Dashboard">
      <Container>
        <img src={HomeImg} alt="" />
      </Container>
    </PageContainer>
  );
};

export default Home;
