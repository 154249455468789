import styled, { css } from 'styled-components';
import { 
  Button as ButtonAnt,
  Select as SelectAnt,
  Input as InputAnt,
  DatePicker as DatePickerAnt,
 } from 'antd'
import { FiletypePdf, InfoCircle, TrashIconA } from '../../../styles/Icons';

export const Select = styled(SelectAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selector {
    height: 2.5rem !important;
  }
`;

export const Input = styled(InputAnt)`
  width: 100%;
  height: 2.5rem !important;
  border-radius: 5px !important;

  .ant-input {
    height: 1.9rem !important;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
  height: 2.5rem !important;

  .ant-picker-input > input {
    font-weight: 400 !important;
    font-size: .9rem !important;
  }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 578px) {
    width: 100%;
    overflow-y: scroll;
  }
`;

export const ContentFiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentFilesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: .5rem;

  padding: .5rem;

  &:hover {
    background-color: var(--white-100); 
  }
`;

export const AddNewSupplier = styled.button`
    background-color: transparent;
    border: none;

    color: var(--gray-45);
    font-size: .9rem;

    margin-bottom: 1rem;
`;

export const Title = styled.span`
    color: var(--gray-45);
    font-size: 1.2rem;
    text-transform: capitalize;
    margin-top: 3rem;
`;

const ButtonCSS = css`
  font-weight: 500;
  font-size: 1rem;
  width: 7rem;
  height: 3rem;
  border-radius: 5px;
`;

export const ButtonCancel = styled.button`
  ${ButtonCSS}
  color: var(--gray-650);

  :hover {
    background-color: var(--white-100);
  }
`;

export const ContentPDF = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: .8rem;
    color: var(--gray-600);
    font-size: .9rem;
  }
`;

export const ContentImage = styled.img`
  width: 10rem;
  height: 10rem; 
  margin: .5rem;
`;

export const ButtonSave = styled(ButtonAnt)`
  ${ButtonCSS}
  color: white;
  background-color: var(--orange-350);
  transition: 0.2s;

  :hover,
  :active,
  :focus {
    background-color: var(--orange-600);
    border: 1px solid var(--orange-600);
    color: white;
  }
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  width: 15px;
  height: 15px;
  margin-left: 5px;

  cursor: pointer;
  color: var(--c64-blue);
`;

export const TrashIconUpload = styled(TrashIconA)`
    width: 1rem;
    height: 1rem;
    color: var(--maroon);
    cursor: pointer;
`;

export const FiletypePdfIcon = styled(FiletypePdf)`
  width: 25px;
  height: 25px;

  cursor: pointer;
  color: var(--gray-200);
`;

export const UploadWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;

  input[type="file"]::file-selector-button {
    border: none;
    background: var(--orange-gradient);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 100%;
  }
`;